import React from 'react';

const ViewDetailsIcon = ({ size = 16, className = 'text-dark-gray' }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.387 8A2.384 2.384 0 018 10.387 2.384 2.384 0 015.613 8 2.384 2.384 0 018 5.613 2.384 2.384 0 0110.387 8z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 13.514c2.353 0 4.547-1.387 6.073-3.787.6-.94.6-2.52 0-3.46C12.547 3.867 10.353 2.48 8 2.48S3.453 3.867 1.927 6.267c-.6.94-.6 2.52 0 3.46 1.526 2.4 3.72 3.787 6.073 3.787z"
        />
      </svg>
    </span>
  );
};

export default ViewDetailsIcon;
