import React from 'react';

const AddToServiceIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.67 7.718a.771.771 0 00-.128-.17l-.573-.566a.676.676 0 00-.205-.143m.905.88a.68.68 0 01-.12.787c-.202.199-.559.559-1.196 1.185-1.24 1.226-3.336 3.559-4.25 4.633a.504.504 0 01-.74.027L1.144 13.12a.5.5 0 01.034-.735c1.054-.888 3.385-2.969 4.64-4.21.656-.649 1.02-1.006 1.218-1.201a.654.654 0 01.728-.134m.905.88c.436-.423 1.572-1.361 1.876-1.548.304-.188.55-.24.667-.253.231-.023.464.028.665.144.036.02.068.045.097.074.202.205.19.54-.016.742l-.062.06a.172.172 0 000 .244l1.072 1.063a.172.172 0 00.125.05.176.176 0 00.125-.05L14.95 6.53a.182.182 0 000-.25l-1.072-1.063a.17.17 0 00-.125-.05.175.175 0 00-.125.05.357.357 0 01-.299.103c-.14-.016-.289-.06-.385-.152-.219-.215.034-.637-.158-.907a7.593 7.593 0 00-.722-.835c-.22-.218-1.088-1.046-2.548-1.641a3.869 3.869 0 00-1.469-.289c-.823 0-1.456.368-1.687.579-.184.166-.375.43-.375.43a2.486 2.486 0 011.065-.147c.486.04 1.072.269 1.386.516.507.406.725.95.759 1.65.025.522-.957 1.777-1.432 2.314"
        />
      </svg>
    </span>
  );
};

export default AddToServiceIcon;
