/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useSelector } from "react-redux";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { CompletedTripInfoCard } from "../Data/TripDetailData";
import TrikeChanged from "./TrikeChanged";

const Label = ({ text }) => {
  return (
    <p className="font-normal text-10 font-aileron text-dark-gray">{text}</p>
  );
};

const Value = ({ text, redirect, onClick }) => {
  return (
    <div
      className={`flex items-center mt-2 gap-x-2 ${
        redirect && "cursor-pointer"
      }`}
      onClick={onClick}
    >
      <p className="text-xs font-semibold text-black capitalize font-aileron">
        {text}
      </p>
      {redirect ? <ChevronIcon className="w-4 h-4 stroke-dark-gray" /> : null}
    </div>
  );
};

const CompletedTripInformation = () => {
  const { overallSummaryForTrip } = useSelector((state) => state.trips);
  const { currentTrike, checklist } = useSelector((state) => state.trikes);

  const checklistPassed =
    currentTrike && currentTrike.checklist
      ? currentTrike.checklist.filter((item) => item.passed === 1)
      : [];

  return (
    <div className="w-full h-full bg-white p-4 border-[0.5px] border-light-grey rounded-md">
      <div className="flex justify-between">
        <div className="flex items-center gap-x-2.5">
          <CustomRoundedImage
            background="bg-primary"
            image={<TrikeIcon size={16} className="text-white" />}
          />

          <p className="text-base font-medium capitalize font-poppins">
            Trip Info
          </p>

          {overallSummaryForTrip?.previousTrikeId ? <TrikeChanged /> : null}
        </div>

        <div className="bg-background rounded-md p-2 flex items-center gap-x-2">
          <img src="/assets/info-circle.svg" alt="Speed Limit" />
          <p className="font-poppins text-xs font-medium text-dark-gray">
            Max. Speed {currentTrike?.currentSpeed} Km/Hr
          </p>
        </div>
      </div>

      <div className="mt-6 ml-12 flex gap-x-24">
        {CompletedTripInfoCard(
          overallSummaryForTrip,
          currentTrike,
          checklistPassed.length,
          checklist.length,
        )
          .slice(0, 4)
          .map((item) => (
            <div className="w-[137px]" key={item.id}>
              <Label text={item.label} />

              <Value
                id={item.id}
                text={item.value}
                redirect={item.redirect}
                onClick={item?.onClick}
              />
            </div>
          ))}
      </div>

      <div className="mt-6 ml-12 flex gap-x-24">
        {CompletedTripInfoCard(
          overallSummaryForTrip,
          currentTrike,
          checklistPassed.length,
          checklist.length,
        )
          .slice(4)
          .map((item) => (
            <div className="w-[137px]" key={item.id}>
              <Label text={item.label} />

              <Value
                id={item.id}
                text={item.value}
                redirect={item.redirect}
                onClick={item?.onClick}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default CompletedTripInformation;
