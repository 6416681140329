const renderErrorStateForStepHeader = (
  step,
  validationForSteps,
  setErrorStates,
) => {
  switch (step.id) {
    case 1:
      if (
        validationForSteps?.step1?.stopLength ||
        validationForSteps?.step1?.location
      ) {
        setErrorStates({
          showErrorIcon: true,
          backgroundColorForStep: true,
          textColorForTitle: true,
          textColorForDescription: true,
        });
      } else {
        setErrorStates({
          showErrorIcon: false,
          backgroundColorForStep: false,
          textColorForTitle: false,
          textColorForDescription: false,
        });
      }
      break;
    case 2:
      if (
        validationForSteps?.step2?.timeSlot ||
        validationForSteps?.step2?.startDate
      ) {
        setErrorStates({
          showErrorIcon: true,
          backgroundColorForStep: true,
          textColorForTitle: true,
          textColorForDescription: true,
        });
      } else {
        setErrorStates({
          showErrorIcon: false,
          backgroundColorForStep: false,
          textColorForTitle: false,
          textColorForDescription: false,
        });
      }
      break;
    case 3:
      if (validationForSteps.step3) {
        setErrorStates({
          showErrorIcon: true,
          backgroundColorForStep: true,
          textColorForTitle: true,
          textColorForDescription: true,
        });
      } else {
        setErrorStates({
          showErrorIcon: false,
          backgroundColorForStep: false,
          textColorForTitle: false,
          textColorForDescription: false,
        });
      }
      break;
    default:
      setErrorStates({
        showErrorIcon: false,
        backgroundColorForStep: false,
        textColorForTitle: false,
        textColorForDescription: false,
      });
  }
};

export default renderErrorStateForStepHeader;
