/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client as axios } from '../../Utils/axiosClient';

export const getServiceDetails = createAsyncThunk(
  'batteries/getServiceDetails',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/service-details`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getServicesFromODK = createAsyncThunk(
  'services/getServicesFromODK',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/odk/get-services-from-odk`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// phase 3
export const getServicesV3 = createAsyncThunk(
  'services/getServicesV3',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/service/get-services`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addServiceV3 = createAsyncThunk(
  'services/addServiceV3',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/service/add-service`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateServiceV3 = createAsyncThunk(
  'services/updateServiceV3',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/service/edit-service`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const completeServiceV3 = createAsyncThunk(
  'services/completeServiceV3',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/service/complete-service`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
