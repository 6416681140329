import React from "react";

const AssetsIcon = ({ size = 20, className, fillColor }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          fill={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.133 5.142a7.191 7.191 0 013.05 5.125M2.908 10.308a7.168 7.168 0 013-5.125M6.824 17.45a7.105 7.105 0 003.225.767c1.117 0 2.167-.25 3.109-.709M10.05 6.417a2.317 2.317 0 10-.001-4.634 2.317 2.317 0 000 4.634zM4.026 16.6a2.317 2.317 0 100-4.633 2.317 2.317 0 000 4.633zM15.975 16.6a2.317 2.317 0 100-4.633 2.317 2.317 0 000 4.633z"
        />
      </svg>
    </span>
  );
};

export default AssetsIcon;
