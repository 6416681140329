/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { Row, Col } from "antd";
import { useForm } from "react-hook-form";
import { CustomInputWithController } from "../../Core/Components/CustomInput";
import Button from "../../Core/Components/CustomButton";
import { updateLoggedInUser } from "../../Redux/Slices/loggedInUser.slice";
import { client as axios } from "../../Utils/axiosClient";
import BlockedEyeIcon from "../../Common/Svgs/BlockedEyeIcon";

const SetPasswordResetScreen = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { handleSubmit, control, formState, setError, getValues } = useForm({
    newPassword: "",
    confirmNewPassword: "",
  });

  const localNewPassword = getValues("newPassword");
  const localConfirmPassword = getValues("confirmNewPassword");

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPasswordFocus, setNewPasswordFocus] = useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  const [isValidToken, setIsValidToken] = useState(true);

  const validationForPassword = {
    required: "please enter password",
    minLength: {
      value: 8,
      message: "less characters for password",
    },
    pattern: {
      value: /^(?!.* )/,
      message: "Whitespace is not allowed",
    },
  };

  const verifyToken = (token) => {
    const decodedToken = jwtDecode(token);
    const expirationTime = new Date(decodedToken.exp);
    const currentTime = new Date();

    const isValid = expirationTime * 1000 > currentTime.getTime();

    setIsValidToken(isValid);
  };

  const onSubmit = async (e) => {
    const { newPassword, confirmNewPassword } = e;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/reset-password`,
        {
          token: currentToken,
          newPassword,
          confirmNewPassword,
        },
      );

      if (response.data.token) {
        const { message, token, ...rest } = response.data;

        dispatch(updateLoggedInUser({ key: "userDetails", value: rest }));
        localStorage.setItem("token", response.data.token);

        navigate("/home");
      }
    } catch (error) {
      setError("confirmNewPassword", {
        type: "server",
        message: error.response.data.message,
      });
    }
  };

  useEffect(() => {
    const token = searchParams.get("token");
    setCurrentToken(token);
    verifyToken(token);
  }, []);

  return (
    <div className="w-screen h-screen ">
      <Row className="h-full">
        <Col span={15} className="h-full overflow-hidden ">
          <img
            src="/assets/images/Login/login-image.svg"
            alt="LoginImage"
            className="object-cover w-full h-full"
          />
        </Col>
        {isValidToken ? (
          <Col span={9}>
            <div className="flex flex-col items-center justify-center w-full h-full gap-y-6 font-poppins">
              <img
                src="/assets/songa-main-logo.svg"
                alt="Songa Mobility"
                className="w-[258px] h-14"
              />
              <div>
                <div className="text-base font-medium text-center capitalize">
                  Reset password
                </div>
                <div className="mt-2 text-xs font-light text-dark-gray">
                  Enter new password to continue
                </div>
              </div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col items-center justify-center w-full gap-y-6"
              >
                <CustomInputWithController
                  intent="login"
                  control={control}
                  formState={formState}
                  focus={newPasswordFocus}
                  setFocus={setNewPasswordFocus}
                  inputType={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  placeholder="Enter New Password"
                  rules={validationForPassword}
                  suffix={
                    <button
                      type="button"
                      onClick={() => {
                        if (localNewPassword) {
                          setShowNewPassword(!showNewPassword);
                        }
                      }}
                      disabled={!localNewPassword}
                    >
                      {showNewPassword ? (
                        <img src="/images/eye.png" className="w-5 h-5" alt="" />
                      ) : (
                        <BlockedEyeIcon
                          className={
                            localNewPassword ? "text-black" : "text-dark-gray"
                          }
                        />
                      )}
                    </button>
                  }
                />

                <CustomInputWithController
                  intent="login"
                  control={control}
                  formState={formState}
                  focus={confirmPasswordFocus}
                  setFocus={setConfirmPasswordFocus}
                  inputType={showConfirmPassword ? "text" : "password"}
                  name="confirmNewPassword"
                  placeholder="Confirm New Password"
                  rules={validationForPassword}
                  suffix={
                    <button
                      type="button"
                      onClick={() => {
                        if (localConfirmPassword) {
                          setShowConfirmPassword(!showConfirmPassword);
                        }
                      }}
                      disabled={!localConfirmPassword}
                    >
                      {showConfirmPassword ? (
                        <img src="/images/eye.png" className="w-5 h-5" alt="" />
                      ) : (
                        <BlockedEyeIcon
                          className={
                            localConfirmPassword
                              ? "text-black"
                              : "text-dark-gray"
                          }
                        />
                      )}
                    </button>
                  }
                />

                <Button width="login" type="submit">
                  Reset password
                </Button>
                <button
                  type="button"
                  onClick={() => navigate("/")}
                  className="cursor-pointer font-poppins font-light text-xs leading-4.5"
                >
                  Back to Login
                </button>
              </form>
            </div>
          </Col>
        ) : (
          <Col span={9}>
            <div className="flex flex-col items-center justify-center w-full h-full gap-y-6 font-poppins">
              <img
                src="/assets/songa-main-logo.svg"
                alt="Songa Mobility"
                className="w-[258px] h-14"
              />
              <div>
                <div className="text-base font-medium text-center capitalize text-alert-red">
                  Token has expired
                </div>
                <button
                  type="button"
                  onClick={() => navigate("/reset-password")}
                  className="mt-4 flex justify-center w-full font-poppins font-light text-xs leading-4.5 text-dark-grey-text hover:text-black hover:underline"
                >
                  Back to Reset Password
                </button>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default SetPasswordResetScreen;
