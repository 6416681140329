/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
/* eslint-disable no-case-declarations */
// import { updateTrips } from '../../../Redux/Slices/trips.slice';
// import { useDispatch } from 'react-redux';

const checkValidaton = (
  currentStep,
  stops,
  dispatch,
  tripDetails,
  updateTrips,
  validationForSteps,
) => {
  switch (currentStep) {
    case 1:
      // if the stop length is 2 (only source and destination), throw a validation error
      if (stops?.length === 2) {
        dispatch(
          updateTrips({
            key: "validationForSteps",
            value: {
              ...validationForSteps,
              step1: {
                ...validationForSteps.step1,
                stopLength: true,
              },
            },
          }),
        );
        return false;
      } else {
        // if the stop exists, throw a validation error
        const hasStopLocation = stops?.some((st) => st.location === "Stop");
        const isActivityEmpty = stops
          ?.slice(1, stops.length - 1)
          .map((st) => {
            if (st.activities.length === 0) {
              return st.id;
            }
            return null;
          })
          .filter((item) => item !== null);

        const conditions = {};

        if (hasStopLocation) {
          conditions.location = true;
        }

        if (isActivityEmpty.length > 0) {
          conditions.activity = [...isActivityEmpty];
        }

        if (hasStopLocation || isActivityEmpty.length > 0) {
          dispatch(
            updateTrips({
              key: "validationForSteps",
              value: {
                ...validationForSteps,
                step1: {
                  ...validationForSteps.step1,
                  ...conditions,
                },
              },
            }),
          );
          return false;
        }
      }
      break;
    case 2:
      const isTimeSlotValid = tripDetails?.timeSlot !== null;
      const isStartDateValid = tripDetails?.startDate !== null;
      const isDaysOfOccurence = tripDetails?.daysOfOccurence.length;
      const isEndDateValid = tripDetails?.endDate !== null;

      // if any of startDate, endDate, timeSlot, daysOfOccurence is null or empty
      // throw a validation error

      const updatedValidation2 = {
        ...validationForSteps.step2,
        timeSlot: !isTimeSlotValid,
        startDate: !isStartDateValid,
        daysOfOccurence: !isDaysOfOccurence,
        endDate: !isEndDateValid,
      };

      dispatch(
        updateTrips({
          key: "validationForSteps",
          value: {
            ...validationForSteps,
            step2: updatedValidation2,
          },
        }),
      );

      if (tripDetails?.occurence) {
        return (
          isTimeSlotValid &&
          isStartDateValid &&
          isDaysOfOccurence &&
          isEndDateValid
        );
      }

      return isTimeSlotValid && isStartDateValid;

    case 3:
      if (tripDetails?.driverName === "") {
        dispatch(
          updateTrips({
            key: "validationForSteps",
            value: { ...validationForSteps, step3: true },
          }),
        );
        return false;
      }
      break;
    default:
      break;
  }

  return true;
};

export default checkValidaton;
