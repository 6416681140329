/* eslint-disable import/prefer-default-export */
import { PERMISSIONS } from "../../Login/constants";

// passing null in options array (wherever required) in order to maintain the layout consistency of the UI

export const trikePermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire module.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.TRIKE_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.TRIKE_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.TRIKE_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify Trikes",
    description: "Manage how this person can modify trikes.",
    options: [
      {
        text: "Add",
        permissionId: PERMISSIONS.TRIKE_ADD,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.TRIKE_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.TRIKE_DELETE,
      },
    ],
  },
  {
    id: 3,
    title: "Trike Override actions",
    description:
      "Manage how this person can perform manual overrides for trikes.",
    options: [
      {
        text: "Dispatch/Move to parking lot",
        permissionId: PERMISSIONS.TRIKE_DISPATCH_AND_MOVE,
      },
      {
        text: "Tracker Switch",
        permissionId: PERMISSIONS.TRACKER_SWITCH,
      },
      {
        text: "Mobilise/Immobilise",
        permissionId: PERMISSIONS.TRIKE_MOBILISE,
      },
    ],
  },
  {
    id: 4,
    title: "Checklist",
    description: "Manage how this person can manage checklist for all trikes.",
    options: [
      {
        text: "Add",
        permissionId: PERMISSIONS.TRIKE_CHECKLIST_ADD,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.TRIKE_CHECKLIST_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.TRIKE_CHECKLIST_DELETE,
      },
    ],
  },
  {
    id: 5,
    title: "Geofence",
    description: "Manage how this person can manage the geofence of trikes.",
    options: [
      {
        text: "Add",
        permissionId: PERMISSIONS.TRIKE_GEOFENCE_ADD,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.TRIKE_GEOFENCE_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.TRIKE_GEOFENCE_DELETE,
      },
    ],
  },
  {
    id: 6,
    title: "Trike Issues",
    description: "Manage how this person can modify trike issues.",
    options: [
      {
        text: "Add",
        permissionId: PERMISSIONS.TRIKE_ISSUE_ADD,
      },
      {
        text: "View",
        permissionId: PERMISSIONS.TRIKE_ISSUE_VIEW,
      },
      null,
    ],
  },
  {
    id: 7,
    title: "Add to Service",
    description: "Select if this person can add trikes to service.",
    options: [
      {
        text: "Yes",
        permissionId: PERMISSIONS.TRIKE_ADD_TO_SERVICE,
      },
      {
        text: "No",
        permissionId: PERMISSIONS.TRIKE_NO_SERVICE,
      },
      null,
    ],
  },
  {
    id: 8,
    title: "View QR Codes",
    description: "Select if this person can view QR codes of all trikes.",
    options: [
      {
        text: "Yes",
        permissionId: PERMISSIONS.TRIKE_VIEW_QR,
      },
      {
        text: "No",
        permissionId: PERMISSIONS.TRIKE_NO_QR,
      },
      null,
    ],
  },
];

export const batteryPermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire module.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.BATTERY_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.BATTERY_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.BATTERY_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify Batteries",
    description: "Manage how this person can modify batteries.",
    options: [
      {
        text: "Add",
        permissionId: PERMISSIONS.BATTERY_ADD,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.BATTERY_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.BATTERY_DELETE,
      },
    ],
  },
  {
    id: 3,
    title: "Request Batteries",
    description: "Manage how this person can request/accept batteries.",
    options: [
      {
        text: "Yes",
        permissionId: PERMISSIONS.BATTERY_REQUEST,
      },
      {
        text: "No",
        permissionId: PERMISSIONS.BATTERY_NO_REQUEST,
      },
      null,
    ],
  },
  {
    id: 4,
    title: "Add to Service",
    description: "Select if this person can add batteries to service.",
    options: [
      {
        text: "Yes",
        permissionId: PERMISSIONS.BATTERY_ADD_TO_SERVICE,
      },
      {
        text: "No",
        permissionId: PERMISSIONS.BATTERY_NO_SERVICE,
      },
      null,
    ],
  },
  {
    id: 5,
    title: "View QR Codes",
    description: "Select if this person can view QR codes of all batteriess.",
    options: [
      {
        text: "Yes",
        permissionId: PERMISSIONS.BATTERY_VIEW_QR,
      },
      {
        text: "No",
        permissionId: PERMISSIONS.BATTERY_NO_QR,
      },
      null,
    ],
  },
];

export const chargerPermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire module.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.CHARGER_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.CHARGER_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.CHARGER_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify Chargers",
    description: "Manage how this person can modify chargers.",
    options: [
      {
        text: "Add",
        permissionId: PERMISSIONS.CHARGER_ADD,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.CHARGER_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.CHARGER_DELETE,
      },
    ],
  },
];

export const tripPermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire module.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.TRIP_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.TRIP_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.TRIP_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify Trips",
    description: "Manage how this person can modify trips.",
    options: [
      {
        text: "Create",
        permissionId: PERMISSIONS.TRIP_CREATE,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.TRIP_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.TRIP_DELETE,
      },
    ],
  },
  {
    id: 3,
    title: "Trip Override Actions",
    description:
      "Manage how this person can perform manual overrides on trips.",
    options: [
      {
        text: "End Trip",
        permissionId: PERMISSIONS.TRIP_MANUAL_END,
      },
      null,
      null,
    ],
  },
  {
    id: 4,
    title: "View Stop Details",
    description: "Select if this person can view stop details for all trips.",
    options: [
      {
        text: "Yes",
        permissionId: PERMISSIONS.TRIP_STOP_DETAILS,
      },
      {
        text: "No",
        permissionId: PERMISSIONS.TRIP_NO_STOP_DETAILS,
      },
      null,
    ],
  },
];

export const driverPermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire section.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.DRIVER_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.DRIVER_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.DRIVER_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify Drivers",
    description: "Manage how this person can modify drivers.",
    options: [
      {
        text: "Add",
        permissionId: PERMISSIONS.DRIVER_ADD,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.DRIVER_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.DRIVER_DELETE,
      },
    ],
  },
  {
    id: 3,
    title: "Driver Override Actions",
    description:
      "Manage how this person can perform manual overrides on drivers.",
    options: [
      {
        text: "Suspend Driver",
        permissionId: PERMISSIONS.DRIVER_SUSPEND,
      },
      null,
      null,
    ],
  },
];

export const partnerPermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire section.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.PARTNER_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.PARTNER_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.PARTNER_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify Partner",
    description: "Manage how this person can modify partners.",
    options: [
      {
        text: "Add",
        permissionId: PERMISSIONS.PARTNER_ADD,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.PARTNER_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.PARTNER_DELETE,
      },
    ],
  },
  {
    id: 3,
    title: "View QR Codes",
    description: "Select if this person can view QR codes of all the partners.",
    options: [
      {
        text: "Yes",
        permissionId: PERMISSIONS.PARTNER_VIEW_QR,
      },
      {
        text: "No",
        permissionId: PERMISSIONS.PARTNER_NO_QR,
      },
      null,
    ],
  },
  {
    id: 4,
    title: "Export",
    description: "Select if this person can export partner data.",
    options: [
      {
        text: "Yes",
        permissionId: PERMISSIONS.PARTNER_EXPORT,
      },
      {
        text: "No",
        permissionId: PERMISSIONS.PARTNER_NO_EXPORT,
      },
      null,
    ],
  },
];

export const locationPermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire module.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.LOCATION_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.LOCATIONS_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.LOCATION_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify Locations",
    description: "Manage how this person can modify locations.",
    options: [
      {
        text: "Add",
        permissionId: PERMISSIONS.LOCATION_ADD,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.LOCATION_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.LOCATION_DELETE,
      },
    ],
  },
];

export const inventoryPermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire module.",
    options: [
      {
        text: "View only",
        permissionId: PERMISSIONS.INVENTORY_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.INVENTORY_NO_ACCESS,
      },
      null,
    ],
  },
];

export const servicePermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire module.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.SERVICE_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.SERVICE_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.SERVICE_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify Service Entries",
    description: "Manage how this person can modify service entries.",
    options: [
      {
        text: "Edit",
        permissionId: PERMISSIONS.SERVICE_EDIT,
      },
      {
        text: "Complete Service",
        permissionId: PERMISSIONS.SERVICE_COMPLETE,
      },
      null,
    ],
  },
];

export const generalSettingsPermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire section.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.SETTINGS_GENERAL_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.SETTINGS_GENERAL_VIEW,
      },
      null,
    ],
  },
  {
    id: 2,
    title: "Personalise Experience",
    description: "Select if this person can modify the logo & theme.",
    options: [
      {
        text: "Yes",
        permissionId: PERMISSIONS.SETTINGS_PERSONALISATION,
      },
      {
        text: "No",
        permissionId: PERMISSIONS.SETTINGS_NO_PERSONALISATION,
      },
      null,
    ],
  },
];

export const systemUserAndRolesPermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire section.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.SETTINGS_SYSTEM_USER_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify System Users",
    description: "Manage how this person can modify the system users.",
    options: [
      {
        text: "Create",
        permissionId: PERMISSIONS.SETTINGS_SYSTEM_USER_CREATE,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.SETTINGS_SYSTEM_USER_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.SETTINGS_SYSTEM_USER_DELETE,
      },
    ],
  },
  {
    id: 3,
    title: "Modify User Roles",
    description:
      "Manage how this person can modify the user roles & permissions.",
    options: [
      {
        text: "Create",
        permissionId: PERMISSIONS.SETTINGS_ROLE_CREATE,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.SETTINGS_ROLE_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.SETTINGS_ROLE_DELETE,
      },
    ],
  },
];

export const revenuePermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire section.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.REVENUE_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.REVENUE_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.REVENUE_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify Payment/Collection Status",
    description:
      "Select if this person can modify the status of payments & collections.",
    options: [
      {
        text: "Yes",
        permissionId: PERMISSIONS.REVENUE_MODIFY_PAYMENT,
      },
      {
        text: "No",
        permissionId: PERMISSIONS.REVENUE_NO_MODIFY_PAYMENT,
      },
      null,
    ],
  },
];

export const ratePermissions = [
  {
    id: 1,
    title: "General Access",
    description: "Manage how/if this person can access the entire section.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.RATE_VIEW_AND_EDIT,
      },
      {
        text: "View only",
        permissionId: PERMISSIONS.RATE_VIEW,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.RATE_NO_ACCESS,
      },
    ],
  },
  {
    id: 2,
    title: "Modify Rates",
    description: "Manage how this person can modify the rates.",
    options: [
      {
        text: "Add",
        permissionId: PERMISSIONS.RATE_ADD,
      },
      {
        text: "Edit",
        permissionId: PERMISSIONS.RATE_EDIT,
      },
      {
        text: "Delete",
        permissionId: PERMISSIONS.RATE_DELETE,
      },
    ],
  },
];

export const mobileAppPermission = [
  {
    id: 1,
    title: "General Access",
    description:
      "Select if this person can access the mobile app to manage batteries in the hub station.",
    options: [
      {
        text: "View & Edit",
        permissionId: PERMISSIONS.APP_VIEW_AND_EDIT,
      },
      {
        text: "No Access",
        permissionId: PERMISSIONS.APP_NO_ACCESS,
      },
      null,
    ],
  },
];
