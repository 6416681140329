import { useSelector } from "react-redux";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS, SEED_ROLES } from "../Login/constants";

const useTabData = () => {
  const { userDetails } = useSelector((state) => state.loggedInUser);

  const tabs = [
    {
      id: 1,
      text: "general",
      path: "/settings",
      hasPermission: true,
    },
    {
      id: 2,
      text: "system users",
      path: "/settings/system-users",
      hasPermission: usePermission([
        PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
        PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 3,
      text: "roles & permissions",
      path: "/settings/roles-and-permissions",
      hasPermission: usePermission([
        PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
        PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 4,
      text: "tenants",
      path: "/settings/tenants",
      hasPermission:
        userDetails.isSongaMobilityUser &&
        userDetails.roleId === SEED_ROLES.SUPER_ADMIN,
    },
  ];

  return tabs;
};

export default useTabData;
