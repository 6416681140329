import React from "react";
import { useNavigate } from "react-router-dom";
import { AlertTriangleIcon } from "../../../../Common/Svgs/AlertIcon";

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        stroke="#F18725"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M35.875 16.625c2.625 0 2.625.875 2.625 2.625v3.5c0 1.75 0 2.625-2.625 2.625M7 37.625l21-33.25M12.25 8.75c-7 0-8.75 1.75-8.75 8.75v7c0 5.95 1.26 8.102 5.932 8.61M22.75 33.25c7 0 8.75-1.75 8.75-8.75v-7c0-5.932-1.26-8.102-5.898-8.61"
      />
    </svg>
  );
};

export const BatteryUnavailable = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex flex-col items-center">
        <div className="bg-warning-10 w-[84px] h-[84px] flex items-center justify-center rounded-full">
          <Icon />
        </div>

        <p className="mt-6 font-poppins text-base font-medium">Uh-Oh</p>

        <p className="mt-2 font-poppins text-xs font-light">
          Batteries are not available at the moment, please check
          <br /> again later or send a request to your nearest hub station.
        </p>

        <button
          type="button"
          className="mt-6 px-4 py-[11px] bg-yellow hover:bg-yellow-dark rounded-md"
          onClick={() => navigate("/add-battery")}
        >
          <div className="flex item-center gap-x-2">
            <img
              src="/assets/icons/request-battery.svg"
              className="w-4 h-4"
              alt=""
            />

            <p className="font-poppins text-xs leading-4.5 font-medium text-black">
              Request Batteries
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};

export const NotEnoughBatteries = ({ requestNo }) => {
  const navigate = useNavigate();

  return (
    <div className="mt-2 py-3 px-6 flex items-center gap-x-6 bg-yellow-10 rounded-xl">
      <AlertTriangleIcon size={24} className="stroke-white fill-warning" />

      <p className="flex-1 font-poppins text-xs font-light">
        There are not enough batteries for all trips at the hub station. You may
        request batteries from your nearest hub station.
      </p>

      <button
        type="button"
        className="px-4 py-[11px] bg-yellow hover:bg-yellow-dark rounded-md"
        onClick={() => navigate("/add-battery")}
      >
        <div className="flex item-center gap-x-2">
          <img
            src="/assets/icons/request-battery.svg"
            className="w-4 h-4"
            alt=""
          />

          <p className="font-poppins text-xs leading-4.5 font-medium text-black">
            Request {requestNo} Batteries
          </p>
        </div>
      </button>
    </div>
  );
};
