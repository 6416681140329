import React from 'react';
import CustomModal from '../CustomModal';
import ModalBody from './ModalBody';
import GeoFenceMapArea from './GeoFenceMapArea';

const GeoFenceModal = ({ visible, onCancel }) => {
  const mapRef = React.useRef(null);

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="1296px" centered>
      <div className="w-full h-[756px] relative rounded-md overflow-hidden">
        <GeoFenceMapArea mapRef={mapRef} />
        <ModalBody onCancel={onCancel} mapRef={mapRef} />
      </div>
    </CustomModal>
  );
};

export default GeoFenceModal;
