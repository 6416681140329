import React, { useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import L from "leaflet";
import {
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { updateTrikes } from "../../../../Redux/Slices/trikes.slice";

const GeoFenceMapArea = ({ mapRef }) => {
  const dispatch = useDispatch();

  const { currentTrike, geoFenceLatLngs, createGeofence } = useSelector(
    (state) => state.trikes,
  );
  const [showMap, setShowMap] = useState(false);

  const lineOptions = {
    color: "#085054",
  };

  React.useEffect(() => {
    setTimeout(() => {
      setShowMap(true);
    }, 50);
  }, []);

  const handleClick = (event) => {
    const { latlng } = event;

    dispatch(
      updateTrikes({
        key: "geoFenceLatLngs",
        value: {
          trikeId: currentTrike.id,
          latLngs: [...geoFenceLatLngs.latLngs, [latlng?.lat, latlng?.lng]],
        },
      }),
    );
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: createGeofence ? handleClick : null,
    });

    return null;
  };

  const renderCustomIcon = () => {
    // eslint-disable-next-line new-cap
    return new L.divIcon({
      html: ReactDOMServer.renderToString(
        <div className="absolute w-5 h-5 rounded-full bg-primary top-[-8px] left-[-4px]" />,
      ),
    });
  };

  return (
    <div className="w-full h-full">
      {showMap && (
        <MapContainer
          center={[-0.680482, 34.777061]}
          zoom={15}
          className="w-full h-full"
          zoomControl={false}
          ref={mapRef}
        >
          <MapClickHandler />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          <Polyline
            pathOptions={lineOptions}
            positions={geoFenceLatLngs?.latLngs}
            weight={1.5}
          />
          {geoFenceLatLngs?.latLngs?.map((item, index) => (
            <Marker
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              position={item}
              icon={renderCustomIcon()}
            />
          ))}
        </MapContainer>
      )}
    </div>
  );
};

export default GeoFenceMapArea;
