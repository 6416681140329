/* eslint-disable import/prefer-default-export */
export const headerButtons = [
  {
    id: 1,
    padding: "filter",
    width: "settingsDriversTab",
    text: "Drivers",
  },
  {
    id: 2,
    padding: "filter",
    width: "settingsDriversTab",
    text: "Farmers",
  },
];

export const userCategoryFilters = [
  {
    id: 1,
    value: "Driver",
  },
  {
    id: 2,
    value: "Employee",
  },
];

export const farmerRoleOptions = [
  {
    id: 1,
    value: "Farmer",
  },
  {
    id: 2,
    value: "Offtaker",
  },
];

export const PARTNER_ROLE = {
  FARMER: 1,
  OFFTAKER: 2,
};

export const rowTitleKeyMap = [
  { field: "id", title: "ID" },
  { field: "firstName", title: "FIRST NAME" },
  { field: "lastName", title: "LAST NAME" },
  { field: "contactNo", title: "CONTACT NUMBER" },
  { field: "uniqueId", title: "UNIQUE ID" },
  { field: "locationName", title: "COLLECTION POINT" },
  { field: "totalWeightCollected", title: "TOTAL WEIGHT COLLECTED" },
  { field: "totalWeightDelivered", title: "TOTAL WEIGHT DELIVERED" },
];
