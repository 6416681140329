/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import ExportIcon from "../../../Common/Svgs/ExportIcon";
import { updateTenants } from "../../../Redux/Slices/tenants.slice";
import CustomTooltip from "../../../Core/Components/CustomTooltip";

const InviteAndDeleteAction = ({ tenant }) => {
  const dispatch = useDispatch();

  const handleInvitation = async (e) => {
    e.stopPropagation();

    dispatch(
      updateTenants({
        key: "inviteModal",
        value: {
          visible: true,
          tenant,
        },
      }),
    );
  };

  const handleDelete = async (e) => {
    e.stopPropagation();

    dispatch(
      updateTenants({
        key: "deleteModal",
        value: {
          visible: true,
          tenant,
        },
      }),
    );
  };

  if (tenant.isActive === 0) {
    return (
      <p className="font-poppins text-xs font-medium text-alert-red">Deleted</p>
    );
  }

  return (
    <div className="flex items-center gap-x-2">
      <CustomTooltip tooltipTitle="Send invitation mail">
        <button
          type="button"
          onClick={handleInvitation}
          className="invisible group-hover:visible w-8 h-6 flex items-center justify-center bg-primary-10 rounded-md"
        >
          <ExportIcon size={16} className="text-primary" />
        </button>
      </CustomTooltip>

      <CustomTooltip tooltipTitle="Delete">
        <button
          type="button"
          onClick={handleDelete}
          className="invisible group-hover:visible w-6 h-6 flex items-center justify-center bg-alert-10 rounded-md"
        >
          <TrashIcon className="text-alert-red" />
        </button>
      </CustomTooltip>
    </div>
  );
};

export default InviteAndDeleteAction;
