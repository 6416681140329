const getChargingStatus = (statusId) => {
  // battery status id
  // 1 - trike, 2 - swap station, 3 - assembly station

  switch (statusId) {
    case 1:
      return 'discharging';
    case 2:
      return 'charging';
    default:
      return '--';
  }
};

export default getChargingStatus;
