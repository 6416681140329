/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";
import CustomModal from "../../Core/Components/Modal/CustomModal";
import { getSnapToRoadPointsOnMap } from "../../Utils/getTripPointsOnMap";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import ZoomButton, {
  RelocateButton,
} from "../../Core/Components/MapMarkerButtons";
import { SnapToRoadModal } from "./CreateTrip/OptimisingRouteModal";
import getCenterOfPolygon from "../../Utils/getCenterOfPolygon";

const FullScreenMapForTrips = ({
  visible,
  onCancel,
  // polygonCoordinates,
  showRoute = true,
  loading = false,
}) => {
  const { fastestRouteCoordinates, snapToRoadCoordinates } = useSelector(
    (state) => state.trips,
  );

  const [showMap, setShowMap] = React.useState(false);
  const mapRef = React.useRef(null);

  const lineOptions = { color: "#135bba" };

  const tempCoordinates = [-0.6803741, 34.7569482];

  React.useEffect(() => {
    setTimeout(() => {
      setShowMap(true);
    }, 100);
  }, []);

  const snapToRoadFarmCoordinates = snapToRoadCoordinates.map((snap) => {
    return [snap[1], snap[0]];
  });

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="1296px" centered>
      <div className="relative w-full h-[700px] overflow-hidden rounded-md">
        {showMap && (
          <div className="w-full h-full">
            <div className="absolute z-[100] top-6 right-6">
              <div className="flex items-center justify-center w-8 h-8 rounded-md cursor-pointer abosolute bg-primary">
                <CloseIcon
                  className="w-4 h-4 stroke-white"
                  onClick={onCancel}
                />
              </div>
            </div>
            <MapContainer
              center={
                snapToRoadFarmCoordinates.length > 1
                  ? getCenterOfPolygon(snapToRoadFarmCoordinates)
                  : tempCoordinates
              }
              zoom={12}
              className="z-10 w-full h-full"
              zoomControl={false}
              ref={mapRef}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {loading ? (
                <div>
                  <SnapToRoadModal visible={loading} />
                </div>
              ) : (
                <>
                  {showRoute && (
                    <div>
                      {fastestRouteCoordinates?.length > 0 ? (
                        <Polyline
                          pathOptions={lineOptions}
                          positions={fastestRouteCoordinates}
                          weight={3}
                        />
                      ) : null}

                      {/* Plotting snap to road coordinates */}
                      {snapToRoadFarmCoordinates?.map((item, index) => (
                        <Marker
                          key={index}
                          position={item}
                          icon={getSnapToRoadPointsOnMap()}
                        />
                      ))}
                    </div>
                  )}
                  {/* Plotting actual farm coordinates */}
                  {/* {polygonCoordinates?.map((item, index) => (
                    <Marker
                      key={index}
                      position={item}
                      icon={getTripPointsOnMap(
                        index + 1,
                        polygonCoordinates.length + 1,
                        fullScreenMapForTrips.pointColor,
                      )}
                    />
                  ))} */}
                </>
              )}
            </MapContainer>
            <div className="absolute bottom-6 right-6 z-[100]">
              <div className="flex flex-col gap-y-3">
                <RelocateButton />
                <ZoomButton mapRef={mapRef} />
              </div>
            </div>
          </div>
        )}
      </div>
    </CustomModal>
  );
};

export default FullScreenMapForTrips;
