/* eslint-disable no-dupe-keys */
import React from "react";
import ArrowIcon from "../../Common/Svgs/ArrowIcon";

const AppDownload = () => {
  const onDownloadClick = () => {
    const downloadLink = document.createElement("a");

    downloadLink.download = "Songa-Mobility.apk";

    downloadLink.href = process.env.REACT_APP_MOBILE_APK;

    document.body.appendChild(downloadLink);

    downloadLink.click();

    downloadLink.remove();
  };

  return (
    <div
      className="w-screen bg-background-white-hover"
      style={{
        height: "100vh",
        height: "calc(100dvh)",
      }}
    >
      <div
        className="w-full h-[30%] flex items-center justify-center bg-white"
        style={{
          boxShadow: "0px 1px 15px -2px #E8E8E8",
        }}
      >
        <img
          src="/assets/songa-main-logo-2.png"
          alt="Songa Mobility"
          // className="w-[208px] h-[45px]"
          className="w-[238px] lg:w-[208px] h-[50px] lg:h-[45px]"
        />
      </div>

      <p className="my-8 font-poppins text-base lg:text-xl font-medium lg:font-semibold text-green text-center">
        Driver Mobile App download link
      </p>

      <div className="w-full flex items-center justify-center">
        <button
          type="button"
          className="p-4 w-[328px] rounded-md bg-green"
          onClick={onDownloadClick}
        >
          <img
            src="/assets/android.svg"
            alt="Android Apk"
            className="w-8 h-8"
          />

          <div className="mt-2 flex items-end justify-between">
            <div className="flex flex-col items-start">
              <p className="font-aileron text-xs lg:text-sm font-normal text-[#E7E7E7]">
                Download for
              </p>
              <p className="font-poppins text-sm lg:text-base font-medium text-white">
                Android
              </p>
            </div>

            <ArrowIcon size={20} className="rotate-180 text-white" />
          </div>
        </button>
      </div>

      <p className="mt-3 font-aileron text-10 text-center lg:text-xs font-normal text-green">
        Last updated on: {process.env.REACT_APP_LAST_APK_RELEASE_DATE}
      </p>
    </div>
  );
};

export default AppDownload;
