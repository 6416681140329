import React from "react";

const ItemBody8 = ({ notification }) => {
  return (
    <p className="font-poppins text-xs font-light">
      You accepted battery request from{" "}
      <span className="font-medium text-primary">
        {notification?.metaData?.hubName}.{" "}
      </span>
      Please check-out the batteries from the mobile app.
    </p>
  );
};

export default ItemBody8;
