import React from "react";
import { useSearchParams } from "react-router-dom";
import Button from "../../Core/Components/CustomButton";
import { headerButtons } from "./data";

const Header = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTab = searchParams.get("tab");

  const onTabClick = (item) => {
    searchParams.set("tab", item.text.toLowerCase());
    setSearchParams(searchParams);
  };

  return (
    <div className="px-6 py-3.5 flex items-center justify-between border-b bg-background-white">
      <div className="flex items-center gap-x-6">
        {headerButtons.map((item) => {
          const selected = selectedTab === item.text.toLowerCase();

          return (
            <Button
              key={item.id}
              colors={selected ? "primary" : "white"}
              width={item.width}
              padding={item.padding}
              modifier="shadow"
              onClick={() => onTabClick(item)}
            >
              <div>{item.text}</div>
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default Header;
