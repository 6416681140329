import React from 'react';

const OptimisingRouteIcon = ({ size = 24, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle cx="12" cy="12" r="9" stroke="#FAFAFA" strokeWidth="2" />
        <mask id="path-2-inside-1_3482_80786" fill="#fff">
          <path d="M20.994 12c.555 0 1.011-.452.955-1.005A9.999 9.999 0 002.655 8.441a10 10 0 005.742 12.887c.518.2 1.074-.12 1.221-.655.148-.536-.171-1.084-.684-1.297a7.988 7.988 0 1110.99-8.38c.07.552.514 1.004 1.07 1.004z" />
        </mask>
        <path
          stroke="currentColor"
          strokeWidth="4"
          d="M20.994 12c.555 0 1.011-.452.955-1.005A9.999 9.999 0 002.655 8.441a10 10 0 005.742 12.887c.518.2 1.074-.12 1.221-.655.148-.536-.171-1.084-.684-1.297a7.988 7.988 0 1110.99-8.38c.07.552.514 1.004 1.07 1.004z"
          mask="url(#path-2-inside-1_3482_80786)"
        />
      </svg>
    </span>
  );
};

export default OptimisingRouteIcon;
