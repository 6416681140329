/* eslint-disable no-console */
import React, { useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";

const RangePicker = ({
  dateRangeOpen,
  setDateRangeOpen,
  startDate,
  endDate,
  onChange,
}) => {
  const [panelOpen, setPanelOpen] = useState(false);

  console.log(panelOpen);

  // if both the dates are populated, show the dates and clear button
  if (startDate && endDate) {
    return (
      <div className="flex items-center gap-x-3">
        <div className="px-3 py-[11px] flex items-center gap-x-3 rounded-md bg-green">
          <p className="text-white font-poppins text-xs font-normal">
            {dayjs(startDate).format("DD MMM YYYY")} -{" "}
            {dayjs(endDate).format("DD MMM YYYY")}
          </p>
        </div>

        <div
          className="text-green font-poppins text-xs font-normal cursor-pointer"
          onClick={onChange}
        >
          Clear
        </div>
      </div>
    );
  }

  // show the date range input and the open the picker panel
  if (dateRangeOpen) {
    return (
      <div className="">
        {/* close the date picker if no values selected */}
        <div
          className="fixed top-0 bottom-0 right-0 left-0 w-full h-full bg-transparent"
          onClick={() => {
            setPanelOpen(false);
            setDateRangeOpen(false);
          }}
        />

        <DatePicker.RangePicker
          format="DD MMM YYYY"
          separator={null}
          allowClear={false}
          className="h-[39px] max-w-[225px] z-10"
          inputReadOnly
          onChange={onChange}
          onOpenChange={(state) => setPanelOpen(state)}
        />
      </div>
    );
  }

  // show the duration button and when clicked, open the range picker
  return (
    <button
      type="button"
      className="px-3 py-[11px] flex items-center gap-x-3 rounded-md bg-background-white text-dark-gray hover:bg-background"
      onClick={() => setDateRangeOpen(true)}
    >
      <p className="font-poppins font-medium text-xs">Duration</p>

      <ChevronIcon
        className={`w-4 h-4 stroke-dark-gray ${
          dateRangeOpen ? "-rotate-90" : "rotate-90"
        }`}
      />
    </button>
  );
};

export default RangePicker;
