/* eslint-disable operator-assignment */
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { Carousel } from "antd";
import CustomLayout from "../../../../Core/Layout/CustomLayout";
import Button from "../../../../Core/Components/CustomButton";
import AssignBatteryCard from "./AssignBatteryCard";
import { tripsRequiringBatteries } from "./constants";
import { BATTERY_STATUS } from "../../Data";
import { BatteryUnavailable, NotEnoughBatteries } from "./BatteryUnavailable";
// import { NextArrow, PrevArrow } from "./CarouselArrows";

const AssignBattery = () => {
  const navigate = useNavigate();

  const { hubId } = useParams();

  const { allBatteries } = useSelector((state) => state.batteries);
  const { allLocations } = useSelector((state) => state.locations);

  const hub = allLocations.find((item) => item.id === +hubId);

  // find batteries at hub station
  const batteriesPresent = allBatteries.filter(
    (item) => item.status === BATTERY_STATUS.HUB_STATION,
  ).length;

  // find batteries required for trips
  const requiredBatteries = tripsRequiringBatteries.reduce((acc, current) => {
    acc = acc + current.batteries;
    return acc;
  }, 0);

  // const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <CustomLayout pageName="Batteries" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            Assign Batteries
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Close
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-[calc(100vh-90px-72px)] p-6 bg-background-white overflow-y-scroll">
        {batteriesPresent ? (
          <div className="h-full">
            <div className="pb-2 border-b border-light-grey mb-6">
              <h1 className="font-poppins text-base font-medium capitalize">
                {hub ? hub.name : ""}
              </h1>

              <p className="mt-1 font-aileron text-xs font-normal text-dark-gray capitalize">
                {batteriesPresent} batteries available
              </p>

              {/* if the number of batteries required for trips are greater than batteries present in the hub station */}
              {requiredBatteries > batteriesPresent && (
                <NotEnoughBatteries
                  requestNo={requiredBatteries - batteriesPresent}
                />
              )}
            </div>

            <div className="flex items-start gap-x-6 pb-6">
              <AssignBatteryCard
                index={1}
                details={tripsRequiringBatteries[0]}
              />
              <AssignBatteryCard
                index={2}
                details={tripsRequiringBatteries[1]}
              />
              <AssignBatteryCard
                index={3}
                details={tripsRequiringBatteries[2]}
              />
              <AssignBatteryCard
                index={4}
                details={tripsRequiringBatteries[3]}
              />
            </div>

            {/* <Carousel
              arrows
              centerMode={false}
              className="relative"
              infinite={false}
              dots={false}
              autoplay={false}
              slidesToShow={2}
              slidesToScroll={1}
              nextArrow={
                <NextArrow
                  currentPage={currentSlideIndex}
                  setCurrentPage={setCurrentSlideIndex}
                />
              }
              prevArrow={
                <PrevArrow
                  currentPage={currentSlideIndex}
                  setCurrentPage={setCurrentSlideIndex}
                />
              }
            >
              {tripsRequiringBatteries.map((item, index) => (
                <div key={item.id} className="border border-black">
                  <AssignBatteryCard index={index + 1} details={item} />
                </div>
              ))}
            </Carousel> */}
          </div>
        ) : (
          <BatteryUnavailable />
        )}
      </div>
    </CustomLayout>
  );
};

export default AssignBattery;
