import React from "react";
import SubmittedDetails from "../../../Core/Components/SubmittedDetails";
import ChargerStatus from "../ChargerStatus";
import ListDropdown from "../ListDropdown";
import { getCurrentType } from "../getCurrentType";

export const chargerColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Serial no.
      </div>
    ),
    dataIndex: "serialNo",
    render: (text) => (
      <div>
        <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
          {text}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        KSC Tag no.
      </div>
    ),
    dataIndex: "tagNo",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Charger Type
      </div>
    ),
    dataIndex: "chargerTypeName",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Current
      </div>
    ),
    dataIndex: "currentId",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {getCurrentType(parseInt(text, 10))}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Hub Station
      </div>
    ),
    dataIndex: "hubName",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: "status",
    render: (text) => <ChargerStatus status={text} />,
  },
  {
    title: () => (
      <div className="font-light font-poppins text-10 text-dark-gray">
        Date of Entry
      </div>
    ),
    sorter: (a, b) => a?.submittedAt?.localeCompare(b?.submittedAt),
    dataIndex: "submittedAt",
    width: "200px",
    render: (text, record) => <SubmittedDetails record={record} />,
  },
  {
    dataIndex: "id",
    width: "5%",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="invisible group-hover:visible w-full flex items-center justify-center"
      >
        <ListDropdown detail={record} />
      </button>
    ),
  },
];
