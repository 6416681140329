import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTooltip from "../../../Core/Components/CustomTooltip";
import getThemeColor from "../../../Utils/getThemeColor";
import {
  getMetaData,
  updateMetaData,
} from "../../../Redux/APIs/systemUsersAPI";
import { DEFAULT_LOGO, DEFAULT_THEME } from "../Data/generalData";

const ResetButton = ({ setSelectedTheme }) => {
  const dispatch = useDispatch();

  const { theme } = useSelector((state) => state.loggedInUser);

  const onReset = async () => {
    const payload = {
      theme: DEFAULT_THEME,
      companyLogo: DEFAULT_LOGO,
    };

    await dispatch(updateMetaData(payload));
    await dispatch(getMetaData());

    setSelectedTheme(getThemeColor("green-theme"));
  };

  return (
    <CustomTooltip
      tooltipTitle="Reset Settings"
      placement="top"
      bgColor={getThemeColor(theme)}
    >
      <button
        type="button"
        className="flex items-center justify-center w-8 h-8 rounded-full bg-primary"
        onClick={onReset}
      >
        <img src="/assets/icons/refresh.svg" alt="" />
      </button>
    </CustomTooltip>
  );
};

export default ResetButton;
