import { useSelector } from "react-redux";
import { BATTERY_STATUS } from "../../Battery/Data";

const useBatteries = () => {
  const { userDetails } = useSelector((state) => state.loggedInUser);
  const { allBatteries } = useSelector((state) => state.batteries);

  // filter the batteries on active hubs of the user and battery status = hub station
  const batteries = allBatteries.filter((item) => {
    if (
      item.status === BATTERY_STATUS.HUB_STATION &&
      userDetails.hubs.includes(+item.currentHubId)
    ) {
      return true;
    }

    return false;
  });

  return batteries;
};

export default useBatteries;
