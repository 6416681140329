/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import SettingsHeader from "../SettingsHeader";
import TenantHeader from "./TenantHeader";
import CustomTable from "../../../Core/Components/CustomTable";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { tenantColumns, tenantData } from "./constants";
import InviteModal from "./InviteModal";
import { updateTenants } from "../../../Redux/Slices/tenants.slice";
import ToastMessageModal from "./ToastMessageModal";
import DeleteModal from "./DeleteModal";
import useDebounce from "../../../Core/hooks/useDebounce";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import { getTenants } from "../../../Redux/APIs/tenantAPI";
import { getUserByEmail } from "../../../Redux/APIs/systemUsersAPI";

const Tenants = () => {
  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const { tenantList, inviteModal, deleteModal, showToastMessage } =
    useSelector((state) => state.tenants);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 500);

  // get tenants along with the filters
  useEffect(() => {
    const filterObj = {};

    if (debouncedSearch) {
      setCurrentPage(1);
      filterObj.searchQuery = debouncedSearch;
    }

    dispatch(
      getTenants({
        ...filterObj,
        offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
      }),
    );
  }, [dispatch, debouncedSearch, currentPage]);

  // to clear the toast message
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateTenants({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Settings">
      <div className="w-full h-full bg-background-white">
        <SettingsHeader />

        <div className="p-6">
          <TenantHeader search={search} setSearch={setSearch} />

          <CustomTable
            scrollX={0}
            columns={tenantColumns}
            data={tenantList.result}
            rowClassName={(record) => {
              return record.isActive ? "group" : "group deleted-row";
            }}
            pagination={{
              pageSize: DEFAULT_LIMIT,
              total: tenantData?.length,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              itemRender: (current, type, originalElement) => {
                const lastPage = tenantList.totalRecords?.length / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>

        <InviteModal
          visible={inviteModal?.visible}
          onCancel={() =>
            dispatch(
              updateTenants({
                key: "inviteModal",
                value: {
                  visible: false,
                  tenant: {},
                },
              }),
            )
          }
        />

        <DeleteModal
          currentPage={currentPage}
          visible={deleteModal?.visible}
          onCancel={() =>
            dispatch(
              updateTenants({
                key: "deleteModal",
                value: {
                  visible: false,
                  tenant: {},
                },
              }),
            )
          }
        />

        <ToastMessageModal
          visible={showToastMessage?.visible}
          title={showToastMessage?.data?.title}
          description={showToastMessage?.data?.description}
          onCancel={() =>
            dispatch(
              updateTenants({
                key: "showToastMessage",
                value: {
                  visible: false,
                  data: {},
                },
              }),
            )
          }
        />
      </div>
    </CustomLayout>
  );
};

export default Tenants;
