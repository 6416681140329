/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomKPI from "../../../Core/Components/CustomKpi";
import { FinanceHoverIcon } from "../../../Common/Svgs/FinanceIcon";
import { paymentColumns, paymentData, PaymentKPIData } from "./data";
import PaymentHeader from "./PaymentHeader";
import CustomTable from "../../../Core/Components/CustomTable";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { updateRevenue } from "../../../Redux/Slices/revenue.slice";

const kpiData = {
  totalAmountPaid: 59251,
  amountDueForDrivers: 4508,
  amountDueForPartners: 2014,
};

const LOCAL_DEFAULT_LIMIT = 10;

const PaymentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    hub: [],
    userType: [],
    paymentStatus: [],
  });

  const handleRowClick = (record) => {
    dispatch(
      updateRevenue({
        key: "selectedPayment",
        value: record,
      }),
    );

    navigate(`/revenue/payment-details/${record.id}`);
  };

  return (
    <div>
      <div className="w-full flex items-center gap-x-6 p-6">
        {PaymentKPIData(kpiData).map((item) => (
          <CustomKPI
            key={item.title}
            title={item.title}
            count={item.count}
            image={<FinanceHoverIcon size={16} className="text-kpi-icon" />}
            unit="KSH"
          />
        ))}
      </div>

      <div>
        <PaymentHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />

        <div
          className={`h-[calc(100vh-72px-71px-162px-69px-40px)] ${
            paymentData.length && "overflow-y-scroll"
          }`}
        >
          <CustomTable
            // scrollX={0}
            // scrollY="518px"
            disableScroll
            columns={paymentColumns}
            data={paymentData}
            onRowClick={handleRowClick}
            emptyState={
              <FinanceHoverIcon size={16} className="text-dark-grey-text" />
            }
            rowClassName="group cursor-pointer"
            pagination={{
              pageSize: LOCAL_DEFAULT_LIMIT,
              total: paymentData.length,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              current: currentPage,
              itemRender: (current, type, originalElement) => {
                const lastPage = paymentData.length / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentList;
