import React from "react";

const EnergyResourcesIcon = ({ className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="20"
        fill="none"
        viewBox="0 0 22 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M5.833 16.833h7c3.333 0 4.166-1.833 4.166-5.166V8.333c0-3.333-.833-5.166-4.166-5.166h-7C2.499 3.167 1.666 5 1.666 8.333v3.334c0 3.333.833 5.166 4.167 5.166zM19.084 7.917c1.25 0 1.25.416 1.25 1.25v1.666c0 .834 0 1.25-1.25 1.25"
        />
        <path
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M6.524 9.96l1.375.342-.792 3.2c-.183.75.183 1 .817.558l4.317-2.992c.524-.366.45-.791-.175-.95l-1.376-.341.792-3.2c.184-.75-.183-1-.816-.559L6.348 9.01c-.525.367-.45.792.175.95z"
        />
      </svg>
    </span>
  );
};

export default EnergyResourcesIcon;
