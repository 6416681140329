import React from "react";

const InfoCircleIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.999 14.667c3.666 0 6.666-3 6.666-6.667s-3-6.667-6.666-6.667c-3.667 0-6.667 3-6.667 6.667s3 6.667 6.667 6.667zM8 11.333V8M8.004 6h-.006"
        />
      </svg>
    </span>
  );
};

export const InfoCircleSecondaryIcon = ({
  className,
  fillColor = "#FAFAFA",
  bgColor = "#EAB726",
}) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        fill="none"
        viewBox="0 0 17 16"
      >
        <path
          fill={bgColor}
          stroke={bgColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.253 14.667c3.666 0 6.666-3 6.666-6.667s-3-6.667-6.666-6.667c-3.667 0-6.667 3-6.667 6.667s3 6.667 6.667 6.667z"
        />
        <path
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.25 5.333v3.334"
        />
        <path
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8.25 10.666h.006"
        />
      </svg>
    </span>
  );
};

export default InfoCircleIcon;
