import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';

const ResetPasswordSuccessScreen = ({ email }) => {
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen">
      <Row className="h-full">
        <Col span={15} className="h-full overflow-hidden">
          <img
            src="/assets/images/Login/login-image.svg"
            alt="LoginImage"
            className="object-cover w-full h-full"
          />
        </Col>
        <Col span={9}>
          <div className="flex flex-col items-center justify-center w-full h-full gap-y-6 font-poppins bg-background-white">
            <img
              src="/assets/songa-main-logo.svg"
              alt="Songa Mobility"
              className="w-[258px] h-14"
            />
            <div>
              <div className="text-2xl font-medium leading-9 text-center text-black capitalize">
                Check your inbox!
              </div>
              <div className="mt-2 w-[255px] text-xs font-light leading-4.5 text-center text-dark-grey-text whitespace-nowrap">
                A Link is sent to{' '}
                <span className="font-medium underline text-green">
                  {email}
                </span>{' '}
                <br />
                to reset your password
              </div>
            </div>

            <button
              type="button"
              onClick={() => navigate('/')}
              className="cursor-pointer font-poppins font-light text-xs leading-4.5 text-dark-grey-text hover:text-new-black hover:underline"
            >
              Back to Login
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ResetPasswordSuccessScreen;
