/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as axios } from "../../Utils/axiosClient";

export const getSystemUsers = createAsyncThunk(
  "loggedInUser/getSystemUsers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/system-users`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAllSystemUsers = createAsyncThunk(
  "loggedInUser/getAllSystemUsers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/all-system-users`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateUser = createAsyncThunk(
  "loggedInUser/updateUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/update-user`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteUser = createAsyncThunk(
  "loggedInUser/deleteUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/delete-user`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUserByEmail = createAsyncThunk(
  "loggedInUser/getUserByEmail",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/get-user-by-email`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateMetaData = createAsyncThunk(
  "loggedInUser/updateMetaData",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/update-meta-details`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMetaData = createAsyncThunk(
  "loggedInUser/getMetaData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/meta-details`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSystemRoles = createAsyncThunk(
  "loggedInUser/getSystemRoles",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/get-user-roles`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
