import React from "react";

const ItemBody1 = ({ notification }) => {
  return (
    <p className="font-poppins text-xs font-light">
      <span className="font-normal">Trike No. </span>
      <span className="font-medium text-primary underline cursor-pointer">
        {notification?.metaData?.registrationNo}
      </span>
      . has gone out of its geofence area.
    </p>
  );
};

export default ItemBody1;
