import Checkbox from "antd/es/checkbox/Checkbox";
import React, { useState } from "react";
import dayjs from "dayjs";
import { DatePicker, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import CustomDropdown from "../../../Core/Components/CustomDropdown";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import AlertIcon from "../../../Common/Svgs/AlertIcon";
import timeSlots, { days } from "./TripStep2Data";

const TripStep2 = () => {
  const dispatch = useDispatch();

  const { tripDetails, validationForSteps, editTripDetails } = useSelector(
    (state) => state.trips,
  );

  const [selectedDay, setSelectedDay] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDateSelection = (input, key) => {
    const parsedInput = dayjs(input).utc();

    dispatch(
      updateTrips({
        key: "tripDetails",
        value: { ...tripDetails, [key]: parsedInput },
      }),
    );
    dispatch(
      updateTrips({
        key: "validationForSteps",
        value: {
          ...validationForSteps,
          step2: {
            ...validationForSteps.step2,
            [key]: false,
          },
        },
      }),
    );
  };

  const handleDaySelection = (itemId) => {
    const isItemPresent = tripDetails.daysOfOccurence.includes(itemId);

    if (isItemPresent) {
      const updatedDaysOfOccurence = tripDetails.daysOfOccurence.filter(
        (id) => id !== itemId,
      );
      dispatch(
        updateTrips({
          key: "tripDetails",
          value: { ...tripDetails, daysOfOccurence: updatedDaysOfOccurence },
        }),
      );

      setSelectedDay(selectedDay.filter((id) => id !== itemId));
    } else {
      const updatedDaysOfOccurence = [...tripDetails.daysOfOccurence, itemId];
      dispatch(
        updateTrips({
          key: "tripDetails",
          value: { ...tripDetails, daysOfOccurence: updatedDaysOfOccurence },
        }),
      );
      setSelectedDay([...selectedDay, itemId]);
      dispatch(
        updateTrips({
          key: "validationForSteps",
          value: {
            ...validationForSteps,
            step2: {
              ...validationForSteps.step2,
              daysOfOccurence: false,
            },
          },
        }),
      );
    }
  };

  const handleTimeSlotSelection = (e) => {
    dispatch(
      updateTrips({
        key: "tripDetails",
        value: { ...tripDetails, timeSlot: e.value },
      }),
    );
    dispatch(
      updateTrips({
        key: "validationForSteps",
        value: {
          ...validationForSteps,
          step2: {
            ...validationForSteps.step2,
            timeSlot: false,
          },
        },
      }),
    );
  };

  const renderBorderColor = () => {
    if (validationForSteps.step2?.timeSlot) {
      return "border border-alert-red";
    }
    if (dropdownOpen) return "border border-primary";
    return "border border-light-grey";
  };

  return (
    <div>
      <div>
        <div className="mb-1.5 text-xs font-normal font-aileron text-dark-gray">
          Trip Date
        </div>
        <DatePicker
          className={`font-aileron text-dark-gray font-normal h-9 w-[416px] ${
            validationForSteps?.step2?.startDate &&
            "border border-alert-red hover:border-alert-red"
          }`}
          allowClear={false}
          suffixIcon={null}
          placeholder="Select Date"
          value={tripDetails.startDate && dayjs(tripDetails.startDate)}
          format="dddd, MMMM DD"
          onChange={(e) => handleDateSelection(e, "startDate")}
          disabledDate={(current) =>
            current.isBefore(dayjs().subtract(1, "day"))
          }
        />
        {validationForSteps?.step2?.startDate && (
          <p className="mt-1.5 text-xs font-normal font-aileron text-alert-red">
            Select start date to proceed
          </p>
        )}
      </div>
      <div className="mt-6">
        <div className="mb-1.5 text-xs font-normal font-aileron text-dark-gray">
          Select slots
        </div>

        <div className="w-[416px]">
          <CustomDropdown
            trigger={["click"]}
            customHeight="h-[200px]"
            items={timeSlots}
            dropDownOpen={dropdownOpen}
            onOpenChange={(flag) => {
              setDropdownOpen(flag);
            }}
            handleClick={(e) => {
              handleTimeSlotSelection(e);
            }}
          >
            <div>
              <div
                className={`mt-1 h-9 w-full cursor-pointer bg-white rounded-md border py-2 px-3.5 flex items-center justify-between ${renderBorderColor()}`}
              >
                <div
                  className={`text-[13px] capitalize text-dark-gray font-aileron ${
                    tripDetails.timeSlot ? "font-semibold" : "font-normal"
                  }`}
                >
                  {tripDetails.timeSlot
                    ? tripDetails.timeSlot
                    : "Select A Time"}
                </div>
                <div className="flex items-center gap-x-2">
                  {validationForSteps.step2?.timeSlot && (
                    <AlertIcon className="text-alert-red" />
                  )}
                  <ChevronIcon
                    className={`w-5 h-5  stroke-dark-gray ${
                      dropdownOpen ? "-rotate-90" : "rotate-90"
                    }`}
                  />
                </div>
              </div>
            </div>
          </CustomDropdown>
        </div>
        {validationForSteps?.step2?.timeSlot && (
          <p className="mt-1.5 text-xs font-normal font-aileron text-alert-red">
            Select at least one slot to proceed
          </p>
        )}
      </div>

      {editTripDetails.flag ? null : (
        <div>
          <div className="w-[416px] m-0">
            <Divider />
          </div>
          <div
            className="flex items-center justify-start mt-6 cursor-pointer gap-x-3 w-[120px]"
            onClick={() => {
              dispatch(
                updateTrips({
                  key: "tripDetails",
                  value: { ...tripDetails, occurence: !tripDetails.occurence },
                }),
              );
            }}
          >
            <Checkbox checked={tripDetails.occurence} />
            <div className="text-xs font-semibold font-aileron text-dark-gray">
              Set Recurrence
            </div>
          </div>
          <div>
            {tripDetails.occurence ? (
              <div>
                <div className="mt-6">
                  <div className="text-xs font-normal font-aileron text-dark-gray">
                    Repeat On
                  </div>
                  <div className="flex items-center justify-start mt-2 gap-x-3">
                    {days.map((a) => (
                      <div
                        className={`capitalize w-7.5 h-7.5 border rounded-full flex justify-center items-center font-poppins text-xs font-medium cursor-pointer
                  ${
                    tripDetails.daysOfOccurence.includes(a.id)
                      ? "bg-primary text-sidebar-active border-primary"
                      : "bg-white text-dark-gray border-light-grey"
                  }`}
                        onClick={() => handleDaySelection(a.id)}
                      >
                        {a.value}
                      </div>
                    ))}
                  </div>
                </div>
                {tripDetails.occurence &&
                  validationForSteps?.step2?.daysOfOccurence && (
                    <p className="mt-1.5 text-xs font-normal font-aileron text-alert-red">
                      Select atleast one day to proceed
                    </p>
                  )}
                <div className="mt-6">
                  <div className="mb-1.5 text-xs font-normal font-aileron text-dark-gray">
                    Ends on
                  </div>
                  <DatePicker
                    className={`font-aileron text-dark-gray font-normal w-[416px] h-9 ${
                      validationForSteps?.step2?.endDate &&
                      "border border-alert-red hover:border-alert-red"
                    }`}
                    allowClear={false}
                    suffixIcon={null}
                    value={tripDetails.endDate && dayjs(tripDetails?.endDate)}
                    placeholder="Select Date"
                    format="dddd, MMMM DD"
                    onChange={(e) => handleDateSelection(e, "endDate")}
                    disabledDate={(current) =>
                      current.isBefore(dayjs().subtract(1, "day"))
                    }
                  />
                </div>
                {tripDetails.occurence &&
                  validationForSteps?.step2?.endDate && (
                    <p className="mt-1.5 text-xs font-normal font-aileron text-alert-red">
                      Select end date to proceed
                    </p>
                  )}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default TripStep2;
