/* eslint-disable no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";
import CloseIcon from "../../../../Common/Svgs/CloseIcon";
import Button from "../../CustomButton";
import {
  updateMultipleTrikes,
  updateTrikes,
} from "../../../../Redux/Slices/trikes.slice";
import {
  getTrikeByIdV3,
  updateGeofenceV3,
} from "../../../../Redux/APIs/trikesAPI";

const SaveGeoFenceLatLngModal = () => {
  const dispatch = useDispatch();

  const {
    geoFenceLatLngs,
    saveGeoFenceLatLngModal,
    isWithinGeofence,
    currentTrike,
  } = useSelector((state) => state.trikes);

  const [forAllTrikes, setForAllTrikes] = useState(false);

  const checkSameLatLng = () => {
    const variance = 0.001;

    if (geoFenceLatLngs.latLngs.length > 1) {
      const [firstPointLat, firstPointLng] = geoFenceLatLngs.latLngs[0];
      const [lastPointLat, lastPointLng] =
        geoFenceLatLngs.latLngs[geoFenceLatLngs.latLngs.length - 1];

      const differenceLat = Math.abs(lastPointLat - firstPointLat);
      const differenceLng = Math.abs(lastPointLng - firstPointLng);

      if (differenceLat <= variance && differenceLng <= variance) {
        dispatch(
          updateTrikes({
            key: "saveGeoFenceLatLngModal",
            value: {
              visible: true,
              text: "Save",
            },
          }),
        );
      } else {
        dispatch(
          updateTrikes({
            key: "saveGeoFenceLatLngModal",
            value: {
              visible: false,
            },
          }),
        );
      }
    }
  };

  const onCancel = () => {
    dispatch(
      updateTrikes({
        key: "saveGeoFenceLatLngModal",
        value: {
          visible: false,
        },
      }),
    );

    dispatch(
      updateTrikes({
        key: "createGeofence",
        value: false,
      }),
    );
  };

  const handleSubmit = async () => {
    const latlngs = geoFenceLatLngs.latLngs;

    const payload = {
      geofence: latlngs,
      trikeId: geoFenceLatLngs.trikeId,
      isWithinGeofence,
      currentStatus: currentTrike.status,
      createForAll: forAllTrikes,
    };

    await dispatch(updateGeofenceV3(payload));
    await dispatch(getTrikeByIdV3({ id: currentTrike.id }));

    dispatch(
      updateMultipleTrikes([
        {
          key: "saveGeoFenceLatLngModal",
          value: {
            visible: false,
          },
        },
        {
          key: "geoFenceModal",
          value: false,
        },

        { key: "createGeofence", value: false },
      ]),
    );
  };

  useEffect(() => {
    if (geoFenceLatLngs?.latLngs?.length > 1) {
      checkSameLatLng();
    } else {
      dispatch(
        updateTrikes({
          key: "saveGeoFenceLatLngModal",
          value: {
            visible: true,
            text: "Create",
          },
        }),
      );
    }
  }, [geoFenceLatLngs.latLngs]);

  return (
    <div
      className={`p-6 rounded-xl shadow-modal bg-white w-[400px] ${
        saveGeoFenceLatLngModal.visible ? "" : "hidden"
      }`}
    >
      <div className="flex items-center justify-between">
        <img
          src="/assets/icons/geo-fence-new.svg"
          className="w-12 h-12"
          alt=""
        />
        <button type="button" onClick={() => onCancel()}>
          <CloseIcon className="w-6 h-6 stroke-dark-grey-text" />
        </button>
      </div>
      <div className="pt-5 text-base font-medium font-poppins text-new-black">
        Create New Geofence
      </div>
      <div className="pt-2 font-poppins text-new-black text-xs leading-4.5 font-light">
        Mark the points on the map to create a geofence.
      </div>
      <div className="pt-5.5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-3">
            <Checkbox
              checked={forAllTrikes}
              onChange={() => setForAllTrikes(!forAllTrikes)}
            />
            <div className="font-poppins text-dark-grey-text text-xs leading-4.5 font-medium capitalize">
              Create for all trikes
            </div>
          </div>
          <div className="flex justify-center gap-x-3">
            <Button
              colors="grey"
              size="primary"
              onClick={() => {
                onCancel();

                dispatch(
                  updateTrikes({
                    key: "geoFenceLatLngs",
                    value: {
                      trikeId: "",
                      latLngs: [],
                    },
                  }),
                );
              }}
            >
              {saveGeoFenceLatLngModal.text === "Save" ? "Clear" : "Cancel"}
            </Button>
            <Button
              size="primary"
              onClick={() => {
                dispatch(
                  updateTrikes({
                    key: "createGeofence",
                    value:
                      saveGeoFenceLatLngModal.text === "Create" ? true : false,
                  }),
                );

                dispatch(
                  updateTrikes({
                    key: "saveGeoFenceLatLngModal",
                    value: {
                      visible: false,
                    },
                  }),
                );

                saveGeoFenceLatLngModal.text === "Save"
                  ? handleSubmit()
                  : false;
              }}
            >
              {saveGeoFenceLatLngModal.text}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveGeoFenceLatLngModal;
