import React from "react";

const Screen7 = ({ onClick }) => {
  return (
    <div className="flex flex-col items-center">
      <h1 className="font-poppins text-2xl text-background-white font-semibold">
        All set!
      </h1>

      <p className="mt-4 font-poppins text-sm font-normal text-background-white-hover text-center">
        Thanks for providing the details! Your dashboard is ready.
      </p>

      <button
        type="button"
        className="mt-[111px] w-full h-[42px] py-3 rounded-md bg-yellow hover:bg-yellow-dark"
        onClick={onClick}
      >
        <p className="font-poppins text-xs font-medium">Go to dashboard</p>
      </button>
    </div>
  );
};

export default Screen7;
