import React from 'react';

const VerifyIcon = ({ className = 'text-yellow-dark' }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        fill="none"
        viewBox="0 0 17 16"
      >
        <path
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7.738 1.633c.46-.393 1.214-.393 1.68 0l1.054.907c.2.173.573.313.84.313h1.133c.707 0 1.287.58 1.287 1.287v1.133c0 .26.14.64.313.84l.906 1.054c.394.46.394 1.213 0 1.68L14.045 9.9c-.174.2-.313.573-.313.84v1.133c0 .707-.58 1.287-1.287 1.287h-1.133c-.26 0-.64.14-.84.313l-1.054.907c-.46.393-1.213.393-1.68 0l-1.053-.907a1.478 1.478 0 00-.84-.313H4.692c-.707 0-1.287-.58-1.287-1.287v-1.14c0-.26-.14-.633-.307-.833l-.9-1.06c-.386-.46-.386-1.207 0-1.667l.9-1.06c.167-.2.307-.573.307-.833V4.133c0-.706.58-1.286 1.287-1.286h1.153c.26 0 .64-.14.84-.314l1.053-.9z"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.158 8l1.607 1.613 3.22-3.226"
        />
      </svg>
    </span>
  );
};

export const UnverifiedIcon = ({ className = 'text-dark-gray' }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7.166 1.633c.467-.393 1.22-.393 1.673 0l1.054.9c.2.167.58.307.846.307h1.134c.706 0 1.286.58 1.286 1.286V5.26c0 .266.14.64.307.84l.9 1.053c.393.467.393 1.22 0 1.673l-.9 1.054a1.52 1.52 0 00-.307.84v1.133c0 .707-.58 1.287-1.286 1.287h-1.134c-.266 0-.64.14-.84.306l-1.053.9c-.467.394-1.22.394-1.673 0l-1.054-.9c-.2-.166-.58-.306-.84-.306H4.113c-.707 0-1.287-.58-1.287-1.287v-1.14c0-.26-.133-.64-.3-.833l-.9-1.06c-.387-.46-.387-1.207 0-1.667l.9-1.06c.167-.2.3-.573.3-.833V4.133c0-.707.58-1.287 1.287-1.287h1.153c.267 0 .64-.14.84-.306l1.06-.907z"
        />
        <path
          stroke="#FAFAFA"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8 5.42v3.22"
        />
        <path
          stroke="#FAFAFA"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M7.996 10.666h.006"
        />
      </svg>
    </span>
  );
};

export default VerifyIcon;
