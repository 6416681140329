/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../Core/Components/CustomRoundedImage";
import { TrashSecondaryIcon } from "../../Common/Svgs/TrashIcon";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import Button from "../../Core/Components/CustomButton";
import ChargerIcon from "../../Common/Svgs/ChargerIcon";
import { updateCharger } from "../../Redux/Slices/chargers.slice";
import { client } from "../../Utils/axiosClient";
import { getChargers } from "../../Redux/APIs/chargersAPI";

const LOCAL_DEFAULT_LIMIT = 10;

const DeleteChargerModal = ({
  visible,
  onCancel,
  currentPage,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();

  const { deleteChargerModal } = useSelector((state) => state.chargers);

  const onConfirm = async () => {
    const payload = {
      chargerId: deleteChargerModal?.charger?.id,
    };

    try {
      dispatch(updateCharger({ key: "loading", value: true }));

      await client.post(
        `${process.env.REACT_APP_API_URL}/charger/delete-charger`,
        payload,
      );

      const response = await dispatch(
        getChargers({
          offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
        }),
      );

      // If no records exist for the current page, move to the previous page
      if (!response.payload.result.length && currentPage > 1) {
        const previousPage = currentPage - 1;
        setCurrentPage(previousPage);

        await dispatch(
          getChargers({
            offset: (previousPage - 1) * LOCAL_DEFAULT_LIMIT,
          }),
        );
      }

      dispatch(
        updateCharger({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "1 charger deleted",
              description: `Charger ${deleteChargerModal?.charger?.serialNo} was deleted successfully`,
            },
          },
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateCharger({ key: "loading", value: false }));
      onCancel();
    }
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>
            <CustomRoundedImage
              size="w-12 h-12"
              image={<ChargerIcon size={24} className="text-white" />}
              background="bg-primary"
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Delete this charger
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to delete this charger from the system?
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            This action cannot be undone.
          </p>
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button colors="alert" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteChargerModal;
