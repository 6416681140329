import React from "react";
import { CustomSecondaryInput } from "../../Core/Components/CustomInput";

const Screen5 = ({ setScreen, details, setDetails }) => {
  return (
    <div className="flex flex-col items-center">
      <h1 className="font-poppins text-2xl text-background-white font-semibold">
        Operational details
      </h1>

      <p className="my-4 font-poppins text-sm font-normal text-background-white-hover text-center">
        How many operational hubs do you manage?
      </p>

      <CustomSecondaryInput
        value={details.hubs}
        onChange={(e) => setDetails({ ...details, hubs: e.target.value })}
      />

      <button
        type="button"
        className="mt-[50px] w-full h-[42px] py-3 rounded-md bg-yellow hover:bg-yellow-dark disabled:cursor-not-allowed"
        onClick={() => setScreen(6)}
        disabled={details.hubs === ""}
      >
        <p className="font-poppins text-xs font-medium">Continue</p>
      </button>
    </div>
  );
};

export default Screen5;
