import React from "react";
import EditIcon from "../../Common/Svgs/EditIcon";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import TrashIcon from "../../Common/Svgs/TrashIcon";
import QRCodeIcon from "../../Common/Svgs/QRCodeIcon";

const useChargerThreeDotsOptions = () => {
  const threeDotsOptions = [
    {
      id: 1,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.CHARGER_EDIT]),
    },
    {
      id: 2,
      value: "View QR Code",
      icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.CHARGER_VIEW_AND_EDIT]),
    },
    {
      id: 3,
      value: "Delete Charger",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.CHARGER_DELETE]),
    },
  ];

  return threeDotsOptions;
};

export default useChargerThreeDotsOptions;
