import React from 'react';
import CloseIcon from '../../Common/Svgs/CloseIcon';
import BatteryStringsWithVoltage from './Data/BatteryStringsWithVoltage';

// TODO : to be reviewed by Preet.
// primary focus - position alignment
// Definitely need to refactor or rewrite but you will have to guide me here.
const BatteryStringsPopUp = ({ visible, setVisible }) => {
  const batteryVoltageData = [
    {
      id: 1,
      voltage: '3.878 V',
    },
    {
      id: 2,
      voltage: '3.878 V',
    },
    {
      id: 3,
      voltage: '3.878 V',
    },
    {
      id: 4,
      voltage: '3.878 V',
    },
    {
      id: 5,
      voltage: '3.878 V',
    },
    {
      id: 6,
      voltage: '3.878 V',
    },
    {
      id: 7,
      voltage: '3.878 V',
    },
    {
      id: 8,
      voltage: '3.878 V',
    },
    {
      id: 9,
      voltage: '3.878 V',
    },
    {
      id: 10,
      voltage: '3.878 V',
    },
    {
      id: 11,
      voltage: '3.878 V',
    },
    {
      id: 12,
      voltage: '3.878 V',
    },
    {
      id: 13,
      voltage: '3.878 V',
    },
    {
      id: 14,
      voltage: '3.878 V',
    },
    {
      id: 15,
      voltage: '3.878 V',
    },
    {
      id: 16,
      voltage: '3.878 V',
    },
    {
      id: 17,
      voltage: '3.878 V',
    },
    {
      id: 18,
      voltage: '3.878 V',
    },
    {
      id: 19,
      voltage: '3.878 V',
    },
    {
      id: 20,
      voltage: '3.878 V',
    },
  ];

  return visible ? (
    <div className="absolute z-10 bg-white top-[330px] right-[354px] rounded-xl w-115 h-106 shadow-modal">
      <div className="p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-3">
            <div className="text-base font-medium font-poppins text-new-black">
              Battery strings
            </div>
            <div className="w-6 h-6 rounded-full bg-background-grey">
              <div className="text-new-black font-poppins font-medium text-10 leading-3.75 text-center py-1">
                20
              </div>
            </div>
          </div>
          <CloseIcon
            className="w-6 h-6 cursor-pointer stroke-dark-grey-text"
            onClick={() => setVisible(false)}
          />
        </div>
        <div className="flex flex-wrap mt-4 gap-x-2">
          {batteryVoltageData.map((item) => (
            <div key={item.id} className="">
              <BatteryStringsWithVoltage id={item.id} voltage={item.voltage} />
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default BatteryStringsPopUp;
