/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from './CustomInput';
import Button from './CustomButton';
import CustomDropdown from './CustomDropdown';
import CloseIcon from '../../Common/Svgs/CloseIcon';
import ChevronIcon from '../../Common/Svgs/ChevronIcon';
import MoreColumnsDropdown from '../../Modules/Trikes/MoreColumnsDropdown';
import { MoreTrikeFilters } from '../../Modules/Trikes/TrikeFilters';
import { MoreBatteryFilters } from '../../Modules/Battery/BatteryFilters';
import { MoreUserFilters } from '../../Modules/Users/UserFilters';
import { MoreServiceFilters } from '../../Modules/Service/ServiceFilters';
import BatteryDetailsFilters from '../../Modules/Battery/BatteryDetailsFilters';
import { resetTrikes } from '../../Redux/Slices/trikes.slice';
import { resetBatteries } from '../../Redux/Slices/batteries.slice';
import { resetUsers } from '../../Redux/Slices/users.slice';
import { resetServices } from '../../Redux/Slices/services.slice';
import { getBatterySwapsById } from '../../Redux/APIs/batteriesAPI';

const CustomTableTopBar = ({
  searchPlaceholder,
  searchFunc,
  searchValue,
  detailsTitle,
  detailsCount,
  dropdownBtnArr,
  customFilters,
  module,
  moreColumnBtn = false,
  showMoreColumnBtn = false,
  showBtnFunc,
  showNewBatteryCTA = false,
  detailsFilter = false,
  showMoreFilters = true,
  addButton,
  selectedTabForUsers,
  showToggleButton = true,
  assetId = null,
}) => {
  const dispatch = useDispatch();

  const { filtersForTrikes } = useSelector((state) => state.trikes);
  const { filtersForBatteries, filtersForBatteryDetails } = useSelector(
    (state) => state.batteries,
  );
  const { filtersForUsers } = useSelector((state) => state.users);
  const { filtersForServices } = useSelector((state) => state.services);

  const [focus, setFocus] = useState(false);
  const [toggleArrow, setToggleArrow] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [filtersLength, setFiltersLength] = useState(0);

  const handleClearAllForBatteries = async () => {
    await dispatch(getBatterySwapsById({ batteryId: assetId }));
    // setSwapStationLocationList([]);
    // setDriverNameList([]);
    // setTrikesList([]);

    dispatch(resetBatteries(['filtersForBatteryDetails']));
  };

  const handleClearAll = () => {
    switch (module) {
      case 'trikes':
        dispatch(resetTrikes(['filtersForTrikes']));
        break;
      case 'batteries':
        dispatch(resetBatteries(['filtersForBatteries']));
        break;
      case 'users':
        dispatch(resetUsers(['filtersForUsers']));
        break;
      case 'service':
        dispatch(resetServices(['filtersForServices']));
        break;
      case 'battery-details':
        handleClearAllForBatteries();
        // dispatch(resetBatteries(['filtersForBatteryDetails']));

        break;
      default:
        dispatch(resetTrikes(['filtersForTrikes']));
    }
  };

  const getAvailableValuesLength = (obj, keyToIgnore = []) => {
    let len = 0;
    // let isDateRangeValid = true;
    let hasDateRangeStart = false;
    let hasDateRangeEnd = false;

    Object.keys(obj).forEach((key) => {
      if (!keyToIgnore.includes(key)) {
        const value = obj[key];

        if (Array.isArray(value)) {
          if (value.length) {
            len += 1;
          }
        } else if (typeof value === 'object' && value !== null) {
          const allKeysHaveValue = Object.keys(value).every(
            (innerKey) => value[innerKey] !== '',
          );

          if (allKeysHaveValue) {
            len += 1;
          }
        } else if (key === 'dateRangeStart') {
          if (value !== '') {
            hasDateRangeStart = true;
          }
        } else if (key === 'dateRangeEnd') {
          if (value !== '') {
            hasDateRangeEnd = true;
          }
        } else if (
          typeof value === 'string' &&
          value !== '' &&
          hasDateRangeStart &&
          hasDateRangeEnd
        ) {
          len += 1;
        } else if (typeof value === 'string' && value !== '') {
          len += 1;
        }
      }
    });

    if (hasDateRangeStart && hasDateRangeEnd) {
      len += 1;
    }

    return len;
  };

  const filtersAppliedCount = () => {
    let len = 0;

    switch (module) {
      case 'trikes':
        len = getAvailableValuesLength(filtersForTrikes, [
          'percentage',
          'status',
          'toggleStatus',
        ]);
        break;
      case 'batteries':
        len = getAvailableValuesLength(filtersForBatteries, [
          'percentage',
          'drivers',
          'status',
        ]);
        break;
      case 'users':
        len = getAvailableValuesLength(filtersForUsers, [
          'category',
          'trike',
          'type',
        ]);
        break;
      case 'service':
        len = getAvailableValuesLength(filtersForServices, [
          'assetType',
          'location',
          'severity',
        ]);
        break;
      case 'battery-details':
        len = getAvailableValuesLength(filtersForBatteryDetails);
        break;
      default:
        len = 0;
    }

    setFiltersLength(len);
  };

  const renderFilters = () => {
    if (!showFilters) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    switch (module) {
      case 'trikes':
        return <MoreTrikeFilters setShowFilters={setShowFilters} />;
      case 'batteries':
        return <MoreBatteryFilters setShowFilters={setShowFilters} />;
      case 'users':
        return <MoreUserFilters setShowFilters={setShowFilters} />;
      case 'service':
        return <MoreServiceFilters setShowFilters={setShowFilters} />;
      case 'battery-details':
        return <BatteryDetailsFilters setShowFilters={setShowFilters} />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    filtersAppliedCount();
  }, [
    filtersForTrikes,
    filtersForBatteries,
    filtersForBatteryDetails,
    filtersForUsers,
    filtersForServices,
  ]);

  useEffect(() => {
    setFocus(false);
  }, [selectedTabForUsers]);

  return (
    <div className="w-full bg-background-white px-6 py-2.5 flex border-t border-t-light-grey justify-between items-center ">
      <div className="flex items-center justify-start gap-x-3">
        {detailsTitle ? (
          <div className="flex items-center justify-start gap-x-3">
            <div className="text-base font-medium font-poppins text-black py-[5.5px]">
              {detailsTitle}
            </div>
            {detailsCount && (
              <div className="font-poppins text-10 leading-3.75 bg-background-grey rounded-full font-medium px-1.5 py-[5px]">
                {detailsCount}
              </div>
            )}
          </div>
        ) : (
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={searchValue}
            focus={focus}
            border={focus ? 'success' : 'none'}
            placeholder={focus ? null : searchPlaceholder}
            onChange={(e) => searchFunc(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              searchValue.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    searchFunc('');
                  }}
                />
              ) : null
            }
          />
        )}

        {!customFilters &&
          dropdownBtnArr?.map((item) => (
            <CustomDropdown key={item.title} items={item.menuItems}>
              <Button
                size={item.size}
                colors={item.color}
                padding={item.padding}
              >
                <div className="flex items-center justify-start gap-x-3">
                  <div>{item.title}</div>
                  <img src="/assets/icons/chevron-down.svg" alt="" />
                </div>
              </Button>
            </CustomDropdown>
          ))}
        {customFilters || null}
      </div>
      <div className="flex items-center justify-end gap-x-3">
        {filtersLength ? (
          <Button
            size="filter"
            colors="white"
            padding="filter"
            onClick={handleClearAll}
          >
            <div className="flex items-center justify-start gap-x-3">
              <div>Clear All</div>
            </div>
          </Button>
        ) : null}
        {!detailsTitle && showMoreFilters ? (
          filtersLength ? (
            <div className="relative">
              <Button
                padding="filter"
                size="filter"
                width="moreColumns"
                onClick={() => setShowFilters(true)}
              >
                <div className="flex item-center gap-x-3">
                  <div className="font-poppins text-xs leading-4.5 font-medium">
                    Filters Applied
                  </div>
                  <div className="w-4 h-4 bg-white text-new-black font-poppins font-medium text-10 leading-3.75 rounded-full flex items-center justify-center">
                    {filtersLength}
                  </div>
                </div>
              </Button>
              {showFilters ? renderFilters(module) : null}
            </div>
          ) : (
            <div className="relative">
              <Button
                size="filter"
                colors="white"
                padding="filter"
                onClick={() => setShowFilters(true)}
              >
                <div className="flex items-center justify-start gap-x-3">
                  <img src="/assets/icons/sort.svg" alt="" />
                  <div>More Filters</div>
                </div>
              </Button>
              {showFilters ? renderFilters(module) : null}
            </div>
          )
        ) : null}

        {detailsFilter ? (
          filtersLength ? (
            <div className="relative">
              <Button
                padding="filter"
                size="filter"
                width="moreColumns"
                onClick={() => setShowFilters(true)}
              >
                <div className="flex item-center gap-x-3">
                  <div className="font-poppins text-xs leading-4.5 font-medium">
                    Filters Applied
                  </div>
                  <div className="w-4 h-4 bg-white text-new-black font-poppins font-medium text-10 leading-3.75 rounded-full flex items-center justify-center">
                    {filtersLength}
                  </div>
                </div>
              </Button>
              {showFilters ? renderFilters(module) : null}
            </div>
          ) : (
            <div className="relative">
              <Button
                size="filter"
                colors="white"
                padding="filter"
                onClick={() => setShowFilters(true)}
              >
                <div className="flex items-center justify-start gap-x-3">
                  <img src="/assets/icons/sort.svg" alt="" />
                  <div>Filters</div>
                </div>
              </Button>
              {showFilters ? renderFilters('battery-details') : null}
            </div>
          )
        ) : null}

        {moreColumnBtn ? <MoreColumnsDropdown /> : null}

        {addButton}

        {(moreColumnBtn ||
          showNewBatteryCTA ||
          showMoreColumnBtn ||
          addButton) &&
        showToggleButton ? (
          <Button
            onClick={() => {
              showBtnFunc();
              setToggleArrow(!toggleArrow);
            }}
            padding="arrows"
            size="arrows"
            colors="grey"
          >
            <ChevronIcon
              className={`w-4 h-4 stroke-new-black ${
                toggleArrow ? '-rotate-90' : 'rotate-90'
              }`}
            />
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export default CustomTableTopBar;
