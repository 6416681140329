import React from "react";

const TrackerMobileIcon = ({ size, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.9 4.75v8c0 3.2-.8 4-4 4H6.1c-3.2 0-4-.8-4-4v-8c0-3.2.8-4 4-4h4.8c3.2 0 4 .8 4 4zM10.1 3.55H6.9"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.5 14.43a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48z"
        />
      </svg>
    </span>
  );
};

export default TrackerMobileIcon;
