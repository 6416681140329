const colorThemes = {
  "#085054": "green-theme",
  "#135bba": "blue-theme",
  "#dd6898": "pink-theme",
  "#aa68d9": "purple-theme",
  "#8e786c": "brown-theme",
  "#878787": "grey-theme",
  "#3a3b3e": "black-theme",
};

export const DEFAULT_THEME = "green-theme";
export const DEFAULT_LOGO =
  "https://smp-content.s3.eu-west-3.amazonaws.com/platform-content/songa_logo.png";

export default colorThemes;
