/* eslint-disable radix */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import TripsIcon from "../../../Common/Svgs/TripsIcon";
import { TrashSecondaryIcon } from "../../../Common/Svgs/TrashIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import { deleteTrip, getTripsV3 } from "../../../Redux/APIs/tripsAPI";
import { resetTrips, updateTrips } from "../../../Redux/Slices/trips.slice";

const LOCAL_DEFAULT_LIMIT = 10;

const DeleteTripModal = ({ visible, id, onCancel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { toastMessage, currentPage } = useSelector((state) => state.trips);

  const onConfirm = async () => {
    const tripId = parseInt(id);

    await dispatch(deleteTrip({ tripId }));
    await dispatch(resetTrips(["deleteTripModal"]));

    const response = await dispatch(
      getTripsV3({
        offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      }),
    );

    // If no records exist for the current page, move to the previous page
    if (!response.payload.result.length && currentPage > 1) {
      const previousPage = currentPage - 1;

      dispatch(updateTrips({ key: "currentPage", value: currentPage + 1 }));

      await dispatch(
        getTripsV3({
          offset: (previousPage - 1) * LOCAL_DEFAULT_LIMIT,
        }),
      );
    }

    navigate("/trips");

    dispatch(
      updateTrips({
        key: "toastMessage",
        value: {
          ...toastMessage,
          visible: true,
          type: "tripDeleted",
        },
      }),
    );
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>
            <CustomRoundedImage
              size="w-12 h-12"
              image={<TripsIcon className="text-primary" />}
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Delete trip
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to delete this trip from the system?
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            This action cannot be undone.
          </p>
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button colors="alert" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteTripModal;
