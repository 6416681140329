/* eslint-disable no-lonely-if */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Checkbox } from "antd";
import ModuleHeader from "./ModuleHeader";
import { locationPermissions } from "./data";
import PermissionTitle from "./PermissionTitle";
import { PERMISSIONS } from "../../Login/constants";
import { locationAddEditDelete } from "./permissionFunctions";
import LocationsModuleIcon from "../../../Common/Svgs/LocationsModuleIcon";

const CONDITIONS = {
  VIEW_AND_EDIT: [PERMISSIONS.LOCATIONS_VIEW, PERMISSIONS.LOCATION_NO_ACCESS],
  // VIEW_ONLY: ["location_view_and_edit", "location_no_access"],
  // NO_ACCESS: ["location_view_and_edit", PERMISSIONS.LOCATIONS_VIEW],
  REMOVE_ALL: [
    PERMISSIONS.LOCATION_ADD,
    PERMISSIONS.LOCATION_EDIT,
    PERMISSIONS.LOCATION_DELETE,
    PERMISSIONS.LOCATIONS_VIEW,
    PERMISSIONS.LOCATION_VIEW_AND_EDIT,
    PERMISSIONS.LOCATION_NO_ACCESS,
  ],
};

const LocationPermissions = ({ permissions, setPermissions }) => {
  // check if the permissions needs to be disabled
  const disabled = CONDITIONS.VIEW_AND_EDIT.some((d) =>
    permissions.includes(d),
  );

  const handleModulePermission = (permissionId) => {
    let local = [];

    if (permissionId === PERMISSIONS.LOCATION_VIEW_AND_EDIT) {
      local = permissions.filter(
        (item) => !CONDITIONS.VIEW_AND_EDIT.includes(item),
      );
    }

    // removing all the location permissions
    if (permissionId === PERMISSIONS.LOCATIONS_VIEW) {
      local = permissions.filter(
        (item) => !CONDITIONS.REMOVE_ALL.includes(item),
      );
    }

    // removing all the location permissions
    if (permissionId === PERMISSIONS.LOCATION_NO_ACCESS) {
      local = permissions.filter(
        (item) => !CONDITIONS.REMOVE_ALL.includes(item),
      );
    }

    setPermissions([...local, permissionId]);
  };

  const handleIndividualPermission = (permissionId) => {
    let local = [];

    if (permissions.includes(permissionId)) {
      local = permissions.filter((item) => item !== permissionId);
      setPermissions(local);
    } else {
      // handling multiple insertions for location add, edit, delete
      if (permissionId === PERMISSIONS.LOCATION_ADD) {
        locationAddEditDelete({ setPermissions });
      } else {
        setPermissions((prev) => [...prev, permissionId]);
      }
    }
  };

  //   console.log(permissions, "check permisisons");

  return (
    <div>
      <ModuleHeader
        module="Locations"
        icon={<LocationsModuleIcon size={16} className="text-primary" />}
      />
      {/* main module permission */}
      <div className="w-full h-[60px]">
        <div
          className="flex items-center w-full h-full border-b"
          style={{
            background: "#FAF2D8",
            borderColor: "#FAD152",
          }}
        >
          <div
            className="w-[40%] border-r py-3 px-6"
            style={{
              borderColor: "#FAD152",
            }}
          >
            <PermissionTitle
              title={locationPermissions[0].title}
              description={locationPermissions[0].description}
            />
          </div>

          <div className="w-[60%] h-full flex items-center">
            {locationPermissions[0].options.map((option) => {
              // rendering empty div for layout consistency
              if (!option) {
                return (
                  <div
                    className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                    style={{
                      borderColor: "#FAD152",
                    }}
                  />
                );
              }

              const selected = permissions.includes(option.permissionId);

              return (
                <div
                  key={option.text}
                  className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                  style={{
                    borderColor: "#FAD152",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    onClick={() => handleModulePermission(option.permissionId)}
                  />
                  <p
                    className={`font-poppins text-xs ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {option.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* individual functionality permission */}
      <div className="w-full">
        {locationPermissions.slice(1).map((item) => {
          return (
            <div
              key={item.id}
              className="flex items-center w-full h-[60px] border-b"
              style={{
                borderColor: "#DFDFDF",
              }}
            >
              <div
                className="w-[40%] h-full border-r py-3 px-6"
                style={{
                  borderColor: "#DFDFDF",
                }}
              >
                <PermissionTitle
                  title={item.title}
                  description={item.description}
                />
              </div>

              <div className="w-[60%] h-full flex items-center">
                {item.options.map((option, idx) => {
                  // rendering empty div for layout consistency
                  if (!option) {
                    return (
                      <div
                        key={idx}
                        className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                        style={{
                          borderColor: "#DFDFDF",
                        }}
                      />
                    );
                  }

                  const selected = permissions.includes(option.permissionId);

                  // if the permission is view only, remove pointer events and add an opacity
                  return (
                    <div
                      key={option.text}
                      className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                      style={{
                        borderColor: "#DFDFDF",
                        opacity: disabled && 0.5,
                        pointerEvents: disabled && "none",
                      }}
                    >
                      {/* if the permissions should be disabled then it should not
                          show any tick mark else show the selected tick mark */}
                      <Checkbox
                        checked={disabled ? false : selected}
                        onClick={() =>
                          handleIndividualPermission(option.permissionId)
                        }
                      />
                      <p
                        className={`font-poppins text-xs
                            ${selected ? "font-medium" : "font-normal"}
                            `}
                      >
                        {option.text}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LocationPermissions;
