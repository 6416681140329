/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../Core/Components/CustomRoundedImage";
import { TrashSecondaryIcon } from "../../Common/Svgs/TrashIcon";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import Button from "../../Core/Components/CustomButton";
import RateManagerIcon from "../../Common/Svgs/RateManagerIcon";
import { updateRates } from "../../Redux/Slices/rates.slice";
import { client } from "../../Utils/axiosClient";
import { getCommodities } from "../../Redux/APIs/ratesAPI";

const DeleteCommodityModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const { currentCommodity } = useSelector((state) => state.rates);

  const onConfirm = async () => {
    const payload = {
      commodityId: currentCommodity.id,
    };

    try {
      dispatch(updateRates({ key: "loading", value: true }));

      await client.post(
        `${process.env.REACT_APP_API_URL}/commodity/delete-commodity`,
        payload,
      );

      await dispatch(getCommodities());

      dispatch(
        updateRates({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "Commodity Deleted",
              description: `${currentCommodity?.name} was deleted for ${currentCommodity?.hubName} successfully`,
            },
          },
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateRates({ key: "loading", value: false }));
      onCancel();
    }
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>
            <CustomRoundedImage
              size="w-12 h-12"
              image={<RateManagerIcon size={24} className="text-white" />}
              background="bg-primary"
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Delete this commodity
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to delete this commodity from the hub station?
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            This action cannot be undone.
          </p>
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button colors="alert" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteCommodityModal;
