import React from 'react';

const LoadIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.667 14.666h2.666C12.667 14.666 14 13.333 14 10V6c0-3.334-1.333-4.667-4.667-4.667H6.667C3.333 1.333 2 2.666 2 6v4c0 3.333 1.333 4.666 4.667 4.666z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M0.75 -0.75L8.421 -0.75"
          transform="matrix(.32711 .94499 -.98837 .15206 6 3.333)"
        />
      </svg>
    </span>
  );
};

export default LoadIcon;
