import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: {},
  selectedRowIds: [],
  viewModal: {
    visible: false,
    data: null,
  },
  threeDotsPopoverId: 0,
};

export const qrCodeSlice = createSlice({
  name: "qrCode",
  initialState,
  reducers: {
    updateQrCode: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { updateQrCode } = qrCodeSlice.actions;
export default qrCodeSlice.reducer;
