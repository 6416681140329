import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const useHeaderButtons = () => {
  const headerButtons = [
    {
      id: 1,
      padding: "filter",
      width: "settingsDriversTab",
      text: "Drivers",
      hasPermission: usePermission([
        PERMISSIONS.DRIVER_VIEW,
        PERMISSIONS.DRIVER_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 2,
      padding: "filter",
      width: "settingsDriversTab",
      text: "Partners",
      hasPermission: usePermission([
        PERMISSIONS.PARTNER_VIEW,
        PERMISSIONS.PARTNER_VIEW_AND_EDIT,
      ]),
    },
  ];

  return headerButtons;
};

export default useHeaderButtons;
