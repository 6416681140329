/* eslint-disable no-nested-ternary */
import { Drawer } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '../../Common/Svgs/CloseIcon';
import CustomTable from '../../Core/Components/CustomTable';
import { userDetailsColumn } from './Data/UsersListData';
import UserIcon from '../../Common/Svgs/UserIcon';

const ViewDetailsDrawer = ({ visible, onCancel }) => {
  const { currentTrike } = useSelector((state) => state.trikes);
  const { tripsForDriver } = useSelector((state) => state.trips);
  const { tripDetailsDrawer } = useSelector((state) => state.users);

  const data = tripsForDriver?.map((trip) => ({
    ...trip,
    averageSpeed: currentTrike.averageSpeed,
  }));

  return (
    <Drawer
      width={560}
      placement="right"
      closable={false}
      maskStyle={{ background: 'transparent' }}
      open={visible}
      onClose={onCancel}
    >
      <div className="flex justify-between p-6 bg-background-white">
        <div>
          <div className="text-base font-medium font-poppins">
            <span className="capitalize">
              {currentTrike.driverFirstName
                ? `${currentTrike?.driverFirstName} ${currentTrike.driverLastName} - Trip Details`
                : tripDetailsDrawer.driverName
                ? `${tripDetailsDrawer.driverName} - Trip Details`
                : '--'}
            </span>
          </div>
          <div className="mt-6 text-xs leading-4.5 font-light font-poppins text-dark-grey-text capitalize">
            No. of trips
          </div>
        </div>
        <div>
          <CloseIcon
            className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
            onClick={onCancel}
          />
          <div className="mt-6 text-xs leading-4.5 font-medium font-poppins">
            {tripsForDriver?.length}
          </div>
        </div>
      </div>
      <div className="custom-header-background">
        <CustomTable
          columns={userDetailsColumn}
          data={data}
          scrollX={null}
          emptyState={
            <UserIcon className="w-4 h-4 fill-none stroke-dark-grey-text" />
          }
        />
      </div>
    </Drawer>
  );
};

export default ViewDetailsDrawer;
