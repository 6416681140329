import React from "react";
import { BatterySecondaryIcon } from "../../../Common/Svgs/BatteryIcon";
import ChargerIcon from "../../../Common/Svgs/ChargerIcon";
import usePermission from "../../hooks/usePermission";
import { PERMISSIONS } from "../../../Modules/Login/constants";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import InventoryIcon from "../../../Common/Svgs/InventoryIcon";
import UserIcon from "../../../Common/Svgs/UserIcon";
import LocationsModuleIcon from "../../../Common/Svgs/LocationsModuleIcon";
import ServiceIcon from "../../../Common/Svgs/ServiceIcon";
import QRCodeIcon from "../../../Common/Svgs/QRCodeIcon";
import RevenueManagerIcon from "../../../Common/Svgs/RevenueManagerIcon";
import RateManagerIcon from "../../../Common/Svgs/RateManagerIcon";

export const useEnergyResourcesItem = () => {
  // TODO: update the hasPermission for chargers
  const items = [
    {
      label: "Batteries",
      icon: <BatterySecondaryIcon />,
      path: "/batteries",
      hasPermission: usePermission([
        PERMISSIONS.BATTERY_VIEW,
        PERMISSIONS.BATTERY_VIEW_AND_EDIT,
      ]),
    },
    {
      label: "Chargers",
      icon: <ChargerIcon />,
      path: "/chargers",
      hasPermission: usePermission([
        PERMISSIONS.CHARGER_VIEW,
        PERMISSIONS.CHARGER_VIEW_AND_EDIT,
      ]),
    },
  ];

  return items;
};

export const useAssetsItem = () => {
  const items = [
    {
      label: "Trikes",
      icon: <TrikeIcon size={16} />,
      path: "/trikes",
      hasPermission: usePermission([
        PERMISSIONS.TRIKE_VIEW,
        PERMISSIONS.TRIKE_VIEW_AND_EDIT,
      ]),
    },
    {
      label: "Inventory",
      icon: <InventoryIcon size={16} />,
      path: "/inventory",
      hasPermission: usePermission([PERMISSIONS.INVENTORY_VIEW]),
    },
  ];

  return items;
};

export const useManagementCentreItem = () => {
  const items = [
    {
      label: "Users",
      icon: <UserIcon size={16} />,
      path: "/users",
      hasPermission: usePermission([
        PERMISSIONS.DRIVER_VIEW,
        PERMISSIONS.DRIVER_VIEW_AND_EDIT,
        PERMISSIONS.PARTNER_VIEW,
        PERMISSIONS.PARTNER_VIEW_AND_EDIT,
      ]),
    },
    {
      label: "Locations",
      icon: <LocationsModuleIcon size={16} />,
      path: "/locations",
      hasPermission: usePermission([
        PERMISSIONS.LOCATIONS_VIEW,
        PERMISSIONS.LOCATION_VIEW_AND_EDIT,
      ]),
    },
    {
      label: "QR Codes",
      icon: <QRCodeIcon size={16} />,
      path: "/qr-codes",
      hasPermission: usePermission([
        PERMISSIONS.TRIKE_VIEW_QR,
        PERMISSIONS.BATTERY_VIEW_QR,
        PERMISSIONS.PARTNER_VIEW_QR,
      ]),
    },
    {
      label: "Service",
      icon: <ServiceIcon size={16} />,
      path: "/service",
      hasPermission: usePermission([
        PERMISSIONS.SERVICE_VIEW,
        PERMISSIONS.SERVICE_VIEW_AND_EDIT,
      ]),
    },
  ];

  return items;
};

// TODO: update the hasPermission
export const useFinanceItem = () => {
  const items = [
    {
      label: "Revenue Manager",
      icon: <RevenueManagerIcon size={16} />,
      path: "/revenue?tab=payments",
      identifier: "revenue",
      hasPermission: true,
    },
    {
      label: "Rates Manager",
      icon: <RateManagerIcon size={16} />,
      path: "/rates",
      identifier: "rates",
      hasPermission: true,
    },
  ];

  return items;
};
