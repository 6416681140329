import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import { TrashSecondaryIcon } from "../../../Common/Svgs/TrashIcon";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import { deleteUser, getSystemUsers } from "../../../Redux/APIs/systemUsersAPI";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import UserIcon from "../../../Common/Svgs/UserIcon";

const DeleteSystemUserModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const { deleteSystemUserModal } = useSelector((state) => state.loggedInUser);

  const onConfirm = async () => {
    await dispatch(deleteUser({ userId: deleteSystemUserModal.user.id }));

    onCancel();

    await dispatch(getSystemUsers());

    dispatch(
      updateLoggedInUser({
        key: "toastMessageModal",
        value: {
          visible: true,
          title: "1 User Deleted",
          description: `${deleteSystemUserModal.user.firstName} ${deleteSystemUserModal.user.lastName} was deleted successfully.`,
        },
      }),
    );
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>
            <CustomRoundedImage
              size="w-12 h-12"
              image={<UserIcon className="stroke-white fill-none" />}
              background="bg-primary"
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            Delete system user
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to delete this user from the system?
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            This action cannot be undone.
          </p>
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button colors="alert" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteSystemUserModal;
