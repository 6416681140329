import React from "react";

const getUserType = (type) => {
  switch (type) {
    case 1:
      return (
        <div className="py-1 px-2 bg-[#F5F3FF] rounded-md">
          <p className="font-poppins text-xs font-medium text-[#775DFA]">
            Driver
          </p>
        </div>
      );

    case 2:
      return (
        <div className="py-1 px-2 bg-[#FEFAED] rounded-md">
          <p className="font-poppins text-xs font-medium text-[#EAB726]">
            Farmer
          </p>
        </div>
      );

    case 3:
      return (
        <div className="py-1 px-2 bg-green-10 rounded-md">
          <p className="font-poppins text-xs font-medium text-green">
            Off-Taker
          </p>
        </div>
      );

    default:
      return (
        <div className="py-1 px-2 bg-[#F5F3FF] rounded-md">
          <p className="font-poppins text-xs font-medium text-[#775DFA]">
            Driver
          </p>
        </div>
      );
  }
};

export default getUserType;
