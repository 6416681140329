/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { CustomInputWithController } from "../../Core/Components/CustomInput";
import CustomLayout from "../../Core/Layout/CustomLayout";
import Button from "../../Core/Components/CustomButton";
import { PopoverDropdown } from "../../Core/Components/CustomDropdown";
import {
  CHARGER_TYPE,
  chargerCurrentOptions,
  chargerTypeFilters,
} from "./Data/constants";
import { updateCharger } from "../../Redux/Slices/chargers.slice";
import { addCharger, editCharger } from "../../Redux/APIs/chargersAPI";
import { getCurrentType } from "./getCurrentType";
import useHubStationOnAdd from "../../Utils/useHubStationOnAdd";

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
}) => {
  return (
    <div className="">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="createUser"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
      />
    </div>
  );
};

const AddCharger = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { currentCharger } = useSelector((state) => state.chargers);

  // const hubStations = allLocations
  //   .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
  //   .map((location) => ({
  //     id: location.id,
  //     value: location.name,
  //   }));

  // get all the hubs
  const hubStations = useHubStationOnAdd();

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    clearErrors,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      serialNo: id ? currentCharger.serialNo : "",
      tagNo: id ? currentCharger.tagNo : "",
      chargerTypeId: id
        ? currentCharger.chargerTypeId
        : chargerTypeFilters[0].id,
      chargerTypeName: id
        ? currentCharger.chargerTypeName
        : chargerTypeFilters[0].value,
      currentId: id ? currentCharger.currentId : chargerCurrentOptions[0].id,
      currentValue: id
        ? getCurrentType(currentCharger.currentId)
        : chargerCurrentOptions[0].value,
      hubId: id ? currentCharger?.hubId : null,
      hubName: id ? currentCharger?.hubName : "",
    },
  });

  const [focus, setFocus] = useState({
    serialNo: false,
    tagNo: false,
  });

  const rules = {
    required: "Required.",
  };

  const hubNameError = formState?.errors?.hubName?.message;

  const onCancel = () => navigate(-1);

  const onAdd = async (e) => {
    const payload = e;

    if (id) {
      payload.chargerId = id;
      const editResponse = await dispatch(editCharger(payload));

      if (
        editResponse?.payload?.response &&
        editResponse?.payload?.response?.status !== 200
      ) {
        setError("serialNo", {
          type: "server",
          shouldFocus: true,
          message: editResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateCharger({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Charger Edited",
                description: `Charger ${e.serialNo} was edited successfully`,
              },
            },
          }),
        );

        navigate(-1);
      }
    } else {
      const addResponse = await dispatch(addCharger(payload));

      if (
        addResponse?.payload?.response &&
        addResponse?.payload?.response?.status !== 200
      ) {
        setError("serialNo", {
          type: "server",
          shouldFocus: true,
          message: addResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateCharger({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Charger Added",
                description: `Charger ${e.serialNo} was added successfully`,
              },
            },
          }),
        );

        navigate(-1);
      }
    }
  };

  return (
    <CustomLayout pageName="Chargers" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            {id ? "edit charger" : "Add a new charger"}
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onAdd)}
            >
              {id ? "Edit" : "Add"} Charger
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-[calc(100vh-88px-72px)] bg-background-white p-6 flex flex-col gap-y-6 overflow-y-scroll">
        <LabelAndInput
          label="Serial No."
          control={control}
          formState={formState}
          name="serialNo"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.serialNo}
          setFocus={(state) => setFocus({ ...focus, serialNo: state })}
        />

        <LabelAndInput
          label="KSC Tag No."
          control={control}
          formState={formState}
          name="tagNo"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.tagNo}
          setFocus={(state) => setFocus({ ...focus, tagNo: state })}
        />

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Charger Type
          </p>

          <Controller
            name="chargerTypeName"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                placeholder="Select Charger Type"
                className="max-h-[300px] overflow-y-scroll"
                formState={formState}
                options={chargerTypeFilters}
                onSelect={(data) => {
                  // if the charger type is selected as canbus, the current will be 10 amps of canbus
                  // if the charger type is selected as non-canbus, the current will be 10 amps (1 battery) of non-canbus
                  if (data.id === CHARGER_TYPE.CANBUS) {
                    setValue("currentId", chargerCurrentOptions[0].id);
                    setValue("currentValue", chargerCurrentOptions[0].value);
                  } else {
                    setValue("currentId", chargerCurrentOptions[1].id);
                    setValue("currentValue", chargerCurrentOptions[1].value);
                  }

                  setValue("chargerTypeName", data.value);
                  setValue("chargerTypeId", data.id);
                }}
              />
            )}
          />
        </div>

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Current
          </p>

          {/* do not show the first option of chargerCurrentOptions array (as the
          first entry corresponds to the canbus charger ) */}
          <Controller
            name="currentValue"
            control={control}
            rules={rules}
            render={({ field }) => (
              // disable the popup if the charger type is selected as canbus
              <PopoverDropdown
                {...field}
                placeholder="Select Charger Type"
                formState={formState}
                options={chargerCurrentOptions.slice(1)}
                disabled={watch("currentId") === 1}
                onSelect={(data) => {
                  setValue("currentValue", data.value);
                  setValue("currentId", data.id);
                }}
              />
            )}
          />
        </div>

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Hub Station
          </p>

          <Controller
            name="hubName"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Hub Station"
                placeholder="Assign Hub Station"
                className="max-h-[300px] overflow-y-scroll"
                formState={formState}
                options={hubStations}
                onSelect={(data) => {
                  setValue("hubName", data.value);
                  setValue("hubId", data.id);
                  clearErrors("hubName");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              hubNameError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {hubNameError}
          </p>
        </div>
      </div>
    </CustomLayout>
  );
};

export default AddCharger;
