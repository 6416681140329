/* eslint-disable import/prefer-default-export */
import React from 'react';
import EditIcon from '../../../Common/Svgs/EditIcon';
import AddToServiceIcon from '../../../Common/Svgs/AddToServiceIcon';

export const SEVERITY = ['Low', 'Medium', 'Serious', 'Critical'];

export const severityOptions = [
  {
    id: 1,
    value: 'Low',
    textColor: 'text-dark-grey-text',
  },
  {
    id: 2,
    value: 'Medium',
    textColor: 'text-light-blue',
  },
  {
    id: 3,
    value: 'Serious',
    textColor: 'text-warning',
  },
  {
    id: 4,
    value: 'Critical',
    textColor: 'text-alert-red',
  },
];

export const typeFilters = [
  {
    id: 1,
    value: 'Trike',
  },
  {
    id: 2,
    value: 'Battery',
  },
];

export const severityFilters = [
  {
    id: 1,
    value: 'Low',
    className: 'text-dark-grey-text',
  },
  {
    id: 2,
    value: 'Medium',
    className: 'text-light-blue',
  },
  {
    id: 3,
    value: 'Serious',
    className: 'text-warning',
  },
  {
    id: 4,
    value: 'Critical',
    className: 'text-alert-red',
  },
];

export const threeDotsOptions = [
  {
    id: 1,
    value: 'Edit Info',
    icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 2,
    value: 'Complete Service',
    icon: (
      <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
    ),
  },
];

export const SERVICE_ASSET_TYPE = {
  TRIKE: 1,
  BATTERY: 2,
};
