import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import CloseIcon from '../../Common/Svgs/CloseIcon';
import Button from '../../Core/Components/CustomButton';
import CustomSearchAndSelect from '../../Core/Components/CustomSearchAndSelect';
import CustomSelectWithCheckbox from '../../Core/Components/CustomSelectWithCheckbox';
import ChevronIcon from '../../Common/Svgs/ChevronIcon';
import { usersDropdownBtnArr } from './Data/UsersListData';
import { getUserDetails } from '../../Redux/APIs/usersAPI';
import { resetUsers, updateUsers } from '../../Redux/Slices/users.slice';
import getUniqueList from '../../Utils/getUniqueList';

const MoreUserFilters = ({ setShowFilters }) => {
  const dispatch = useDispatch();

  const { filtersForUsers, addedByFilters } = useSelector(
    (state) => state.users,
  );

  const [finalList, setFinalList] = useState([]);
  const [dateRangeValues, setDateRangeValues] = useState({
    startTime: '',
    endTime: '',
  });

  const uniqueAddedByList = getUniqueList(addedByFilters, 'name');

  const onCancel = () => {
    setShowFilters(false);
  };

  const hasFieldValues = () => {
    return (
      filtersForUsers?.addedBy?.length > 0 ||
      (filtersForUsers?.dateRangeStart !== '' &&
        filtersForUsers?.dateRangeEnd !== '')
    );
  };

  const handleDateRangeChange = (e) => {
    // console.log(e, 'check date e');
    if (e && e.length === 2 && e[0] && e[1]) {
      const startTimeCheck = e[0].format('YYYY-MM-DD');
      const endTimeCheck = e[1].format('YYYY-MM-DD');

      setDateRangeValues({
        ...dateRangeValues,
        startTime: startTimeCheck,
        endTime: endTimeCheck,
      });
      dispatch(
        updateUsers({
          key: 'filtersForUsers',
          value: {
            ...filtersForUsers,
            dateRangeStart: startTimeCheck,
            dateRangeEnd: endTimeCheck,
          },
        }),
      );
    }
  };

  // useEffect(() => {
  //   setFinalList(filtersForUsers?.addedBy);
  // }, [filtersForUsers?.addedBy]);

  const handleApplyFilters = () => {
    dispatch(
      getUserDetails({
        addedBy: filtersForUsers?.addedBy,
        userType: filtersForUsers?.category,
        trike: filtersForUsers?.trike,
        userRole: filtersForUsers?.type,
        dateRangeStart: filtersForUsers?.dateRangeStart,
        dateRangeEnd: filtersForUsers?.dateRangeEnd,
      }),
    );
    onCancel();
  };

  const handleClearAll = () => {
    setFinalList([]);
    dispatch(resetUsers(['filtersForUsers']));
  };

  // useEffect(() => {
  //   dispatch(resetUsers(['filtersForUsers']));
  // }, [filtersForUsers]);

  return (
    <div className="absolute z-10 bg-white right-[0px] top-10 w-78 rounded-md p-3 shadow-moreFilters">
      <div className="flex items-center justify-between">
        <div className="text-base font-medium text-new-black font-poppins">
          More filters
        </div>
        <CloseIcon
          className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
          onClick={onCancel}
        />
      </div>
      <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mt-6">
        Date Range
      </div>
      <DatePicker.RangePicker
        value={[
          filtersForUsers?.dateRangeStart !== '' &&
            dayjs(filtersForUsers?.dateRangeStart),
          filtersForUsers?.dateRangeEnd !== '' &&
            dayjs(filtersForUsers?.dateRangeEnd),
        ]}
        format="DD MMM YYYY"
        separator={<div className="text-dark-grey-text"> - </div>}
        onCalendarChange={(e) => handleDateRangeChange(e)}
        allowClear={false}
        className="h-[39px] mt-2"
      />
      <div className="mt-6">
        <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text">
          Added By
        </div>
        <CustomSearchAndSelect
          placeholder="Type or Select User"
          inputList={uniqueAddedByList}
          finalList={finalList}
          setFinalList={setFinalList}
          onChange={(e) =>
            dispatch(
              updateUsers({
                key: 'filtersForUsers',
                value: { ...filtersForUsers, addedBy: e },
              }),
            )
          }
        />
      </div>
      <div className="flex mt-6 gap-x-3">
        <Button
          size="primary"
          width="save"
          colors="grey"
          modifier={hasFieldValues() ? null : 'blur'}
          onClick={handleClearAll}
        >
          Clear All
        </Button>
        <Button
          size="primary"
          width="save"
          type="submit"
          modifier={hasFieldValues() ? null : 'blur'}
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
};

const UserFilters = () => {
  const dispatch = useDispatch();

  const {
    filtersForUsers,
    userCategoryFilters,
    userRoleFilters,
    trikesFilters,
  } = useSelector((state) => state.users);

  const trikeIds = getUniqueList(trikesFilters, 'value').filter(
    (trike) => trike.value !== null,
  );

  const functionSwitch = (e, filter) => {
    switch (filter) {
      case 'User Category':
        return dispatch(
          updateUsers({
            key: 'filtersForUsers',
            value: { ...filtersForUsers, category: e },
          }),
        );
      case 'Current Trike':
        return dispatch(
          updateUsers({
            key: 'filtersForUsers',
            value: { ...filtersForUsers, trike: e },
          }),
        );
      case 'Type':
        return dispatch(
          updateUsers({
            key: 'filtersForUsers',
            value: { ...filtersForUsers, type: e },
          }),
        );
      default:
        return dispatch(
          updateUsers({
            key: 'filtersForUsers',
            value: { ...filtersForUsers, category: e },
          }),
        );
    }
  };

  const filterSwitch = (filter) => {
    switch (filter) {
      case 'User Category':
        return userCategoryFilters;
      case 'Current Trike':
        return trikeIds;
      case 'UserType':
        return userRoleFilters;
      default:
        return userCategoryFilters;
    }
  };

  useEffect(() => {
    dispatch(resetUsers(['filtersForUsers']));
  }, []);

  useEffect(() => {
    dispatch(
      getUserDetails({
        userType: filtersForUsers?.category,
        trike: filtersForUsers?.trike,
        // userRole: filtersForUsers?.type,
      }),
    );
  }, [filtersForUsers]);

  return (
    <div className="flex items-center justify-start gap-x-3">
      {usersDropdownBtnArr?.map((item) => (
        <CustomSelectWithCheckbox
          key={item.title}
          filterType={item.renderBody}
          selectedVal={filtersForUsers?.[item.filters]}
          inputList={filterSwitch(item.renderBody)}
          onChange={(e) => functionSwitch(e, item.title)}
        >
          {filtersForUsers?.[item.filters]?.length === 0 ? (
            <Button size={item.size} colors={item.color} padding={item.padding}>
              <div className="flex items-center justify-start gap-x-3">
                <div>{item.title}</div>
                <img src="/assets/icons/chevron-down.svg" alt="" />
              </div>
            </Button>
          ) : (
            <Button padding="filter" size="filter" width="dynamic">
              <div className="flex item-center gap-x-3">
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  {item.title}
                </div>
                <div className="w-4.5 h-4.5 bg-white text-black font-aileron font-semibold text-10 leading-3.75 rounded-full flex items-center justify-center">
                  {filtersForUsers?.[item.filters]?.length}
                </div>
                <div>
                  <ChevronIcon className="w-4 h-4 -rotate-90 stroke-white" />
                </div>
              </div>
            </Button>
          )}
        </CustomSelectWithCheckbox>
      ))}
    </div>
  );
};

export { MoreUserFilters, UserFilters };
