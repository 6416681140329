import React from "react";

const Footer = () => {
  return (
    <div className="w-full h-10 bg-background-white z-[100]">
      <div className="flex items-center justify-center w-full h-full py-2 gap-x-2">
        <p className="text-xs font-normal capitalize text-dark-gray font-aileron">
          Powered by
        </p>
        <img
          src="/assets/songa-main-logo.svg"
          alt="Songa Mobility"
          className="w-[110px] h-6"
        />
      </div>
    </div>
  );
};

export default Footer;
