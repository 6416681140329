import React from 'react';
import { BatteryEmptyIcon } from '../../../Common/Svgs/BatteryIcon';

const BatteryStringsWithVoltage = ({ id, voltage }) => {
  // TODO: to be reviewed by Preet
  // need to come up with a better way to place the battery id in center of the svg icon
  return (
    <div className="relative px-4 mt-2 rounded-md w-19 h-19 bg-background-grey">
      <div className="relative px-0.5">
        <BatteryEmptyIcon />
        <div className="absolute top-[13px] left-[35%] text-dark-grey-text text-10 font-poppins leading-3.75 font-light">
          {id}
        </div>
      </div>
      <div className="pt-2 font-poppins font-medium text-xs leading-4.5 text-dark-grey-text whitespace-nowrap">
        {voltage}
      </div>
    </div>
  );
};

export default BatteryStringsWithVoltage;
