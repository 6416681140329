/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Row } from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ReactDOMServer from "react-dom/server";
import L from "leaflet";
import { MapContainer, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import CustomTags from "../Components/CustomTags";
import TrikeMapMarkerDetails, {
  BatteryDetailsDrawer,
  TrikeDetailsDrawer,
} from "../Components/CustomMapMarkerDetails";
import CustomMapMarker from "../Components/CustomMapMarker";
import { ExpandButton, GeoFenceButton } from "../Components/MapMarkerButtons";
import GeoFenceModal from "../Components/Modal/GeoFenceModal/GeoFenceModal";
import { updateTrikes } from "../../Redux/Slices/trikes.slice";
import getGeofencePoint from "../../Utils/getGeofencePoint";
import FullScreenMap from "../../Modules/Trikes/FullScreenMap";
import TripDetailsMapSection from "../../Modules/Trips/TripDetails/TripDetailsMapSection";
import ViewDetailsDrawer from "../../Modules/Users/ViewDetailsDrawer";
import getBatteryStatus from "../../Utils/getBatteryStatus";
import { MobiliseModalCTA } from "../Components/Modal/MobiliseModalV3";
import getTrikeStatus from "../../Modules/Trikes/phase3/getTrikeStatus";
import TripDetailsHeader from "../../Modules/Trips/phase3/TripDetailsHeader";
import TrikeTrackerSwitch from "../Components/TrikeTrackerSwitch";
import TrikeTrackerModal from "../../Modules/Trikes/phase3/TrikeTrackerModal";
import ToastMessageModal from "../../Modules/Trikes/phase3/ToastMessageModal";
import usePermission from "../hooks/usePermission";
import { PERMISSIONS } from "../../Modules/Login/constants";
import {
  INITIAL_ZOOM_LEVEL,
  MAX_BOUNDS,
} from "../../Modules/Home/Data/HomeData";

const renderMarkerIcon = (icon, topIcon, colors) => {
  // eslint-disable-next-line new-cap
  return new L.divIcon({
    html: ReactDOMServer.renderToString(
      <CustomMapMarker
        position="map"
        icon={icon}
        topIcon={topIcon}
        colors={colors}
      />,
    ),
  });
};

const lineOptions = {
  color: "#085054",
};

const DetailsHeader = ({ assetType }) => {
  const { currentTrike } = useSelector((state) => state.trikes);
  const { currentBattery } = useSelector((state) => state.batteries);

  return (
    <>
      {assetType === "trips" ? (
        <TripDetailsHeader />
      ) : (
        <div className="flex items-center justify-between px-6 border-b border-r bg-background-white border-light-grey h-14">
          <div className="text-base font-medium font-poppins text-new-black">
            Overview
          </div>
          <div className="flex items-center justify-end gap-x-3">
            {assetType === "batteries" ? (
              <p>{getBatteryStatus(currentBattery.status)}</p>
            ) : (
              <div>{getTrikeStatus(+currentTrike.status)}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const MapDetailsHeader = ({ assetType }) => {
  const { currentTrike } = useSelector((state) => state.trikes);

  const hasMobilisePermission = usePermission([PERMISSIONS.TRIKE_MOBILISE]);

  const renderText = () => {
    switch (assetType) {
      case "trikes":
      case "batteries":
        return "Live Location";
      case "trips":
        return "Trip Route";
      default:
        return "Live Location";
    }
  };

  return (
    <div className="flex items-center justify-between px-6 border-b bg-background-white border-light-grey h-14">
      <div>
        {assetType === "trikes" ? (
          <p className="text-base font-medium text-black capitalize font-poppins">
            Live Location
          </p>
        ) : (
          <p className="text-base font-medium text-black capitalize font-poppins">
            {renderText()}
          </p>
        )}
      </div>
      {assetType === "trikes" ? (
        <div className="flex items-center justify-end gap-x-3">
          <CustomTags colors="service" background="service">
            <div className="flex gap-x-2">
              <img src="/assets/icons/details/flash-circle.svg" alt="" />
              <div>Ignition On</div>
            </div>
          </CustomTags>

          {hasMobilisePermission && (
            <div>
              <MobiliseModalCTA trike={currentTrike} />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

const TrikeMapMarkerWithGeofence = ({ assetLatLng }) => {
  const { currentTrike } = useSelector((state) => state.trikes);

  return (
    <>
      <>
        {currentTrike?.sensorNo === null ? null : (
          <Marker position={assetLatLng} icon={renderMarkerIcon("trike.svg")}>
            <Popup>
              <TrikeMapMarkerDetails
                assetId={currentTrike.registrationNo}
                trikeDetail={currentTrike}
                page="details"
              />
            </Popup>
          </Marker>
        )}
        {/* <Polygon
          positions={geoFenceLatLngs.latLngs}
          pathOptions={lineOptions}
          weight={1.5}
        /> */}
        {currentTrike.geofence ? (
          <Polygon
            positions={currentTrike.geofence}
            pathOptions={lineOptions}
            weight={1.5}
          />
        ) : null}

        {currentTrike.geofence
          ? currentTrike?.geofence.map((item, index) => (
              <Marker
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                position={item}
                icon={getGeofencePoint()}
              />
            ))
          : null}
        {/* {geoFenceLatLngs?.latLngs?.map((item, index) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            position={item}
            icon={getGeofencePoint()}
          />
        ))} */}
      </>
    </>
  );
};

const BatteryMapMarkerWithGeofence = () => {
  // const { batterySensorId, batteryDetails, batteryShouldBeMapped } =
  //   useSelector((state) => state.batteries);
  // const { geoFenceLatLngs } = useSelector((state) => state.trikes);

  const getAssetLatLngs = () => {
    // if (
    //   batteryShouldBeMapped ||
    //   (batteryDetails && batteryDetails.length > 0)
    // ) {
    //   const lat =
    //     typeof batteryDetails?.global_values?.Latitude === 'number'
    //       ? batteryDetails.global_values.Latitude
    //       : NaN;
    //   const lng =
    //     typeof batteryDetails?.global_values?.Longitude === 'number'
    //       ? batteryDetails.global_values.Longitude
    //       : NaN;

    //   if (Number.isNaN(lat) || Number.isNaN(lng)) {
    //     console.log('Invalid latitude or longitude.');
    //     return [0, 0];
    //   }

    //   return [lat, lng];
    // }

    return [-0.6803741, 34.7569482];
  };

  // console.log(uniqueTrikesWithSensorId, 'check trikes with sensor id');

  return (
    <>
      {/* {batteryShouldBeMapped && ( */}
      <>
        <Marker
          position={getAssetLatLngs()}
          icon={renderMarkerIcon("battery.svg", false, "farm")}
        />
        {/* <Polygon
          positions={geoFenceLatLngs.latLngs}
          pathOptions={lineOptions}
          weight={1.5}
        />
        {geoFenceLatLngs?.latLngs?.map((item, index) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            position={item}
            icon={getGeofencePoint()}
          />
        ))} */}
      </>
      {/* )} */}
    </>
  );
};

const CustomDetailsLayout = ({ KPIBody, TableBody, assetType = "trikes" }) => {
  const dispatch = useDispatch();

  const {
    geoFenceModal,
    geoFenceLatLngs,
    currentTrike,
    fullScreenMap,
    trikeTrackerModal,
    showToastMessage,
  } = useSelector((state) => state.trikes);

  const [viewDetailsDrawer, setViewDetailsDrawer] = React.useState(false);

  // tracker permission
  const hasTrackerPermission = usePermission([PERMISSIONS.TRACKER_SWITCH]);

  // geofence permissions
  const hasGeofencePermission = usePermission([
    PERMISSIONS.TRIKE_GEOFENCE_ADD,
    PERMISSIONS.TRIKE_GEOFENCE_EDIT,
    PERMISSIONS.TRIKE_GEOFENCE_DELETE,
  ]);

  const mapRef = useRef(null);
  const initialZoomLevel = 16;

  const isPointWithinPolygon = (lat, lng) => {
    const point = { lat, lng };
    const polygon = L?.polygon(geoFenceLatLngs?.latLngs);

    if (polygon && polygon.getBounds().isValid()) {
      return polygon.getBounds().contains(point);
    }

    return undefined;
  };

  const getAssetLatLngs = () => {
    if (assetType === "trikes") {
      if (currentTrike?.locationLat != 0 && currentTrike?.locationLong != 0) {
        const lat = parseFloat(currentTrike?.locationLat);
        const long = parseFloat(currentTrike?.locationLong);

        return [lat, long];
      }

      return [-0.6803741, 34.7569482];
    }

    return [-0.6803741, 34.7569482];
  };

  // eslint-disable-next-line consistent-return
  const checkAssetWithinGeofence = () => {
    try {
      const [lat, lng] = getAssetLatLngs();
      const withinGeofence = isPointWithinPolygon(lat, lng);

      if (withinGeofence) {
        // Update DB to be checked in
        dispatch(updateTrikes({ key: "isWithinGeofence", value: true }));
      } else {
        // Update DB to be Out Of Geofence
        dispatch(updateTrikes({ key: "isWithinGeofence", value: false }));
      }
      return withinGeofence;
    } catch (error) {
      console.error(
        "Error occurred while checking asset within geofence:",
        error.message,
      );
    }
  };

  useEffect(() => {
    getAssetLatLngs();
    mapRef?.current?.setView(getAssetLatLngs());
  }, [currentTrike]);

  useEffect(() => {
    checkAssetWithinGeofence();
  }, [currentTrike, geoFenceLatLngs.latLngs]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateTrikes({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  return (
    <Row className="w-full h-full">
      <Col span={16}>
        <div className="w-full h-full bg-white">
          <DetailsHeader
            assetType={assetType}
            withinGeofence={checkAssetWithinGeofence()}
          />
          {KPIBody}
          {TableBody}
        </div>
      </Col>
      <Col span={8}>
        <MapDetailsHeader
          assetType={assetType}
          withinGeofence={checkAssetWithinGeofence()}
        />
        {assetType === "trips" ? (
          <TripDetailsMapSection />
        ) : (
          <div className="border-l border-light-grey">
            <div className="h-[calc(100vh-128px)]">
              <MapContainer
                center={getAssetLatLngs()}
                zoom={INITIAL_ZOOM_LEVEL}
                maxBounds={MAX_BOUNDS}
                className="z-10 w-full h-full"
                zoomControl={false}
                ref={mapRef}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                {assetType === "trikes" ? (
                  <TrikeMapMarkerWithGeofence assetLatLng={getAssetLatLngs()} />
                ) : (
                  <BatteryMapMarkerWithGeofence />
                )}
              </MapContainer>
            </div>

            {assetType === "trikes" ? (
              <div className="absolute z-20 w-full left-6 top-20">
                <div className="flex items-center justify-between">
                  {hasTrackerPermission ? (
                    <TrikeTrackerSwitch
                      trikeId={currentTrike.id}
                      trikeRegistrationNo={currentTrike.registrationNo}
                      locationFetchSource={currentTrike.locationFetchSource}
                    />
                  ) : (
                    <div />
                  )}

                  <div className="flex mr-14 gap-x-4">
                    {hasGeofencePermission && <GeoFenceButton />}

                    <ExpandButton
                      onClick={() =>
                        dispatch(
                          updateTrikes({
                            key: "fullScreenMap",
                            value: true,
                          }),
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="z-20">
              {assetType === "trikes" ? (
                <TrikeDetailsDrawer
                  mapRef={mapRef}
                  initialZoomLevel={initialZoomLevel}
                  center={getAssetLatLngs()}
                  setViewDetailsDrawer={setViewDetailsDrawer}
                />
              ) : (
                <BatteryDetailsDrawer
                  mapRef={mapRef}
                  initialZoomLevel={initialZoomLevel}
                  center={getAssetLatLngs()}
                />
              )}
            </div>
          </div>
        )}
      </Col>
      <GeoFenceModal
        visible={geoFenceModal}
        onCancel={() => {
          dispatch(updateTrikes({ key: "geoFenceModal", value: false }));
          dispatch(
            updateTrikes({
              key: "geoFenceLatLngs",
              value: { trikeId: "", latLngs: [] },
            }),
          );
        }}
      />

      <FullScreenMap
        id="fullscreen"
        visible={fullScreenMap}
        onCancel={() => {
          dispatch(updateTrikes({ key: "fullScreenMap", value: false }));
        }}
      />
      <div className="">
        <ViewDetailsDrawer
          visible={viewDetailsDrawer}
          onCancel={() => setViewDetailsDrawer(false)}
          currentDriver={currentTrike.driverName}
        />
      </div>

      <TrikeTrackerModal
        visible={trikeTrackerModal.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "trikeTrackerModal",
              value: {
                visible: false,
                trikeId: 0,
                trikeRegistrationNo: "",
                currentSource: 1,
              },
            }),
          )
        }
        page="trike-details"
        currentPage={1}
      />

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "showToastMessage",
              value: { visible: false, data: {} },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </Row>
  );
};
export default CustomDetailsLayout;
