/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import {
  addLocationV3,
  editLocationV3,
} from "../../../Redux/APIs/locationsAPI";
import { LOCATION_TYPE } from "../Data";
import { updateLocations } from "../../../Redux/Slices/locations.slice";

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
}) => {
  return (
    <div className="">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="createUser"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
      />
    </div>
  );
};

const AddHub = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { currentLocation } = useSelector((state) => state.locations);

  const { handleSubmit, control, formState, setError } = useForm({
    defaultValues: {
      name: id ? currentLocation.name : "",
      address: id ? currentLocation.address : "",
      lat: id ? currentLocation.lat : null,
      long: id ? currentLocation.long : null,
      activity: LOCATION_TYPE.HUB_STATION,
    },
  });

  const [focus, setFocus] = useState({
    name: false,
    address: false,
    lat: false,
    long: false,
  });

  const rules = {
    required: "Required.",
  };

  const ruleForLat = {
    required: "Required.",
    validate: {
      isNumber: (value) => {
        return (value <= 90 && value >= -90) || "Latitude can only be a number";
      },
    },
  };

  const ruleForLong = {
    required: "Required.",
    validate: {
      isNumber: (value) => {
        return (
          (value <= 180 && value >= -180) || "Longtitude can only be a number"
        );
      },
    },
  };

  const onCancel = () => navigate(-1);

  const onAdd = async (e) => {
    const payload = e;
    payload.name = e.name.toLowerCase();

    if (id) {
      payload.locationId = id;
      const editResponse = await dispatch(editLocationV3(payload));

      if (
        editResponse?.payload?.response &&
        editResponse?.payload?.response?.status !== 200
      ) {
        setError("name", {
          type: "server",
          shouldFocus: true,
          message: editResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateLocations({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Location Edited",
                description: `Hub station ${e.name} was edited successfully.`,
              },
            },
          }),
        );

        navigate(-1);
      }
    } else {
      const addResponse = await dispatch(addLocationV3(payload));

      if (
        addResponse?.payload?.response &&
        addResponse?.payload?.response?.status !== 200
      ) {
        setError("name", {
          type: "server",
          shouldFocus: true,
          message: addResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateLocations({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Hub Station Added",
                description: "1 new hub station was added successfully.",
              },
            },
          }),
        );

        navigate(-1);
      }
    }
  };

  return (
    <CustomLayout pageName="Locations" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            {id ? "Edit Hub" : "Add a new hub"}
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onAdd)}
            >
              Add hub station
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-[calc(100vh-88px-72px)] bg-background-white p-6 flex flex-col gap-y-6 overflow-y-scroll">
        <LabelAndInput
          label="Name"
          control={control}
          formState={formState}
          name="name"
          placeholder="Enter Name"
          rules={rules}
          focus={focus.name}
          setFocus={(state) => setFocus({ ...focus, name: state })}
        />

        <LabelAndInput
          label="Address"
          control={control}
          formState={formState}
          name="address"
          placeholder="Enter Address"
          rules={rules}
          focus={focus.address}
          setFocus={(state) => setFocus({ ...focus, address: state })}
        />

        <LabelAndInput
          label="Latitude"
          control={control}
          formState={formState}
          name="lat"
          placeholder="Enter Latitude"
          rules={ruleForLat}
          focus={focus.lat}
          setFocus={(state) => setFocus({ ...focus, lat: state })}
        />

        <LabelAndInput
          label="Longitude"
          control={control}
          formState={formState}
          name="long"
          placeholder="Enter Longitude"
          rules={ruleForLong}
          focus={focus.long}
          setFocus={(state) => setFocus({ ...focus, long: state })}
        />
      </div>
    </CustomLayout>
  );
};

export default AddHub;
