import React from 'react';

const ArrowIcon = ({ size = 16, className, strokeWidth = 1.5 }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}
          d="M12.046 6.38L8 2.333 3.953 6.38M8 13.667V2.447"
        />
      </svg>
    </span>
  );
};

export default ArrowIcon;
