import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { useFinanceItem } from "./useItems";
import FinanceIcon, {
  FinanceActiveIcon,
  FinanceHoverIcon,
} from "../../../Common/Svgs/FinanceIcon";
import { SUBMENU } from "../data/contants";
import { getThemePrimary50Color } from "../../../Utils/getThemeColor";

const FinanceItem = ({ sidebarActive, subMenu, setSubMenu }) => {
  // removed prop - pageName
  const navigate = useNavigate();

  const { theme } = useSelector((state) => state.loggedInUser);

  const [onHover, setOnHover] = useState(false);

  // sub menu items
  const items = useFinanceItem().filter((item) => item.hasPermission);

  const activeItem =
    window.location.href.includes("revenue") ||
    window.location.href.includes("rates")
      ? 1
      : -1;

  // const activeItem = items.findIndex(
  //   (i) => i.label.toLowerCase() === pageName.toLowerCase(),
  // );

  const handleSubMenu = () => {
    if (subMenu === SUBMENU.FINANCE) {
      setSubMenu(SUBMENU.DEFAULT);
    } else {
      setSubMenu(SUBMENU.FINANCE);
    }
  };

  const getContainerStyle = () => {
    // if (sidebarActive) return "bg-transparent-white";
    if (sidebarActive) return "bg-primary-50";

    return "";
  };

  const getHeaderMenuStyle = () => {
    if (activeItem > -1 || subMenu === SUBMENU.FINANCE) {
      return "text-sidebar-active stroke-sidebar-active";
    }

    return "text-sidebar-base stroke-sidebar-base";
  };

  const getHeaderIcon = useCallback(() => {
    if (activeItem > -1) {
      return (
        <FinanceActiveIcon
          strokeColor={theme === "green-theme" ? "#FAD152" : "#fff"}
          fillColor={getThemePrimary50Color(theme)}
        />
      );
    }

    if (onHover || subMenu === SUBMENU.FINANCE) {
      return <FinanceHoverIcon className="text-sidebar-active" />;
    }

    return <FinanceIcon />;
  }, [activeItem, subMenu, onHover, theme]);

  const getSubMenuStyle = (selected) => {
    // if (pageName.toLowerCase() === item.toLowerCase()) return "text-primary";
    // return "text-sidebar-base group-hover:text-sidebar-active";
    if (selected) return "text-primary";
    return "text-sidebar-base group-hover:text-sidebar-active";
  };

  // close the submenu if the sidebar is closed
  // useEffect(() => {
  //   if (!sidebarActive) {
  //     setSubMenu(SUBMENU.DEFAULT);
  //   }
  // }, [sidebarActive, setSubMenu]);

  useEffect(() => {
    // close the submenu if the sidebar is closed
    if (!sidebarActive) setSubMenu(SUBMENU.DEFAULT);

    // open the submenu if the sidebar is open and active item is present
    if (sidebarActive && activeItem > -1) {
      setTimeout(() => {
        setSubMenu(SUBMENU.FINANCE);
      }, 200);
    }
  }, [sidebarActive, setSubMenu, activeItem]);

  return (
    <div>
      <div
        role="button"
        className={`flex items-center mt-5 py-2 px-6 h-9 cursor-pointer w-full group ${getContainerStyle()}`}
        // onClick={() => setSubMenu((prev) => !prev)}
        onClick={handleSubMenu}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <motion.div
          // layout={subMenu === SUBMENU.DEFAULT}
          className="w-5 h-5"
        >
          {getHeaderIcon()}
        </motion.div>

        {sidebarActive && (
          <motion.p
            // layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className={`w-full ml-2 flex-1 font-aileron font-semibold text-xs group-hover:text-sidebar-active ${getHeaderMenuStyle()}`}
          >
            Finance
          </motion.p>
        )}

        {sidebarActive && (
          <motion.div
            // layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="w-2.5 h-2.5"
          >
            <ChevronIcon
              strokeWidth="1.5"
              className={`w-2.5 h-2.5 group-hover:stroke-sidebar-active transform transition-all ${getHeaderMenuStyle()}
              ${subMenu === SUBMENU.FINANCE ? "-rotate-90" : "rotate-90"}
            `}
            />
          </motion.div>
        )}
      </div>

      {sidebarActive &&
        subMenu === SUBMENU.FINANCE &&
        items.map((i) => {
          // const selected = pageName.toLowerCase() === i.label.toLowerCase();
          const selected = window.location.href.includes(i.identifier);

          return (
            <div
              key={i.label}
              className={`flex items-center gap-x-2 py-3 px-[30px] cursor-pointer group ${
                selected && "bg-sidebar-active"
              }`}
              onClick={() => navigate(i.path)}
            >
              <span className={`w-4 h-4 ${getSubMenuStyle(selected)}`}>
                {i.icon}
              </span>
              <p
                className={`font-aileron text-xs font-normal capitalize ${getSubMenuStyle(
                  selected,
                )}`}
              >
                {i.label}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default FinanceItem;
