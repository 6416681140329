/* eslint-disable no-console */
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import CustomInput from "../../../Core/Components/CustomInput";
import Button from "../../../Core/Components/CustomButton";
import { BATTERY_STATUS } from "../Data";
import { updateBatteries } from "../../../Redux/Slices/batteries.slice";
import AcceptBatteryRequestIcon from "../../../Common/Svgs/AcceptBatteryRequestIcon";
import AlertIcon from "../../../Common/Svgs/AlertIcon";
import { client } from "../../../Utils/axiosClient";

const MainCheckbox = ({ selected, setSelected, batteries }) => {
  const handleSelection = () => {
    // select all the batteries
    if (selected.length === 0) {
      const local = batteries.map((item) => item.id);
      setSelected([...local]);
      return;
    }

    // unselect all the batteries
    setSelected([]);
  };

  // show checkbox with checked=false
  if (selected.length === 0) {
    return (
      <Checkbox
        className="w-4 h-4"
        checked={false}
        onChange={handleSelection}
      />
    );
  }

  // if all the batteries are selected then show checkbox with checked=true
  if (selected.length === batteries.length) {
    return <Checkbox className="w-4 h-4" checked onChange={handleSelection} />;
  }

  // if some of the batteries are selected (not all) show checkbox with unselect icon
  return (
    <button
      type="button"
      className="w-4 h-4 rounded bg-primary px-[2px]"
      onClick={handleSelection}
    >
      <div className="w-full h-[2px] rounded bg-white" />
    </button>
  );
};

const AcceptBatteryRequestModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const { allBatteries, acceptBatteryRequestModal } = useSelector(
    (state) => state.batteries,
  );

  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);
  const [selected, setSelected] = useState([]);
  const [disabled, setDisabled] = useState("");

  // select only those batteries that are currently in the hub station and where currentHubId matches with the supplier hub id
  const batteries = allBatteries.filter(
    (item) =>
      item.status === BATTERY_STATUS.HUB_STATION &&
      +item.currentHubId === acceptBatteryRequestModal?.data?.supplierHubId,
  );

  const getFilteredBatteries = useCallback(() => {
    return batteries.filter((item) => item.registrationNo.includes(search));
  }, [batteries, search]);

  const handleSelection = (id) => {
    if (selected.includes(id)) {
      const local = selected.filter((item) => item !== id);
      setSelected(local);
    } else {
      setSelected((current) => [...current, id]);
    }
  };

  const handleCancel = () => {
    setSelected([]);
    setSearch("");
    onCancel();
  };

  const handleConfirm = async () => {
    if (selected.length > acceptBatteryRequestModal?.data?.batteries) {
      setDisabled("Selected batteries exceed the requested batteries count.");
      return;
    }

    if (selected.length < acceptBatteryRequestModal?.data?.batteries) {
      setDisabled("Less no. of batteries selected.");
      return;
    }

    try {
      dispatch(updateBatteries({ key: "loading", value: true }));

      const payload = {
        batteries: selected,
        requestId: parseInt(acceptBatteryRequestModal?.data?.requestId, 10),
      };

      const response = await client.post(
        `${process.env.REACT_APP_API_URL}/battery/assign-batteries`,
        payload,
      );

      if (response.status === 200) {
        dispatch(
          updateBatteries({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "Battery request accepted.",
                description:
                  "Please proceed to checkout the batteries in the next 1 hour to initiate this transfer.",
              },
            },
          }),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateBatteries({ key: "loading", value: false }));
      setDisabled("");
      setSelected([]);
      onCancel();
    }
  };

  return (
    <CustomModal visible={visible} onCancel={handleCancel} width="536px">
      <div
        className="rounded-xl bg-background-white w-full"
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between pl-4 pr-6 pt-4">
          <div className="flex items-center gap-x-3">
            <CustomRoundedImage
              size="w-12 h-12"
              image={<AcceptBatteryRequestIcon className="text-white" />}
              background="bg-primary"
            />

            <h1 className="font-poppins text-base font-medium">
              Select Batteries
            </h1>
          </div>

          <button type="button" onClick={handleCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        <div className="px-6 mt-6">
          <h2 className="font-poppins text-xs font-medium capitalize">
            No. of batteries requested:{" "}
            {acceptBatteryRequestModal?.data?.batteries}
          </h2>

          <p className="mt-1 mb-3 font-aileron text-xs font-normal text-dark-gray">
            List of available batteries to transfer from your hub station.
          </p>

          <CustomInput
            intent="searchHome"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Battery No."}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />
        </div>

        <div className="mt-3 flex items-center bg-background border-b-[0.5px] px-6 py-3 gap-x-12">
          <MainCheckbox
            selected={selected}
            setSelected={setSelected}
            batteries={batteries}
          />

          <p className="w-[236px] font-aileron text-10 font-normal text-dark-gray">
            Registration No.
          </p>

          <p className="font-aileron text-10 font-normal text-dark-gray">
            Charge %
          </p>
        </div>

        <div className="h-[272px] overflow-y-scroll">
          {getFilteredBatteries().map((battery) => {
            const isChecked = selected.includes(battery.id);

            return (
              <div
                key={battery.id}
                className={`flex items-center px-6 py-3 gap-x-12 border-b border-light-grey ${
                  isChecked && "bg-primary-10"
                }`}
              >
                <Checkbox
                  className="w-4 h-4"
                  checked={isChecked}
                  onChange={() => handleSelection(battery.id)}
                />

                <p className="w-[236px] font-aileron text-xs font-semibold">
                  {battery.registrationNo}
                </p>

                <p className="font-aileron text-10 font-normal text-dark-gray">
                  {battery.percentage}
                </p>
              </div>
            );
          })}
        </div>

        <div className="flex items-center justify-between gap-x-3 mt-7 px-6 pb-6">
          {disabled ? (
            <div className="flex items-start gap-x-2">
              <AlertIcon size={16} className="text-alert-red" />

              <p className="font-aileron text-xs font-medium text-alert-red">
                {disabled}
              </p>
            </div>
          ) : (
            <div />
          )}

          <div className="flex items-center gap-x-3">
            <Button colors="grey" size="primary" onClick={handleCancel}>
              Cancel
            </Button>

            <button
              type="button"
              className="bg-primary hover:bg-primary-50 px-4 py-3 rounded-md disabled:cursor-not-allowed disabled:hover:bg-primary"
              onClick={handleConfirm}
            >
              <p className="text-background-white font-poppins text-xs font-medium capitalize">
                Confirm
              </p>
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default AcceptBatteryRequestModal;
