import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'antd';
import { CustomInputWithController } from '../../Core/Components/CustomInput';
import Button from '../../Core/Components/CustomButton';

const SetNewPassword = () => {
  const navigate = useNavigate();

  const { handleSubmit, control, formState } = useForm({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const validationForPassword = {
    required: 'please enter password',
    minLength: {
      value: 8,
      message: 'less characters for password',
    },
  };

  const onSubmit = async () => {
    // console.log(e, 'check change password');
    // const { oldPassword, newPassword, confirmNewPassword } = e;
    // try {
    //   const response = await axios.post(
    //     `${process.env.REACT_APP_API_URL}/auth/change-password`,
    //     {
    //       oldPassword,
    //       newPassword,
    //       confirmNewPassword,
    //     },
    //   );
    //   if (response) {
    //     const userDetailsTemp = response.data;
    //     dispatch(
    //       updateLoggedInUser({ key: 'userDetails', value: userDetailsTemp }),
    //     );
    //     localStorage.setItem('token', response.data.token);
    //     navigate('/home');
    //   }
    // } catch (error) {
    //   setError('confirmNewPassword', {
    //     type: 'server',
    //     message: error.response.data.message,
    //   });
    // }
  };

  return (
    <div className="w-screen h-screen ">
      <Row className="h-full">
        <Col span={15} className="h-full overflow-hidden ">
          <img
            src="/assets/images/Login/login-image.svg"
            alt="LoginImage"
            className="object-cover w-full h-full"
          />
        </Col>
        <Col span={9}>
          <div className="flex flex-col items-center justify-center w-full h-full gap-y-6 font-poppins">
            <img
              src="/assets/songa-main-logo.svg"
              alt="Songa Mobility"
              className="w-[258px] h-14"
            />
            <div>
              <div className="text-2xl font-medium leading-9 capitalize">
                Set new password
              </div>
              <div className="mt-2 text-xs font-light leading-4.5 ">
                Enter new password to continue
              </div>
            </div>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col items-center justify-center w-full gap-y-6"
            >
              <CustomInputWithController
                intent="login"
                control={control}
                formState={formState}
                inputType="password"
                name="oldPassword"
                placeholder="Enter Old Password"
                rules={validationForPassword}
              />
              <CustomInputWithController
                intent="login"
                control={control}
                formState={formState}
                inputType="password"
                name="newPassword"
                placeholder="Enter Old Password"
                rules={validationForPassword}
              />
              <CustomInputWithController
                intent="login"
                control={control}
                formState={formState}
                inputType="password"
                name="confirmNewPassword"
                placeholder="Enter Old Password"
                rules={validationForPassword}
              />

              <Button width="login" type="submit">
                Save password
              </Button>
              <button
                type="button"
                onClick={() => navigate('/')}
                className="cursor-pointer font-poppins font-light text-xs leading-4.5"
              >
                Back to Login
              </button>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default SetNewPassword;
