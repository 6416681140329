/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../../Core/Components/CustomDropdown";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import { deleteUser, getSystemUsers } from "../../../Redux/APIs/systemUsersAPI";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import EditIcon from "../../../Common/Svgs/EditIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS, SEED_ROLES } from "../../Login/constants";

const EditAndDeleteDropDown = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { toastMessageModal } = useSelector((state) => state.loggedInUser);

  const handleClick = async (e) => {
    switch (e.key) {
      case "1":
        dispatch(
          updateLoggedInUser({
            key: "selectedUser",
            value: user,
          }),
        );

        navigate(`/settings/system-users/edit-user/${user.id}`);
        break;
      case "2":
        await dispatch(deleteUser({ userId: user.id }));

        await dispatch(getSystemUsers());
        dispatch(
          updateLoggedInUser({
            key: "toastMessageModal",
            value: {
              ...toastMessageModal,
              visible: true,
              title: "1 User Deleted",
              description: "1 user was deleted successfully.",
            },
          }),
        );
        break;
      default:
        break;
    }
  };

  const items = [
    {
      key: 1,
      value: "Edit",
      label: (
        <div className="flex items-center px-3 py-2 cursor-pointer gap-x-3">
          <EditIcon className="text-dark-gray" />
          <p className="text-xs font-semibold font-aileron text-dark-gray">
            Edit Details
          </p>
        </div>
      ),
    },
    {
      key: 2,
      value: "Delete",
      label: (
        <div className="flex items-center px-3 py-2 cursor-pointer gap-x-3">
          <TrashIcon className="text-dark-gray" />
          <p className="text-xs font-semibold font-aileron text-dark-gray">
            Delete user
          </p>
        </div>
      ),
    },
  ];

  return (
    <CustomDropdown
      items={items}
      trigger="click"
      placement="bottomLeft"
      handleClick={handleClick}
    >
      <div className="px-2 py-1 cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </CustomDropdown>
  );
};

export const DeleteAction = ({ user }) => {
  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const hasPermission = usePermission([
    PERMISSIONS.SETTINGS_SYSTEM_USER_DELETE,
  ]);

  const handleDelete = async (e) => {
    e.stopPropagation();

    dispatch(
      updateLoggedInUser({
        key: "deleteSystemUserModal",
        value: {
          visible: true,
          user,
        },
      }),
    );
  };

  if (!hasPermission) {
    return <></>;
  }

  // no delete action for the logged in user (cannot delete self) or user with super admin role
  if (
    user.id === userDetails.userId ||
    user.roleId === SEED_ROLES.SUPER_ADMIN
  ) {
    return <></>;
  }

  return (
    <button
      type="button"
      onClick={handleDelete}
      className="invisible group-hover:visible w-full flex items-center justify-center"
    >
      <TrashIcon className="text-alert-red" />
    </button>
  );
};

export default EditAndDeleteDropDown;
