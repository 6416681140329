import React from "react";
import ViewDetailsIcon from "../../../Common/Svgs/ViewDetailsIcon";
import EditIcon from "../../../Common/Svgs/EditIcon";
import DriverSuspendIcon from "../../../Common/Svgs/DriverSuspendIcon";
import DriverResumeIcon from "../../../Common/Svgs/DriverResumeIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const useThreeDotsForDrivers = () => {
  const threeDotsForDrivers = [
    {
      id: 1,
      value: "View Trip Details",
      icon: (
        <ViewDetailsIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: true,
    },
    {
      id: 2,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.DRIVER_EDIT]),
    },
    {
      id: 4,
      value: "Suspend",
      icon: (
        <DriverSuspendIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([PERMISSIONS.DRIVER_SUSPEND]),
    },
    {
      id: 5,
      value: "Resume",
      icon: (
        <DriverResumeIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([PERMISSIONS.DRIVER_SUSPEND]),
    },
    {
      id: 3,
      value: "Delete User",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.DRIVER_DELETE]),
    },
  ];

  return threeDotsForDrivers;
};

export default useThreeDotsForDrivers;
