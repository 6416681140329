/* eslint-disable no-console */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TickIcon from "../../../Common/Svgs/TickIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { updateBatteries } from "../../../Redux/Slices/batteries.slice";
import { updateHome } from "../../../Redux/Slices/home.slice";
import { client } from "../../../Utils/axiosClient";

const ItemBody4 = ({ notification }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onAccept = () => {
    dispatch(updateHome({ key: "notificationPopupVisible", value: false }));

    dispatch(
      updateBatteries({
        key: "acceptBatteryRequestModal",
        value: {
          visible: true,
          data: {
            hubId: notification?.metaData?.receiverHubId,
            hubName: notification?.metaData?.receiverHubName,
            batteries: notification?.metaData?.batteryCount,
            supplierHubId: notification?.metaData?.supplierHubId,
            requestId: notification?.metaData?.requestId,
          },
        },
      }),
    );

    navigate("/batteries");
  };

  const onReject = async () => {
    dispatch(updateHome({ key: "notificationPopupVisible", value: false }));

    try {
      const payload = {
        requestId: parseInt(notification?.metaData?.requestId, 10),
        batteryCount: parseInt(notification?.metaData?.batteryCount, 10),
      };
      dispatch(updateHome({ key: "loading", value: true }));

      await client.post(
        `${process.env.REACT_APP_API_URL}/battery/reject-request`,
        payload,
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateHome({ key: "loading", value: false }));
    }
  };

  return (
    <div>
      <p className="font-poppins text-xs font-light text-black">
        <span className="font-medium text-primary capitalize">
          {notification?.metaData?.receiverHubName}{" "}
        </span>
        is requesting {notification?.metaData?.batteryCount} batteries. Do you
        want to accept?
      </p>

      <div className="mt-1 w-full flex items-center gap-x-2">
        <button
          type="button"
          className="flex flex-1 items-center gap-x-2 bg-primary hover:bg-primary-50 py-2 px-4 rounded-md"
          onClick={onAccept}
        >
          <TickIcon size={16} className="text-white" />

          <p className="font-poppins text-xs font-medium text-background-white">
            Accept
          </p>
        </button>

        <button
          type="button"
          className="flex flex-1 items-center gap-x-2 bg-alert-10 hover:bg-light-alert-red py-2 px-4 rounded-md"
          onClick={onReject}
        >
          <CloseIcon className="w-4 h-4 stroke-alert-red" />

          <p className="font-poppins text-xs font-medium text-alert-red">
            Reject
          </p>
        </button>
      </div>
    </div>
  );
};

export default ItemBody4;
