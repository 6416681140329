import React from 'react';

const LocationsModuleIcon = ({ size = 24, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeWidth="1.5"
          d="M3.622 8.49c1.97-8.66 14.8-8.65 16.76.01 1.15 5.08-2.01 9.38-4.78 12.04a5.194 5.194 0 01-7.21 0c-2.76-2.66-5.92-6.97-4.77-12.05z"
        />
        <path
          stroke="currentColor"
          strokeWidth="1.5"
          d="M11.999 13.43a3.12 3.12 0 100-6.24 3.12 3.12 0 000 6.24z"
        />
      </svg>
    </span>
  );
};

export const ActiveLocationsModuleIcon = ({
  size = 24,
  className,
  centerColor = '#085054',
}) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.5"
          d="M3.62 8.49c1.97-8.66 14.8-8.65 16.76.01 1.15 5.08-2.01 9.38-4.78 12.04a5.193 5.193 0 01-7.21 0c-2.76-2.66-5.92-6.97-4.77-12.05z"
        />
        <path
          fill={centerColor}
          stroke={centerColor}
          strokeWidth="1.5"
          d="M12 13.43a3.12 3.12 0 100-6.24 3.12 3.12 0 000 6.24z"
        />
      </svg>
    </span>
  );
};

export default LocationsModuleIcon;
