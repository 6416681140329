import React from 'react';

const CurrentIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M14.667 8A6.667 6.667 0 001.334 8"
        />
        <path
          fill="currentColor"
          d="M4.985 12a.51.51 0 01-.459-.256.55.55 0 01-.032-.544l2.998-7.157c.106-.25.28-.374.522-.374.256 0 .43.125.523.374l3.008 7.178c.078.2.064.38-.043.544-.1.157-.252.235-.458.235a.615.615 0 01-.31-.085.555.555 0 01-.202-.256l-2.646-6.57h.3l-2.689 6.57a.529.529 0 01-.224.256.542.542 0 01-.288.085zm.565-1.835l.427-.917h4.181l.427.917H5.55z"
        />
      </svg>
    </span>
  );
};

export default CurrentIcon;
