/* eslint-disable no-console */
import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { CompletedTripData } from "../Data/TripDetailData";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import StopDetails from "../TripDetails/StopDetails";
import CompletedTripInformation from "../TripDetails/CompletedTripInformation";

const TripSummaryStops = () => {
  const { overallSummaryForTrip } = useSelector((state) => state.trips);

  return (
    <div className="flex flex-col p-6 gap-y-6 bg-background h-[calc(100vh-128px)] overflow-y-scroll">
      <Row gutter={24}>
        {CompletedTripData(overallSummaryForTrip).map((kpi) => (
          <Col span={6} key={kpi.id}>
            <div className="p-4 rounded-md bg-kpi-background">
              <div className="flex items-center gap-x-2.5">
                <CustomRoundedImage background="bg-kpi-icon" image={kpi.icon} />
                <p className="text-xs font-normal capitalize font-aileron text-trip-kpi-title">
                  {kpi.title}
                </p>
              </div>
              <p className="ml-12 text-2xl font-semibold text-trip-kpi-value font-poppins">
                {kpi.value}
                {kpi.unit && (
                  <span className="text-base ml-0.5">{kpi.unit}</span>
                )}
              </p>
            </div>
          </Col>
        ))}
      </Row>

      <Row>
        <Col span={24}>
          <CompletedTripInformation />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <StopDetails />
        </Col>
      </Row>
    </div>
  );
};

export default TripSummaryStops;
