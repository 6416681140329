/* eslint-disable array-callback-return */
const getCenterOfPolygon = (polygonCoordinates) => {
  let totalLat = 0;
  let totalLng = 0;

  polygonCoordinates.map((coord) => {
    totalLat += coord[0];
    totalLng += coord[1];
  });

  const centerLat = totalLat / polygonCoordinates.length;
  const centerLng = totalLng / polygonCoordinates.length;

  return [centerLat, centerLng];
};

export default getCenterOfPolygon;
