import React from "react";
import { Checkbox } from "antd";
import { chargerPermissions, mobileAppPermission } from "./data";
import PermissionTitle from "./PermissionTitle";
import { PERMISSIONS } from "../../Login/constants";
import MobileIcon from "../../../Common/Svgs/MobileIcon";

const MobileAppPermission = ({ permissions, setPermissions }) => {
  const handleModulePermission = (permissionId) => {
    let local = [];

    if (permissionId === PERMISSIONS.APP_VIEW_AND_EDIT) {
      local = permissions.filter((item) => item !== PERMISSIONS.APP_NO_ACCESS);
    }

    // removing all the battery permissions
    if (permissionId === PERMISSIONS.APP_NO_ACCESS) {
      local = permissions.filter(
        (item) => item !== PERMISSIONS.APP_VIEW_AND_EDIT,
      );
    }

    setPermissions([...local, permissionId]);
  };

  return (
    <div>
      <div className="py-3 px-6 w-full flex items-center gap-x-2 bg-primary">
        <MobileIcon className="text-background-white" />

        <p className="font-poppins text-sm text-background-white font-semibold uppercase">
          Mobile App
        </p>
      </div>

      {/* main module permission */}
      <div className="w-full h-[60px]">
        <div
          className="flex items-center w-full h-full border-b"
          style={{
            background: "#FAF2D8",
            borderColor: "#FAD152",
          }}
        >
          <div
            className="w-[40%] border-r py-3 px-6"
            style={{
              borderColor: "#FAD152",
            }}
          >
            <PermissionTitle
              title={chargerPermissions[0].title}
              description={chargerPermissions[0].description}
            />
          </div>

          <div className="w-[60%] h-full flex items-center">
            {mobileAppPermission[0].options.map((option) => {
              // rendering empty div for layout consistency
              if (!option) {
                return (
                  <div
                    className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                    style={{
                      borderColor: "#FAD152",
                    }}
                  />
                );
              }

              const selected = permissions.includes(option.permissionId);

              return (
                <div
                  key={option.text}
                  className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                  style={{
                    borderColor: "#FAD152",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    onClick={() => handleModulePermission(option.permissionId)}
                  />
                  <p
                    className={`font-poppins text-xs ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {option.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAppPermission;
