import React from 'react';
import ImgCrop from 'antd-img-crop';
import CloseIcon from '../../../Common/Svgs/CloseIcon';

const CropModal = ({ children }) => {
  return (
    <ImgCrop
      modalClassName="image-crop-modal"
      modalOk="Crop"
      modalProps={{
        closeIcon: (
          <CloseIcon className="w-5 h-5 cursor-pointer stroke-dark-gray" />
        ),
      }}
      modalTitle={<div className="text-black">Crop your image</div>}
    >
      {children}
    </ImgCrop>
  );
};

export default CropModal;
