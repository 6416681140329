import React from "react";

const Screen1 = ({ setScreen }) => {
  return (
    <div className="flex flex-col items-center">
      <img
        src="https://smp-content.s3.eu-west-3.amazonaws.com/platform-content/songa_logo.png"
        className="w-[30px] h-[30px] shrink-0"
        alt="Songa Mobility"
      />

      <h1 className="mt-4.5 font-poppins text-2xl text-background-white font-semibold">
        Welcome to Songa Mobility!
      </h1>

      <p className="mt-3 font-poppins text-sm font-normal text-background-white-hover text-center">
        Before we take you to your dashboard, we need to ask a few quick
        <br />
        questions to set up your account.
      </p>

      <button
        type="button"
        className="mt-[100px] w-[464px] py-3 rounded-md bg-yellow hover:bg-yellow-dark"
        onClick={() => setScreen(2)}
      >
        <p className="font-poppins text-xs font-medium">Continue</p>
      </button>
    </div>
  );
};

export default Screen1;
