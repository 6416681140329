import React from 'react';

const AddSquareIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          d="M5.333 8h5.333M8 10.667V5.333M6 14.667h4c3.333 0 4.666-1.334 4.666-4.667V6c0-3.333-1.333-4.667-4.666-4.667H6C2.666 1.333 1.333 2.667 1.333 6v4c0 3.333 1.333 4.667 4.667 4.667z"
        />
      </svg>
    </span>
  );
};

export default AddSquareIcon;
