import React from "react";

const MobileIcon = ({ className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="19"
        fill="none"
        viewBox="0 0 16 19"
      >
        <path
          stroke="#FAFAFA"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.402 5.5v8c0 3.2-.8 4-4 4h-4.8c-3.2 0-4-.8-4-4v-8c0-3.2.8-4 4-4h4.8c3.2 0 4 .8 4 4zM9.598 4.3h-3.2"
        />
        <path
          stroke="#FAFAFA"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7.998 15.18a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48z"
        />
      </svg>
    </span>
  );
};

export default MobileIcon;
