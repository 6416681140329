import React from "react";

const ChargerIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M4.06 8.853h2.06v4.8c0 1.12.607 1.347 1.347.507l5.047-5.733c.62-.7.36-1.28-.58-1.28h-2.06v-4.8c0-1.12-.607-1.347-1.347-.507L3.481 7.573c-.614.707-.354 1.28.58 1.28z"
        />
      </svg>
    </span>
  );
};

export default ChargerIcon;
