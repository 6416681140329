/* eslint-disable no-console */
import React from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { PopoverDropdown } from "../../../../Core/Components/CustomDropdown";

const AssignBatteryCard = ({ index, details }) => {
  const { allBatteries } = useSelector((state) => state.batteries);

  const batteryOptions = allBatteries.map((item) => ({
    id: item.id,
    value: item.registrationNo,
  }));

  const { handleSubmit, control, formState, setValue, clearErrors } = useForm({
    defaultValues: {
      assignBatteryList: Array(details?.batteries || 0).fill({
        batteryId: null,
        batteryValue: "",
      }),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "assignBatteryList",
  });

  const onSubmit = (e) => {
    console.log(e);
  };

  return (
    <div className="p-6 rounded-xl border border-light-grey w-[283px]">
      <div className="w-6 h-6 flex items-center justify-center bg-light-grey rounded-full">
        <p className="font-aileron text-10 font-semibold text-dark-gray">
          {index}
        </p>
      </div>

      <div className="mt-3">
        <p className="font-poppins text-xs font-medium">
          Trip ID: {details?.id}
        </p>

        <p className="font-poppins text-xs font-medium my-1">
          Start Time: {dayjs(details?.startTime).format("hh:mm a")}
        </p>

        <p className="font-aileron text-xs font-normal text-dark-gray">
          {details.batteries} Batteries Needed
        </p>

        {fields.map((item, idx) => {
          const hasError =
            formState?.errors?.assignBatteryList?.[idx]?.batteryValue?.message;

          return (
            <div key={item.id} className="mt-3">
              <p className="font-aileron text-xs font-normal text-dark-gray mb-1.5">
                Battery {idx + 1}
              </p>

              <Controller
                control={control}
                rules={{ required: "Required." }}
                name={`assignBatteryList.${idx}.batteryValue`}
                render={({ field }) => (
                  <PopoverDropdown
                    {...field}
                    placeholder="Select Battery"
                    formState={formState}
                    options={batteryOptions}
                    onSelect={(data) => {
                      setValue(`assignBatteryList.${idx}.batteryId`, data.id);
                      setValue(
                        `assignBatteryList.${idx}.batteryValue`,
                        data.value,
                      );
                      clearErrors(`assignBatteryList.${idx}.batteryValue`);
                    }}
                    error={hasError}
                    className="w-[235px] h-[200px] overflow-y-scroll"
                    valueStyle={{
                      width: "235px",
                    }}
                  />
                )}
              />
              <p
                className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                  hasError ? "text-alert-red visible mt-2" : "invisible"
                }`}
              >
                {hasError}
              </p>
            </div>
          );
        })}

        <div className="mt-3 w-full flex items-center gap-x-2">
          <button
            type="button"
            className="bg-primary hover:bg-primary-50 py-2 px-4 rounded-md w-full"
            onClick={handleSubmit(onSubmit)}
          >
            <p className="font-poppins text-xs font-medium text-background-white text-center">
              Assign Batteries
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignBatteryCard;
