import React from "react";
import { useNavigate } from "react-router-dom";

const NoPathFound = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen">
      <div className="flex items-center justify-center w-full h-full">
        <div className="text-center">
          <h1 className="font-poppins font-semibold text-8xl">404</h1>

          <h2 className="mt-2 font-aileron font-light text-2xl text-dark-gray">
            Oops, the page you are looking for does not exist.
          </h2>

          <h2 className="font-aileron font-light text-2xl text-dark-gray">
            Go back to{" "}
            <button
              type="button"
              className="underline font-medium"
              onClick={() => navigate("/home")}
            >
              Homepage
            </button>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default NoPathFound;
