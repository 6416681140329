// eslint-disable-next-line no-unused-vars
import React from 'react';
import dayjs from 'dayjs';

const getTripDays = (start, end, daysArr) => {
  if (end === null) {
    return '1 Day';
  }

  if (start > end) {
    return '0 Day';
  }

  const dates = [];
  let current = dayjs(start);

  while (dayjs(current).isBefore(dayjs(end))) {
    if (daysArr.includes(dayjs(current).day() + 1)) {
      dates.push(current.clone());
    }
    current = current.add(1, 'day');
  }
  return `${dates.length} days`;
};

export default getTripDays;
