import React from "react";
import { LOCATION_TYPE } from "../Modules/Location/Data/index";
import CustomTags from "../Core/Components/CustomTags";
import CollectionPointIcon from "../Common/Svgs/CollectionPointIcon";
import { HomeKPIInventoryIcon } from "../Common/Svgs/InventoryIcon";
import AggregationPointIcon from "../Common/Svgs/AggregationPointIcon";
import HubStationIcon from "../Common/Svgs/HubStationIcon";

const getLocationType = (type) => {
  switch (type) {
    case LOCATION_TYPE.COLLECTION:
      return (
        <CustomTags colors="collectionPoint" background="collectionPoint">
          <div className="flex items-center justify-center gap-x-2">
            <CollectionPointIcon />
            <p className="text-xs font-medium capitalize font-poppins">
              Collection
            </p>
          </div>
        </CustomTags>
      );

    case LOCATION_TYPE.DELIVERY:
      return (
        <CustomTags colors="deliveryPoint" background="deliveryPoint">
          <div className="flex items-center justify-center gap-x-2">
            <HomeKPIInventoryIcon className="text-user-pink" />
            <p className="text-xs font-medium capitalize font-poppins">
              Delivery
            </p>
          </div>
        </CustomTags>
      );

    case LOCATION_TYPE.AGGREGATION:
      return (
        <CustomTags colors="aggregationPoint" background="aggregationPoint">
          <div className="flex items-center justify-center gap-x-2">
            <AggregationPointIcon />
            <p className="text-xs font-medium capitalize font-poppins">
              Aggregation
            </p>
          </div>
        </CustomTags>
      );

    case LOCATION_TYPE.HUB_STATION:
      return (
        <CustomTags colors="hubStation" background="hubStation">
          <div className="flex items-center justify-center gap-x-2">
            <HubStationIcon />
            <p className="text-xs font-medium capitalize font-poppins">
              Hub Station
            </p>
          </div>
        </CustomTags>
      );
    default:
      return (
        <CustomTags colors="hubStation" background="hubStation">
          <div className="flex items-center justify-center gap-x-2">
            <HubStationIcon />
            <p className="text-xs font-medium capitalize font-poppins">
              Hub Station
            </p>
          </div>
        </CustomTags>
      );
  }
};

export default getLocationType;
