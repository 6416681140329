/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomInput from "../../Core/Components/CustomInput";
import CloseIcon from "../../Common/Svgs/CloseIcon";
import CustomHomeListing from "../../Core/Components/CustomHomeListing";
import useSearch from "../../Core/hooks/useSearch";

const TEMP_COORDINATES = [-0.6803741, 34.7569482];

const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full bg-white flex flex-col items-center justify-center">
      <p className="font-poppins text-base font-medium">Trikes</p>
      <p className="mt-2 font-aileron text-xs font-normal text-dark-gray text-center">
        You can view a list your trikes and locate
        <br /> them easily on the map.
      </p>
      <button
        type="button"
        className="mt-6 px-4 py-3 rounded-md bg-primary hover:bg-primary-50"
        onClick={() => navigate("/trikes")}
      >
        <p className="font-poppins text-xs font-medium text-background-white capitalize">
          Add Trikes
        </p>
      </button>
    </div>
  );
};

const TrikeListings = ({ mapRef }) => {
  const { allTrikes } = useSelector((state) => state.trikes);

  const [focus, setFocus] = useState(false);

  const { filteredData, search, setSearch } = useSearch(allTrikes, [
    "registrationNo",
  ]);

  const handleOnClickOfListings = (trike) => {
    if (trike.locationLat != 0 && trike.locationLong != 0) {
      const currentLat = parseFloat(trike.locationLat);
      const currentLong = parseFloat(trike.locationLong);

      mapRef?.current?.flyTo([currentLat, currentLong], 15);
    } else {
      mapRef?.current?.flyTo(TEMP_COORDINATES, 7);
    }
  };

  return (
    <div className="w-full h-full bg-white">
      <div className="flex items-center justify-center pt-6 mb-4">
        <div className="w-full px-6">
          <CustomInput
            intent="searchHome"
            border={focus ? "success" : "none"}
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            placeholder={focus ? null : "Search Trikes"}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            suffix={
              search?.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setSearch("");
                  }}
                />
              ) : null
            }
          />
        </div>
      </div>

      <div className="h-[calc(100vh-152px)] overflow-y-scroll">
        {allTrikes.length ? (
          filteredData.map((item) => (
            <CustomHomeListing
              key={item.id}
              trike={item}
              onClick={() => handleOnClickOfListings(item)}
            />
          ))
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

export default TrikeListings;
