/* eslint-disable import/no-cycle */
/* eslint-disable radix */
/* eslint-disable no-case-declarations */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { updateQrCode } from "../../Redux/Slices/qrcode.slice";
import { threeDotsOptions } from "./data";

const ListDropdown = ({ data }) => {
  const dispatch = useDispatch();

  const { threeDotsPopoverId } = useSelector((state) => state.qrCode);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [open, setOpen] = useState(false);

  const onOptionClick = (optionId) => {
    switch (optionId) {
      case 1:
        setOpen(false);

        dispatch(
          updateQrCode({
            key: "viewModal",
            value: {
              visible: true,
              data,
            },
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateQrCode({
        key: "threeDotsPopoverId",
        value: data.id,
      }),
    );
  };

  // do not show the three dots options if the user does not have access in that hub
  if (!userDetails.hubs.includes(+data.hubId)) {
    return <div />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && data.id === threeDotsPopoverId}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {threeDotsOptions.map((option) => {
            return (
              <button
                type="button"
                className="w-full flex items-center gap-x-3 px-3 py-2 group hover:bg-background"
                key={option.id}
                onClick={() => onOptionClick(option.id)}
              >
                {option.icon}
                <p className="font-aileron text-xs font-semibold capitalize text-dark-gray group-hover:text-black">
                  {option.value}
                </p>
              </button>
            );
          })}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;
