/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import TripsIcon, { ActiveTripsIcon } from "../../../Common/Svgs/TripsIcon";

const TripItem = ({ sidebarActive, pageName }) => {
  const navigate = useNavigate();

  return (
    <div
      role="button"
      className="flex items-center gap-x-2 mt-4 py-2 px-6 h-9 cursor-pointer w-full group mx-auto"
      onClick={() => navigate("/trips")}
    >
      <motion.div className="w-5 h-5">
        {pageName === "Trips" ? (
          <ActiveTripsIcon size={20} className="text-sidebar-active" />
        ) : (
          <TripsIcon
            size={20}
            className="fill-none text-sidebar-base group-hover:text-sidebar-active"
          />
        )}
      </motion.div>

      {sidebarActive && (
        <motion.p
          // layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className={` font-aileron font-semibold text-xs group-hover:text-sidebar-active pt-1 ${
            pageName === "Trips" ? "text-sidebar-active" : "text-sidebar-base"
          }
      `}
        >
          Trips
        </motion.p>
      )}
    </div>
  );
};

export default TripItem;
