/* eslint-disable import/prefer-default-export */

import React from "react";
import SwitchIcon from "../../../Common/Svgs/switch";
import ViewDetailsIcon from "../../../Common/Svgs/ViewDetailsIcon";
import EditIcon from "../../../Common/Svgs/EditIcon";
import AddToServiceIcon from "../../../Common/Svgs/AddToServiceIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import QRCodeIcon from "../../../Common/Svgs/QRCodeIcon";
import AddSquareIcon from "../../../Common/Svgs/AddSquareIcon";
import TickIcon from "../../../Common/Svgs/TickIcon";
import TrikeDispatchIcon from "../../../Common/Svgs/TrikeDispatchIcon";
import { ParkingLotSecondaryIcon } from "../../../Common/Svgs/ParkingIcon";

export const batteryPercentageFilters = [
  {
    id: 1,
    value: "0-10%",
  },
  {
    id: 2,
    value: "11-20%",
  },
  {
    id: 3,
    value: "21-30%",
  },
  {
    id: 4,
    value: "31-40%",
  },
  {
    id: 5,
    value: "41-50%",
  },
  {
    id: 6,
    value: "51-60%",
  },
  {
    id: 7,
    value: "61-70%",
  },
  {
    id: 8,
    value: "71-80%",
  },
  {
    id: 9,
    value: "81-90%",
  },
  {
    id: 10,
    value: "91-100%",
  },
];

export const statusFilters = [
  {
    id: 1,
    value: "Dispatched",
    className:
      "bg-yellow-10 text-yellow-dark px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium",
  },
  {
    id: 2,
    value: "Hub Station",
    className:
      "bg-light-blue-10 text-light-blue px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium",
  },
  {
    id: 3,
    value: "On Trip",
    className:
      "bg-green-10 text-green px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium",
  },
  {
    id: 4,
    value: "Un-Checked",
    className:
      "bg-warning-10 text-warning px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium",
  },

  {
    id: 5,
    value: "Out of Geofence",
    className:
      "bg-alert-10 text-alert-red px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium",
  },
  {
    id: 6,
    value: "Service",
    className:
      "bg-light-grey text-dark-gray px-2 py-1 rounded-md max-w-max font-poppins text-xs font-medium",
  },
];

export const toggleFilters = [
  {
    id: 1,
    value: (
      <div className="bg-alert-10 px-2 py-1 rounded-md flex items-center gap-x-2 max-w-max">
        <SwitchIcon className="stroke-[1.5px] stroke-alert-red fill-none" />
        <p className="text-alert-red font-poppins text-xs font-medium">
          Immobilise
        </p>
      </div>
    ),
  },
  {
    id: 2,
    value: (
      <div className="bg-green-10 px-2 py-1 rounded-md flex items-center gap-x-2 max-w-max">
        <SwitchIcon className="stroke-[1.5px] stroke-green fill-none" />
        <p className="text-green font-poppins text-xs font-medium">Mobilise</p>
      </div>
    ),
  },
];

export const addTrikeType = [
  {
    id: 1,
    value: "EV",
  },
  {
    id: 2,
    value: "ICE",
  },
];

export const addTrikeModel = [
  {
    id: 1,
    value: "Dukar",
  },
  {
    id: 2,
    value: "Jinpeng",
  },
];

export const trikeParts = [
  {
    id: 1,
    value: "Horn",
  },
  {
    id: 2,
    value: "Indicators",
  },
  {
    id: 3,
    value: "Mirrors",
  },
  {
    id: 4,
    value: "Tyre Pressure",
  },
  {
    id: 5,
    value: "Brakes",
  },
  {
    id: 6,
    value: "Body Scratches",
  },
];

export const TRIKE_STATUS = {
  DISPATCHED: 1,
  HUB_STATION: 2,
  ON_TRIP: 3,
  UN_CHECKED: 4,
  OUT_OF_GEOFENCE: 5,
  SERVICE: 6,
};

export const threeDotsOptions = [
  {
    id: 1,
    value: "View Details",
    icon: <ViewDetailsIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 2,
    value: "Edit Details",
    icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 3,
    value: "Add Issues",
    icon: <AddSquareIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 4,
    value: "View QR Code",
    icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 7,
    value: "Dispatch",
    icon: (
      <TrikeDispatchIcon className="text-dark-gray group-hover:text-black" />
    ),
  },
  {
    id: 8,
    value: "Move To Parking Lot",
    icon: (
      <ParkingLotSecondaryIcon className="text-dark-gray group-hover:text-black" />
    ),
  },
  {
    id: 5,
    value: "Add to Service",
    icon: (
      <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
    ),
  },
  {
    id: 6,
    value: "Delete Trike",
    icon: <TrashIcon className="text-alert-red" />,
  },
];

export const threeDotsOptionForKnownIssues = [
  {
    id: 1,
    value: "Resolve",
    icon: <TickIcon size={18} className="text-dark-gray" />,
  },
  {
    id: 2,
    value: "Delete",
    icon: <TrashIcon className="text-alert-red" />,
  },
];

export const LOCATION_FETCH_SOURCE = {
  MOBILE: 1,
  THINGSBOARD: 2,
};
