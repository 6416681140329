import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import WeatherInformation from "../../Modules/Home/WeatherInformation";
import NotificationPopup from "./Notifications/NotificationPopup";

const HeaderName = ({
  pageName = "Home",
  nestedName,
  customPageNameNavigation = null,
}) => {
  const navigate = useNavigate();

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const handlePageNameNavigation = () => {
    if (customPageNameNavigation) {
      customPageNameNavigation();
    } else {
      navigate(`/${pageName.toLowerCase()}`);
    }
  };

  const getGreetingText = useCallback(() => {
    const date = new Date();
    const hours = date.getHours();

    if (hours < 12) return `Good Morning, ${userDetails.firstName}!`;
    if (hours >= 12 && hours <= 16)
      return `Good Afternoon, ${userDetails.firstName}!`;
    if (hours >= 16) return `Good Evening, ${userDetails.firstName}!`;

    return `Good Morning, ${userDetails.firstName}!`;
  }, [userDetails.firstName]);

  const message = getGreetingText();

  return (
    <div>
      {nestedName ? (
        <div className="flex items-center justify-start text-2xl font-medium leading-9 cursor-pointer text-dark-gray font-poppins gap-x-3">
          <div className="capitalize" onClick={handlePageNameNavigation}>
            {pageName}
          </div>
          <span className="flex items-center justify-start text-2xl font-medium leading-9 text-black font-poppins gap-x-3">
            <img src="/assets/icons/light-grey-chevron.svg" alt="" />
            {nestedName}
          </span>
        </div>
      ) : (
        <div className="flex items-center justify-start text-2xl font-semibold leading-9 text-black font-poppins">
          {pageName === "Home" ? `${message}` : pageName}
        </div>
      )}
    </div>
  );
};

const Header = ({
  pageName = "Home",
  nestedName,
  customPageNameNavigation,
}) => {
  return (
    <div className="w-full border-b bg-background-white h-18 min-h-18 max-h-18 border-light-grey">
      <div className="flex items-center justify-between px-6 h-18">
        <HeaderName
          pageName={pageName}
          nestedName={nestedName}
          customPageNameNavigation={customPageNameNavigation}
        />

        <div className="flex items-center gap-x-6">
          <NotificationPopup />

          <div className="flex flex-col items-end">
            <div className="text-base font-medium leading-6 text-black font-poppins">
              {dayjs().format("ddd, MMM DD, YYYY")}
            </div>

            <WeatherInformation />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
