import React from "react";
import getPaymentStatus from "../getPaymentStatus";
import getUserType from "../getUserType";

export const paymentColumns = [
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">Name</div>
    ),
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Main Hub
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a.hubName.localeCompare(b.hubName),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        User Type
      </div>
    ),
    dataIndex: "userType",
    sorter: (a, b) => a.userType.localeCompare(b.userType),
    render: (text) => <div className="max-w-max">{getUserType(text)}</div>,
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        No. of Trips Due
      </div>
    ),
    dataIndex: "tripsDue",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Total Amount Due
      </div>
    ),
    dataIndex: "amountDue",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text} KSH
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Payment Status
      </div>
    ),
    dataIndex: "paymentStatus",
    render: (text) => (
      <div className="max-w-[120px]">{getPaymentStatus(text)}</div>
    ),
  },
];

export const paymentData = [
  {
    id: 1,
    name: "Viola Toperesu",
    hubId: 14,
    hubName: "Kilgoris Agr-E-Hub",
    userType: 1,
    tripsDue: 0,
    amountDue: 0,
    paymentStatus: 1,
  },
  {
    id: 2,
    name: "Brian Okiamba",
    hubId: 15,
    hubName: "Magena Agr-E-Hub",
    userType: 2,
    tripsDue: 1,
    amountDue: 100,
    paymentStatus: 2,
  },
  {
    id: 3,
    name: "Steven Oyugi",
    hubId: 14,
    hubName: "Ogembo Agr-E-Hub",
    userType: 1,
    tripsDue: 2,
    amountDue: 25,
    paymentStatus: 3,
  },
  {
    id: 4,
    name: "Moses Sambaza",
    hubId: 16,
    hubName: "Kilgoris Agr-E-Hub",
    userType: 1,
    tripsDue: 10,
    amountDue: 500,
    paymentStatus: 3,
  },
  {
    id: 5,
    name: "Jerusha Mwebi",
    hubId: 14,
    hubName: "Ogembo Agr-E-Hub",
    userType: 1,
    tripsDue: 1,
    amountDue: 14,
    paymentStatus: 2,
  },
  {
    id: 6,
    name: "Kemuma Obwoni",
    hubId: 14,
    hubName: "Magena Agr-E-Hub",
    userType: 2,
    tripsDue: 4,
    amountDue: 200,
    paymentStatus: 2,
  },
  {
    id: 7,
    name: "James Omabane",
    hubId: 14,
    hubName: "Kilgoris Agr-E-Hub",
    userType: 2,
    tripsDue: 0,
    amountDue: 0,
    paymentStatus: 1,
  },
];

export const PaymentKPIData = (kpidata) => {
  return [
    {
      title: "Total Amount Paid",
      count: kpidata.totalAmountPaid,
      redirect: false,
    },
    {
      title: "Amount Due To Drivers",
      count: kpidata.amountDueForDrivers,
      redirect: false,
    },
    {
      title: "Amount Due to Partners",
      count: kpidata.amountDueForPartners,
      redirect: false,
    },
  ];
};

export const userTypeFilterOptions = [
  {
    id: 1,
    value: "Driver",
    className:
      "py-1 px-2 bg-[#F5F3FF] text-[#775DFA] font-poppins text-xs font-medium rounded-md",
  },
  {
    id: 2,
    value: "Farmer",
    className:
      "py-1 px-2 bg-[#FEFAED] text-[#EAB726] font-poppins text-xs font-medium rounded-md",
  },
];

export const paymentStatusFilterOptions = [
  {
    id: 1,
    value: getPaymentStatus(1),
  },
  {
    id: 2,
    value: getPaymentStatus(2),
  },
  {
    id: 3,
    value: getPaymentStatus(3),
  },
];
