import React from 'react';

const LocationTickIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeWidth="1.5"
          d="M2.412 5.66c1.313-5.773 9.867-5.767 11.173.007.767 3.386-1.34 6.253-3.187 8.026a3.462 3.462 0 01-4.806 0c-1.84-1.773-3.947-4.646-3.18-8.033z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.168 7.667l1 1L9.835 6"
        />
      </svg>
    </span>
  );
};

export default LocationTickIcon;
