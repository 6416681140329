/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import NotificationIcon, {
  NotificationIconWithRedDot,
} from "../../../Common/Svgs/NotificationIcon";
import NotificationItem from "./NotificationItem";
import {
  getNotifications,
  updateNotifications,
} from "../../../Redux/APIs/homeAPI";
import { updateHome } from "../../../Redux/Slices/home.slice";

const EmptyState = () => {
  return (
    <div className="flex justify-center w-full h-[300px] px-2">
      <div className="mt-[100px] flex flex-col items-center gap-y-2">
        <NotificationIcon className="fill-light-grey stroke-light-grey" />

        <p className="text-sm font-semibold font-aileron text-dark-gray">
          No new notifications
        </p>
      </div>
    </div>
  );
};

const NotificationPopup = () => {
  const dispatch = useDispatch();

  const {
    loading,
    notificationPopupVisible,
    showNotificationDot,
    notifications,
  } = useSelector((state) => state.home);

  useEffect(() => {
    const notifcationFn = async () => {
      // if (notificationPopupVisible) {
      await dispatch(getNotifications());
      await dispatch(updateNotifications());
      // }
    };

    notifcationFn();
  }, [dispatch, notificationPopupVisible]);

  return (
    <Popover
      placement="bottom"
      open={notificationPopupVisible}
      onOpenChange={(state) =>
        dispatch(updateHome({ key: "notificationPopupVisible", value: state }))
      }
      arrow={false}
      trigger="click"
      content={
        <div className="w-[312px] max-h-[600px] overflow-y-scroll">
          {loading ? (
            <div className="flex items-center justify-center my-20">
              <Spin
                spinning={loading}
                indicator={<LoadingOutlined size="large" />}
              />
            </div>
          ) : (
            <div>
              <div className="flex items-center justify-between px-6 pt-6">
                <h1 className="text-base font-medium text-new-black font-poppins">
                  Alerts
                </h1>

                <CloseIcon
                  className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
                  onClick={() =>
                    dispatch(
                      updateHome({
                        key: "notificationPopupVisible",
                        value: false,
                      }),
                    )
                  }
                />
              </div>

              <div className="max-h-[400px] mt-2 overflow-y-scroll hide-scrollbar">
                {notifications.length ? (
                  <>
                    {notifications.map((item, index) => (
                      <NotificationItem
                        key={item.id}
                        notification={item}
                        isLast={index === notifications.length - 1}
                      />
                    ))}
                  </>
                ) : (
                  <EmptyState />
                )}
              </div>
            </div>
          )}
        </div>
      }
    >
      <button type="button">
        {showNotificationDot ? (
          <NotificationIconWithRedDot className="fill-none stroke-black" />
        ) : (
          <NotificationIcon className="fill-none stroke-black" />
        )}
      </button>
    </Popover>
  );
};

export default NotificationPopup;
