import React from "react";
import BatteryLowIcon, {
  BatteryHighIcon,
  BatteryNeutralIcon,
  BatteryNoStatusIcon,
} from "../../../Common/Svgs/BatteryChargeIcon";

const BatteryPercentageStatus = ({
  detail,
  isCharging = false,
  showChargingStatus = true,
}) => {
  const { percentage } = detail;

  const chargingStatus = isCharging ? "Charging" : "Discharging";

  let batteryIcon;
  let batteryColor;

  if (percentage >= 0 && percentage <= 24) {
    batteryIcon = <BatteryLowIcon />;
    batteryColor = "text-alert-red";
  } else if (percentage >= 25 && percentage <= 50) {
    batteryIcon = <BatteryNeutralIcon />;
    batteryColor = "text-warning";
  } else if (percentage >= 51 && percentage <= 100) {
    batteryIcon = <BatteryHighIcon />;
    batteryColor = "text-primary";
  } else {
    batteryIcon = <BatteryNoStatusIcon />;
  }

  return (
    <div>
      <div className="flex items-center gap-x-2">
        {batteryIcon}
        <div
          className={`text-xs font-medium font-poppins leading-4.5 ${batteryColor}`}
        >
          {percentage === -1 ? "--" : `${percentage}%`}
        </div>
      </div>

      {showChargingStatus && (
        <p className="font-light capitalize font-poppins text-10 text-dark-grey-text">
          {chargingStatus}
        </p>
      )}
    </div>
  );
};

export default BatteryPercentageStatus;
