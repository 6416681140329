import React from 'react';

const timeSlots = [
  {
    key: 1,
    value: '5:00 AM',
    label: (
      <p className="px-3 py-2 mt-1 text-xs font-semibold font-aileron text-dark-gray">
        5:00 AM
      </p>
    ),
  },
  {
    key: 2,
    value: '6:00 AM',
    label: (
      <p className="px-3 py-2 mt-1 text-xs font-semibold font-aileron text-dark-gray">
        6:00 AM
      </p>
    ),
  },
  {
    key: 3,
    value: '7:00 AM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        7:00 AM
      </p>
    ),
  },
  {
    key: 4,
    value: '8:00 AM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        8:00 AM
      </p>
    ),
  },
  {
    key: 5,
    value: '9:00 AM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        9:00 AM
      </p>
    ),
  },
  {
    key: 6,
    value: '10:00 AM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        10:00 AM
      </p>
    ),
  },
  {
    key: 7,
    value: '11:00 AM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        11:00 AM
      </p>
    ),
  },
  {
    key: 8,
    value: '12:00 PM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        12:00 PM
      </p>
    ),
  },
  {
    key: 9,
    value: '1:00 PM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        1:00 PM
      </p>
    ),
  },
  {
    key: 9,
    value: '2:00 PM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        2:00 PM
      </p>
    ),
  },
  {
    key: 10,
    value: '3:00 PM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        3:00 PM
      </p>
    ),
  },
  {
    key: 12,
    value: '4:00 PM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        4:00 PM
      </p>
    ),
  },
  {
    key: 13,
    value: '5:00 PM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        5:00 PM
      </p>
    ),
  },
  {
    key: 14,
    value: '6:00 PM',
    label: (
      <p className="px-3 py-2 text-xs font-semibold font-aileron text-dark-gray">
        6:00 PM
      </p>
    ),
  },
];

export const days = [
  {
    id: 1,
    value: 's',
  },
  {
    id: 2,
    value: 'm',
  },
  {
    id: 3,
    value: 't',
  },
  {
    id: 4,
    value: 'w',
  },
  {
    id: 5,
    value: 't',
  },
  {
    id: 6,
    value: 'f',
  },
  {
    id: 7,
    value: 's',
  },
];

export default timeSlots;
