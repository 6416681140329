import React from "react";

const DuplicateIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.67 8.6v2.8c0 2.333-.934 3.267-3.267 3.267h-2.8c-2.334 0-3.267-.934-3.267-3.267V8.6c0-2.333.933-3.267 3.267-3.267h2.8c2.333 0 3.266.934 3.266 3.267z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.67 4.6v2.8c0 2.333-.934 3.267-3.267 3.267h-.734V8.6c0-2.333-.933-3.267-3.266-3.267H5.336V4.6c0-2.333.933-3.267 3.267-3.267h2.8c2.333 0 3.266.934 3.266 3.267z"
        />
      </svg>
    </span>
  );
};

export default DuplicateIcon;
