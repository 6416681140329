/* eslint-disable no-console */
import React, { useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { updateTenants } from "../../Redux/Slices/tenants.slice";
import { client } from "../../Utils/axiosClient";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import Screen3 from "./Screen3";
import Screen4 from "./Screen4";
import Screen5 from "./Screen5";
import Screen6 from "./Screen6";
import Screen7 from "./Screen7";

const Progress = ({ percent, isLastScreen }) => {
  return (
    <div className="w-full h-1 relative mb-9">
      <div className="w-full h-full bg-background-white opacity-10" />
      <motion.div
        initial={{ width: isLastScreen ? `${percent}%` : `${percent - 25}%` }}
        animate={{ width: `${percent}%` }}
        transition={{ duration: 0.3 }}
        className="absolute left-0 top-0 bg-yellow h-full"
      />
    </div>
  );
};

const OnboardingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [details, setDetails] = useState({
    name: "",
    vehicles: "",
    drivers: "",
    hubs: "",
    baseLocation: "",
  });

  const [screen, setScreen] = useState(1);

  const onSubmit = async () => {
    // remove the redux state
    localStorage.removeItem("persist:root");

    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);

    // fetch the user details
    await dispatch(getUserByEmail({ email: decoded.email }));

    const payload = {
      ...details,
      vehicles: parseInt(details.vehicles, 10),
      drivers: parseInt(details.drivers, 10),
      hubs: parseInt(details.hubs, 10),
      id: decoded.tenantId,
    };

    try {
      dispatch(
        updateTenants({
          key: "loading",
          value: true,
        }),
      );

      const response = await client.post(
        `${process.env.REACT_APP_API_URL}/tenant/update-tenant`,
        payload,
      );

      if (response.status === 200) navigate("/home");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(
        updateTenants({
          key: "loading",
          value: false,
        }),
      );
    }
  };

  return (
    <div className="w-screen h-screen bg-green flex items-center justify-center">
      {screen === 1 && <Screen1 setScreen={setScreen} />}

      {screen === 2 && (
        <div className="w-[512px]">
          <Progress percent={20} />
          <Screen2
            setScreen={setScreen}
            details={details}
            setDetails={setDetails}
          />
        </div>
      )}

      {screen === 3 && (
        <div className="w-[512px]">
          <Progress percent={40} />
          <Screen3
            setScreen={setScreen}
            details={details}
            setDetails={setDetails}
          />
        </div>
      )}

      {screen === 4 && (
        <div className="w-[512px]">
          <Progress percent={60} />
          <Screen4
            setScreen={setScreen}
            details={details}
            setDetails={setDetails}
          />
        </div>
      )}

      {screen === 5 && (
        <div className="w-[512px]">
          <Progress percent={80} />
          <Screen5
            setScreen={setScreen}
            details={details}
            setDetails={setDetails}
          />
        </div>
      )}

      {screen === 6 && (
        <div className="w-[512px]">
          <Progress percent={100} />
          <Screen6
            setScreen={setScreen}
            details={details}
            setDetails={setDetails}
          />
        </div>
      )}

      {screen === 7 && (
        <div className="w-[512px]">
          <Progress percent={100} isLastScreen />
          <Screen7 onClick={onSubmit} />
        </div>
      )}
    </div>
  );
};

export default OnboardingPage;
