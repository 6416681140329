export const headerButtons = [
  {
    id: 1,
    padding: "filter",
    width: "settingsDriversTab",
    text: "Payments",
  },
  {
    id: 2,
    padding: "filter",
    width: "settingsDriversTab",
    text: "Collections",
  },
];
