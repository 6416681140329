import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as axios } from "../../Utils/axiosClient";

export const addCommodity = createAsyncThunk(
  "rates/addCommodity",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/commodity/add-commodity`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCommodities = createAsyncThunk(
  "rates/getCommodities",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/commodity/get-commodities`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editCommodity = createAsyncThunk(
  "rates/editCommodity",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/commodity/edit-commodity`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAllCommodities = createAsyncThunk(
  "rates/getAllCommodities",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/commodity/get-all-commodities`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
