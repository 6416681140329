import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";

const TenantHeader = ({ search, setSearch }) => {
  const navigate = useNavigate();

  const [focus, setFocus] = useState(false);

  return (
    <div>
      <h1 className="font-poppins text-xs font-medium">Tenants</h1>

      <h2 className="mt-2 font-aileron text-xs font-normal text-dark-gray">
        Add and onboard the key stakeholder of the organization, granting them
        full access to both the web and mobile platforms as a super admin.
      </h2>

      <div className="mt-4">
        <div
          className="bg-background-white px-6 py-2.5 rounded-t-md border-light-grey flex items-center justify-between"
          style={{
            borderTopWidth: "1px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
          }}
        >
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Tenants"}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />

          <button
            type="button"
            className="px-4 py-[11px] bg-primary hover:bg-primary-50 rounded-md"
            onClick={() => navigate("/settings/tenants/create-tenant")}
          >
            <div className="flex item-center gap-x-2">
              <img src="/assets/icons/add.svg" alt="" />
              <p className="font-poppins text-xs leading-4.5 font-medium text-background-white">
                Create Tenant
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TenantHeader;
