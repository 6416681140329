import React from 'react';

const Label = ({ text, className = 'mb-[6px]' }) => {
  return (
    <div
      className={`text-xs font-normal capitalize font-aileron text-dark-gray ${className}`}
    >
      {text}
    </div>
  );
};
export default Label;
