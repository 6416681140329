import React from "react";
import dayjs from "dayjs";
import InviteAndDeleteAction from "./InviteAndDeleteAction";
import { AlertTriangleIcon } from "../../../Common/Svgs/AlertIcon";
import CustomTooltip from "../../../Core/Components/CustomTooltip";

export const tenantColumns = [
  {
    title: () => <div />,
    width: "40px",
    dataIndex: "verificationComplete",
    render: (text) => (
      <div>
        {parseInt(text, 10) === 0 && (
          <CustomTooltip tooltipTitle="Verification pending">
            <AlertTriangleIcon size={16} className="stroke-yellow-dark" />
          </CustomTooltip>
        )}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Organisation
      </div>
    ),
    width: "17%",
    dataIndex: "organisationName",
    sorter: (a, b) => a.organisationName.localeCompare(b.organisationName),
    render: (text) => (
      <div className="text-xs font-semibold text-black font-aileron py-2 capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">Name</div>
    ),
    width: "15%",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Email
      </div>
    ),
    width: "17%",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Contact
      </div>
    ),
    width: "10%",
    dataIndex: "contactNo",
    sorter: (a, b) => a.contactNo.localeCompare(b.contactNo),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Vehicles
      </div>
    ),
    width: "6%",
    dataIndex: "vehicles",
    sorter: (a, b) => a.vehicles.localeCompare(b.vehicles),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Drivers
      </div>
    ),
    width: "6%",
    dataIndex: "drivers",
    sorter: (a, b) => a.drivers.localeCompare(b.drivers),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">Hubs</div>
    ),
    width: "6%",
    dataIndex: "hubs",
    sorter: (a, b) => a.hubs.localeCompare(b.hubs),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Date of entry
      </div>
    ),
    width: "10%",
    dataIndex: "submittedAt",
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {dayjs(text).format("DD-MM-YYYY")}
      </div>
    ),
  },
  {
    title: () => null,
    // width: "25%",
    dataIndex: "createdAt",
    render: (text, record) => (
      <div>
        <InviteAndDeleteAction tenant={record} />
      </div>
    ),
  },
];

export const tenantData = [
  {
    id: 1,
    organisation: "Antarctica Global",
    name: "Kevin Shah",
    email: "kevin@antarcticaglobal.com",
    contact: "1234567890",
    assets: 20,
    drivers: 30,
    hubs: 40,
    createdAt: "01-10-2024",
    isActive: 1,
  },
  {
    id: 2,
    organisation: "Pebble",
    name: "Ganesh Mohanty",
    email: "ganesh@antarcticaglobal.com",
    contact: "1234567890",
    assets: 20,
    drivers: 30,
    hubs: 40,
    createdAt: "11-10-2024",
    isActive: 1,
  },
  {
    id: 3,
    organisation: "Songa Mobility",
    name: "Kevin Shah",
    email: "kevin@antarcticaglobal.com",
    contact: "1234567890",
    assets: 20,
    drivers: 30,
    hubs: 40,
    createdAt: "01-10-2024",
    isActive: 0,
  },
];
