import React from 'react';

const WeightCollectedIcon = ({ size = 17, className = 'text-[#6F6F6F]' }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 17 17"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.263"
          d="M11.646 6.305c2.55.22 3.591 1.53 3.591 4.398v.093c0 3.166-1.268 4.434-4.434 4.434H6.192c-3.166 0-4.434-1.268-4.434-4.434v-.093c0-2.847 1.027-4.157 3.534-4.391M8.5 1.416v9.123"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.263"
          d="M10.87 8.96l-2.372 2.373-2.373-2.372"
        />
      </svg>
    </span>
  );
};

export default WeightCollectedIcon;
