import React from "react";

const DriverSuspendIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 12.667h-2.667M8.1 7.246a1.211 1.211 0 00-.22 0 2.947 2.947 0 01-2.847-2.953c0-1.633 1.32-2.96 2.96-2.96A2.957 2.957 0 018.1 7.246zM7.993 14.54c-1.213 0-2.42-.306-3.34-.92-1.613-1.08-1.613-2.84 0-3.913 1.834-1.227 4.84-1.227 6.674 0"
        />
      </svg>
    </span>
  );
};

export default DriverSuspendIcon;
