/* eslint-disable no-lonely-if */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Checkbox } from "antd";
import { driverPermissions, partnerPermissions } from "./data";
import PermissionTitle from "./PermissionTitle";
import ModuleHeader from "./ModuleHeader";
import { PERMISSIONS } from "../../Login/constants";
import {
  driverAddEditDelete,
  partnerAddEditDelete,
  partnerExport,
  partnerViewQR,
} from "./permissionFunctions";
import UserIcon from "../../../Common/Svgs/UserIcon";

const CONDITIONS = {
  DRIVER_VIEW_AND_EDIT: [PERMISSIONS.DRIVER_VIEW, PERMISSIONS.DRIVER_NO_ACCESS],
  DRIVER_REMOVE_ALL: [
    PERMISSIONS.DRIVER_ADD,
    PERMISSIONS.DRIVER_EDIT,
    PERMISSIONS.DRIVER_DELETE,
    PERMISSIONS.DRIVER_SUSPEND,
    PERMISSIONS.DRIVER_VIEW,
    PERMISSIONS.DRIVER_VIEW_AND_EDIT,
    PERMISSIONS.DRIVER_NO_ACCESS,
  ],
  PARTNER_VIEW_AND_EDIT: [
    PERMISSIONS.PARTNER_VIEW,
    PERMISSIONS.PARTNER_NO_ACCESS,
  ],
  PARTNER_REMOVE_ALL: [
    PERMISSIONS.PARTNER_ADD,
    PERMISSIONS.PARTNER_EDIT,
    PERMISSIONS.PARTNER_DELETE,
    PERMISSIONS.PARTNER_VIEW_QR,
    PERMISSIONS.PARTNER_EXPORT,
    PERMISSIONS.PARTNER_VIEW,
    PERMISSIONS.PARTNER_NO_QR,
    PERMISSIONS.PARTNER_NO_EXPORT,
    PERMISSIONS.PARTNER_VIEW_AND_EDIT,
    PERMISSIONS.PARTNER_NO_ACCESS,
  ],
};

const UserPermissions = ({ permissions, setPermissions }) => {
  // check if the permissions needs to be disabled for driver
  const disabledDriver = CONDITIONS.DRIVER_VIEW_AND_EDIT.some((d) =>
    permissions.includes(d),
  );

  // check if the permissions needs to be disabled for farmer
  const disabledPartner = CONDITIONS.PARTNER_VIEW_AND_EDIT.some((d) =>
    permissions.includes(d),
  );

  const handleModulePermission = (permissionId) => {
    let local = [];

    // driver module permissions
    if (permissionId === PERMISSIONS.DRIVER_VIEW_AND_EDIT) {
      local = permissions.filter(
        (item) => !CONDITIONS.DRIVER_VIEW_AND_EDIT.includes(item),
      );
    }

    // removing all the driver permissions
    if (permissionId === PERMISSIONS.DRIVER_VIEW) {
      local = permissions.filter(
        (item) => !CONDITIONS.DRIVER_REMOVE_ALL.includes(item),
      );
    }

    // removing all the driver permissions
    if (permissionId === PERMISSIONS.DRIVER_NO_ACCESS) {
      local = permissions.filter(
        (item) => !CONDITIONS.DRIVER_REMOVE_ALL.includes(item),
      );
    }

    // farmer module permissions
    if (permissionId === PERMISSIONS.PARTNER_VIEW_AND_EDIT) {
      local = permissions.filter(
        (item) => !CONDITIONS.PARTNER_VIEW_AND_EDIT.includes(item),
      );
    }

    // removing all the farmer permissions
    if (permissionId === PERMISSIONS.PARTNER_VIEW) {
      local = permissions.filter(
        (item) => !CONDITIONS.PARTNER_REMOVE_ALL.includes(item),
      );
    }

    // removing all the farmer permissions
    if (permissionId === PERMISSIONS.PARTNER_NO_ACCESS) {
      local = permissions.filter(
        (item) => !CONDITIONS.PARTNER_REMOVE_ALL.includes(item),
      );
    }

    setPermissions([...local, permissionId]);
  };

  const handleIndividualPermission = (permissionId) => {
    let local = [];

    if (permissions.includes(permissionId)) {
      local = permissions.filter((item) => item !== permissionId);
      setPermissions(local);
    } else {
      if (permissionId === PERMISSIONS.DRIVER_ADD) {
        // handling multiple insertions for driver add, edit, delete
        driverAddEditDelete({ setPermissions });
      } else if (permissionId === PERMISSIONS.PARTNER_ADD) {
        // handling multiple insertions for partner add, edit, delete
        partnerAddEditDelete({ setPermissions });
      } else if (
        permissionId === PERMISSIONS.PARTNER_VIEW_QR ||
        permissionId === PERMISSIONS.PARTNER_NO_QR
      ) {
        // handling either view or don't view partner qr code
        partnerViewQR({
          current: permissionId,
          permissions,
          setPermissions,
        });
      } else if (
        permissionId === PERMISSIONS.PARTNER_EXPORT ||
        permissionId === PERMISSIONS.PARTNER_NO_EXPORT
      ) {
        // handling either export or don't export partner data
        partnerExport({
          current: permissionId,
          permissions,
          setPermissions,
        });
      } else {
        setPermissions((prev) => [...prev, permissionId]);
      }
    }
  };

  return (
    <div>
      <ModuleHeader
        module="Users"
        icon={<UserIcon className="w-4 h-4 fill-none stroke-primary" />}
      />
      {/* main module permission for drivers section */}
      <div className="w-full px-6 py-2 flex items-center gap-x-2 font-poppins text-xs font-medium bg-background border-b border-light-grey">
        <p>A.</p>
        <p>Drivers</p>
      </div>

      <div className="w-full h-[60px]">
        <div
          className="flex items-center w-full h-full border-b"
          style={{
            background: "#FAF2D8",
            borderColor: "#FAD152",
          }}
        >
          <div
            className="w-[40%] border-r py-3 px-6"
            style={{
              borderColor: "#FAD152",
            }}
          >
            <PermissionTitle
              title={driverPermissions[0].title}
              description={driverPermissions[0].description}
            />
          </div>

          <div className="w-[60%] h-full flex items-center">
            {driverPermissions[0].options.map((option) => {
              // rendering empty div for layout consistency
              if (!option) {
                return (
                  <div
                    className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                    style={{
                      borderColor: "#FAD152",
                    }}
                  />
                );
              }

              const selected = permissions.includes(option.permissionId);

              return (
                <div
                  key={option.text}
                  className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                  style={{
                    borderColor: "#FAD152",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    onClick={() => handleModulePermission(option.permissionId)}
                  />
                  <p
                    className={`font-poppins text-xs ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {option.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* individual functionality permission for drivers section */}
      <div className="w-full">
        {driverPermissions.slice(1).map((item) => {
          return (
            <div
              key={item.id}
              className="flex items-center w-full h-[60px] border-b"
              style={{
                borderColor: "#DFDFDF",
              }}
            >
              <div
                className="w-[40%] h-full border-r py-3 px-6"
                style={{
                  borderColor: "#DFDFDF",
                }}
              >
                <PermissionTitle
                  title={item.title}
                  description={item.description}
                />
              </div>

              <div className="w-[60%] h-full flex items-center">
                {item.options.map((option, idx) => {
                  // rendering empty div for layout consistency
                  if (!option) {
                    return (
                      <div
                        key={idx}
                        className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                        style={{
                          borderColor: "#DFDFDF",
                        }}
                      />
                    );
                  }

                  const selected = permissions.includes(option.permissionId);

                  // if the permission is view only, remove pointer events and add an opacity
                  return (
                    <div
                      key={option.text}
                      className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                      style={{
                        borderColor: "#DFDFDF",
                        opacity: disabledDriver && 0.5,
                        pointerEvents: disabledDriver && "none",
                      }}
                    >
                      {/* if the permissions should be disabled then it should not
                          show any tick mark else show the selected tick mark */}
                      <Checkbox
                        checked={disabledDriver ? false : selected}
                        onClick={() =>
                          handleIndividualPermission(option.permissionId)
                        }
                      />
                      <p
                        className={`font-poppins text-xs
                            ${selected ? "font-medium" : "font-normal"}
                            `}
                      >
                        {option.text}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {/* main module permission for farmers module */}
      <div className="w-full px-6 py-2 flex items-center gap-x-2 font-poppins text-xs font-medium bg-background border-b border-light-grey">
        <p>B.</p>
        <p>Partners</p>
      </div>
      <div className="w-full h-[60px]">
        <div
          className="flex items-center w-full h-full border-b"
          style={{
            background: "#FAF2D8",
            borderColor: "#FAD152",
          }}
        >
          <div
            className="w-[40%] border-r py-3 px-6"
            style={{
              borderColor: "#FAD152",
            }}
          >
            <PermissionTitle
              title={partnerPermissions[0].title}
              description={partnerPermissions[0].description}
            />
          </div>

          <div className="w-[60%] h-full flex items-center">
            {partnerPermissions[0].options.map((option) => {
              // rendering empty div for layout consistency
              if (!option) {
                return (
                  <div
                    className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                    style={{
                      borderColor: "#FAD152",
                    }}
                  />
                );
              }

              const selected = permissions.includes(option.permissionId);

              return (
                <div
                  key={option.text}
                  className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                  style={{
                    borderColor: "#FAD152",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    onClick={() => handleModulePermission(option.permissionId)}
                  />
                  <p
                    className={`font-poppins text-xs ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {option.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* individual functionality permission for farmers module */}
      <div className="w-full">
        {partnerPermissions.slice(1).map((item) => {
          return (
            <div
              key={item.id}
              className="flex items-center w-full h-[60px] border-b"
              style={{
                borderColor: "#DFDFDF",
              }}
            >
              <div
                className="w-[40%] h-full border-r py-3 px-6"
                style={{
                  borderColor: "#DFDFDF",
                }}
              >
                <PermissionTitle
                  title={item.title}
                  description={item.description}
                />
              </div>

              <div className="w-[60%] h-full flex items-center">
                {item.options.map((option, idx) => {
                  // rendering empty div for layout consistency
                  if (!option) {
                    return (
                      <div
                        key={idx}
                        className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                        style={{
                          borderColor: "#DFDFDF",
                        }}
                      />
                    );
                  }

                  const selected = permissions.includes(option.permissionId);

                  // if the permission is view only, remove pointer events and add an opacity
                  return (
                    <div
                      key={option.text}
                      className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                      style={{
                        borderColor: "#DFDFDF",
                        opacity: disabledPartner && 0.5,
                        pointerEvents: disabledPartner && "none",
                      }}
                    >
                      {/* if the permissions should be disabled then it should not
                          show any tick mark else show the selected tick mark */}
                      <Checkbox
                        checked={disabledPartner ? false : selected}
                        onClick={() =>
                          handleIndividualPermission(option.permissionId)
                        }
                      />
                      <p
                        className={`font-poppins text-xs
                            ${selected ? "font-medium" : "font-normal"}
                            `}
                      >
                        {option.text}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserPermissions;
