import { useSelector } from "react-redux";
import { SEED_ROLES } from "../Modules/Login/constants";
import { LOCATION_TYPE } from "../Modules/Location/Data";

const useHubStationOnAdd = () => {
  const { userDetails } = useSelector((state) => state.loggedInUser);

  const { allLocations } = useSelector((state) => state.locations);

  if (userDetails.roleId === SEED_ROLES.HUB_MANAGER) {
    return allLocations
      .filter(
        (item) =>
          item.type === LOCATION_TYPE.HUB_STATION &&
          userDetails.hubs.includes(parseInt(item.hubId, 10)),
      )
      .map((location) => ({
        id: location.id,
        value: location.name,
      }));
  }

  return allLocations
    .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));
};

export default useHubStationOnAdd;
