import dayjs from 'dayjs';

const getDateAndTime = (input) => {
  if (input !== '--') {
    return dayjs(input).format('DD-MM-YYYY, HH:mm:ss');
  }
  return null;
};

export const getDifferenceOfTime = (start, end) => {
  const diffInMinutes = dayjs(end).diff(dayjs(start), 'minute');
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  if (start && end) {
    return `${hours} H ${minutes} M`;
  }
  return '--';
};

export default getDateAndTime;
