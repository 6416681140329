import React from "react";
import { TickCircleIcon } from "../Common/Svgs/TickIcon";
import AlertIcon from "../Common/Svgs/AlertIcon";

const getToastMessage = (type, id) => {
  let toastMessageDetails = {
    icon: <TickCircleIcon />,
    iconBackground: "bg-primary-10",
    title: "1 Activity And Cargo Added",
    description:
      "Activity and Cargo combination for Stop 2 was added successfully.",
  };
  switch (type) {
    case "sameStop":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <AlertIcon size={24} className="text-white" />,
        iconBackground: "bg-alert-red",
        title: "Same Stop Already exists",
        description: (
          <p>
            Stop already exists at position
            <span className="font-semibold text-black"> {id}</span>
          </p>
        ),
      };
      break;
    case "activityCreated":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <TickCircleIcon />,
        iconBackground: "bg-primary-10",
        title: "1 Activity And Cargo Combination Added",
        description: (
          <p>
            Activity and Cargo combination for{" "}
            <span className="font-semibold text-black">Stop {id}</span> was
            added successfully.
          </p>
        ),
      };
      break;
    case "sameActivity":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <AlertIcon size={24} className="text-white" />,
        iconBackground: "bg-alert-red",
        title: "Same Activity and Cargo Combination Already exists",
        description: (
          <p>
            Activity and Cargo combination for{" "}
            <span className="font-semibold text-black">Stop {id}</span> already
            exists.
          </p>
        ),
      };
      break;
    case "sameCargoActivity":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <AlertIcon size={24} className="text-white" />,
        iconBackground: "bg-alert-red",
        title: "Multiple activities for same cargo",
        description: (
          <p>
            Cannot add multiple activities for same cargo at{" "}
            <span className="font-semibold text-black">Stop {id}</span>
          </p>
        ),
      };
      break;
    case "activityDeleted":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <TickCircleIcon />,
        iconBackground: "bg-primary-10",
        title: "1 Activity And Cargo Combination Deleted",
        description: (
          <p>
            Activity and Cargo combination for{" "}
            <span className="font-semibold text-black">Stop {id}</span> was
            deleted successfully.
          </p>
        ),
      };
      break;
    case "tripCreated":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <TickCircleIcon />,
        iconBackground: "bg-primary-10",
        title: "1 Trip Created",
        description: "Your trip was created successfully.",
      };
      break;
    case "tripDeleted":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <TickCircleIcon />,
        iconBackground: "bg-primary-10",
        title: "1 Trip Deleted",
        description: "Your trip was deleted successfully.",
      };
      break;
    case "tripExists":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <AlertIcon size={24} className="text-white" />,
        iconBackground: "bg-alert-red",
        title: "Trip already exists",
        description: "Trip with same details already exists.",
      };
      break;
    case "tripUpdated":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <TickCircleIcon />,
        iconBackground: "bg-primary-10",
        title: "1 Trip Updated",
        description: "Your trip was updated successfully.",
      };
      break;
    case "timeStampUpdated":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <TickCircleIcon />,
        iconBackground: "bg-primary-10",
        title: "Entries updated",
        description: "Entries were updated and will be reflected shortly.",
      };
      break;
    case "endTripByAdmin":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <TickCircleIcon />,
        iconBackground: "bg-primary-10",
        title: "Trip Ended",
        description: `1 trip was ended successfully.`,
      };
      break;
    case "editDriverError":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <AlertIcon size={24} className="text-white" />,
        iconBackground: "bg-alert-red",
        title: "Cannot edit driver",
        description: "Driver of an ongoing trip cannot be changed.",
      };
      break;
    case "editTrikeError":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <AlertIcon size={24} className="text-white" />,
        iconBackground: "bg-alert-red",
        title: "Cannot edit trike",
        description: "Trike of an ongoing trip cannot be changed.",
      };
      break;
    case "editBatteriesError":
      toastMessageDetails = {
        ...toastMessageDetails,
        icon: <AlertIcon size={24} className="text-white" />,
        iconBackground: "bg-alert-red",
        title: "Cannot assign batteries",
        description: "Batteries cannot be assigned to an ongoing trip.",
      };
      break;
    default:
      break;
  }
  return toastMessageDetails;
};

export default getToastMessage;
