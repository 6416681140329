import React from "react";
import CustomTooltip from "../../../Core/Components/CustomTooltip";
import getDateAndTime from "../../../Utils/getDateAndTime";

export const inventoryColumns = [
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Inventory Name
      </div>
    ),
    dataIndex: "name",
    // sorter: (a, b) => a.name - b.name,
    sorter: (a, b) => a.name.localeCompare(b.name),

    render: (text) => (
      <div>
        <div className="text-xs font-semibold font-aileron leading-4.5 text-black py-1">
          {text}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Inventory ID
      </div>
    ),
    dataIndex: "id",
    sorter: (a, b) => a.id - b.id,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Asset Type
      </div>
    ),
    dataIndex: "asset_type",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Quantity
      </div>
    ),
    dataIndex: "quantity",
    sorter: (a, b) => a.quantity - b.quantity,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text} Nos
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Checklist
      </div>
    ),
    dataIndex: "checklist",
    // sorter: (a, b) => a.severity.localeCompare(b.severity),
    // sorter: (a, b) => a.severityId - b.severityId,
    render: (text) => (
      <CustomTooltip
        tooltipTitle={text}
        bgColor="#fff"
        textColor="text-dark-gray"
        placement="bottomLeft"
        padding="8px"
        trigger="click"
      >
        <div className="flex items-center cursor-pointer gap-x-1">
          <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
            View
          </div>
          <img src="/assets/icons/chevron-down.svg" alt="" />
        </div>
      </CustomTooltip>
    ),
  },
  {
    title: () => (
      <div className="font-normal text-dark-gray font-aileron text-10">
        Date Of Entry
      </div>
    ),
    dataIndex: "submittedAt",
    render: (text, record) => (
      <div>
        <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
          {text}
        </div>
        <div className="text-10 font-normal font-aileron leading-3.75 text-dark-gray">
          {getDateAndTime(record.created_at)}
        </div>
      </div>
    ),
  },
];

export const serviceDropdownBtnArr = [
  {
    title: "Type",
    renderBody: "Type",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "assetType",
  },
];
