export const tripsRequiringBatteries = [
  {
    id: 101,
    startTime: new Date(),
    batteries: 3,
  },
  {
    id: 102,
    startTime: new Date(),
    batteries: 4,
  },
  {
    id: 103,
    startTime: new Date(),
    batteries: 5,
  },
  {
    id: 104,
    startTime: new Date(),
    batteries: 3,
  },
  {
    id: 105,
    startTime: new Date(),
    batteries: 3,
  },
  {
    id: 106,
    startTime: new Date(),
    batteries: 4,
  },
  {
    id: 107,
    startTime: new Date(),
    batteries: 6,
  },
];
