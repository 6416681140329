import React, { useState } from "react";
import { Popover } from "antd";
import { threeDotsOptionForKnownIssues, threeDotsOptions } from "../Data";

const TrikeKnownIssueDropdown = ({ onResolve, onDelete }) => {
  const [open, setOpen] = useState(false);

  const onOptionClick = async (optionId) => {
    switch (optionId) {
      case 1:
        setOpen(false);
        onResolve();
        break;
      case 2:
        setOpen(false);
        onDelete();
        break;
      default:
        break;
    }
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {threeDotsOptionForKnownIssues.map((option, index) => (
            <button
              type="button"
              className={`w-full flex items-center gap-x-3 px-3 py-2 group
              ${
                index === threeDotsOptions.length - 1
                  ? "hover:bg-light-alert-red-hover"
                  : "hover:bg-background"
              }
              `}
              style={{
                borderBottom: index === 0 && "1px solid #DFDFDF",
              }}
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p
                className={`font-aileron text-xs font-semibold capitalize ${
                  index === 1
                    ? "text-alert-red group-hover:text-alert-red-hover"
                    : "text-dark-gray group-hover:text-black"
                }`}
              >
                {option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="px-2 py-1 rounded-md cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default TrikeKnownIssueDropdown;
