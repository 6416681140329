/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-console */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import { FinanceHoverIcon } from "../../../Common/Svgs/FinanceIcon";
import CustomTable from "../../../Core/Components/CustomTable";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import CollectionDetailsHeader from "./CollectionDetailsHeader";
import { collectionDetailColumns, collectionDetailData } from "./data";

const LOCAL_DEFAULT_LIMIT = 10;

const CollectionDetails = () => {
  const navigate = useNavigate();

  const { selectedCollection } = useSelector((state) => state.revenue);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    status: [],
    dateRangeStart: "",
    dateRangeEnd: "",
  });

  const handlePageNameNavigation = () => {
    navigate("/revenue?tab=collections");
  };

  console.log(currentPage);

  return (
    <CustomLayout
      pageName="Collections"
      nestedName={selectedCollection?.name}
      customPageNameNavigation={handlePageNameNavigation}
    >
      <div className="w-full bg-background-white">
        <div className="p-6">
          <h1 className="font-poppins text-base font-medium capitalize">
            Collection History
          </h1>

          <p className="mt-3 font-poppins text-base font-medium">
            {selectedCollection.amountDue} KSH
          </p>

          <p className="mt-1 font-aileron text-xs font-normal text-dark-gray capitalize">
            Total amount due
          </p>
        </div>

        <CollectionDetailsHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />

        <div
          className={`h-[calc(100vh-72px-128px-70px-40px)] ${
            collectionDetailData.length && "overflow-y-scroll"
          }`}
        >
          <CustomTable
            // scrollX={0}
            // scrollY="388px"
            disableScroll
            columns={collectionDetailColumns}
            data={collectionDetailData}
            emptyState={
              <FinanceHoverIcon size={16} className="text-dark-grey-text" />
            }
            emptyStateHeight="420px"
            rowClassName="group"
            pagination={{
              pageSize: LOCAL_DEFAULT_LIMIT,
              total: collectionDetailData.length,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              current: currentPage,
              itemRender: (current, type, originalElement) => {
                const lastPage = collectionDetailData.length / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>
    </CustomLayout>
  );
};

export default CollectionDetails;
