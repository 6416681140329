import { createSlice } from "@reduxjs/toolkit";
import {
  addCommodity,
  editCommodity,
  getAllCommodities,
  getCommodities,
} from "../APIs/ratesAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  commodityList: {
    result: [],
    totalRecords: 0,
  },
  allCommodities: [],
  currentCommodity: {},
  threeDotsPopoverId: 0,
  showToastMessage: {
    visible: false,
    data: {},
  },
  deleteCommodityModal: false,
};

export const ratesSlice = createSlice({
  name: "rates",
  initialState,
  reducers: {
    updateRates: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // add commodity
    builder.addCase(addCommodity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCommodity.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addCommodity.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get commodities
    builder.addCase(getCommodities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCommodities.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.commodityList = action.payload;
    });
    builder.addCase(getCommodities.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // edit commodity
    builder.addCase(editCommodity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editCommodity.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editCommodity.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get all commodities
    builder.addCase(getAllCommodities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCommodities.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.allCommodities = action.payload.result;
    });
    builder.addCase(getAllCommodities.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateRates } = ratesSlice.actions;

export default ratesSlice.reducer;
