/* eslint-disable no-console */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import PlusIcon from "../../../Common/Svgs/PlusIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import {
  createChecklist,
  updateChecklist,
} from "../../../Redux/APIs/trikesAPI";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const Checklist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { checklist } = useSelector((state) => state.trikes);

  const hasChecklistAddPermission = usePermission([
    PERMISSIONS.TRIKE_CHECKLIST_ADD,
  ]);

  const hasChecklistEditPermission = usePermission([
    PERMISSIONS.TRIKE_CHECKLIST_EDIT,
  ]);

  const hasChecklistDeletePermission = usePermission([
    PERMISSIONS.TRIKE_CHECKLIST_DELETE,
  ]);

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      checklist: checklist.length
        ? checklist
        : [
            {
              partId: "",
              partName: "",
            },
          ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "checklist",
  });

  const [focusStates, setFocusStates] = useState({});

  const rules = {
    required: "Required.",
  };

  const onCancel = () => navigate("/trikes");

  const onCreate = async (e) => {
    if (checklist.length) {
      await dispatch(updateChecklist({ checklist: e.checklist }));
    } else {
      await dispatch(createChecklist({ checklist: e.checklist }));
    }
    navigate("/trikes");
  };

  return (
    <CustomLayout pageName="Trikes" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            Create checklist for trikes
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>

            {/* if add or edit permission exists then show the button */}
            {(hasChecklistAddPermission || hasChecklistEditPermission) && (
              <Button
                size="filter"
                width="generateUser"
                type="submit"
                onClick={handleSubmit(onCreate)}
              >
                {checklist.length ? "Edit" : "Create"}
              </Button>
            )}
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="bg-background-white h-[calc(100vh-88px-72px)] p-6 overflow-y-scroll">
        <p className="font-poppins text-xs font-medium">
          Add a trike part to report issues
        </p>

        {fields.map((checkist, index) => (
          <div key={checkist.id} className="mt-6 max-w-[367px]">
            <div className="flex items-center justify-between">
              <div className="w-6 h-6 bg-light-grey flex items-center justify-center rounded-full">
                <p className="font-aileron text-10 font-semibold text-dark-gray">
                  {index + 1}
                </p>
              </div>

              {/* if delete permission exists then delete the item */}
              {hasChecklistDeletePermission && fields.length > 1 && (
                <button
                  type="button"
                  className="bg-alert-10 py-1 px-2 rounded-md"
                  onClick={() => remove(index)}
                >
                  <TrashIcon className="text-alert-red" />
                </button>
              )}
            </div>

            <p className="font-aileron text-xs font-normal text-dark-gray mt-3 mb-1.5">
              Trike Part
            </p>
            <CustomInputWithController
              intent="createUser"
              control={control}
              formState={formState}
              name={`checklist.${index}.partName`}
              placeholder="Enter Name"
              rules={rules}
              focus={focusStates[index]}
              setFocus={(state) =>
                setFocusStates({ ...focusStates, [index]: state })
              }
              error={formState?.errors?.checklist?.[index]?.partName?.message}
            />

            {}
          </div>
        ))}

        {/* if add or edit permission exists then show the button */}
        {(hasChecklistAddPermission || hasChecklistEditPermission) && (
          <button
            type="button"
            className="py-1 px-2 mt-6"
            onClick={() => {
              append({
                item: "",
              });
            }}
          >
            <div className="flex items-center gap-x-2 ">
              <p className="font-poppins text-xs font-medium text-primary">
                Add More
              </p>
              <PlusIcon className="w-4 h-4 stroke-primary" />
            </div>
          </button>
        )}
      </div>
    </CustomLayout>
  );
};

export default Checklist;
