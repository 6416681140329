/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import {
  BATTERY_REQUEST_TRANSFER_TYPE,
  BATTERY_REQUEST_TYPE,
  batteryRequestTypeOptions,
  batteryTransferForTripOptions,
} from "../Data";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { updateBatteries } from "../../../Redux/Slices/batteries.slice";
import { client } from "../../../Utils/axiosClient";
import { TRIP_STATUS } from "../../Trips/Data/TripConstants";
import { LOCATION_TYPE } from "../../Location/Data";

const RequestBatteries = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allHubs, allLocations } = useSelector((state) => state.locations);
  const { allBatteries } = useSelector((state) => state.batteries);

  const recevierHubStations = allLocations
    .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const { handleSubmit, control, formState, setValue, clearErrors, watch } =
    useForm({
      defaultValues: {
        receiverHubId: null,
        receiverHubName: "",
        supplierHubId: null,
        supplierHubName: "",
        requestTypeId: null,
        requestType: "",
        tripTransferId: null,
        tripTransfer: "",
        tripId: null,
        batteryCount: null,
      },
    });

  const [inputFocus, setInputFocus] = useState(false);
  const [tripIds, setTripIds] = useState([]);

  const receiverHubNameError = formState?.errors?.receiverHubName?.message;
  const supplierHubNameError = formState?.errors?.supplierHubName?.message;
  const requestTypeError = formState?.errors?.requestType?.message;
  const tripTransferError = formState?.errors?.tripTransfer?.message;
  const tripIdError = formState?.errors?.tripId?.message;

  const rules = {
    required: "Required.",
  };

  const supplierHubStations = useCallback(() => {
    const hubId = watch("receiverHubId");

    if (hubId) {
      return allHubs
        .filter((item) => hubId !== +item.hubId)
        .map((location) => ({
          id: location.id,
          value: location.name,
        }));
    }
    return allHubs.map((location) => ({
      id: location.id,
      value: location.name,
    }));
  }, [allHubs, watch]);

  const getTripIds = useCallback(async () => {
    try {
      const response = await client.get(
        `${process.env.REACT_APP_API_URL}/trip/get-trip-ids`,
        {
          params: {
            statusId: [TRIP_STATUS.ONGOING, TRIP_STATUS.UPCOMING],
          },
        },
      );

      const trips = response?.data?.trips.map((item) => ({
        id: item,
        value: `${item}`,
      }));

      setTripIds([...trips]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getBatteryCount = () => {
    let batteryCount = 0;
    const hubId = watch("supplierHubId");

    if (hubId) {
      batteryCount = allBatteries.filter(
        (item) => +item.currentHubId === hubId,
      ).length;
    }

    return batteryCount;
  };

  const onCancel = () => {
    navigate("/batteries");
  };

  const onSubmit = async (e) => {
    console.log(e);

    const payload = e;

    try {
      dispatch(updateBatteries({ key: "loading", value: true }));

      await client.post(
        `${process.env.REACT_APP_API_URL}/battery/request-batteries`,
        payload,
      );

      dispatch(
        updateBatteries({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "Request Sent!",
              description: `Your request of ${e.batteryCount} batteries was sent successfully.`,
            },
          },
        }),
      );

      navigate("/batteries");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateBatteries({ key: "loading", value: false }));
    }
  };

  // get trip ids
  useEffect(() => {
    getTripIds();
  }, [getTripIds]);

  return (
    <CustomLayout pageName="Batteries" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            Request Batteries
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              size="filter"
              width="generateUser"
              colors="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Send Request
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-full p-6 bg-background-white">
        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Receiver Hub Station
          </p>

          <Controller
            name="receiverHubName"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Hub Station"
                placeholder="Assign Hub Station"
                className="max-h-[300px] overflow-y-scroll"
                formState={formState}
                options={recevierHubStations}
                onSelect={(data) => {
                  setValue("receiverHubName", data.value);
                  setValue("receiverHubId", data.id);
                  setValue("supplierHubName", "");
                  setValue("supplierHubId", null);
                  clearErrors("receiverHubName");
                }}
              />
            )}
          />

          {receiverHubNameError && (
            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                receiverHubNameError
                  ? "text-alert-red visible mt-2"
                  : "invisible"
              }`}
            >
              {receiverHubNameError}
            </p>
          )}
        </div>

        <div className="w-[367px] h-[1px] my-6 bg-light-grey" />

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Supplier Hub Station
          </p>

          <Controller
            name="supplierHubName"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Hub Station"
                placeholder="Assign Hub Station"
                className="max-h-[300px] overflow-y-scroll"
                formState={formState}
                options={supplierHubStations()}
                onSelect={(data) => {
                  setValue("supplierHubName", data.value);
                  setValue("supplierHubId", data.id);
                  clearErrors("supplierHubName");
                }}
              />
            )}
          />

          {supplierHubNameError ? (
            <p
              className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                supplierHubNameError
                  ? "text-alert-red visible mt-2"
                  : "invisible"
              }`}
            >
              {supplierHubNameError}
            </p>
          ) : (
            <div className="mt-3 flex items-center justify-between w-[367px] pr-1 font-poppins text-xs font-medium">
              <p>Available Batteries</p>
              <p>{getBatteryCount()}</p>
            </div>
          )}
        </div>

        <div className="mt-6">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Request Type
          </p>

          <Controller
            name="requestType"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                placeholder="Select request type"
                formState={formState}
                options={batteryRequestTypeOptions}
                onSelect={(data) => {
                  setValue("requestType", data.value);
                  setValue("requestTypeId", data.id);

                  if (data.id === BATTERY_REQUEST_TYPE.HUB) {
                    setValue(
                      "tripTransferId",
                      BATTERY_REQUEST_TRANSFER_TYPE.TRANSFER,
                    );
                  }
                  clearErrors("requestType");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              requestTypeError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {requestTypeError}
          </p>
        </div>

        {watch("requestTypeId") === BATTERY_REQUEST_TYPE.TRIP && (
          <div>
            <div className="mt-6">
              <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
                Transfer Type
              </p>

              <Controller
                name="tripTransfer"
                control={control}
                rules={rules}
                render={({ field }) => (
                  <PopoverDropdown
                    {...field}
                    placeholder="Select transfer type"
                    formState={formState}
                    options={batteryTransferForTripOptions}
                    onSelect={(data) => {
                      setValue("tripTransfer", data.value);
                      setValue("tripTransferId", data.id);
                      clearErrors("tripTransfer");
                    }}
                  />
                )}
              />

              <p
                className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                  tripTransferError
                    ? "text-alert-red visible mt-2"
                    : "invisible"
                }`}
              >
                {tripTransferError}
              </p>
            </div>

            <div className="mt-6">
              <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
                Trip ID
              </p>

              <Controller
                name="tripId"
                control={control}
                rules={rules}
                render={({ field }) => (
                  <PopoverDropdown
                    {...field}
                    enableSearch
                    placeholder="Select Trip ID"
                    searchPlaceholder="Search Trip ID"
                    formState={formState}
                    options={tripIds}
                    onSelect={(data) => {
                      setValue("tripId", data.id);
                      clearErrors("tripId");
                    }}
                  />
                )}
              />

              <p
                className={`font-poppins text-xs font-light capitalize leading-4.5 ${
                  tripIdError ? "text-alert-red visible mt-2" : "invisible"
                }`}
              >
                {tripIdError}
              </p>
            </div>
          </div>
        )}

        <div className="mt-6">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            How many batteries do you require?
          </p>

          <CustomInputWithController
            intent="createUser"
            control={control}
            formState={formState}
            focus={inputFocus}
            setFocus={setInputFocus}
            name="batteryCount"
            placeholder="Enter Number"
            rules={{
              ...rules,
              validate: {
                isNumber: (value) => {
                  return value > 0 || "Please enter valid number";
                },
              },
            }}
          />
        </div>
      </div>
    </CustomLayout>
  );
};

export default RequestBatteries;
