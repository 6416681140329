/* eslint-disable no-console */
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import TrikeDispatchIcon from "../../../Common/Svgs/TrikeDispatchIcon";
import CustomInput from "../../../Core/Components/CustomInput";
import Button from "../../../Core/Components/CustomButton";
import { getTrikesKPI, getTrikesV3 } from "../../../Redux/APIs/trikesAPI";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import useBatteries from "./useBatteries";
import { client } from "../../../Utils/axiosClient";
import { DEFAULT_LIMIT } from "../../../Utils/constants";

const MainCheckbox = ({ selected, setSelected, batteries }) => {
  const handleSelection = () => {
    // select all the batteries
    if (selected.length === 0) {
      const local = batteries.map((item) => item.id);
      setSelected([...local]);
      return;
    }

    // unselect all the batteries
    setSelected([]);
  };

  // show checkbox with checked=false
  if (selected.length === 0) {
    return (
      <Checkbox
        className="w-4 h-4"
        checked={false}
        onChange={handleSelection}
      />
    );
  }

  // if all the batteries are selected then show checkbox with checked=true
  if (selected.length === batteries.length) {
    return <Checkbox className="w-4 h-4" checked onChange={handleSelection} />;
  }

  // if some of the batteries are selected (not all) show checkbox with unselect icon
  return (
    <button
      type="button"
      className="w-4 h-4 rounded bg-primary px-[2px]"
      onClick={handleSelection}
    >
      <div className="w-full h-[2px] rounded bg-white" />
    </button>
  );
};

const SelectBatteriesModal = ({ visible, onCancel, currentPage }) => {
  const dispatch = useDispatch();

  const { selectBatteryModal } = useSelector((state) => state.trikes);

  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);
  const [selected, setSelected] = useState([]);

  // select only those batteries that are currently in the hub station
  const batteries = useBatteries();

  const getFilteredBatteries = useCallback(() => {
    return batteries.filter((item) => item.registrationNo.includes(search));
  }, [batteries, search]);

  const handleSelection = (id) => {
    if (selected.includes(id)) {
      const local = selected.filter((item) => item !== id);
      setSelected(local);
    } else {
      setSelected((current) => [...current, id]);
    }
  };

  const handleConfirm = async () => {
    const payload = {
      trikeId: selectBatteryModal?.trike?.id,
      batteries: selected,
    };

    try {
      dispatch(updateTrikes({ key: "loading", value: true }));

      await client.post(
        `${process.env.REACT_APP_API_URL}/trike/dispatch`,
        payload,
      );

      await dispatch(
        updateTrikes({
          key: "selectBatteryModal",
          value: { visible: false, trike: {} },
        }),
      );

      await dispatch(
        getTrikesV3({
          offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
          limit: DEFAULT_LIMIT,
        }),
      );

      dispatch(getTrikesKPI());

      dispatch(
        updateTrikes({
          key: "showToastMessage",
          value: {
            visible: true,
            data: {
              title: "1 Trike Dispatched.",
              description: `Trike ${selectBatteryModal?.trike?.registrationNo} was dispatched successfully.`,
            },
          },
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateTrikes({ key: "loading", value: false }));
      setSelected([]);
      onCancel();
    }
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px">
      <div
        className="rounded-xl bg-background-white w-full"
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between pl-4 pr-6 pt-4">
          <div className="flex items-center gap-x-3">
            <CustomRoundedImage
              size="w-12 h-12"
              image={<TrikeDispatchIcon size={24} className="text-white" />}
              background="bg-primary"
            />

            <h1 className="font-poppins text-base font-medium">
              Select Batteries
            </h1>
          </div>

          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>

        <div className="px-6 mt-6">
          <h2 className="font-poppins text-xs font-medium capitalize">
            Select batteries for dispatch
          </h2>

          <p className="mt-1 mb-3 font-aileron text-xs font-normal text-dark-gray">
            List of available batteries to transfer from your hub station.
          </p>

          <CustomInput
            intent="searchHome"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Battery No."}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />
        </div>

        <div className="mt-3 flex items-center bg-background border-b-[0.5px] px-6 py-3 gap-x-12">
          <MainCheckbox
            selected={selected}
            setSelected={setSelected}
            batteries={batteries}
          />

          <p className="w-[236px] font-aileron text-10 font-normal text-dark-gray">
            Registration No.
          </p>

          <p className="font-aileron text-10 font-normal text-dark-gray">
            Charge %
          </p>
        </div>

        <div className="h-[272px] overflow-y-scroll">
          {getFilteredBatteries().map((battery) => {
            const isChecked = selected.includes(battery.id);

            return (
              <div
                key={battery.id}
                className={`flex items-center px-6 py-3 gap-x-12 border-b border-light-grey ${
                  isChecked && "bg-primary-10"
                }`}
              >
                <Checkbox
                  className="w-4 h-4"
                  checked={isChecked}
                  onChange={() => handleSelection(battery.id)}
                />

                <p className="w-[236px] font-aileron text-xs font-semibold">
                  {battery.registrationNo}
                </p>

                <p className="font-aileron text-10 font-normal text-dark-gray">
                  {battery.percentage}
                </p>
              </div>
            );
          })}
        </div>

        <div className="flex justify-end gap-x-3 mt-7 px-6 pb-6">
          <Button colors="grey" size="primary" onClick={onCancel}>
            Cancel
          </Button>

          <Button colors="primary" size="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default SelectBatteriesModal;
