import React from 'react';

const WatchIcon = ({ size = 14, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 15 14"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.668 5.635v1.627l.817.817"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.875 7a3.792 3.792 0 117.583 0A3.797 3.797 0 0110 9.992h-.006c-.642.502-1.447.8-2.327.8-.87 0-1.669-.292-2.31-.788H5.35A3.783 3.783 0 013.875 7z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.352 10.004h.005a3.76 3.76 0 002.31.787c.881 0 1.686-.297 2.328-.799H10l-.298 1.441c-.286 1.108-.927 1.4-1.715 1.4h-.636c-.787 0-1.435-.292-1.715-1.406l-.285-1.423zM5.352 3.996h.005a3.759 3.759 0 012.31-.787c.881 0 1.686.297 2.328.799H10l-.298-1.441c-.286-1.108-.927-1.4-1.715-1.4h-.636c-.787 0-1.435.292-1.715 1.406l-.285 1.423z"
        />
      </svg>
    </span>
  );
};

export default WatchIcon;
