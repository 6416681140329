import React, { useEffect } from 'react';

const useSearch = (ListData, filterKeys) => {
  const [search, setSearch] = React.useState('');
  const [filteredData, setFilteredData] = React.useState([]);

  const handleSearchFilter = (data) => {
    setFilteredData(
      data?.filter((item) => {
        return filterKeys.some((key) => {
          const value = item[key];
          return value && value.toLowerCase().includes(search.toLowerCase());
        });
      }),
    );
  };

  useEffect(() => {
    handleSearchFilter(ListData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, ListData]);

  return { search, setSearch, filteredData };
};

export default useSearch;
