import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Divider } from 'antd';
import dayjs from 'dayjs';
import CloseIcon from '../../Common/Svgs/CloseIcon';
import Button from '../../Core/Components/CustomButton';
import CustomSearchAndSelect from '../../Core/Components/CustomSearchAndSelect';
import CustomInput from '../../Core/Components/CustomInput';
import { resetTrikes, updateTrikes } from '../../Redux/Slices/trikes.slice';
import CustomSelectWithCheckbox from '../../Core/Components/CustomSelectWithCheckbox';
// import { getTrikeDetails } from '../../Redux/APIs/trikesAPI';
import { trikesDropdownBtnArr } from './Data/TrikesListData';
import ChevronIcon from '../../Common/Svgs/ChevronIcon';
import getUniqueList from '../../Utils/getUniqueList';

const MoreTrikeFilters = ({ setShowFilters }) => {
  const dispatch = useDispatch();

  const { trikeDetails, filtersForTrikes } = useSelector(
    (state) => state.trikes,
  );

  const [finalAddedByList, setFinalAddedByList] = useState([]);
  const [finalDriverList, setFinalDriverList] = useState([]);
  const onCancel = () => {
    setShowFilters(false);
  };

  const uniqueDriversList = getUniqueList(trikeDetails, 'driverName').filter(
    (item) => item.driverName !== '--',
  );
  const uniqueAddedByList = getUniqueList(trikeDetails, 'submittedBy');

  const driversList = uniqueDriversList.map((item) => {
    return { id: item.IDNumber, name: item.driverName };
  });

  const addedByList = uniqueAddedByList.map((item) => {
    return { id: item.IDNumber, name: item.submittedBy };
  });

  const hasFieldValues = () => {
    return (
      filtersForTrikes?.addedBy?.length > 0 ||
      filtersForTrikes?.driverName?.length > 0 ||
      // finalAddedByList.length > 0 ||
      // finalDriverList.length > 0 ||
      filtersForTrikes?.distance !== '' ||
      (filtersForTrikes?.mileage.lowerLimit !== '' &&
        filtersForTrikes?.mileage.upperLimit !== '') ||
      (filtersForTrikes?.averageSpeed.lowerLimit !== '' &&
        filtersForTrikes?.averageSpeed.upperLimit !== '') ||
      (filtersForTrikes?.dateRange.startTime !== '' &&
        filtersForTrikes?.dateRange.endTime !== '')
    );
  };

  const handleReduxStateUpdate = (stateToUpdate, currentValue) => {
    dispatch(
      updateTrikes({
        key: 'filtersForTrikes',
        value: { ...filtersForTrikes, [stateToUpdate]: currentValue },
      }),
    );
  };

  const handleDateRangeChange = (e) => {
    if (e && e.length === 2 && e[0] && e[1]) {
      handleReduxStateUpdate('dateRange', {
        ...filtersForTrikes.dateRange,
        startTime: e[0].format('YYYY-MM-DD'),
        endTime: e[1].format('YYYY-MM-DD'),
      });
    }
  };

  useEffect(() => {
    setFinalAddedByList(filtersForTrikes?.addedBy);
    setFinalDriverList(filtersForTrikes?.driverName);
  }, [filtersForTrikes]);

  const handleApplyFilters = () => {
    // dispatch(
    //   getTrikeDetails({
    //     batteryPercentageRange: filtersForTrikes?.percentage,
    //     status: filtersForTrikes?.status,
    //     toggleStatus: filtersForTrikes?.toggleStatus,
    //     addedBy: filtersForTrikes?.addedBy,
    //     driverName: filtersForTrikes?.driverName,
    //     distance: filtersForTrikes.distance,
    //     mileage: filtersForTrikes?.mileage,
    //     averageSpeed: filtersForTrikes.averageSpeed,
    //   }),
    // );

    dispatch(
      updateTrikes({
        key: 'filtersForTrikes',
        value: { ...filtersForTrikes, filtersApplied: true },
      }),
    );

    onCancel();
  };

  const handleClearAll = () => {
    setFinalAddedByList([]);
    setFinalDriverList([]);
    dispatch(resetTrikes(['filtersForTrikes']));
  };

  // useEffect(() => {
  //   dispatch(resetTrikes(['filtersForTrikes']));
  // }, []);

  return (
    <div className="absolute z-10 h-[618px] max-h-[60vh] overflow-y-auto bg-white left-[-40px] top-9 w-78 rounded-md p-3 shadow-moreFilters">
      <div className="flex items-center justify-between">
        <div className="text-base font-medium text-new-black font-poppins">
          More filters
        </div>
        <CloseIcon
          className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
          onClick={onCancel}
        />
      </div>
      <div className="mt-6">
        <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mb-2">
          Date Range
        </div>
        <DatePicker.RangePicker
          value={[
            filtersForTrikes?.dateRange?.startTime !== '' &&
              dayjs(filtersForTrikes?.dateRange?.startTime),
            filtersForTrikes?.dateRange?.endTime !== '' &&
              dayjs(filtersForTrikes?.dateRange?.endTime),
          ]}
          format="DD MMM YYYY"
          separator={<div className="text-dark-grey-text"> - </div>}
          onCalendarChange={(e) => handleDateRangeChange(e)}
          allowClear={false}
          className="h-[39px]"
        />

        <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mt-6">
          Added By
        </div>
        <CustomSearchAndSelect
          placeholder="Type or Select User"
          inputList={addedByList}
          finalList={finalAddedByList}
          setFinalList={setFinalAddedByList}
          onChange={(e) =>
            dispatch(
              updateTrikes({
                key: 'filtersForTrikes',
                value: { ...filtersForTrikes, addedBy: e },
              }),
            )
          }
        />
      </div>
      <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mt-6">
        Driver
      </div>
      <CustomSearchAndSelect
        placeholder="Select Driver"
        inputList={driversList}
        finalList={finalDriverList}
        setFinalList={setFinalDriverList}
        onChange={(e) =>
          dispatch(
            updateTrikes({
              key: 'filtersForTrikes',
              value: { ...filtersForTrikes, driverName: e },
            }),
          )
        }
      />

      <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mt-6">
        Distance (Km)
      </div>
      <div className="pt-1.5">
        <CustomInput
          intent="primary"
          value={filtersForTrikes?.distance}
          border={filtersForTrikes?.distance === '' ? 'primary' : 'success'}
          placeholder="Enter Value"
          onChange={(e) => handleReduxStateUpdate('distance', e.target.value)}
        />
      </div>

      <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mt-6">
        Mileage (Km)
      </div>
      <div className="flex items-center justify-center gap-x-2">
        <CustomInput
          intent="primary"
          border={
            filtersForTrikes?.mileage?.lowerLimit === '' ? 'primary' : 'success'
          }
          value={filtersForTrikes?.mileage?.lowerLimit}
          placeholder="Enter Value"
          onChange={(e) =>
            handleReduxStateUpdate('mileage', {
              ...filtersForTrikes.mileage,
              lowerLimit: e.target.value,
            })
          }
        />
        <div className="w-6">
          <Divider className="" />
        </div>
        <CustomInput
          intent="primary"
          border={
            filtersForTrikes?.mileage?.upperLimit === '' ? 'primary' : 'success'
          }
          placeholder="Enter Value"
          value={filtersForTrikes?.mileage?.upperLimit}
          onChange={(e) =>
            handleReduxStateUpdate('mileage', {
              ...filtersForTrikes.mileage,
              upperLimit: e.target.value,
            })
          }
        />
      </div>
      <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mt-6">
        Average Speed (Km/H)
      </div>
      <div className="flex items-center justify-center gap-x-2">
        <CustomInput
          intent="primary"
          border={
            filtersForTrikes?.averageSpeed?.lowerLimit === ''
              ? 'primary'
              : 'success'
          }
          placeholder="Enter Value"
          value={filtersForTrikes?.averageSpeed?.lowerLimit}
          onChange={(e) =>
            handleReduxStateUpdate('averageSpeed', {
              ...filtersForTrikes.averageSpeed,
              lowerLimit: e.target.value,
            })
          }
        />
        <div className="w-6">
          <Divider className="" />
        </div>
        <CustomInput
          intent="primary"
          value={filtersForTrikes?.averageSpeed?.upperLimit}
          border={
            filtersForTrikes?.averageSpeed?.upperLimit === ''
              ? 'primary'
              : 'success'
          }
          placeholder="Enter Value"
          onChange={(e) =>
            handleReduxStateUpdate('averageSpeed', {
              ...filtersForTrikes.averageSpeed,
              upperLimit: e.target.value,
            })
          }
        />
      </div>

      <div className="flex mt-6 gap-x-3">
        <Button
          size="primary"
          width="save"
          colors="grey"
          modifier={hasFieldValues() ? null : 'blur'}
          onClick={handleClearAll}
        >
          Clear All
        </Button>
        <Button
          size="primary"
          width="save"
          type="submit"
          modifier={hasFieldValues() ? null : 'blur'}
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
};

const TrikeFilters = () => {
  const dispatch = useDispatch();
  const {
    batteryPercentageFilters,
    toggleFilters,
    filtersForTrikes,
    statusFilters,
  } = useSelector((state) => state.trikes);

  const functionSwitch = (e, filter) => {
    switch (filter) {
      case 'Battery %':
        return dispatch(
          updateTrikes({
            key: 'filtersForTrikes',
            value: { ...filtersForTrikes, percentage: e },
          }),
        );
      case 'On/Off':
        return dispatch(
          updateTrikes({
            key: 'filtersForTrikes',
            value: { ...filtersForTrikes, toggleStatus: e },
          }),
        );
      case 'Status':
        return dispatch(
          updateTrikes({
            key: 'filtersForTrikes',
            value: { ...filtersForTrikes, status: e },
          }),
        );
      default:
        return dispatch(
          updateTrikes({
            key: 'filtersForTrikes',
            value: { ...filtersForTrikes, percentage: e },
          }),
        );
    }
  };

  const filterSwitch = (filter) => {
    switch (filter) {
      case 'Battery %':
        return batteryPercentageFilters;
      case 'On/Off':
        return toggleFilters;
      case 'TrikeStatus':
        return statusFilters;
      default:
        return batteryPercentageFilters;
    }
  };

  useEffect(() => {
    dispatch(resetTrikes(['filtersForTrikes']));
  }, []);

  // useEffect(() => {
  //   dispatch(
  //     getTrikeDetails({
  //       status: filtersForTrikes.status,
  //       toggleStatus: filtersForTrikes.toggleStatus,
  //     }),
  //   );
  // }, [filtersForTrikes.status, filtersForTrikes.toggleStatus]);

  return (
    <div className="flex items-center justify-start gap-x-3">
      {trikesDropdownBtnArr?.map((item) => (
        <CustomSelectWithCheckbox
          filterType={item.renderBody}
          inputList={filterSwitch(item.renderBody)}
          selectedVal={filtersForTrikes?.[item.filters]}
          onChange={(e) => functionSwitch(e, item.title)}
        >
          {filtersForTrikes[item.filters]?.length === 0 ? (
            <Button size={item.size} colors={item.color} padding={item.padding}>
              <div className="flex items-center justify-start gap-x-3">
                <div>{item.title}</div>
                <img src="/assets/icons/chevron-down.svg" alt="" />
              </div>
            </Button>
          ) : (
            <Button padding="filter" size="filter" width="dynamic">
              <div className="flex item-center gap-x-3">
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  {item.title}
                </div>
                <div className="w-4.5 h-4.5 bg-white text-black font-aileron font-semibold text-10 leading-3.75 rounded-full flex items-center justify-center">
                  {filtersForTrikes[item.filters]?.length}
                </div>
                <div>
                  <ChevronIcon className="w-4 h-4 -rotate-90 stroke-white" />
                </div>
              </div>
            </Button>
          )}
        </CustomSelectWithCheckbox>
      ))}
    </div>
  );
};

export { MoreTrikeFilters, TrikeFilters };
