import { createSlice } from "@reduxjs/toolkit";
import { addCharger, editCharger, getChargers } from "../APIs/chargersAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  chargerList: {
    result: [],
    totalRecords: 0,
  },
  currentCharger: {},
  deleteChargerModal: {
    visible: false,
    charger: {},
  },
  showToastMessage: {
    visible: false,
    data: {},
  },

  threeDotsPopoverId: 0,
};

export const chargersSlice = createSlice({
  name: "chargers",
  initialState,
  reducers: {
    updateCharger: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // add charger
    builder.addCase(addCharger.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCharger.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addCharger.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get charger
    builder.addCase(getChargers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getChargers.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.chargerList = action.payload;
    });
    builder.addCase(getChargers.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // edit charger
    builder.addCase(editCharger.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editCharger.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editCharger.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateCharger } = chargersSlice.actions;

export default chargersSlice.reducer;
