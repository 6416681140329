import React from "react";

const ItemBody3 = ({ notification }) => {
  return (
    <p className="font-poppins text-xs font-light">
      <span className="font-normal">Driver </span>
      <span className="font-medium text-primary underline cursor-pointer">
        {notification?.metaData?.driverName}
      </span>
      ’s license is expiring on {notification?.metaData?.expiryDate}.
    </p>
  );
};

export default ItemBody3;
