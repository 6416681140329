import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import useChargerThreeDotsOptions from "./useChargerThreeDotsOptions";
import { updateCharger } from "../../Redux/Slices/chargers.slice";
import { updateQrCode } from "../../Redux/Slices/qrcode.slice";
import { QR_CODE_TYPE } from "../QRCode/data";

const ListDropdown = ({ detail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { threeDotsPopoverId } = useSelector((state) => state.chargers);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [open, setOpen] = useState(false);

  const threeDotsOptions = useChargerThreeDotsOptions().filter(
    (item) => item.hasPermission,
  );

  const onOptionClick = async (optionId) => {
    dispatch(
      updateCharger({
        key: "currentCharger",
        value: detail,
      }),
    );

    setOpen(false);

    switch (optionId) {
      case 1:
        navigate(`/edit-charger/${detail.id}`);
        break;

      case 2:
        dispatch(
          updateQrCode({
            key: "viewModal",
            value: {
              visible: true,
              data: {
                assetId: detail.serialNo,
                assetType: QR_CODE_TYPE.CHARGER,
                metaData: {
                  chargerid: detail.id,
                  chargertypename: detail.chargerTypeName,
                },
              },
            },
          }),
        );
        break;

      case 3:
        dispatch(
          updateCharger({
            key: "deleteChargerModal",
            value: {
              visible: true,
              charger: detail,
            },
          }),
        );
        break;

      default:
        break;
    }
  };

  const handleOpenChange = (state) => {
    setOpen(state);

    dispatch(
      updateCharger({
        key: "threeDotsPopoverId",
        value: detail.id,
      }),
    );
  };

  // if three dots has no items (depending on the permission) then return empty fragments
  if (!threeDotsOptions.length) {
    return <div />;
  }

  // do not show the three dots options if the user does not have access in that hub
  if (!userDetails.hubs.includes(+detail.hubId)) {
    return <div />;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open && threeDotsPopoverId === detail.id}
      arrow={false}
      onOpenChange={(state) => handleOpenChange(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {threeDotsOptions.map((option) => (
            <button
              type="button"
              className={`w-full flex items-center gap-x-3 px-3 py-2 group 
              ${
                option.id === 3
                  ? "hover:bg-light-alert-red-hover"
                  : "hover:bg-background"
              }
              `}
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p
                className={`font-aileron text-xs font-semibold capitalize ${
                  option.id === 3
                    ? "text-alert-red group-hover:text-alert-red-hover"
                    : "text-dark-gray group-hover:text-black"
                }`}
              >
                {option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default ListDropdown;
