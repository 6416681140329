import React from 'react';

const MileageIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6 14.666h4c3.333 0 4.666-1.333 4.666-4.666V6c0-3.334-1.333-4.667-4.666-4.667H6C2.666 1.333 1.333 2.666 1.333 6v4c0 3.333 1.333 4.666 4.667 4.666zM6 1.333l3.3 13.333M7.686 8.146L1.333 10"
        />
      </svg>
    </span>
  );
};

export default MileageIcon;
