import React from "react";

const ManagementCentreIcon = ({
  size,
  className,
  fillColor,
  borderColor,
  insideColor,
}) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke={borderColor}
          fill={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M17.5 5.833v8.334c0 2.5-1.25 4.166-4.167 4.166H6.667c-2.917 0-4.167-1.666-4.167-4.166V5.833c0-2.5 1.25-4.166 4.167-4.166h6.666c2.917 0 4.167 1.666 4.167 4.166z"
        />
        <path
          stroke={insideColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M12.084 3.75v1.667c0 .916.75 1.666 1.667 1.666h1.666M6.666 10.833h3.333M6.666 14.167h6.667"
        />
      </svg>
    </span>
  );
};

export default ManagementCentreIcon;
