import React from 'react';

const CollectionPointIcon = ({ size = 16, className = 'text-purple' }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M5.668 9.5a2.344 2.344 0 002.333 2.333A2.344 2.344 0 0010.335 9.5M5.874 1.333l-2.413 2.42M10.125 1.333l2.413 2.42"
        />
        <path
          stroke="currentColor"
          strokeWidth="1.5"
          d="M1.332 5.233c0-1.233.66-1.333 1.48-1.333h10.373c.82 0 1.48.1 1.48 1.333 0 1.434-.66 1.334-1.48 1.334H2.812c-.82 0-1.48.1-1.48-1.334z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M2.332 6.667l.94 5.76c.213 1.293.727 2.24 2.633 2.24h4.02c2.074 0 2.38-.907 2.62-2.16l1.12-5.84"
        />
      </svg>
    </span>
  );
};

export default CollectionPointIcon;
