import React from 'react';

const getSeveritytext = (input) => {
  switch (input) {
    case 1:
      return 'low';
    case 2:
      return 'medium';
    case 3:
      return 'serious';
    case 4:
      return 'critical';
    default:
      return 'low';
  }
};

export const getSeverityTextAndColor = (input) => {
  const { id, text } = input;
  switch (id) {
    case 1:
      return (
        <div className="font-poppins text-xs text-dark-grey-text leading-4.5 font-medium capitalize">
          {text}
        </div>
      );
    case 2:
      return (
        <div className="font-poppins text-xs text-light-blue leading-4.5 font-medium capitalize">
          {text}
        </div>
      );
    case 3:
      return (
        <div className="font-poppins text-xs text-warning leading-4.5 font-medium capitalize">
          {text}
        </div>
      );
    case 4:
      return (
        <div className="font-poppins text-xs text-alert-red leading-4.5 font-medium capitalize">
          {text}
        </div>
      );
    default:
      return (
        <div className="font-poppins text-xs text-dark-grey-text leading-4.5 font-medium capitalize">
          {text}
        </div>
      );
  }
};

export default getSeveritytext;
