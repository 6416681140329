/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable radix */
/* eslint-disable no-case-declarations */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../Core/Components/CustomDropdown";
import TrashIcon from "../../Common/Svgs/TrashIcon";
import EditIcon from "../../Common/Svgs/EditIcon";
import { updateTrips } from "../../Redux/Slices/trips.slice";
import { getTripById } from "../../Redux/APIs/tripsAPI";
import TripsIcon from "../../Common/Svgs/TripsIcon";
import { TRIP_STATUS } from "./Data/TripConstants";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import { BatterySecondaryIcon } from "../../Common/Svgs/BatteryIcon";

const EditAndDeleteDropDown = ({
  tripId,
  showBg = true,
  detail,
  listingPage = true,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { editTripDetails, deleteTripModal } = useSelector(
    (state) => state.trips,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const handleClick = async (e) => {
    switch (e.key) {
      case "1":
        dispatch(getTripById({ tripId }));

        dispatch(
          updateTrips({
            key: "editTripDetails",
            value: { ...editTripDetails, tripId, flag: true },
          }),
        );

        navigate("/trips/create-trip");
        break;

      case "2":
        dispatch(
          updateTrips({
            key: "deleteTripModal",
            value: { ...deleteTripModal, visible: true, id: tripId },
          }),
        );

        break;
      case "3":
        dispatch(
          updateTrips({
            key: "endTripByAdminModal",
            value: { visible: true, tripId },
          }),
        );

        break;
      case "4":
        const response = await dispatch(getTripById({ tripId }));
        const { distance, time } = response?.payload?.result || {};

        dispatch(
          updateTrips({
            key: "editTripDetails",
            value: { tripId, flag: true },
          }),
        );

        dispatch(
          updateTrips({
            key: "currentStep",
            value: 5,
          }),
        );

        dispatch(
          updateTrips({
            key: "isRouteOptimised",
            value: false,
          }),
        );

        dispatch(
          updateTrips({
            key: "calculatedRouteDistance",
            value: distance || 0,
          }),
        );

        dispatch(
          updateTrips({
            key: "calculatedRouteTime",
            value: time || 0,
          }),
        );

        navigate("/trips/create-trip");
        break;
      default:
        break;
    }
  };

  const items = [
    {
      key: 1,
      value: "Edit",
      label: (
        <div className="flex items-center px-3 py-2 cursor-pointer gap-x-3">
          <EditIcon className="text-dark-gray" />
          <p className="text-xs font-semibold font-aileron text-dark-gray">
            Edit Details
          </p>
        </div>
      ),
      hasPermission: usePermission([PERMISSIONS.TRIP_EDIT]),
    },
    {
      key: 2,
      value: "Delete",
      label: (
        <div className="flex items-center px-3 py-2 cursor-pointer gap-x-3">
          <TrashIcon className="text-dark-gray" />
          <p className="text-xs font-semibold font-aileron text-dark-gray">
            Delete Trip
          </p>
        </div>
      ),
      hasPermission: usePermission([PERMISSIONS.TRIP_DELETE]),
    },
    {
      key: 3,
      value: "End",
      label: (
        <div className="flex items-center px-3 py-2 cursor-pointer gap-x-3">
          <TripsIcon size={16} className="text-dark-gray" />
          <p className="text-xs font-semibold font-aileron text-dark-gray">
            End Trip
          </p>
        </div>
      ),
      hasPermission: usePermission([PERMISSIONS.TRIP_MANUAL_END]),
    },
    {
      key: 4,
      value: "Battery",
      label: (
        <div className="flex items-center px-3 py-2 cursor-pointer gap-x-3">
          <BatterySecondaryIcon size={16} className="text-dark-gray" />
          <p className="text-xs font-semibold font-aileron text-dark-gray">
            Associate Batteries
          </p>
        </div>
      ),
      hasPermission: usePermission([PERMISSIONS.TRIP_EDIT]),
    },
  ];

  const getItems = () => {
    const permissionBasedItems = items.filter((item) => item.hasPermission);

    const localItems = listingPage
      ? permissionBasedItems
      : permissionBasedItems.filter((i) => i.key !== 3 || i.key !== 4);

    // edit, manual end and associate batteries options
    if (detail?.statusId === TRIP_STATUS.ONGOING) {
      return localItems.filter((i) => i.key !== 2);
    }

    // edit, delete and associate batteries options
    if (detail?.statusId === TRIP_STATUS.UPCOMING) {
      return localItems.filter((i) => i.key !== 3);
    }

    // only delete trip option
    if (detail?.statusId === TRIP_STATUS.COMPLETED) {
      return localItems.filter((i) => i.key === 2);
    }

    return localItems;
  };

  // if three dots has no items (depending on the permission) then return empty fragments
  if (!getItems().length) {
    return <></>;
  }

  // do not show the three dots options if the user does not have access in that hub
  if (!userDetails.hubs.includes(+detail.hubId)) {
    return <div />;
  }

  return (
    <CustomDropdown
      items={getItems()}
      trigger="click"
      placement="bottomLeft"
      handleClick={(e) => handleClick(e)}
    >
      <div
        className={`cursor-pointer ${
          showBg && "bg-light-grey hover:bg-background px-2 py-1 rounded-md"
        }`}
      >
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </CustomDropdown>
  );
};

export default EditAndDeleteDropDown;
