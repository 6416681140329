import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import { listForTrikeChange } from "../Data/TripDetailData";

const ListingsIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        fill="none"
        viewBox="0 0 16 17"
      >
        <path stroke="#DFDFDF" d="M1 0v7a4 4 0 004 4h11" />
      </svg>
    </span>
  );
};

const TrikeChanged = () => {
  const { overallSummaryForTrip } = useSelector((state) => state.trips);

  const reasonList = listForTrikeChange.filter((listItem) =>
    overallSummaryForTrip?.trikeChangeReason.includes(listItem.id),
  );

  const [open, setOpen] = useState(false);

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div
          className="bg-background-white rounded-xl p-6"
          style={{ width: "460px" }}
        >
          <div className="flex items-center justify-between">
            <p className="font-poppins text-base font-medium">Trike Change</p>
            <button type="button" onClick={() => setOpen(false)}>
              <CloseIcon className="w-6 h-6 stroke-dark-gray" />
            </button>
          </div>

          <p className="font-poppins text-xs font-medium mt-6">
            New trike number
          </p>
          <div className="flex items-center mt-2 pb-4 border-b border-light-grey">
            <ListingsIcon />
            <p className="ml-1.5 text-sm font-aileron font-semibold text-grey-200">
              {overallSummaryForTrip?.trikeRegistrationNo}
            </p>
          </div>

          <p className="font-poppins text-xs font-medium mt-6">
            Original trike number
          </p>
          <div className="flex items-center mt-2 pb-4 border-b border-light-grey">
            <ListingsIcon />
            <p className="ml-1.5 text-sm font-aileron font-semibold text-grey-200">
              {overallSummaryForTrip?.previousTrikeRegNo}
            </p>
          </div>

          <p className="font-poppins text-xs font-medium mt-6">
            Reason for changing the trike
          </p>

          <div className="mt-2 bg-background rounded-xl py-2">
            {reasonList?.map((item, index) => (
              <div
                key={item.id}
                className={`p-3 text-sm font-aileron font-semibold ${
                  index !== reasonList.length - 1
                    ? "border-b-[0.5px] border-[#E7E7E7]"
                    : ""
                }`}
              >
                {item.reason}
              </div>
            ))}
          </div>
        </div>
      }
    >
      <button type="button" className="bg-background p-2 rounded-md max-w-max">
        <div className="flex items-center gap-x-2">
          <p className="font-poppins text-xs font-medium text-warning">
            Trike Changed
          </p>
          <ChevronIcon className="w-4 h-4 stroke-warning rotate-90" />
        </div>
      </button>
    </Popover>
  );
};

export default TrikeChanged;
