/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  addServiceV3,
  getServiceDetails,
  getServicesFromODK,
  getServicesV3,
  updateServiceV3,
} from "../APIs/servicesAPI";

const initialState = {
  loading: false,
  serviceDetails: [],
  filtersForServices: {
    assetType: [],
    location: [],
    severity: [],
    addedBy: [],
    dateRangeStart: "",
    dateRangeEnd: "",
  },
  assetTypeFilters: [
    {
      id: 1,
      value: "trike",
    },
    {
      id: 2,
      value: "battery",
    },
  ],
  severityFilters: [
    {
      id: 1,
      text: "low",
    },
    {
      id: 2,
      text: "medium",
    },
    {
      id: 3,
      text: "serious",
    },
    {
      id: 4,
      text: "critical",
    },
  ],

  // phase3
  serviceListV3: {
    result: [],
    totalRecords: 0,
  },
  updatingService: false,
  selectedService: [],
  showToastMessage: {
    visible: false,
    data: {},
  },
  threeDotsPopoverId: 0,
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    updateServices: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    resetServices: (state, action) => {
      action.payload.map((item) => {
        state[item] = initialState[item];
      });
    },
  },
  extraReducers(builder) {
    // get services from DB
    builder.addCase(getServiceDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getServiceDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      const responseData = action.payload.map((item) => ({
        id: item.id,
        assetNumber: item.id_number,
        assetType: item.asset_type,
        incidentType: item.incident_type,
        severity: item.severity,
        description: item.description,
        // location: item?.incident_location?.trim(),
        coordinates: item.incident_geolocation,
        submittedBy: item.submitted_by || "--",
        submittedAt: item.submitted_at || "--",
      }));

      state.serviceDetails = responseData;
    });
    builder.addCase(getServiceDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get services from ODK

    builder.addCase(getServicesFromODK.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getServicesFromODK.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(getServicesFromODK.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // phase 3

    // get services
    builder.addCase(getServicesV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getServicesV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.serviceListV3 = action.payload;
    });
    builder.addCase(getServicesV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // add service
    builder.addCase(addServiceV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addServiceV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addServiceV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // update service
    builder.addCase(updateServiceV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateServiceV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateServiceV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateServices, resetServices } = servicesSlice.actions;

export default servicesSlice.reducer;
