import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateLoggedInUser } from "../../Redux/Slices/loggedInUser.slice";
import useTabData from "./useTabData";

const SettingsHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { settingsTab } = useSelector((state) => state.loggedInUser);

  const tabs = useTabData().filter((item) => item.hasPermission);

  const buttonClassName = (item) => {
    if (item.text === settingsTab) {
      return "bg-primary text-background-white";
    }

    return "bg-background-white text-dark-gray";
  };

  const handleClick = (item) => {
    dispatch(
      updateLoggedInUser({
        key: "settingsTab",
        value: item.text,
      }),
    );

    navigate(`${item.path}`);
  };

  return (
    <div className="px-6 py-3.5 flex items-center gap-x-6 border-b">
      {tabs.map((item) => (
        <button
          key={item.id}
          type="button"
          className={`px-6 py-3 rounded-md font-poppins text-xs font-medium capitalize 
            ${buttonClassName(item)}
            `}
          style={{
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
          onClick={() => handleClick(item)}
        >
          <div>{item.text}</div>
        </button>
      ))}
    </div>
  );
};

export default SettingsHeader;
