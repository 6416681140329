/* eslint-disable no-console */
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { addBatteryV3, editBatteryV3 } from "../../../Redux/APIs/batteriesAPI";
import {
  batteryTypeOptions,
  chargerTypeOptions,
} from "../Data/BatteryListData";
import { updateBatteries } from "../../../Redux/Slices/batteries.slice";
import useHubStationOnAdd from "../../../Utils/useHubStationOnAdd";

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
}) => {
  return (
    <div className="">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="createUser"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
      />
    </div>
  );
};

const AddBattery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { currentBattery } = useSelector((state) => state.batteries);

  // const hubStations = allLocations
  //   ?.filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
  //   .map((location) => ({
  //     id: location.id,
  //     value: location.name,
  //   }));

  const hubStations = useHubStationOnAdd();

  const { handleSubmit, control, formState, setValue, clearErrors, setError } =
    useForm({
      defaultValues: {
        registrationNo: id ? currentBattery.registrationNo : "",
        tagNo: id ? currentBattery.tagNo : "",
        chargerType: id ? currentBattery.chargerType : "",
        hubId: id ? currentBattery.permanentHubId : "",
        hubName: id ? currentBattery.permanentHubName : "",
        type: id ? currentBattery.type : "",
      },
    });

  const [focus, setFocus] = useState({
    registrationNo: false,
    tagNo: false,
    chargerType: false,
    location: false,
  });

  const rules = {
    required: "Required.",
  };

  const batteryTypeError = formState?.errors?.type?.message;
  const chargerTypeError = formState?.errors?.chargerType?.message;
  const hubNameError = formState?.errors?.hubName?.message;

  const onCancel = () => navigate("/batteries");

  const onAdd = async (e) => {
    const payload = e;
    if (id) {
      payload.batteryId = currentBattery.id;

      const editResponse = await dispatch(editBatteryV3(payload));

      if (
        editResponse?.payload?.response &&
        editResponse?.payload?.response?.status !== 200
      ) {
        setError("registrationNo", {
          type: "server",
          shouldFocus: true,
          message: editResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateBatteries({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Battery Edited",
                description: "1 battery was edited successfully.",
              },
            },
          }),
        );

        navigate("/batteries");
      }
    } else {
      const addResponse = await dispatch(addBatteryV3(payload));

      if (
        addResponse?.payload?.response &&
        addResponse?.payload?.response?.status !== 200
      ) {
        setError("registrationNo", {
          type: "server",
          shouldFocus: true,
          message: addResponse?.payload?.response?.data?.message,
        });
      } else {
        dispatch(
          updateBatteries({
            key: "showToastMessage",
            value: {
              visible: true,
              data: {
                title: "1 Battery Added",
                description: "1 new battery was added successfully.",
              },
            },
          }),
        );
        navigate("/batteries");
      }
    }
  };

  return (
    <CustomLayout pageName="Batteries" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            {id ? "Edit battery" : "Add a new battery"}
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onAdd)}
            >
              {id ? "Edit" : "Add"} Battery
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-full bg-background-white p-6 flex flex-col gap-y-6">
        <LabelAndInput
          label="Registration No."
          control={control}
          formState={formState}
          name="registrationNo"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.registrationNo}
          setFocus={(state) => setFocus({ ...focus, registrationNo: state })}
        />

        <LabelAndInput
          label="Tag number"
          control={control}
          formState={formState}
          name="tagNo"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.tagNo}
          setFocus={(state) => setFocus({ ...focus, tagNo: state })}
        />

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Charger type
          </p>
          <Controller
            name="chargerType"
            rules={rules}
            control={control}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                placeholder="Select Charger Type"
                formState={formState}
                options={chargerTypeOptions}
                onSelect={(data) => {
                  setValue("chargerType", data.value.toLowerCase());
                  clearErrors("chargerType");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              chargerTypeError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {chargerTypeError}
          </p>
        </div>

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Battery type
          </p>
          <Controller
            name="type"
            rules={rules}
            control={control}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                placeholder="Select Type"
                formState={formState}
                options={batteryTypeOptions}
                onSelect={(data) => {
                  setValue("type", data.value);
                  clearErrors("type");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              batteryTypeError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {batteryTypeError}
          </p>
        </div>

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Hub Station
          </p>

          <Controller
            name="hubName"
            rules={rules}
            control={control}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Hub Station"
                placeholder="Assign Hub Station"
                formState={formState}
                options={hubStations}
                onSelect={(data) => {
                  setValue("hubName", data.value);
                  setValue("hubId", data.id);
                  clearErrors("hubName");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              hubNameError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {hubNameError}
          </p>
        </div>
      </div>
    </CustomLayout>
  );
};

export default AddBattery;
