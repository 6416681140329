/* eslint-disable react/jsx-no-useless-fragment */
import { Divider } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Core/Components/CustomButton';
import ChevronIcon from '../../../Common/Svgs/ChevronIcon';
import { getDistance } from '../../../Redux/APIs/tripsAPI';
import { updateTrips } from '../../../Redux/Slices/trips.slice';

const CalculateTimeAndDistance = () => {
  const {
    stops,
    calculatedRouteDistance,
    calculatedRouteTime,
    calculateRouteAndDistanceVisible,
    isRouteOptimised,
  } = useSelector((state) => state.trips);

  const dispatch = useDispatch();

  const handleTimeAndDistance = async () => {
    await dispatch(getDistance({ stops }));
    dispatch(
      updateTrips({ key: 'calculateRouteAndDistanceVisible', value: true }),
    );
  };

  const getEstimatedDistance = () => {
    if (calculatedRouteDistance !== null) {
      return `${parseFloat(calculatedRouteDistance).toFixed(2)} Km`;
    }
    return '--';
  };

  return (
    <>
      {!isRouteOptimised && (
        <div className="">
          <Divider className="m-0 text-light-grey" />
          <button
            type="button"
            className="flex items-center justify-start py-2 gap-x-1"
            onClick={() => handleTimeAndDistance()}
          >
            <p className="text-xs font-medium text-primary font-poppins">
              Calculate Time and Distance
            </p>
            <Button
              padding="checkIn"
              width="viewDetails"
              size="viewDetails"
              colors="viewDetails"
            >
              <div className="flex items-center justify-center w-full h-full rounded-md">
                <ChevronIcon className="w-3 h-3 stroke-primary" />
              </div>
            </Button>
          </button>
          {calculateRouteAndDistanceVisible && (
            <div className="mt-4 bg-background-white-hover py-2 px-[27px] rounded-lg">
              <div className="flex items-center justify-center gap-x-6">
                <div className="w-[117px]">
                  <p className="font-normal font-aileron text-10 text-dark-gray">
                    Estimated Time
                  </p>
                  <p className="mt-2 text-xs font-semibold font-aileron">
                    {calculatedRouteTime || '--'}
                  </p>
                </div>
                <div className="w-[117px]">
                  <p className="font-normal font-aileron text-10 text-dark-gray">
                    Estimated Distance
                  </p>
                  <p className="mt-2 text-xs font-semibold font-aileron">
                    {getEstimatedDistance()}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CalculateTimeAndDistance;
