import { useSelector } from "react-redux";

const useSystemUser = (userId) => {
  const { allSystemUsers } = useSelector((state) => state.loggedInUser);

  const user = allSystemUsers?.find((u) => u.id === +userId);

  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }

  return `--`;
};

export default useSystemUser;
