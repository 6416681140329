import React, { useCallback } from "react";
import TickIcon from "../../Common/Svgs/TickIcon";
import { CHARGER_STATUS } from "./Data/constants";
import ChargingIcon from "../../Common/Svgs/ChargingIcon";

const ChargerStatus = ({ status }) => {
  const getStyle = () => {
    if (status === CHARGER_STATUS.AVAILABLE) return "bg-green-10 text-green";

    return "bg-yellow-10 text-yellow";
  };

  const getBody = useCallback(() => {
    return (
      <div className="flex items-center gap-x-2">
        {status === CHARGER_STATUS.AVAILABLE ? (
          <TickIcon size={16} className="text-green" />
        ) : (
          <ChargingIcon size={16} className="text-yellow" />
        )}

        <p className="font-poppins text-xs font-medium">
          {status === CHARGER_STATUS.AVAILABLE ? "Available" : "Charging"}
        </p>
      </div>
    );
  }, [status]);

  return (
    <div className={`py-1 px-2 max-w-[98px] rounded-md ${getStyle()}`}>
      {getBody()}
    </div>
  );
};

export default ChargerStatus;
