import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Row, Col } from "antd";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CustomInputWithController } from "../../Core/Components/CustomInput";
import Button from "../../Core/Components/CustomButton";
import SetPasswordResetScreen from "./SetPasswordResetScreen";
import ResetPasswordSuccessScreen from "./ResetPasswordSuccessScreen";
import { client as axios } from "../../Utils/axiosClient";
import { updateLoggedInUser } from "../../Redux/Slices/loggedInUser.slice";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleSubmit, control, formState, setError } = useForm({
    email: "",
  });

  const [enteredEmail, setEnteredEmail] = useState("");
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [focus, setFocus] = useState(false);

  const [searchParams] = useSearchParams();

  if (searchParams.has("token")) {
    return <SetPasswordResetScreen />;
  }

  const validationForEmail = {
    required: "please enter email address",
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
      message: "Enter a vaild email",
    },
  };

  const onSubmit = async (e) => {
    const { email } = e;
    setEnteredEmail(email);

    try {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: true,
        }),
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        {
          email,
        },
      );

      if (response.status === 200) {
        setShowSuccessPopUp(true);
      }
    } catch (error) {
      setError(error.response.data.type, {
        type: "server",
        message: error.response.data.message,
      });
    } finally {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: false,
        }),
      );
    }
  };

  return (
    <div>
      {showSuccessPopUp ? (
        <ResetPasswordSuccessScreen email={enteredEmail} />
      ) : (
        <div className="w-screen h-screen">
          <Row className="h-full">
            <Col span={15} className="h-full overflow-hidden">
              <img
                src="/assets/images/Login/login-image.svg"
                alt="LoginImage"
                className="object-cover w-full h-full"
              />
            </Col>
            <Col span={9}>
              <div className="flex flex-col items-center justify-center w-full h-full gap-y-5 font-poppins bg-background-white">
                <img
                  src="/assets/songa-main-logo.svg"
                  alt="Songa Mobility"
                  className="w-[258px] h-14"
                />
                <div>
                  <div className="text-base font-medium text-center capitalize">
                    reset password
                  </div>
                  <div className="mt-2 w-[242px] text-xs font-light leading-4.5 text-dark-gray text-center">
                    Enter your email and you will receive a link to rest your
                    pasword
                  </div>
                </div>
                <form
                  className="flex flex-col items-center justify-center w-full gap-y-6"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <CustomInputWithController
                    intent="login"
                    control={control}
                    formState={formState}
                    focus={focus}
                    setFocus={setFocus}
                    name="email"
                    placeholder="Enter Email"
                    rules={validationForEmail}
                  />
                  <Button width="login" type="submit" className="capitalize">
                    Send me the link
                  </Button>
                  <button
                    type="button"
                    onClick={() => navigate("/")}
                    className="cursor-pointer font-poppins font-light text-xs leading-4.5 text-dark-grey-text hover:text-new-black hover:underline"
                  >
                    Back to Login
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
export default ResetPassword;
