import React from "react";
import EditIcon from "../../../Common/Svgs/EditIcon";
import AddToServiceIcon from "../../../Common/Svgs/AddToServiceIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const useServiceThreeDots = () => {
  const threeDotsOptions = [
    {
      id: 1,
      value: "Edit Info",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.SERVICE_EDIT]),
    },
    {
      id: 2,
      value: "View Service",
      icon: (
        <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: true,
    },
    {
      id: 3,
      value: "Complete Service",
      icon: (
        <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([PERMISSIONS.SERVICE_COMPLETE]),
    },
  ];

  return threeDotsOptions;
};

export default useServiceThreeDots;
