import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../../Core/Components/Modal/CustomModal';
import CloseIcon from '../../../Common/Svgs/CloseIcon';
import Button from '../../../Core/Components/CustomButton';
import AlertIcon from '../../../Common/Svgs/AlertIcon';
import { updateTrips } from '../../../Redux/Slices/trips.slice';
import { getDistance } from '../../../Redux/APIs/tripsAPI';

const OptimisationNotPossibleModal = ({ visible, onCancel }) => {
  const onConfirm = () => {
    onCancel();
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-xl">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center w-12 h-12 rounded-full bg-warning-10">
            <AlertIcon size={24} className="text-warning" />
          </div>
          <button className="p-2" type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-black font-poppins">
            Your conditions do not meet the criteria of optimisation
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Do you still want continue with your current stops?
          </p>
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button
            colors="cancelButton"
            size="primary"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button colors="primary" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export const MissingActivityModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();
  const { missingActivityModal, stops } = useSelector((state) => state.trips);

  const onConfirm = async () => {
    onCancel();
    await dispatch(getDistance({ stops }));
    // dispatch(updateTrips({ key: 'isRouteOptimised', value: false }));
    dispatch(
      updateTrips({ key: 'calculateRouteAndDistanceVisible', value: true }),
    );
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-xl">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center w-12 h-12 rounded-full bg-warning-10">
            <AlertIcon size={24} className="text-warning" />
          </div>
          <button className="p-2" type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          {missingActivityModal.missingActivities.map((item) => (
            <div className="pt-2 first:pt-0" key={item.cargo}>
              <p className="text-xs font-medium text-dark-gray font-poppins">
                No{' '}
                <span className="font-semibold text-black capitalize">
                  {item.activity}
                </span>{' '}
                exists for the cargo{' '}
                <span className="font-semibold text-black">{item.cargo}</span>
              </p>
            </div>
          ))}
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Do you still want continue with your current stops?
          </p>
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button
            colors="cancelButton"
            size="primary"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button colors="primary" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default OptimisationNotPossibleModal;
