import { Tooltip } from 'antd';
import React from 'react';

const CustomTooltip = ({
  children,
  tooltipTitle,
  placement = 'bottom',
  height = '23px',
  bgColor = '#626263',
  textColor = 'text-white',
  padding = '4px 8px',
  trigger = 'hover',
}) => {
  return (
    <div data-testid="CustomTooltipTitle">
      <Tooltip
        title={
          <div
            className={`font-light font-poppins text-10 leading-3.75 ${textColor}`}
          >
            {tooltipTitle}
          </div>
        }
        overlayInnerStyle={{
          borderRadius: '4px',
          padding,
          minHeight: height,
        }}
        color={bgColor}
        placement={placement}
        trigger={trigger}
      >
        {children}
      </Tooltip>
    </div>
  );
};

export default CustomTooltip;
