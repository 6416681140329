import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import CloseIcon from '../../Common/Svgs/CloseIcon';
import Button from '../../Core/Components/CustomButton';
import CustomSearchAndSelect from '../../Core/Components/CustomSearchAndSelect';
import CustomSelectWithCheckbox from '../../Core/Components/CustomSelectWithCheckbox';
import ChevronIcon from '../../Common/Svgs/ChevronIcon';
import { serviceDropdownBtnArr } from './Data/ServiceListData';
import {
  resetServices,
  updateServices,
} from '../../Redux/Slices/services.slice';
import { getServiceDetails } from '../../Redux/APIs/servicesAPI';
import getUniqueList from '../../Utils/getUniqueList';

const MoreServiceFilters = ({ setShowFilters }) => {
  const dispatch = useDispatch();

  const { serviceDetails, filtersForServices } = useSelector(
    (state) => state.services,
  );
  const [finalList, setFinalList] = useState([]);

  const uniqueAddedByList = getUniqueList(serviceDetails, 'submittedBy');

  const addedByList = uniqueAddedByList.map((item) => {
    return { id: item.assetNumber, name: item.submittedBy };
  });

  const onCancel = () => {
    setShowFilters(false);
  };

  const hasFieldValues = () => {
    return (
      filtersForServices?.addedBy?.length > 0 ||
      (filtersForServices?.dateRangeStart !== '' &&
        filtersForServices?.dateRangeEnd !== '')
    );
  };

  const handleDateRangeChange = (e) => {
    if (e && e.length === 2 && e[0] && e[1]) {
      const startTimeCheck = e[0].format('YYYY-MM-DD');
      const endTimeCheck = e[1].format('YYYY-MM-DD');

      dispatch(
        updateServices({
          key: 'filtersForServices',
          value: {
            ...filtersForServices,
            dateRangeStart: startTimeCheck,
            dateRangeEnd: endTimeCheck,
          },
        }),
      );
    }
  };

  const handleApplyFilters = () => {
    dispatch(
      getServiceDetails({
        addedBy: filtersForServices?.addedBy,
        assetType: filtersForServices?.assetType,
        location: filtersForServices?.location,
        severity: filtersForServices?.severity,
        dateRangeStart: filtersForServices?.dateRangeStart,
        dateRangeEnd: filtersForServices?.dateRangeEnd,
      }),
    );
    onCancel();
  };

  useEffect(() => {
    setFinalList(filtersForServices?.addedBy);
  }, [filtersForServices]);

  const handleClearAll = () => {
    setFinalList([]);
    dispatch(resetServices(['filtersForServices']));
  };

  // useEffect(() => {
  //   dispatch(resetServices(['filtersForServices']));
  // }, []);

  return (
    <div className="absolute z-10 bg-white right-[0px] top-10 w-78 rounded-md p-3 shadow-moreFilters">
      <div className="flex items-center justify-between">
        <div className="text-base font-medium text-new-black font-poppins">
          More filters
        </div>
        <CloseIcon
          className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
          onClick={onCancel}
        />
      </div>
      <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text mt-6 mb-2">
        Date Range
      </div>
      <DatePicker.RangePicker
        value={[
          filtersForServices?.dateRangeStart !== '' &&
            dayjs(filtersForServices?.dateRangeStart),
          filtersForServices?.dateRangeEnd !== '' &&
            dayjs(filtersForServices?.dateRangeEnd),
        ]}
        format="DD MMM YYYY"
        separator={<div className="text-dark-grey-text"> - </div>}
        onCalendarChange={(e) => handleDateRangeChange(e)}
        className="h-[39px]"
        allowClear={false}
      />
      <div className="mt-6">
        <div className="font-light font-poppins text-xs leading-4.5 text-dark-grey-text">
          Added By
        </div>
        <CustomSearchAndSelect
          placeholder="Type or Select User"
          inputList={addedByList}
          finalList={finalList}
          setFinalList={setFinalList}
          onChange={(e) =>
            dispatch(
              updateServices({
                key: 'filtersForServices',
                value: { ...filtersForServices, addedBy: e },
              }),
            )
          }
        />
      </div>
      <div className="flex mt-6 gap-x-3">
        <Button
          size="primary"
          width="save"
          colors="grey"
          modifier={hasFieldValues() ? null : 'blur'}
          onClick={handleClearAll}
        >
          Clear All
        </Button>
        <Button
          size="primary"
          width="save"
          type="submit"
          modifier={hasFieldValues() ? null : 'blur'}
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
};

const ServiceFilters = () => {
  const dispatch = useDispatch();
  const { filtersForServices, assetTypeFilters, severityFilters } = useSelector(
    (state) => state.services,
  );

  // const { serviceDetails } = useSelector((state) => state.services);

  // const serviceLocations = [
  //   ...new Set(serviceDetails.map((item) => item.location)),
  // ];

  // const mappedLocations = serviceLocations.map((item, index) => {
  //   return { id: index, value: item };
  // });

  const functionSwitch = (e, filter) => {
    switch (filter) {
      case 'Type':
        return dispatch(
          updateServices({
            key: 'filtersForServices',
            value: { ...filtersForServices, assetType: e },
          }),
        );
      // case 'Location':
      //   return dispatch(
      //     updateServices({
      //       key: 'filtersForServices',
      //       value: { ...filtersForServices, location: e },
      //     }),
      //   );
      case 'Severity':
        return dispatch(
          updateServices({
            key: 'filtersForServices',
            value: { ...filtersForServices, severity: e },
          }),
        );
      default:
        return dispatch(
          updateServices({
            key: 'filtersForServices',
            value: { ...filtersForServices, assetType: e },
          }),
        );
    }
  };

  const filterSwitch = (filter) => {
    switch (filter) {
      case 'Type':
        return assetTypeFilters;
      // case 'Location':
      //   return mappedLocations;
      case 'Severity':
        return severityFilters;
      default:
        return assetTypeFilters;
    }
  };

  useEffect(() => {
    dispatch(resetServices(['filtersForServices']));
  }, []);

  useEffect(() => {
    dispatch(
      getServiceDetails({
        assetType: filtersForServices?.assetType,
        // location: filtersForServices?.location,
        severity: filtersForServices?.severity,
      }),
    );
  }, [filtersForServices]);

  return (
    <div className="flex items-center justify-start gap-x-3">
      {serviceDropdownBtnArr?.map((item) => (
        <CustomSelectWithCheckbox
          key={item.title}
          selectedVal={filtersForServices[item.filters]}
          filterType={item.renderBody}
          inputList={filterSwitch(item.renderBody)}
          onChange={(e) => functionSwitch(e, item.title)}
        >
          {filtersForServices[item.filters]?.length === 0 ? (
            <Button size={item.size} colors={item.color} padding={item.padding}>
              <div className="flex items-center justify-start gap-x-3">
                <div>{item.title}</div>
                <img src="/assets/icons/chevron-down.svg" alt="" />
              </div>
            </Button>
          ) : (
            <Button padding="filter" size="filter" width="dynamic">
              <div className="flex item-center gap-x-3">
                <div className="font-poppins text-xs leading-4.5 font-medium">
                  {item.title}
                </div>
                <div className="w-4.5 h-4.5 bg-white text-black font-aileron font-semibold text-10 leading-3.75 rounded-full flex items-center justify-center">
                  {filtersForServices[item.filters]?.length}
                </div>
                <div>
                  <ChevronIcon className="w-4 h-4 -rotate-90 stroke-white" />
                </div>
              </div>
            </Button>
          )}
        </CustomSelectWithCheckbox>
      ))}
    </div>
  );
};

export { MoreServiceFilters, ServiceFilters };
