/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import ChargerHeader from "./ChargerHeader";
import CustomTable from "../../Core/Components/CustomTable";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import ChargerIcon from "../../Common/Svgs/ChargerIcon";
import { chargerColumns } from "./Data/listingsData";
import ToastMessageModal from "./ToastMessageModal";
import { updateCharger } from "../../Redux/Slices/chargers.slice";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import DeleteChargerModal from "./DeleteChargerModal";
import { getTableContainerHeight } from "../../Utils/getTableContainerHeight";
import { getChargers } from "../../Redux/APIs/chargersAPI";
import useDebounce from "../../Core/hooks/useDebounce";
import { getAllLocationsV3 } from "../../Redux/APIs/locationsAPI";
import QRCodeModal from "../../Core/Components/Modal/QRCodeModal";
import { updateQrCode } from "../../Redux/Slices/qrcode.slice";

const LOCAL_DEFAULT_LIMIT = 10;

const ChargerList = () => {
  const dispatch = useDispatch();

  const { chargerList, showToastMessage, deleteChargerModal } = useSelector(
    (state) => state.chargers,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const { viewModal } = useSelector((state) => state.qrCode);

  const tableContainerRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    type: [],
  });

  const debounceSearch = useDebounce(search, 500);

  // get charger list with filters
  useEffect(() => {
    const filterObj = {
      limit: LOCAL_DEFAULT_LIMIT,
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hubs = filters.hub;
    if (filters.type.length) filterObj.type = filters.type;

    dispatch(getChargers(filterObj));
  }, [dispatch, currentPage, debounceSearch, filters]);

  // close the toast message after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateCharger({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  // fetch user details and all the locations (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
    dispatch(getAllLocationsV3());
  }, [dispatch]);

  return (
    <CustomLayout pageName="Chargers">
      <div>
        <ChargerHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />

        <div
          className={`h-[calc(100vh-72px-69px-40px)] ${
            chargerList.result.length && "overflow-y-scroll"
          }`}
          ref={tableContainerRef}
        >
          <CustomTable
            // scrollX={0}
            // scrollY="518px"
            disableScroll
            columns={chargerColumns}
            data={chargerList.result}
            emptyState={
              <ChargerIcon size={16} className="text-dark-grey-text" />
            }
            emptyStateHeight={getTableContainerHeight(tableContainerRef)}
            rowClassName="group"
            pagination={{
              pageSize: LOCAL_DEFAULT_LIMIT,
              total: chargerList.totalRecords,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              current: currentPage,
              itemRender: (current, type, originalElement) => {
                const lastPage = chargerList.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>

      <DeleteChargerModal
        visible={deleteChargerModal?.visible}
        onCancel={() =>
          dispatch(
            updateCharger({
              key: "deleteChargerModal",
              value: {
                visible: false,
                charger: {},
              },
            }),
          )
        }
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <QRCodeModal
        visible={viewModal?.visible}
        type={viewModal?.data?.assetType}
        onCancel={() =>
          dispatch(
            updateQrCode({
              key: "viewModal",
              value: { visible: false, data: {} },
            }),
          )
        }
      />

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateCharger({
              key: "showToastMessage",
              value: { visible: false, data: {} },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
};

export default ChargerList;
