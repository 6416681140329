import React from "react";

const TrikeDispatchIcon = ({ size = 16, className = "text-[#525252]" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.333 3.333V8c0 .733-.6 1.334-1.333 1.334H.667V6.667"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14 12l-.667-4h-2m1.334 5.334H6C6 12.6 5.4 12 4.667 12c-.734 0-1.334.6-1.334 1.334H.667v-4H8c.733 0 1.333-.6 1.333-1.334m0-4.667h-2 6"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.667 14.667a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.667zM14 14.667A1.333 1.333 0 1014 12a1.333 1.333 0 000 2.667zM2 5.333h3.333M4 7.333l2-2-2-2"
        />
      </svg>
    </span>
  );
};

export default TrikeDispatchIcon;
