/* eslint-disable eqeqeq */
import React from "react";
import { useSelector } from "react-redux";

const useCargoItems = () => {
  const { stops } = useSelector((state) => state.trips);

  const { allCommodities } = useSelector((state) => state.rates);

  const commodities = allCommodities.map((item) => ({
    key: item.id,
    value: `${item.name[0].toUpperCase()}${item.name.slice(1)}`,
    label: (
      <div className="flex items-center px-2 py-2 gap-x-3">
        <p className="text-xs font-semibold text-black capitalize font-aileron">
          {item.name[0].toUpperCase() + item.name.slice(1)}
        </p>
      </div>
    ),
    hubId: item.hubId,
  }));

  if (stops[0].locationId != 1) {
    return commodities.filter((item) => item.hubId == stops[0].locationId);
  }

  return commodities;
};

export default useCargoItems;
