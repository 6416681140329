/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "./CustomButton";
import LocationIcon from "../../Common/Svgs/LocationIcon";
import SettingsIcon from "../../Common/Svgs/SettingsIcon";
import { updateTrikes } from "../../Redux/Slices/trikes.slice";
import usePermission from "../hooks/usePermission";
import { PERMISSIONS } from "../../Modules/Login/constants";
import { INITIAL_ZOOM_LEVEL } from "../../Modules/Home/Data/HomeData";

const ZoomButton = ({ mapRef }) => {
  const [currentZoomLevel, setCurrentZoomLevel] = useState(INITIAL_ZOOM_LEVEL);

  const handleZoomIn = () => {
    mapRef?.current?.setZoom(mapRef?.current?._zoom + 1);
  };

  const handleZoomOut = () => {
    if (mapRef?.current?._zoom >= 4) {
      mapRef?.current?.setZoom(mapRef?.current?._zoom - 1);
    }
  };

  // to store the current zoom level
  useEffect(() => {
    setInterval(() => {
      setCurrentZoomLevel(mapRef?.current?._zoom);
    }, 1500);
  }, [mapRef, mapRef?.current?._zoom]);

  return (
    <div className="w-8 h-16 p-2 bg-white rounded-md shadow-button">
      <div className="flex flex-col items-center gap-y-2">
        <img
          src="/assets/icons/home/map-markers/add.svg"
          className={`w-4 h-4 ${
            currentZoomLevel <= 17 ? "cursor-pointer" : "cursor-not-allowed"
          }`}
          alt=""
          onClick={() => handleZoomIn()}
        />

        <div className="w-full h-[1px] bg-light-grey" />

        <img
          src="/assets/icons/home/map-markers/minus.svg"
          className={`w-4 h-4 ${
            currentZoomLevel > 3 ? "cursor-pointer" : "cursor-not-allowed"
          }`}
          alt=""
          onClick={() => handleZoomOut()}
        />
      </div>
    </div>
  );
};

export const RelocateButton = ({ mapRef, initialZoomlevel, center }) => {
  const handleRelocate = (zoomLevel = 11) => {
    mapRef?.current?.setView(center, zoomLevel);
  };
  return (
    <Button
      colors="kpis"
      padding="location"
      size="location"
      modifier="shadow"
      width="maximize"
      onClick={() => handleRelocate(initialZoomlevel)}
    >
      <img src="/assets/icons/home/map-markers/relocate.svg" alt="" />
    </Button>
  );
};

export const LiveLocationButton = () => {
  return (
    <Button
      colors="location"
      padding="location"
      size="location"
      modifier="shadow"
    >
      <div className="flex gap-x-2">
        <LocationIcon className="w-4 h-4 stroke-dark-gray" />
        <div className="font-aileron font-semibold text-xs leading-4.5 text-dark-gray">
          live location
        </div>
      </div>
    </Button>
  );
};

export const GeoFenceButton = () => {
  const dispatch = useDispatch();

  const { currentTrike } = useSelector((state) => state.trikes);

  const hasGeofencePermission = usePermission([
    PERMISSIONS.TRIKE_GEOFENCE_ADD,
    PERMISSIONS.TRIKE_GEOFENCE_EDIT,
    PERMISSIONS.TRIKE_GEOFENCE_DELETE,
  ]);

  const onGeofenceButtonClick = () => {
    if (currentTrike?.geofence) {
      dispatch(
        updateTrikes({
          key: "geoFenceLatLngs",
          value: {
            trikeId: currentTrike.id,
            latLngs: currentTrike?.geofence,
          },
        }),
      );
    }

    dispatch(
      updateTrikes({
        key: "geoFenceModal",
        value: hasGeofencePermission,
      }),
    );
  };

  return (
    <div>
      {hasGeofencePermission ? (
        <Button
          colors="kpis"
          padding="geofence"
          size="location"
          modifier={hasGeofencePermission ? "shadow" : "disabledAndShadow"}
          onClick={onGeofenceButtonClick}
        >
          <div className="flex gap-x-2">
            <SettingsIcon className="w-4 h-4 stroke-dark-grey-text" />
            <div className="font-poppins font-medium text-xs leading-4.5 text-dark-grey-text">
              geofence
            </div>
          </div>
        </Button>
      ) : null}
    </div>
  );
};

export const ExpandButton = ({ onClick }) => {
  return (
    <Button
      colors="kpis"
      padding="location"
      size="location"
      modifier="shadow"
      onClick={onClick}
    >
      <img src="/assets/maximize.svg" className="w-4 h-4" alt="" />
    </Button>
  );
};

export default ZoomButton;
