import React from "react";
import { LOCATION_FETCH_SOURCE } from "../Data";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import TrackerMobileIcon from "../../../Common/Svgs/TrackerMobileIcon";

const TrackerTags = ({ locationSource }) => {
  if (locationSource && locationSource.length === 2) {
    return (
      <div
        className="px-2 py-1 rounded flex items-center gap-x-2"
        style={{
          background: "#F5F3FF",
        }}
      >
        <TrikeIcon size={16} className="text-[#775DFA]" />

        <p className="font-poppins text-xs font-medium text-[#775DFA]">+</p>

        <TrackerMobileIcon size={16} className="text-[#775DFA]" />
      </div>
    );
  }

  const isLocationFromMobile =
    locationSource && locationSource[0] === LOCATION_FETCH_SOURCE.MOBILE;

  if (isLocationFromMobile) {
    return (
      <div className="px-2 py-1 bg-yellow-10 rounded">
        <TrackerMobileIcon size={16} className="text-yellow-dark" />
      </div>
    );
  }

  return (
    <div className="px-2 py-1 bg-green-10 rounded">
      <TrikeIcon size={16} className="text-green" />
    </div>
  );
};

export default TrackerTags;
