import React from "react";
import dayjs from "dayjs";
import CustomTags from "../../../Core/Components/CustomTags";
import CustomTooltip from "../../../Core/Components/CustomTooltip";
import getDateAndTime from "../../../Utils/getDateAndTime";
import SpeedIcon from "../../../Common/Svgs/SpeedIcon";
import TripsIcon from "../../../Common/Svgs/TripsIcon";
import MileageIcon from "../../../Common/Svgs/MileageIcon";
import LoadIcon from "../../../Common/Svgs/LoadIcon";
import getTripStatus from "../../../Utils/getTripStatus";

export const KPIData = (currentTrike, ioTData) => {
  return [
    {
      id: 1,
      title: "Current Speed",
      image: <SpeedIcon className="text-kpi-icon" />,
      value: (
        <div>
          <CustomTooltip
            trigger="hover"
            // tooltipTitle={`As on ${getDateAndTime(ioTData?.created_at)}`}
          >
            <div>{currentTrike.currentSpeed || "--"} Km/H</div>
          </CustomTooltip>
        </div>
      ),
      background: "bg-kpi-background",
    },
    {
      id: 2,
      title: "Average Speed",
      image: <SpeedIcon className="text-kpi-icon" />,
      value: (
        <div>
          <CustomTooltip
            trigger="hover"
            // tooltipTitle={`As on ${getDateAndTime(ioTData?.created_at)}`}
          >
            <div>{currentTrike.averageSpeed || "--"} Km/H</div>
          </CustomTooltip>
        </div>
      ),
      background: "bg-kpi-background",
    },
    {
      id: 3,
      title: "Distance Covered",
      image: <TripsIcon size={16} className="text-kpi-icon" />,
      value: <div>{currentTrike?.distance || "--"} Km</div>,
      background: "bg-kpi-background",
    },
    {
      id: 4,
      title: "Mileage",
      image: <MileageIcon className="text-kpi-icon" />,
      value: `${currentTrike.mileage}` || "--",
      background: "bg-kpi-background",
    },
    {
      id: 5,
      title: "Load Utilisation",
      image: <LoadIcon className="text-kpi-icon" />,
      value: (
        <div>
          <CustomTooltip
            trigger="hover"
            tooltipTitle={`As on ${getDateAndTime(ioTData?.created_at)}`}
          >
            <div>
              {currentTrike.totalPayload / currentTrike.distance || 0} Kg/Km
            </div>
          </CustomTooltip>
        </div>
      ),
      background: "bg-kpi-background",
    },
  ];
};

export const trikesDetailsColumnsOld = [
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Start Time
      </div>
    ),
    dataIndex: "startTime",
    // sorter: (a, b) => a.startTime - b.startTime,
    render: (text) => (
      <div className="">
        <div className="text-xs font-medium font-aileron leading-4.5 text-black">
          {dayjs(text).format("HH:mm:ss")}
        </div>
        <div className="text-10 font-light font-aileron leading-3.75 text-dark-gray">
          {dayjs(text).format("DD-MM-YYYY")}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Odometer Start
      </div>
    ),
    dataIndex: "odometerStart",
    // sorter: (a, b) => new Date(a.odometerStart) - new Date(b.odometerStart),
    render: (text) => (
      <div className="text-xs font-medium font-aileron leading-4.5 text-dark-gray">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        End Time
      </div>
    ),
    dataIndex: "endTime",
    // sorter: (a, b) => a.endTime - b.endTime,
    render: (text) => (
      <div>
        <div className="text-xs font-medium font-aileron leading-4.5 text-black">
          {text ? dayjs(text).format("HH:mm:ss") : "--"}
        </div>
        <div className="text-10 font-light font-aileron leading-3.75 text-dark-gray">
          {text ? dayjs(text).format("DD-MM-YYYY") : null}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Odometer End
      </div>
    ),
    dataIndex: "odometerEnd",
    // sorter: (a, b) => a.odometerEnd - b.odometerEnd,
    render: (text) => (
      <div className="text-xs font-medium font-aileron leading-4.5 text-dark-gray">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Payload
      </div>
    ),
    dataIndex: "payload",
    // sorter: (a, b) => a.payload - b.payload,
    render: (text) => (
      <div>
        <div className="text-xs font-medium font-aileron leading-4.5 text-dark-gray">
          {text ? `${text} Kg` : "--"}
        </div>
        <div className="text-10 font-light font-aileron leading-3.75 text-dark-gray">
          {/* {text || null} */}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Trip duration
      </div>
    ),
    dataIndex: "duration",
    // sorter: (a, b) => a.duration - b.duration,
    render: (text, record) => (
      <div className="flex items-center gap-x-4">
        <div className="text-xs font-medium font-aileron leading-4.5 uppercase text-dark-gray">
          {text}
        </div>
        {record.id === 1 ? (
          <CustomTags
            colors="checkIn"
            background="checkIn"
            padding="currentTrike"
            size="currentTrike"
            borderRadius="currentTrike"
          >
            <div className="text-10 font-medium font-aileron leading-3.75">
              Current
            </div>
          </CustomTags>
        ) : null}
      </div>
    ),
  },
];

export const trikesDetailsColumns = [
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Trip ID
      </div>
    ),
    dataIndex: "id",
    // sorter: (a, b) => a.startTime - b.startTime,
    render: (text) => (
      <div className="text-xs font-semibold text-black font-aileron">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Driver
      </div>
    ),
    dataIndex: "driverName",
    // sorter: (a, b) => new Date(a.odometerStart) - new Date(b.odometerStart),
    render: (text) => (
      <div className="text-xs font-semibold capitalize font-aileron text-dark-gray">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        No. of Stops
      </div>
    ),
    dataIndex: "stops",
    // sorter: (a, b) => a.endTime - b.endTime,
    render: (text) => (
      <div className="text-xs font-semibold capitalize font-aileron text-dark-gray">
        {text?.length || 2}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">Date</div>
    ),
    dataIndex: "startDate",
    // sorter: (a, b) => a.odometerEnd - b.odometerEnd,
    render: (text) => (
      <div className="text-xs font-semibold capitalize font-aileron text-dark-gray">
        {dayjs(text).format("ddd, MMM DD YYYY")}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Start Time
      </div>
    ),
    dataIndex: "startTime",
    // sorter: (a, b) => a.payload - b.payload,
    render: (text) => (
      <div className="text-xs font-semibold capitalize font-aileron text-dark-gray">
        {text !== null ? dayjs(text).format("hh:mm A") : "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: "statusId",
    // sorter: (a, b) => a.duration - b.duration,
    render: (text) => (
      <div className="flex items-center gap-x-4">
        <div className="text-xs font-medium font-aileron leading-4.5 uppercase text-dark-gray">
          {getTripStatus(text)}
        </div>
      </div>
    ),
  },
];
