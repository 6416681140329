import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { countryList } from './Data/HomeData';

const WeatherInformation = () => {
  const { locationDetails } = useSelector((state) => state.loggedInUser);

  return (
    <div className="flex items-center justify-end mt-1.5 bg-new-gray px-2 py-1 rounded-md">
      <p className="text-xs leading-4.5 font-medium text-black">
        {parseInt(Math.floor(locationDetails?.temperature), 10)}°C
      </p>
      <Divider type="vertical" className="h-4 mt-1 bg-light-grey" />
      <p className="text-xs font-normal font-aileron leading-4.5 text-dark-gray">
        {`${locationDetails?.city}, ${
          countryList[locationDetails?.countryCode]
        }`}
      </p>
    </div>
  );
};

export default WeatherInformation;
