/* eslint-disable no-lonely-if */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Checkbox } from "antd";
import ModuleHeader from "./ModuleHeader";
import {
  generalSettingsPermissions,
  systemUserAndRolesPermissions,
} from "./data";
import PermissionTitle from "./PermissionTitle";
import { PERMISSIONS } from "../../Login/constants";
import {
  roleCreateEditDelete,
  settingsPersonalisation,
  systemUserCreateEditDelete,
} from "./permissionFunctions";
import SettingsIcon from "../../../Common/Svgs/SettingsIcon";

const CONDITIONS = {
  GENERAL_VIEW_AND_EDIT: [PERMISSIONS.SETTINGS_GENERAL_VIEW],
  GENERAL_REMOVE_ALL: [
    PERMISSIONS.SETTINGS_PERSONALISATION,
    PERMISSIONS.SETTINGS_NO_PERSONALISATION,
    PERMISSIONS.SETTINGS_GENERAL_VIEW_AND_EDIT,
  ],
  SYSTEM_USER_VIEW_AND_EDIT: [
    PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
    PERMISSIONS.SETTINGS_SYSTEM_USER_NO_ACCESS,
  ],
  SYSTEM_USER_VIEW_ONLY: [
    PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT,
    PERMISSIONS.SETTINGS_SYSTEM_USER_NO_ACCESS,
  ],
  SYSTEM_USER_NO_ACCESS: [
    PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT,
    PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
  ],
  SYSTEM_USER_REMOVE_ALL: [
    PERMISSIONS.SETTINGS_SYSTEM_USER_CREATE,
    PERMISSIONS.SETTINGS_SYSTEM_USER_EDIT,
    PERMISSIONS.SETTINGS_SYSTEM_USER_DELETE,
    PERMISSIONS.SETTINGS_ROLE_CREATE,
    PERMISSIONS.SETTINGS_ROLE_EDIT,
    PERMISSIONS.SETTINGS_ROLE_DELETE,
    PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
    PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT,
    PERMISSIONS.SETTINGS_SYSTEM_USER_NO_ACCESS,
  ],
};

const SettingsPermissions = ({ permissions, setPermissions }) => {
  // check if the permissions needs to be disabled

  const disabledGeneral = permissions.includes(
    PERMISSIONS.SETTINGS_GENERAL_VIEW,
  );

  const disabledSystemUser = CONDITIONS.SYSTEM_USER_VIEW_AND_EDIT.some((d) =>
    permissions.includes(d),
  );

  const handleModulePermission = (permissionId) => {
    let local = [];

    if (permissionId === PERMISSIONS.SETTINGS_GENERAL_VIEW_AND_EDIT) {
      local = permissions.filter(
        (item) => !CONDITIONS.GENERAL_VIEW_AND_EDIT.includes(item),
      );
    }

    // removing all the general settings permissions
    if (permissionId === PERMISSIONS.SETTINGS_GENERAL_VIEW) {
      local = permissions.filter(
        (item) => !CONDITIONS.GENERAL_REMOVE_ALL.includes(item),
      );
    }

    if (permissionId === PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT) {
      local = permissions.filter(
        (item) => !CONDITIONS.SYSTEM_USER_VIEW_AND_EDIT.includes(item),
      );
    }

    // removing all the system user and role permissions
    if (permissionId === PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW) {
      local = permissions.filter(
        (item) => !CONDITIONS.SYSTEM_USER_REMOVE_ALL.includes(item),
      );
    }

    // removing all the system user and role permissions
    if (permissionId === PERMISSIONS.SETTINGS_SYSTEM_USER_NO_ACCESS) {
      local = permissions.filter(
        (item) => !CONDITIONS.SYSTEM_USER_REMOVE_ALL.includes(item),
      );
    }

    setPermissions([...local, permissionId]);
  };

  const handleIndividualPermission = (permissionId) => {
    let local = [];

    if (permissions.includes(permissionId)) {
      local = permissions.filter((item) => item !== permissionId);
      setPermissions(local);
    } else {
      if (
        permissionId === PERMISSIONS.SETTINGS_PERSONALISATION ||
        permissionId === PERMISSIONS.SETTINGS_NO_PERSONALISATION
      ) {
        // handling either yes or no for personalisation
        settingsPersonalisation({
          current: permissionId,
          permissions,
          setPermissions,
        });
      } else if (permissionId === PERMISSIONS.SETTINGS_SYSTEM_USER_CREATE) {
        // handling multiple insertions for system user create, edit, delete
        systemUserCreateEditDelete({ setPermissions });
      } else if (permissionId === PERMISSIONS.SETTINGS_ROLE_CREATE) {
        // handling multiple insertions for role create, edit, delete
        roleCreateEditDelete({ setPermissions });
      } else {
        setPermissions((prev) => [...prev, permissionId]);
      }
    }
  };

  return (
    <div>
      <ModuleHeader
        module="Settings"
        icon={<SettingsIcon className="w-4 h-4 stroke-primary fill-none" />}
      />
      {/* main module permission for general section */}

      <div className="w-full px-6 py-2 flex items-center gap-x-2 font-poppins text-xs font-medium bg-background border-b border-light-grey">
        <p>A.</p>
        <p>General</p>
      </div>

      <div className="w-full h-[60px]">
        <div
          className="flex items-center w-full h-full border-b"
          style={{
            background: "#FAF2D8",
            borderColor: "#FAD152",
          }}
        >
          <div
            className="w-[40%] border-r py-3 px-6"
            style={{
              borderColor: "#FAD152",
            }}
          >
            <PermissionTitle
              title={generalSettingsPermissions[0].title}
              description={generalSettingsPermissions[0].description}
            />
          </div>

          <div className="w-[60%] h-full flex items-center">
            {generalSettingsPermissions[0].options.map((option, idx) => {
              // rendering empty div for layout consistency
              if (!option) {
                return (
                  <div
                    key={idx}
                    className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                    style={{
                      borderColor: "#FAD152",
                    }}
                  />
                );
              }

              const selected = permissions.includes(option.permissionId);

              return (
                <div
                  key={option.text}
                  className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                  style={{
                    borderColor: "#FAD152",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    onClick={() => handleModulePermission(option.permissionId)}
                  />
                  <p
                    className={`font-poppins text-xs ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {option.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* individual functionality permission for general section */}
      <div className="w-full">
        {generalSettingsPermissions.slice(1).map((item) => {
          return (
            <div
              key={item.id}
              className="flex items-center w-full h-[60px] border-b"
              style={{
                borderColor: "#DFDFDF",
              }}
            >
              <div
                className="w-[40%] h-full border-r py-3 px-6"
                style={{
                  borderColor: "#DFDFDF",
                }}
              >
                <PermissionTitle
                  title={item.title}
                  description={item.description}
                />
              </div>

              <div className="w-[60%] h-full flex items-center">
                {item.options.map((option, idx) => {
                  // rendering empty div for layout consistency
                  if (!option) {
                    return (
                      <div
                        key={idx}
                        className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                        style={{
                          borderColor: "#DFDFDF",
                        }}
                      />
                    );
                  }

                  const selected = permissions.includes(option.permissionId);

                  // if the permission is view only, remove pointer events and add an opacity
                  return (
                    <div
                      key={option.text}
                      className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                      style={{
                        borderColor: "#DFDFDF",
                        opacity: disabledGeneral && 0.5,
                        pointerEvents: disabledGeneral && "none",
                      }}
                    >
                      {/* if the permissions should be disabled then it should not
                          show any tick mark else show the selected tick mark */}
                      <Checkbox
                        checked={disabledGeneral ? false : selected}
                        onClick={() =>
                          handleIndividualPermission(option.permissionId)
                        }
                      />
                      <p
                        className={`font-poppins text-xs
                            ${selected ? "font-medium" : "font-normal"}
                            `}
                      >
                        {option.text}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {/* main module permission for system users section */}

      <div className="w-full px-6 py-2 flex items-center gap-x-2 font-poppins text-xs font-medium bg-background border-b border-light-grey">
        <p>B.</p>
        <p>System Users And Roles</p>
      </div>

      <div className="w-full h-[60px]">
        <div
          className="flex items-center w-full h-full border-b"
          style={{
            background: "#FAF2D8",
            borderColor: "#FAD152",
          }}
        >
          <div
            className="w-[40%] border-r py-3 px-6"
            style={{
              borderColor: "#FAD152",
            }}
          >
            <PermissionTitle
              title={systemUserAndRolesPermissions[0].title}
              description={systemUserAndRolesPermissions[0].description}
            />
          </div>

          <div className="w-[60%] h-full flex items-center">
            {systemUserAndRolesPermissions[0].options.map((option) => {
              // rendering empty div for layout consistency
              if (!option) {
                return (
                  <div
                    className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                    style={{
                      borderColor: "#FAD152",
                    }}
                  />
                );
              }

              const selected = permissions.includes(option.permissionId);

              return (
                <div
                  key={option.text}
                  className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                  style={{
                    borderColor: "#FAD152",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    onClick={() => handleModulePermission(option.permissionId)}
                  />
                  <p
                    className={`font-poppins text-xs ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {option.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* individual functionality permission for general section */}
      <div className="w-full">
        {systemUserAndRolesPermissions.slice(1).map((item) => {
          return (
            <div
              key={item.id}
              className="flex items-center w-full h-[60px] border-b"
              style={{
                borderColor: "#DFDFDF",
              }}
            >
              <div
                className="w-[40%] h-full border-r py-3 px-6"
                style={{
                  borderColor: "#DFDFDF",
                }}
              >
                <PermissionTitle
                  title={item.title}
                  description={item.description}
                />
              </div>

              <div className="w-[60%] h-full flex items-center">
                {item.options.map((option, idx) => {
                  // rendering empty div for layout consistency
                  if (!option) {
                    return (
                      <div
                        key={idx}
                        className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                        style={{
                          borderColor: "#DFDFDF",
                        }}
                      />
                    );
                  }

                  const selected = permissions.includes(option.permissionId);

                  // if the permission is view only, remove pointer events and add an opacity
                  return (
                    <div
                      key={option.text}
                      className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                      style={{
                        borderColor: "#DFDFDF",
                        opacity: disabledSystemUser && 0.5,
                        pointerEvents: disabledSystemUser && "none",
                      }}
                    >
                      {/* if the permissions should be disabled then it should not
                          show any tick mark else show the selected tick mark */}
                      <Checkbox
                        checked={disabledSystemUser ? false : selected}
                        onClick={() =>
                          handleIndividualPermission(option.permissionId)
                        }
                      />
                      <p
                        className={`font-poppins text-xs
                            ${selected ? "font-medium" : "font-normal"}
                            `}
                      >
                        {option.text}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SettingsPermissions;
