import React from "react";
import EditIcon from "../../../Common/Svgs/EditIcon";
import DuplicateIcon from "../../../Common/Svgs/DuplicateIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const useRoleThreeDots = () => {
  const threeDotsOptions = [
    {
      id: 1,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.SETTINGS_ROLE_EDIT]),
    },
    {
      id: 2,
      value: "Duplicate",
      icon: <DuplicateIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.SETTINGS_ROLE_CREATE]),
    },
    {
      id: 3,
      value: "Delete Role",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.SETTINGS_ROLE_DELETE]),
    },
  ];

  return threeDotsOptions;
};

export default useRoleThreeDots;
