/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import HomeIcon, { ActiveHomeIcon } from "../../../Common/Svgs/HomeIcon";
import getThemeColor from "../../../Utils/getThemeColor";

const HomeItem = ({ sidebarActive, pageName }) => {
  const navigate = useNavigate();

  const { theme } = useSelector((state) => state.loggedInUser);

  return (
    <div
      role="button"
      className="flex items-center gap-x-2 mt-5 py-2 px-6 cursor-pointer w-full group mx-auto"
      onClick={() => navigate("/home")}
    >
      <motion.div layout className="w-5 h-5">
        {pageName === "Home" ? (
          <ActiveHomeIcon
            className="text-sidebar-active"
            centerColor={getThemeColor(theme)}
          />
        ) : (
          <HomeIcon
            size={20}
            className="fill-none stroke-sidebar-base group-hover:stroke-sidebar-active"
          />
        )}
      </motion.div>

      {sidebarActive && (
        <motion.p
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className={`font-aileron font-semibold text-xs group-hover:text-sidebar-active pt-1 ${
            pageName === "Home" ? "text-sidebar-active" : "text-sidebar-base"
          }
      `}
        >
          Home
        </motion.p>
      )}
    </div>
  );
};

export default HomeItem;
