import React from "react";
import TickIcon from "../../Common/Svgs/TickIcon";
import {
  AlertSecondaryIcon,
  AlertTriangleIcon,
} from "../../Common/Svgs/AlertIcon";

const getPaymentStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-green-10 max-w-max">
          <TickIcon size={16} className="text-green" />
          <p className="font-poppins text-xs font-medium text-green capitalize">
            All Paid
          </p>
        </div>
      );

    case 2:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-warning-10 max-w-max">
          <TickIcon size={16} className="text-warning" />
          <p className="font-poppins text-xs font-medium text-warning capitalize">
            Partially Paid
          </p>
        </div>
      );

    case 3:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-alert-10 max-w-max">
          <AlertTriangleIcon size={16} className="stroke-alert-red" />
          <p className="font-poppins text-xs font-medium text-alert-red capitalize">
            Pending
          </p>
        </div>
      );

    default:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-green-10 max-w-max">
          <TickIcon size={16} className="text-green" />
          <p className="font-poppins text-xs font-medium text-green capitalize">
            All Paid
          </p>
        </div>
      );
  }
};

export default getPaymentStatus;

export const getCollectionPaymentStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-green-10 max-w-max">
          <TickIcon size={16} className="text-green" />
          <p className="font-poppins text-xs font-medium text-green capitalize">
            All Collected
          </p>
        </div>
      );

    case 2:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-warning-10 max-w-max">
          <TickIcon size={16} className="text-warning" />
          <p className="font-poppins text-xs font-medium text-warning capitalize">
            Partially Collected
          </p>
        </div>
      );

    case 3:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-alert-10 max-w-max">
          <AlertTriangleIcon size={16} className="stroke-alert-red" />
          <p className="font-poppins text-xs font-medium text-alert-red capitalize">
            Pending
          </p>
        </div>
      );

    default:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-green-10 max-w-max">
          <TickIcon size={16} className="text-green" />
          <p className="font-poppins text-xs font-medium text-green capitalize">
            All Collected
          </p>
        </div>
      );
  }
};

export const getPaymentDetailStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-green-10 max-w-max">
          <TickIcon size={16} className="text-green" />
          <p className="font-poppins text-xs font-medium text-green capitalize">
            Paid
          </p>
        </div>
      );

    case 2:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-alert-10 max-w-max">
          <AlertSecondaryIcon size={16} className="text-alert-red" />
          <p className="font-poppins text-xs font-medium text-alert-red capitalize">
            Due
          </p>
        </div>
      );

    default:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-green-10 max-w-max">
          <TickIcon size={16} className="text-green" />
          <p className="font-poppins text-xs font-medium text-green capitalize">
            Paid
          </p>
        </div>
      );
  }
};

export const getCollectionDetailStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-green-10 max-w-max">
          <TickIcon size={16} className="text-green" />
          <p className="font-poppins text-xs font-medium text-green capitalize">
            Collected
          </p>
        </div>
      );

    case 2:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-alert-10 max-w-max">
          <AlertSecondaryIcon size={16} className="text-alert-red" />
          <p className="font-poppins text-xs font-medium text-alert-red capitalize">
            Due
          </p>
        </div>
      );

    default:
      return (
        <div className="flex items-center gap-x-2 py-1 px-2 rounded-md bg-green-10 max-w-max">
          <TickIcon size={16} className="text-green" />
          <p className="font-poppins text-xs font-medium text-green capitalize">
            Paid
          </p>
        </div>
      );
  }
};
