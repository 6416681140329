import React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import L from 'leaflet';

const getGeofencePoint = () => {
  // eslint-disable-next-line new-cap
  return new L.divIcon({
    html: ReactDOMServer.renderToString(
      <div className="absolute w-5 h-5 rounded-full bg-primary top-[-8px] left-[-4px]" />,
    ),
  });
};

export default getGeofencePoint;
