/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { useManagementCentreItem } from "./useItems";
import getThemeColor, {
  getThemePrimary50Color,
} from "../../../Utils/getThemeColor";
import ManagementCentreIcon from "../../../Common/Svgs/ManagementCentreIcon";
import { SUBMENU } from "../data/contants";

const DEFAULT_COLORS = {
  HEADER_ITEM_BG: "#387275",
  ICON_COLOR: "#aabdbd",
  GREEN_THEME_ACTIVE_COLOR: "#fad152",
  OTHER_THEMES_ACTIVE_COLOR: "#fff",
};

const ManagementCentreItem = ({
  sidebarActive,
  pageName,
  subMenu,
  setSubMenu,
}) => {
  const navigate = useNavigate();

  const { theme } = useSelector((state) => state.loggedInUser);

  const [onHover, setOnHover] = useState(false);
  // const [subMenu, setSubMenu] = useState(false);
  const [headerIconStyle, setHeaderIconStyle] = useState({
    fillColor: DEFAULT_COLORS.HEADER_ITEM_BG,
    borderColor: DEFAULT_COLORS.ICON_COLOR,
    insideColor: DEFAULT_COLORS.ICON_COLOR,
  });

  // sub menu items
  const items = useManagementCentreItem().filter((item) => item.hasPermission);

  const activeItem = items.findIndex(
    (i) => i.label.toLowerCase() === pageName.toLowerCase(),
  );

  const handleSubMenu = () => {
    if (subMenu === SUBMENU.MANAGEMENT_CENTRE) {
      setSubMenu(SUBMENU.DEFAULT);
    } else {
      setSubMenu(SUBMENU.MANAGEMENT_CENTRE);
    }
  };

  const getContainerStyle = () => {
    // if (sidebarActive) return "bg-transparent-white";
    if (sidebarActive) return "bg-primary-50";

    return "";
  };

  const getHeaderMenuStyle = () => {
    if (activeItem > -1 || subMenu === SUBMENU.MANAGEMENT_CENTRE) {
      return "text-sidebar-active stroke-sidebar-active";
    }

    return "text-sidebar-base stroke-sidebar-base";
  };

  const getHeaderIconStyle = useCallback(() => {
    const isGreenTheme = theme === "green-theme";

    // if the current page is part of the submenu
    if (activeItem > -1) {
      setHeaderIconStyle({
        fillColor: isGreenTheme
          ? DEFAULT_COLORS.GREEN_THEME_ACTIVE_COLOR
          : DEFAULT_COLORS.OTHER_THEMES_ACTIVE_COLOR,
        borderColor: isGreenTheme
          ? DEFAULT_COLORS.GREEN_THEME_ACTIVE_COLOR
          : DEFAULT_COLORS.OTHER_THEMES_ACTIVE_COLOR,
        insideColor: getThemeColor(theme),
      });

      return;
    }

    // if the sidebar is closed then fill color will be the theme color
    if (!sidebarActive) {
      setHeaderIconStyle({
        fillColor: getThemeColor(theme),
        borderColor: DEFAULT_COLORS.ICON_COLOR,
        insideColor: DEFAULT_COLORS.ICON_COLOR,
      });

      return;
    }

    // if none of the conditions are true then fillColor will be the bgColor of the Header item (transparent-white)
    if (onHover || subMenu === SUBMENU.MANAGEMENT_CENTRE) {
      setHeaderIconStyle({
        fillColor: getThemePrimary50Color(theme),
        borderColor: isGreenTheme
          ? DEFAULT_COLORS.GREEN_THEME_ACTIVE_COLOR
          : DEFAULT_COLORS.OTHER_THEMES_ACTIVE_COLOR,
        insideColor: isGreenTheme
          ? DEFAULT_COLORS.GREEN_THEME_ACTIVE_COLOR
          : DEFAULT_COLORS.OTHER_THEMES_ACTIVE_COLOR,
      });

      return;
    }

    setHeaderIconStyle({
      fillColor: getThemePrimary50Color(theme),
      borderColor: DEFAULT_COLORS.ICON_COLOR,
      insideColor: DEFAULT_COLORS.ICON_COLOR,
    });
  }, [activeItem, sidebarActive, theme, onHover, subMenu]);

  const getSubMenuStyle = (item) => {
    if (pageName.toLowerCase() === item.toLowerCase())
      return "text-primary stroke-primary fill-none";
    return "text-sidebar-base stroke-sidebar-base fill-none group-hover:text-sidebar-active group-hover:stroke-sidebar-active";
  };

  // close the submenu if the sidebar is closed
  // useEffect(() => {
  //   if (!sidebarActive) {
  //     setSubMenu(SUBMENU.DEFAULT);
  //   }
  // }, [sidebarActive, setSubMenu]);

  useEffect(() => {
    // close the submenu if the sidebar is closed
    if (!sidebarActive) setSubMenu(SUBMENU.DEFAULT);

    // open the submenu if the sidebar is open and active item is present
    if (sidebarActive && activeItem > -1) {
      setTimeout(() => {
        setSubMenu(SUBMENU.MANAGEMENT_CENTRE);
      }, 200);
    }
  }, [sidebarActive, setSubMenu, activeItem]);

  useEffect(() => {
    getHeaderIconStyle();
  }, [sidebarActive, activeItem, getHeaderIconStyle, onHover, subMenu]);

  return (
    <div>
      <div
        role="button"
        className={`flex items-center mt-5 py-2 px-6 h-9 cursor-pointer w-full group ${getContainerStyle()}`}
        // onClick={() => setSubMenu((prev) => !prev)}
        onClick={handleSubMenu}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <motion.div
          // layout={subMenu === SUBMENU.DEFAULT}
          className="w-[22px] h-5"
        >
          <ManagementCentreIcon
            className="shrink-0"
            fillColor={headerIconStyle.fillColor}
            borderColor={headerIconStyle.borderColor}
            insideColor={headerIconStyle.insideColor}
          />
        </motion.div>

        {sidebarActive && (
          <motion.p
            layout={!sidebarActive}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className={`w-full ml-2 flex-1 font-aileron font-semibold text-xs group-hover:text-sidebar-active ${getHeaderMenuStyle()}`}
          >
            Management Centre
          </motion.p>
        )}

        {sidebarActive && (
          <motion.div
            // layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="w-2.5 h-2.5"
          >
            <ChevronIcon
              strokeWidth="1.5"
              className={`w-2.5 h-2.5 group-hover:stroke-sidebar-active transform transition-all ${getHeaderMenuStyle()}
              ${
                subMenu === SUBMENU.MANAGEMENT_CENTRE
                  ? "-rotate-90"
                  : "rotate-90"
              }
            `}
            />
          </motion.div>
        )}
      </div>

      {sidebarActive &&
        subMenu === SUBMENU.MANAGEMENT_CENTRE &&
        items.map((i) => {
          const selected = pageName.toLowerCase() === i.label.toLowerCase();

          return (
            <div
              key={i.label}
              className={`flex items-center gap-x-2 py-3 px-[30px] cursor-pointer group ${
                selected && "bg-sidebar-active"
              }`}
              onClick={() => navigate(i.path)}
            >
              <span className={`w-4 h-4 ${getSubMenuStyle(i.label)}`}>
                {i.icon}
              </span>
              <p
                className={`font-aileron text-xs font-normal capitalize ${getSubMenuStyle(
                  i.label,
                )}`}
              >
                {i.label}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default ManagementCentreItem;
