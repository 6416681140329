import React from "react";

const RevenueManagerIcon = ({ size, className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.026 9.033c-.28.274-.44.667-.4 1.087.06.72.72 1.247 1.44 1.247h1.267v.793a2.512 2.512 0 01-2.507 2.507H4.173a2.512 2.512 0 01-2.507-2.507V7.673a2.512 2.512 0 012.507-2.506h7.653a2.512 2.512 0 012.507 2.506v.96h-1.347c-.373 0-.713.147-.96.4z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.666 8.273V5.227c0-.794.487-1.5 1.227-1.78l5.293-2c.827-.314 1.713.3 1.713 1.186v2.534M15.039 9.314v1.373a.685.685 0 01-.667.68h-1.307c-.72 0-1.38-.527-1.44-1.247-.04-.42.12-.813.4-1.086.247-.254.587-.4.96-.4h1.387a.685.685 0 01.667.68zM4.666 8h4.667"
        />
      </svg>
    </div>
  );
};

export default RevenueManagerIcon;
