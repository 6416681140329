import { useSelector } from "react-redux";
import { LOCATION_TYPE } from "../../Location/Data";

const useLocationOnAdd = () => {
  const { userDetails } = useSelector((state) => state.loggedInUser);

  const { allLocations } = useSelector((state) => state.locations);

  return allLocations
    .filter(
      (item) =>
        item.type !== LOCATION_TYPE.HUB_STATION &&
        userDetails.hubs.includes(parseInt(item.hubId, 10)),
    )
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));
};

export default useLocationOnAdd;
