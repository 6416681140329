import React from 'react';

// TODO : to be reviewed by Preet - added textColor prop
// since we have two colors for progress bars
const CustomProgressBar = ({
  color,
  current,
  // currentNode,
  text,
  textColor = 'text-dark-gray',
}) => {
  return (
    <div className="relative w-full h-full bg-background-white-hover rounded-xl">
      {/* {currentNode || (
        <div className="font-aileron font-semibold text-2xl leading-9 text-center py-2.25 relative z-10">
          {current}%
        </div>
      )} */}

      <div
        style={{
          height: `${current}%`,
        }}
        className={`${color} w-full rounded-xl absolute bottom-0`}
      >
        <div
          className={`${textColor} font-aileron font-light text-xs leading-4.5 text-center absolute bottom-2 left-0 right-0 capitalize`}
        >
          {text}
        </div>
      </div>
    </div>
  );
};
export default CustomProgressBar;
