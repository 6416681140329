import React from "react";

const FarmerBadge = ({ type = 1, text = "" }) => {
  return (
    <div
      className={`py-0.5 px-2 rounded max-w-max ${
        type === 1 ? "bg-yellow-dark" : "bg-warning"
      }`}
    >
      <p className="font-poppins text-xs font-normal text-background-white">
        {text}
      </p>
    </div>
  );
};

export default FarmerBadge;
