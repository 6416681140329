/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as axios } from "../../Utils/axiosClient";

export const getHomeKpi = createAsyncThunk(
  "home/getHomeKpi",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/home/get-kpi-data`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getNotifications = createAsyncThunk(
  "home/getNotifications",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/notifications/get-notifications`,
        {
          params,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateNotifications = createAsyncThunk(
  "home/updateNotifications",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/notifications/update-notifications`,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
