import React from "react";

const ExportIcon = ({ size, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M8 1.333A6.67 6.67 0 001.333 8 6.669 6.669 0 008 14.667 6.669 6.669 0 0014.666 8"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.667 7.333l5.467-5.466M14.666 4.553v-3.22h-3.22"
        />
      </svg>
    </span>
  );
};

export default ExportIcon;
