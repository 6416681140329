import dayjs from "dayjs";
import React from "react";
import ListDropdown from "./ListDropdown";

export const rateColumns = [
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">Name</div>
    ),
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray py-1 capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">Date</div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a.hubName.localeCompare(b.hubName),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Unit of Weight
      </div>
    ),
    dataIndex: "unit",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Payment Rate
      </div>
    ),
    dataIndex: "paymentRate",
    sorter: (a, b) => a.paymentRate - b.paymentRate,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text} KSH
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Collection Rate
      </div>
    ),
    dataIndex: "collectionRate",
    sorter: (a, b) => a.collectionRate - b.collectionRate,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text} KSH
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Comission Rate
      </div>
    ),
    dataIndex: "commissionRate",
    sorter: (a, b) => a.commissionRate - b.commissionRate,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text} KSH
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Last Modified
      </div>
    ),
    dataIndex: "updatedAt",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {dayjs(text).format("DD-MM-YYYY, hh:mm a")}
      </div>
    ),
  },
  {
    dataIndex: "id",
    width: "5%",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="invisible group-hover:visible w-full flex items-center justify-center"
      >
        <ListDropdown detail={record} />
      </button>
    ),
  },
];

export const rateData = [
  {
    id: 1,
    name: "Milk",
    hubId: 14,
    hubName: "Ogembo Hub",
    unitName: "per litre",
    paymentRate: 10,
    collectionRate: 15,
    commissionRate: 8,
  },
  {
    id: 2,
    name: "Banana",
    hubId: 14,
    hubName: "Kisii Hub",
    unitName: "per kg",
    paymentRate: 10,
    collectionRate: 15,
    commissionRate: 8,
  },
  {
    id: 3,
    name: "Feed",
    hubId: 14,
    hubName: "Ogembo Hub",
    unitName: "per kg",
    paymentRate: 10,
    collectionRate: 15,
    commissionRate: 8,
  },
  {
    id: 4,
    name: "Milk",
    hubId: 14,
    hubName: "Sengera Hub",
    unitName: "per litre",
    paymentRate: 10,
    collectionRate: 15,
    commissionRate: 8,
  },
  {
    id: 5,
    name: "Banana",
    hubId: 14,
    hubName: "Ogembo Hub",
    unitName: "per kg",
    paymentRate: 10,
    collectionRate: 15,
    commissionRate: 8,
  },
  {
    id: 6,
    name: "Feed",
    hubId: 14,
    hubName: "Ogembo Hub",
    unitName: "per kg",
    paymentRate: 10,
    collectionRate: 15,
    commissionRate: 8,
  },
  {
    id: 7,
    name: "Milk",
    hubId: 14,
    hubName: "Sengera Hub",
    unitName: "per litre",
    paymentRate: 10,
    collectionRate: 15,
    commissionRate: 8,
  },
  {
    id: 8,
    name: "Milk",
    hubId: 14,
    hubName: "Ogembo Hub",
    unitName: "per litre",
    paymentRate: 10,
    collectionRate: 15,
    commissionRate: 8,
  },
  {
    id: 9,
    name: "Feed",
    hubId: 14,
    hubName: "Ogembo Hub",
    unitName: "per kg",
    paymentRate: 10,
    collectionRate: 15,
    commissionRate: 8,
  },
  {
    id: 10,
    name: "Banana",
    hubId: 14,
    hubName: "Kisii Hub",
    unitName: "per kg",
    paymentRate: 10,
    collectionRate: 15,
    commissionRate: 8,
  },
];

export const rateUnitOptions = [
  {
    id: 1,
    value: "Per Litre",
  },
  {
    id: 2,
    value: "Per Mililitre",
  },
  {
    id: 3,
    value: "Per Kg",
  },
  {
    id: 4,
    value: "Per Gram",
  },
];
