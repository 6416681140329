import React from 'react';

const InventoryIcon = ({ size = 24, className, centerColor = '#085054' }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={centerColor}
          d="M12 21.5V12l9-5v9.5l-9 5zM12 21.5V12L3 7v9.5l9 5z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M20.5 7.278L12 12m0 0L3.5 7.278M12 12v9.5m9-5.441V7.94c0-.342 0-.513-.05-.666a1 1 0 00-.215-.364c-.109-.119-.258-.202-.558-.368l-7.4-4.111c-.284-.158-.425-.237-.575-.268a1 1 0 00-.403 0c-.15.031-.292.11-.576.268l-7.4 4.11c-.3.167-.45.25-.558.369a1 1 0 00-.215.364C3 7.428 3 7.599 3 7.94v8.118c0 .342 0 .514.05.666a1 1 0 00.215.364c.109.119.258.202.558.368l7.4 4.111c.284.158.425.237.576.268.133.027.27.027.402 0 .15-.031.292-.11.576-.268l7.4-4.11c.3-.167.45-.25.558-.369a1 1 0 00.215-.364c.05-.152.05-.324.05-.666zM16.5 9.5l-9-5"
        />
      </svg>
    </span>
  );
};

export const ActiveInventoryIcon = ({ size = 24, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M12 21.5V12l9-5v9.5l-9 5zM12 21.5V12L3 7v9.5l9 5z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M20.5 7.278L12 12m0 0L3.5 7.278M12 12v9.5m9-5.441V7.94c0-.342 0-.513-.05-.666a1 1 0 00-.215-.364c-.109-.119-.258-.202-.558-.368l-7.4-4.111c-.284-.158-.425-.237-.575-.268a1 1 0 00-.403 0c-.15.031-.292.11-.576.268l-7.4 4.11c-.3.167-.45.25-.558.369a1 1 0 00-.215.364C3 7.428 3 7.599 3 7.94v8.118c0 .342 0 .514.05.666a1 1 0 00.215.364c.109.119.258.202.558.368l7.4 4.111c.284.158.425.237.576.268.133.027.27.027.402 0 .15-.031.292-.11.576-.268l7.4-4.11c.3-.167.45-.25.558-.369a1 1 0 00.215-.364c.05-.152.05-.324.05-.666zM16.5 9.5l-9-5"
        />
      </svg>
    </span>
  );
};

export const HomeKPIInventoryIcon = ({
  size = 16,
  className = 'text-dark-gray',
}) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.113 4.96L8 8.367l5.847-3.387M8 14.407V8.36"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.62 1.653L3.06 3.627c-.807.446-1.467 1.566-1.467 2.486V9.88c0 .92.66 2.04 1.467 2.487l3.56 1.98c.76.42 2.007.42 2.767 0l3.56-1.98c.806-.447 1.466-1.567 1.466-2.487V6.113c0-.92-.66-2.04-1.466-2.486l-3.56-1.98c-.767-.42-2.007-.42-2.767.006z"
        />
      </svg>
    </span>
  );
};

export default InventoryIcon;
