const getThemeColor = (theme) => {
  switch (theme) {
    case "green-theme":
      return "#085054";
    case "blue-theme":
      return "#135bba";
    case "pink-theme":
      return "#dd6898";
    case "purple-theme":
      return "#aa68d9";
    case "brown-theme":
      return "#8e786c";
    case "grey-theme":
      return "#878787";
    case "black-theme":
      return "#3a3b3e";
    default:
      return "#085054";
  }
};

export default getThemeColor;

export const getThemePrimary50Color = (theme) => {
  switch (theme) {
    case "green-theme":
      return "#528587";
    case "blue-theme":
      return "#5a8ccf";
    case "pink-theme":
      return "#e795b7";
    case "purple-theme":
      return "#c395e4";
    case "brown-theme":
      return "#b0a098";
    case "grey-theme":
      return "#ababab";
    case "black-theme":
      return "#757678";
    default:
      return "#528587";
  }
};
