/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../Core/Components/CustomTable";
import CustomLayout from "../../Core/Layout/CustomLayout";
import CustomTableTopBar from "../../Core/Components/CustomTableTopBar";
import {
  inventoryColumns,
  serviceDropdownBtnArr,
} from "./Data/InventoryListData";
import useSearch from "../../Core/hooks/useSearch";
import ServiceIcon from "../../Common/Svgs/ServiceIcon";
import { getInventory } from "../../Redux/APIs/inventoryAPI";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import { getTableContainerHeight } from "../../Utils/getTableContainerHeight";
import { getAllLocationsV3 } from "../../Redux/APIs/locationsAPI";

function InventoryList() {
  const dispatch = useDispatch();

  const { inventory } = useSelector((state) => state.inventory);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const tableContainerRef = useRef(null);

  const { search, setSearch, filteredData } = useSearch(inventory, ["name"]);

  useEffect(() => {
    dispatch(getInventory());
  }, [dispatch]);

  // fetch user details and all the locations (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
    dispatch(getAllLocationsV3());
  }, [dispatch]);

  return (
    <CustomLayout pageName="Inventory">
      <div>
        <CustomTableTopBar
          module="inventory"
          searchFunc={setSearch}
          searchValue={search}
          searchPlaceholder="Search Inventory"
          dropdownBtnArr={serviceDropdownBtnArr}
          customFilters={<></>}
          showMoreFilters={false}
          showToggleButton={false}
        />

        <div className="h-[calc(100vh-72px-61px-48px)]" ref={tableContainerRef}>
          <CustomTable
            scrollX={0}
            columns={inventoryColumns}
            data={filteredData}
            emptyState={
              <ServiceIcon className="w-4 h-4 fill-none stroke-dark-grey-text" />
            }
            emptyStateHeight={getTableContainerHeight(tableContainerRef)}
          />
        </div>
      </div>
    </CustomLayout>
  );
}
export default InventoryList;
