import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../Core/Components/CustomInput";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import PlusIcon from "../../../Common/Svgs/PlusIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const RoleHeader = ({ search, setSearch }) => {
  const navigate = useNavigate();

  const hasPermission = usePermission([PERMISSIONS.SETTINGS_ROLE_CREATE]);

  const [focus, setFocus] = useState(false);

  return (
    <div className="w-full px-6 py-2.5 flex justify-between items-center">
      <CustomInput
        intent="search"
        prefix={<img src="/assets/icons/search.svg" alt="" className="mr-2" />}
        value={search}
        focus={focus}
        border={focus > 0 ? "success" : "none"}
        placeholder={focus ? null : "Search Roles"}
        onChange={(e) => setSearch(e.target.value)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        suffix={
          search.length > 0 ? (
            <CloseIcon
              className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
              onClick={() => {
                setSearch("");
                setFocus(false);
              }}
            />
          ) : null
        }
      />

      {hasPermission && (
        <Button
          width="dynamic"
          padding="filter"
          onClick={() =>
            navigate("/settings/roles-and-permissions/create-role")
          }
        >
          <div className="flex items-center gap-x-2">
            <PlusIcon className="w-4 h-4 stroke-white" />
            <p>Create Role</p>
          </div>
        </Button>
      )}
    </div>
  );
};

export default RoleHeader;
