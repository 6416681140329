import { Modal } from 'antd';
import React from 'react';

const CustomModal = ({
  intent,
  width,
  children,
  visible,
  onCancel,
  centered = true,
  ...props
}) => {
  return (
    <Modal
      {...props}
      open={visible}
      closable={false}
      width={width}
      destroyOnClose
      centered={centered}
      keyboard
      onCancel={onCancel}
      footer={false}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
