export const ROLES = {
  1: "Admin",
  2: "Basic User",
};

export const validationForEmail = {
  required: "please enter email address",
  pattern: {
    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
    message: "Enter a vaild email",
  },
};

export const validationForPassword = {
  required: "please enter password",
  minLength: {
    value: 8,
    message: "less characters for password",
  },
};
