/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import React from "react";
import getQRCodeStatus from "../getQRCodeStatus";
// import CheckboxCTA, { UnselectAll } from '../CheckboxCTA';
import QRCodeIcon from "../../../Common/Svgs/QRCodeIcon";
import ListDropdown from "../ListDropdown";
import SubmittedDetails from "../../../Core/Components/SubmittedDetails";

export const QR_CODE_TYPE = {
  TRIKE: 1,
  BATTERY: 2,
  FARMER: 3,
  CHARGER: 4,
};

export const typeFilters = [
  {
    id: 1,
    value: getQRCodeStatus(1),
  },
  {
    id: 2,
    value: getQRCodeStatus(2),
  },
  {
    id: 3,
    value: getQRCodeStatus(3),
  },
];

export const qrCodeColumns = [
  // {
  //   title: () => <UnselectAll />,
  //   dataIndex: 'id',
  //   width: '40px',
  //   render: (text) => <CheckboxCTA id={text} />,
  // },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Code ID
      </div>
    ),
    dataIndex: "id",

    render: (text) => (
      <div>
        <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
          {text}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Asset Type
      </div>
    ),
    dataIndex: "assetType",
    render: (text) => <div>{getQRCodeStatus(text)}</div>,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Asset ID
      </div>
    ),
    dataIndex: "assetId",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Date Of Entry
      </div>
    ),
    dataIndex: "submittedBy",
    render: (text, record) => (
      <div>
        <SubmittedDetails record={record} />
      </div>
    ),
  },
  {
    dataIndex: "id",
    width: "100px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="invisible group-hover:visible w-full flex items-center justify-center"
      >
        <ListDropdown data={record} />
      </button>
    ),
  },
];

export const threeDotsOptions = [
  {
    id: 1,
    value: "View QR Code",
    icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
  },
];
