/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable no-unsafe-optional-chaining */
import React from "react";
import { useSelector } from "react-redux";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import LocationsModuleIcon from "../../../Common/Svgs/LocationsModuleIcon";
import Stop from "./Stop";
import StopIcon, { DestinationIcon } from "../../../Common/Svgs/StopIcon";
import { TRIP_STATUS } from "../Data/TripConstants";

const SourceIcon = ({ statusId }) => {
  return (
    <div
      className={`w-3.5 h-3.5 mt-4 border rounded-full ${
        statusId === TRIP_STATUS.UPCOMING
          ? "border-light-grey"
          : "border-kpi-background "
      }`}
    />
  );
};

const DashedLine = ({ statusId, height = "124px" }) => {
  return (
    <div
      className={`w-[1px] border border-dashed ${
        statusId === TRIP_STATUS.UPCOMING
          ? "border-light-grey"
          : "border-kpi-background"
      }`}
      style={{ height }}
    />
  );
};

const StopDetails = () => {
  const { currentTrip, overallSummaryForTrip } = useSelector(
    (state) => state.trips,
  );
  const { allLocations } = useSelector((state) => state.locations);

  const stops = currentTrip?.stops.map((stop) => {
    const currentLocation = allLocations.find(
      (geolocation) =>
        geolocation.name.toLowerCase() === stop.location.toLowerCase(),
    );

    if (stop.id === 1 || stop.id === currentTrip?.stops?.length) {
      return {
        id: stop.id,
        isSourceOrDestination: true,
        stopTag: stop.id === 1 ? "Origin" : "Destination",
        stopName: stop.location,
        address: currentLocation?.address || "--",
        locationId: currentLocation?.id,
        isOrigin: stop.id === 1 ? 1 : 0,
        isDestination: stop.id === currentTrip?.stops?.length ? 1 : 0,
      };
    }
    return {
      id: stop.id,
      isSourceOrDestination: false,
      stopName: stop.location,
      address: currentLocation?.address || "--",
      tags: stop.activities,
      locationId: currentLocation?.id,
      isOrigin: 0,
      isDestination: 0,
    };
  });

  const renderIcon = (index) => {
    switch (index) {
      case 0:
        return <SourceIcon statusId={currentTrip?.statusId} />;
      case stops.length - 1:
        return (
          <DestinationIcon
            className={`mt-4 ${
              currentTrip?.statusId === 2
                ? "text-light-grey"
                : "text-kpi-background"
            }`}
          />
        );
      default:
        return (
          <StopIcon
            className={`mt-4 ${
              currentTrip?.statusId === 2
                ? "text-light-grey"
                : "text-kpi-background"
            }`}
          />
        );
    }
  };

  const getDashedLineHeight = (index, currentStop) => {
    if (currentTrip?.statusId === TRIP_STATUS.COMPLETED) {
      if (currentStop && currentStop[0]?.noShow) {
        return "90px";
      }

      return "170px";
    }
    if (index === 0) {
      return "100px";
    }

    return "120px";
  };

  return (
    <div className="w-full h-full bg-white p-4 border-[0.5px] border-light-grey rounded-md">
      <div className="flex items-center gap-x-2.5">
        <CustomRoundedImage
          background="bg-primary"
          image={<LocationsModuleIcon size={16} className="text-white" />}
        />
        <p className="text-base font-medium capitalize font-poppins">Stops</p>
      </div>

      {stops
        // .filter((item) => !item.isSourceOrDestination)
        .map((stop, index) => {
          const currentStop = overallSummaryForTrip?.stops?.filter(
            (item) => item.stopId === stop.locationId,
          );

          return (
            <div className="relative flex" key={stop.id}>
              <div className="flex flex-col items-center ml-2.5">
                {renderIcon(index)}
                {index < stops.length - 1 && (
                  <div className="absolute top-10">
                    <DashedLine
                      statusId={currentTrip?.statusId}
                      height={getDashedLineHeight(index, currentStop)}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col w-full px-6 mb-3">
                <Stop
                  tripId={currentTrip.id}
                  stopId={index + 1}
                  stopTag={stop.stopTag}
                  isSourceOrDestination={stop.isSourceOrDestination}
                  stopName={stop.stopName}
                  stopAdress={stop.address}
                  tags={stop.tags}
                  statusId={currentTrip?.statusId}
                  locationId={stop.locationId}
                  isOrigin={stop.isOrigin}
                  isDestination={stop.isDestination}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default StopDetails;
