import React from 'react';

const VoltageIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M5.787 8.48h1.16v2.7c0 .4.493.587.76.287l2.84-3.227a.434.434 0 00-.327-.72H9.06v-2.7c0-.4-.493-.587-.76-.287L5.46 7.76a.434.434 0 00.327.72z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M7.98 14.667a6.667 6.667 0 100-13.334 6.667 6.667 0 000 13.334z"
        />
      </svg>
    </span>
  );
};

export default VoltageIcon;
