/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../Core/Components/CustomTable";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { locationColumns } from "./Data/LocationListData";
import {
  getAllLocationsV3,
  getLocationsV3,
} from "../../Redux/APIs/locationsAPI";
import LocationsModuleIcon from "../../Common/Svgs/LocationsModuleIcon";
import LocationListHeader from "./phase3/LocationListHeader";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import useDebounce from "../../Core/hooks/useDebounce";
import { getTableContainerHeight } from "../../Utils/getTableContainerHeight";
import DeleteLocationModal from "./phase3/DeleteLocationModal";
import { updateLocations } from "../../Redux/Slices/locations.slice";
import ToastMessageModal from "../Rates/ToastMessageModal";

const LOCAL_DEFAULT_LIMIT = 10;

function LocationList() {
  const dispatch = useDispatch();

  const { locationsList, deleteLocationModal, showToastMessage } = useSelector(
    (state) => state.locations,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const tableContainerRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    type: [],
  });

  const debounceSearch = useDebounce(search, 500);

  // fetch locations (with filters)
  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hubs = filters.hub;
    if (filters.type.length) filterObj.type = filters.type;

    dispatch(getLocationsV3(filterObj));
  }, [dispatch, currentPage, filters, debounceSearch]);

  // fetch user details and all the locations (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
    dispatch(getAllLocationsV3());
  }, [dispatch]);

  // close the toast message after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateLocations({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  return (
    <CustomLayout pageName="Locations">
      <div>
        <LocationListHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />

        <div
          className={`h-[calc(100vh-72px-69px-40px)] ${
            locationsList.result.length && "overflow-y-scroll"
          }`}
          ref={tableContainerRef}
        >
          <CustomTable
            // scrollX={0}
            // scrollY="518px"
            disableScroll
            columns={locationColumns}
            data={locationsList.result}
            emptyState={
              <LocationsModuleIcon size={16} className="text-dark-grey-text" />
            }
            emptyStateHeight={getTableContainerHeight(tableContainerRef)}
            rowClassName="group"
            pagination={{
              pageSize: LOCAL_DEFAULT_LIMIT,
              total: locationsList.totalRecords,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              current: currentPage,
              itemRender: (current, type, originalElement) => {
                const lastPage = locationsList.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>

      <DeleteLocationModal
        visible={deleteLocationModal?.visible}
        onCancel={() =>
          dispatch(
            updateLocations({
              key: "deleteLocationModal",
              value: {
                visible: false,
                location: {},
              },
            }),
          )
        }
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <ToastMessageModal
        visible={showToastMessage?.visible}
        onCancel={() =>
          dispatch(
            updateLocations({
              key: "showToastMessage",
              value: { visible: false, data: {} },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
}
export default LocationList;
