import React from "react";

const HubStationIcon = ({ className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        fill="none"
        viewBox="0 0 17 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M1.476 14.666h13.333"
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M2.11 14.666l.033-8.02c0-.406.193-.793.513-1.046l4.667-3.633a1.34 1.34 0 011.64 0l4.666 3.626c.327.254.514.64.514 1.053v8.02m-1.334-10l-.02-2H9.856m-1.047 8.667H7.476c-.553 0-1 .447-1 1v2.333H9.81v-2.333c0-.553-.446-1-1-1z"
        />
      </svg>
    </span>
  );
};

export default HubStationIcon;
