const getStatusDetails = (id) => {
  switch (id) {
    case 1:
      return {
        colors: 'checkIn',
        padding: 'primary',
        size: 'primary',
        width: 'maxContent',
        children: 'Check In',
      };
    case 2:
      return {
        colors: 'checkOut',
        padding: 'primary',
        size: 'primary',
        width: 'maxContent',
        children: 'Check Out',
      };
    case 5:
      return {
        colors: 'alert',
        padding: 'primary',
        size: 'primary',
        width: 'maxContent',
        children: 'out of geofence',
        prefix: 'outofgeofence',
      };
    default:
      return {
        colors: 'warning',
        padding: 'primary',
        size: 'primary',
        width: 'maxContent',
        children: 'Un-checked',
        prefix: 'unchecked',
      };
  }
};

export default getStatusDetails;
