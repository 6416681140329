/* eslint-disable no-promise-executor-return */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import axios from "axios";
import pLimit from "p-limit";

// phase 3

const limit = pLimit(1);
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const snapToRoad = async (
  locations,
  stops,
  setError = () => {},
  onProgress = () => {},
) => {
  let completed = 0;

  const limitedSnapToRoad = async (stop) => {
    console.log(completed, "check completed");

    const currentLocation = locations?.find(
      (location) => location.name === stop.location,
    );

    try {
      const response = await axios.get(
        `https://router.project-osrm.org/nearest/v1/driving/${currentLocation.long},${currentLocation.lat}`,
      );

      if (response.data.code === "Ok") {
        const waypoints = response.data.waypoints[0].location;
        return waypoints;
      }
    } catch (err) {
      setError(true);
      console.log(err, "Error in snap to road points");
      return [0, 0];
    } finally {
      completed += 1;
      onProgress(completed, stops.length);
    }
  };

  const promises = stops.map((stop, index) =>
    limit(() => delay(1000 * index).then(() => limitedSnapToRoad(stop))),
  );

  const result = await Promise.all(promises);
  return result;
};

export const fastestRoute = async (snapCoordinates, setError = () => {}) => {
  try {
    const routeCoordinates = snapCoordinates
      .map((point) => point.join(","))
      .join(";");

    const response = await axios.get(
      `https://router.project-osrm.org/route/v1/driving/${routeCoordinates}?steps=true&geometries=geojson&overview=full`,
    );

    if (response.data.code === "Ok") {
      const route = response.data.routes[0].geometry.coordinates.map(
        (coord) => {
          return [coord[1], coord[0]];
        },
      );

      return route;
    }
  } catch (err) {
    setError(true);
    console.log(err, "fastest route error");
  }
};

export default snapToRoad;
