import React from 'react';
import CustomTags from '../Core/Components/CustomTags';

const getTripStatus = (currentStatus) => {
  switch (currentStatus) {
    case 1:
      return (
        <CustomTags colors="tripOngoing" background="tripOngoing">
          <div className="flex items-center gap-x-2">Ongoing</div>
        </CustomTags>
      );
    case 2:
      return (
        <CustomTags colors="checkIn" background="checkIn">
          <div className="flex items-center gap-x-2">Upcoming</div>
        </CustomTags>
      );
    case 3:
      return (
        <CustomTags colors="tripCompleted" background="tripCompleted">
          <div className="flex items-center gap-x-2">Completed</div>
        </CustomTags>
      );
    default:
      return (
        <CustomTags colors="tripOngoing" background="tripOngoing">
          <div className="flex items-center gap-x-2">Ongoing</div>
        </CustomTags>
      );
  }
};

export default getTripStatus;
