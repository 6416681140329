import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import { LOCATION_TYPE } from "../../Location/Data";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import FilterItem from "../../../Core/Components/FilterItem";
import { paymentStatusFilterOptions, userTypeFilterOptions } from "./data";

const PaymentHeader = ({
  search,
  setSearch,
  filters,
  setFilters,
  setCurrentPage,
}) => {
  const { allLocations } = useSelector((state) => state.locations);

  // get all the hubs
  const hubFilters = allLocations
    .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const [focus, setFocus] = useState(false);

  const onSelect = (key, value) => {
    // whenever applying the filter, the page should be defaulted back to first page
    setCurrentPage(1);

    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  return (
    <div className="bg-background-white">
      <div className="px-6 py-3.5 flex items-center justify-between border-b border-light-grey">
        <div className="flex items-center gap-x-3">
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Users"}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />
          <div className="flex items-center">
            <FilterItem
              label="Hub Station"
              identifier="hub"
              contentData={hubFilters}
              onSelect={onSelect}
              selectedValues={filters.hub}
            />

            <FilterItem
              label="User Type"
              identifier="userType"
              customValue
              className="max-h-max"
              contentData={userTypeFilterOptions}
              onSelect={onSelect}
              selectedValues={filters.userType}
            />

            <FilterItem
              label="Payment Status"
              identifier="paymentStatus"
              customValue
              className="max-h-max"
              contentData={paymentStatusFilterOptions}
              onSelect={onSelect}
              selectedValues={filters.paymentStatus}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHeader;
