import React from "react";
import EditIcon from "../../../Common/Svgs/EditIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const useLocationThreeDotsOptions = () => {
  const threeDotsOptions = [
    {
      id: 1,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.LOCATION_EDIT]),
    },

    {
      id: 2,
      value: "Delete Location",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.LOCATION_DELETE]),
    },
  ];

  return threeDotsOptions;
};

export default useLocationThreeDotsOptions;
