import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import UserSettings from "./UserSettings";
import SettingsHeader from "../SettingsHeader";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";

const SystemUsers = () => {
  const dispatch = useDispatch();

  const { toastMessageModal } = useSelector((state) => state.loggedInUser);

  // clear the toast message
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateLoggedInUser({
          key: "toastMessageModal",
          value: { visible: false, title: "", description: "" },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, toastMessageModal]);

  return (
    <CustomLayout pageName="Settings">
      <div className="w-full h-full bg-background-white">
        <SettingsHeader />

        <UserSettings />
      </div>
    </CustomLayout>
  );
};

export default SystemUsers;
