// export const chargerCurrentOptions = [
//   // id = 1 corresponds to canbus 10 amps
//   {
//     id: 1,
//     value: "10 Amps",
//   },
//   {
//     id: 2,
//     value: "10 Amps (1 Battery)",
//   },
//   {
//     id: 3,
//     value: "12 Amps (1 Battery)",
//   },
//   {
//     id: 4,
//     value: "15 Amps (1-2 Batteries)",
//   },
//   {
//     id: 5,
//     value: "20 Amps (1-3 Batteries)",
//   },
// ];
export const getCurrentType = (currentId) => {
  switch (currentId) {
    case 1:
      return "10 Amps";
    case 2:
      return "10 Amps (1 Battery)";
    case 3:
      return "12 Amps (1 Battery)";
    case 4:
      return "15 Amps (1-2 Batteries)";
    case 5:
      return "20 Amps (1-3 Batteries)";
    default:
      return "10 Amps";
  }
};
