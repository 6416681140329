import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../Core/Components/Modal/CustomModal';
import CloseIcon from '../../../Common/Svgs/CloseIcon';
import Button from '../../../Core/Components/CustomButton';
import AlertIcon from '../../../Common/Svgs/AlertIcon';
import { resetTrips } from '../../../Redux/Slices/trips.slice';

const CancelTripModal = ({ visible, onCancel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onConfirm = () => {
    onCancel();
    navigate('/trips');
    dispatch(resetTrips(['tripDetails']));
    dispatch(resetTrips(['stops']));
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-xl">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center w-12 h-12 rounded-full bg-warning-10">
            <AlertIcon size={24} className="text-warning" />
          </div>
          <button className="p-2" type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-black font-poppins">
            End process
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to end this process?
          </p>
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button
            colors="cancelButton"
            size="primary"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button colors="primary" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default CancelTripModal;
