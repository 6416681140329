import React from 'react';
import CustomModal from '../../../Core/Components/Modal/CustomModal';
import OptimisingRouteIcon from '../../../Common/Svgs/OptimisingRouteIcon';

const OptimisingRouteModal = ({ visible, onCancel }) => {
  return (
    <CustomModal
      className="optimise-modal"
      visible={visible}
      onCancel={onCancel}
      width="536px"
      centered
    >
      <div className="p-4 rounded-xl bg-background-white">
        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-kpi-background">
          <OptimisingRouteIcon className="text-kpi-icon" />
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-black font-poppins">
            Optimising your route
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Hold on. This will just take a moment...
          </p>
        </div>
      </div>
    </CustomModal>
  );
};

export const SnapToRoadModal = ({ visible, onCancel }) => {
  return (
    <CustomModal
      className="optimise-modal"
      visible={visible}
      onCancel={onCancel}
      width="357px"
      centered
    >
      <div className="p-4 rounded-xl bg-background-white">
        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-kpi-background">
          <OptimisingRouteIcon className="text-kpi-icon" />
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-black font-poppins">
            Plotting your route on the map
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Hold on. This will just take a moment...
          </p>
        </div>
      </div>
    </CustomModal>
  );
};

export default OptimisingRouteModal;
