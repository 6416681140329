import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CustomLayout from "../../Core/Layout/CustomLayout";
import Header from "./Header";
import PaymentList from "./PaymentList/PaymentList";
import CollectionList from "./CollectionList/CollectionList";

const RevenueList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTab = searchParams.get("tab");

  useEffect(() => {
    if (!searchParams.has("tab")) {
      searchParams.set("tab", "payments");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return (
    <CustomLayout pageName="Revenue Manager">
      <Header />

      {selectedTab === "payments" ? <PaymentList /> : <CollectionList />}
    </CustomLayout>
  );
};

export default RevenueList;
