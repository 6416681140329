/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../Core/Components/CustomTable";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import { KPIData } from "../Data/TrikesListData";
import CustomKPI from "../../../Core/Components/CustomKpi";
import {
  THREE_DOTS,
  TRIKES_HUB_STATION,
  TRIKES_ID_NUMBER,
  allColumns,
} from "../Data/TrikesColumnConstants";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import {
  getChecklist,
  getTrikesKPI,
  getTrikesV3,
} from "../../../Redux/APIs/trikesAPI";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import TrikesListHeader from "./TrikesListHeader";
import QRCodeModal from "../../../Core/Components/Modal/QRCodeModal";
import { updateQrCode } from "../../../Redux/Slices/qrcode.slice";
import ToastMessageModal from "./ToastMessageModal";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import ChecklistModal from "../../../Core/Components/Modal/ChecklistModal";
import MobiliseModalV3 from "../../../Core/Components/Modal/MobiliseModalV3";
import DeleteTrikeModal from "./DeleteTrikeModal";
import TrikeTrackerModal from "./TrikeTrackerModal";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import { getUserByEmail } from "../../../Redux/APIs/systemUsersAPI";
import SelectBatteriesModal from "./SelectBatteriesModal";
import MoveToHubStationModal from "./MoveToHubStationModal";
import useDebounce from "../../../Core/hooks/useDebounce";
import { getAllLocationsV3 } from "../../../Redux/APIs/locationsAPI";
import { getAllBatteriesV3 } from "../../../Redux/APIs/batteriesAPI";

function TrikesList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    selectedColumns,
    selectedRowIds,
    trikeListV3,
    showToastMessage,
    kpiData,
    checklistModal,
    mobiliseModalV3,
    deleteTrikeModal,
    trikeTrackerModal,
    selectBatteryModal,
    moveToHubStationModal,
  } = useSelector((state) => state.trikes);

  const { viewModal } = useSelector((state) => state.qrCode);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  // service permission
  const hasServicePermission = usePermission([
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SERVICE_VIEW_AND_EDIT,
  ]);

  // mobilise permission
  const hasMobilisePermission = usePermission([PERMISSIONS.TRIKE_MOBILISE]);

  // tracker permission
  const hasTrackerPermission = usePermission([PERMISSIONS.TRACKER_SWITCH]);

  const [showKpi, setShowKpi] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    battery: [],
    status: [],
    toggle: [],
    dateRangeStart: "",
    dateRangeEnd: "",
    addedBy: [],
    driver: [],
    distance: "",
    mileageStart: "",
    mileageEnd: "",
    averageSpeedStart: "",
    averageSpeedEnd: "",
  });

  const debounceSearch = useDebounce(search, 500);

  const handleMoreColumns = () => {
    let localColumns = [...selectedColumns];

    // Check for mobilise permission
    if (hasMobilisePermission) {
      localColumns = [...new Set([...localColumns, "TRIKES_ON_OFF"])];
    } else {
      localColumns = localColumns.filter((item) => item !== "TRIKES_ON_OFF");
    }

    // Check for tracker permission
    if (hasTrackerPermission) {
      localColumns = [...new Set([...localColumns, "TRIKES_TRACKER"])];
    } else {
      localColumns = localColumns.filter((item) => item !== "TRIKES_TRACKER");
    }

    // Dispatch once with the final columns
    dispatch(
      updateTrikes({
        key: "selectedColumns",
        value: localColumns,
      }),
    );
  };

  const handleRowClick = (record) => {
    dispatch(
      updateTrikes({
        key: "currentTrike",
        value: trikeListV3.result.find((item) => item.id === record.id),
      }),
    );
    navigate(`/trikes/${record.registrationNo}`);
  };

  useEffect(() => {
    console.log(window.ResizeObserver.prototype);
  }, [window]);

  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
      limit: DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hubs = filters.hub;
    if (filters.battery.length) filterObj.batteryPercentage = filters.battery;
    if (filters.status.length) filterObj.status = filters.status;
    if (filters.toggle.length) filterObj.toggle = filters.toggle;
    if (filters.dateRangeStart && filters.dateRangeEnd) {
      filterObj.dateRangeStart = filters.dateRangeStart;
      filterObj.dateRangeEnd = filters.dateRangeEnd;
    }
    if (filters.addedBy.length) {
      filterObj.addedBy = filters.addedBy.map((user) => user.id);
    }
    if (filters.driver.length) {
      filterObj.drivers = filters.driver.map((driver) => driver.id);
    }
    if (filters.distance) filterObj.distance = filters.distance;
    if (filters.mileageStart && filters.mileageEnd) {
      filterObj.mileageStart = filters.mileageStart;
      filterObj.mileageEnd = filters.mileageEnd;
    }
    if (filters.averageSpeedStart && filters.averageSpeedEnd) {
      filterObj.averageSpeedStart = filters.averageSpeedStart;
      filterObj.averageSpeedEnd = filters.averageSpeedEnd;
    }

    dispatch(getTrikesV3(filterObj));
  }, [dispatch, debounceSearch, filters, currentPage]);

  useEffect(() => {
    dispatch(getTrikesKPI());
    dispatch(getChecklist());
  }, [dispatch]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateTrikes({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  // to handle mobilise and tracker column updation
  useEffect(() => {
    handleMoreColumns();
  }, [hasMobilisePermission, hasTrackerPermission]);

  // fetch user details and all locations (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
    dispatch(getAllLocationsV3());
    dispatch(getAllBatteriesV3());
  }, []);

  return (
    <CustomLayout pageName="Trikes">
      <div className="relative bg-background">
        <div className="flex items-center justify-between w-full h-40 p-6 gap-x-5.5">
          {KPIData(kpiData)?.map((i) => (
            <CustomKPI
              key={i?.id}
              image={i?.image}
              title={i?.title}
              count={i?.count}
              total={i?.total}
              icon={hasServicePermission && i.count > 0 && i?.icon}
              background={i?.background}
              handleOnClick={i.onClick}
              redirect={hasServicePermission && i?.id === 3 && i.count > 0}
            />
          ))}
        </div>

        <div
          className={`absolute w-[calc(100vw-72px)] ${
            showKpi ? "-translate-y-[160px]" : ""
          }  transition-transform duration-500`}
        >
          <TrikesListHeader
            showKpi={showKpi}
            setShowKpi={setShowKpi}
            search={search}
            setSearch={setSearch}
            filters={filters}
            setFilters={setFilters}
            setCurrentPage={setCurrentPage}
          />

          <CustomTable
            scrollX="600px"
            // trike id number will always be the first column,
            // other columns (as selected from the dropdown will be added on the right)
            // three dots columns will always be the last column
            columns={[
              TRIKES_ID_NUMBER,
              TRIKES_HUB_STATION,
              ...allColumns
                .filter((item) => selectedColumns.includes(item.key))
                .map((column) => column.value),
              THREE_DOTS,
            ]}
            emptyState={<TrikeIcon size={16} className="text-dark-gray" />}
            data={trikeListV3.result}
            onRowClick={handleRowClick}
            rowClassName={(record) => {
              return selectedRowIds.includes(record.id)
                ? "selected-row group cursor-pointer"
                : "group cursor-pointer";
            }}
            pagination={{
              pageSize: DEFAULT_LIMIT,
              total: trikeListV3.totalRecords,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              current: currentPage,
              itemRender: (current, type, originalElement) => {
                const lastPage = trikeListV3.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }
                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>

      <MobiliseModalV3
        visible={mobiliseModalV3?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "mobiliseModalV3",
              value: {
                visibe: false,
                trike: {},
              },
            }),
          )
        }
      />

      <QRCodeModal
        visible={viewModal?.visible}
        type={viewModal?.data?.assetType}
        onCancel={() =>
          dispatch(
            updateQrCode({
              key: "viewModal",
              value: { visible: false, data: {} },
            }),
          )
        }
      />

      <ChecklistModal
        visible={checklistModal?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "checklistModal",
              value: { visible: false, checklist: [] },
            }),
          )
        }
      />

      <DeleteTrikeModal
        visible={deleteTrikeModal?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "deleteTrikeModal",
              value: {
                visible: false,
                id: 0,
                registrationNo: "",
              },
            }),
          )
        }
        trikeCurrentPage={currentPage}
        setTrikeCurrentPage={setCurrentPage}
      />

      <TrikeTrackerModal
        visible={trikeTrackerModal?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "trikeTrackerModal",
              value: {
                visible: false,
                trikeId: 0,
                trikeRegistrationNo: "",
                currentSource: 1,
              },
            }),
          )
        }
        page="trike-listing"
        currentPage={currentPage}
      />

      <SelectBatteriesModal
        visible={selectBatteryModal?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "selectBatteryModal",
              value: { visible: false, trike: {} },
            }),
          )
        }
        currentPage={currentPage}
      />

      <MoveToHubStationModal
        visible={moveToHubStationModal?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "moveToHubStationModal",
              value: { visible: false, trike: {} },
            }),
          )
        }
        currentPage={currentPage}
      />

      <ToastMessageModal
        visible={showToastMessage?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "showToastMessage",
              value: { visible: false, data: {} },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
        icon={showToastMessage?.data?.icon}
      />
    </CustomLayout>
  );
}
export default TrikesList;
