import React from "react";

const AlertIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <g clipPath="url(#clip0_514_42891)">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.333"
            d="M8 5.333V8m0 2.667h.007M14.667 8A6.667 6.667 0 111.333 8a6.667 6.667 0 0113.334 0z"
          />
        </g>
        <defs>
          <clipPath id="clip0_514_42891">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export const AlertTriangleIcon = ({ size = 16, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        // stroke="#C84630"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 14.274H3.96c-2.313 0-3.28-1.654-2.16-3.674l2.08-3.746 1.96-3.52c1.187-2.14 3.134-2.14 4.32 0l1.96 3.526 2.08 3.747c1.12 2.02.147 3.673-2.16 3.673H8v-.006zM8 6v3.333"
      />
      <path
        // stroke="#C84630"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.996 11.333h.006"
      />
    </svg>
  );
};

export const AlertSecondaryIcon = ({ size = 17, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 17 17"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.334 6.094v3.5M14.387 6.648v4.56c0 .747-.4 1.44-1.047 1.82l-3.96 2.287a2.109 2.109 0 01-2.1 0l-3.96-2.287a2.1 2.1 0 01-1.047-1.82v-4.56c0-.746.4-1.44 1.047-1.82l3.96-2.286a2.109 2.109 0 012.1 0l3.96 2.286a2.108 2.108 0 011.047 1.82z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8.334 11.729v.066"
        />
      </svg>
    </span>
  );
};

export default AlertIcon;
