/* eslint-disable import/no-cycle */
/* eslint-disable radix */
/* eslint-disable no-case-declarations */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Popover } from "antd";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import useRoleThreeDots from "./useRoleThreeDots";

const RoleThreeDots = ({ role }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const threeDotsOptions = useRoleThreeDots().filter(
    (item) => item.hasPermission,
  );

  const onOptionClick = async (optionId) => {
    switch (optionId) {
      case 1:
        dispatch(
          updateLoggedInUser({
            key: "currentRole",
            value: role,
          }),
        );

        navigate(`/settings/roles-and-permissions/edit-role/${role.id}`);

        break;

      case 2:
        dispatch(
          updateLoggedInUser({
            key: "currentRole",
            value: role,
          }),
        );

        dispatch(
          updateLoggedInUser({
            key: "duplicateRole",
            value: true,
          }),
        );

        navigate("/settings/roles-and-permissions/create-role");

        break;

      case 3:
        setOpen(false);

        dispatch(
          updateLoggedInUser({
            key: "deleteUserRoleModal",
            value: {
              visible: true,
              role,
            },
          }),
        );

        break;

      default:
        break;
    }
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div
          className="rounded-md bg-background-white"
          style={{
            width: "156px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
            paddingTop: threeDotsOptions.length && "4px",
            paddingBottom: threeDotsOptions.length && "4px",
          }}
        >
          {threeDotsOptions.map((option) => (
            <button
              type="button"
              className={`w-full flex items-center gap-x-3 px-3 py-2 group
              ${
                option.id === 3
                  ? "hover:bg-light-alert-red-hover"
                  : "hover:bg-background"
              }
              `}
              key={option.id}
              onClick={() => onOptionClick(option.id)}
            >
              {option.icon}
              <p
                className={`font-aileron text-xs font-semibold capitalize ${
                  option.id === 3
                    ? "text-alert-red group-hover:text-alert-red-hover"
                    : "text-dark-gray group-hover:text-black"
                }`}
              >
                {option.value}
              </p>
            </button>
          ))}
        </div>
      }
    >
      <div className="cursor-pointer">
        <img src="/assets/icons/trips/more.svg" alt="dropdown" />
      </div>
    </Popover>
  );
};

export default RoleThreeDots;
