export const CHARGER_TYPE = {
  CANBUS: 1,
  NON_CANBUS: 2,
};

export const CHARGER_STATUS = {
  AVAILABLE: 1,
  CHARGING: 2,
};

export const CHARGER_CURRENT = {
  CANBUS_10_AMPS: 1,
  NON_CANBUS_10_AMPS: 2,
  NON_CANBUS_12_AMPS: 3,
  NON_CANBUS_15_AMPS: 4,
  NON_CANBUS_20_AMPS: 5,
};

export const chargerTypeFilters = [
  {
    id: 1,
    value: "Canbus",
  },
  {
    id: 2,
    value: "Non-Canbus",
  },
];

export const chargerCurrentOptions = [
  // id = 1 corresponds to canbus 10 amps
  {
    id: 1,
    value: "10 Amps",
  },
  {
    id: 2,
    value: "10 Amps (1 Battery)",
  },
  {
    id: 3,
    value: "12 Amps (1 Battery)",
  },
  {
    id: 4,
    value: "15 Amps (1-2 Batteries)",
  },
  {
    id: 5,
    value: "20 Amps (1-3 Batteries)",
  },
];
