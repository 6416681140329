/* eslint-disable no-lonely-if */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Checkbox } from "antd";
import { ratePermissions, revenuePermissions } from "./data";
import PermissionTitle from "./PermissionTitle";
import ModuleHeader from "./ModuleHeader";
import { PERMISSIONS } from "../../Login/constants";
import { rateAddEditDelete, revenueModifyPayment } from "./permissionFunctions";
import { FinanceHoverIcon } from "../../../Common/Svgs/FinanceIcon";

const CONDITIONS = {
  REVENUE_VIEW_AND_EDIT: [
    PERMISSIONS.REVENUE_VIEW,
    PERMISSIONS.REVENUE_NO_ACCESS,
  ],
  REVENUE_REMOVE_ALL: [
    PERMISSIONS.REVENUE_MODIFY_PAYMENT,
    PERMISSIONS.REVENUE_NO_MODIFY_PAYMENT,
    PERMISSIONS.REVENUE_VIEW,
    PERMISSIONS.REVENUE_VIEW_AND_EDIT,
    PERMISSIONS.REVENUE_NO_ACCESS,
  ],
  RATE_VIEW_AND_EDIT: [PERMISSIONS.RATE_VIEW, PERMISSIONS.RATE_NO_ACCESS],
  RATE_REMOVE_ALL: [
    PERMISSIONS.RATE_ADD,
    PERMISSIONS.RATE_EDIT,
    PERMISSIONS.RATE_DELETE,
    PERMISSIONS.RATE_VIEW,
    PERMISSIONS.RATE_VIEW_AND_EDIT,
    PERMISSIONS.RATE_NO_ACCESS,
  ],
};

const FinancePermissions = ({ permissions, setPermissions }) => {
  // check if the permissions needs to be disabled for driver
  const disabledRevenue = CONDITIONS.REVENUE_VIEW_AND_EDIT.some((d) =>
    permissions.includes(d),
  );

  // check if the permissions needs to be disabled for farmer
  const disabledRate = CONDITIONS.RATE_VIEW_AND_EDIT.some((d) =>
    permissions.includes(d),
  );

  const handleModulePermission = (permissionId) => {
    let local = [];

    // revenue module permissions
    if (permissionId === PERMISSIONS.REVENUE_VIEW_AND_EDIT) {
      local = permissions.filter(
        (item) => !CONDITIONS.REVENUE_VIEW_AND_EDIT.includes(item),
      );
    }

    // removing all the revenue permissions
    if (permissionId === PERMISSIONS.REVENUE_VIEW) {
      local = permissions.filter(
        (item) => !CONDITIONS.REVENUE_REMOVE_ALL.includes(item),
      );
    }

    // removing all the revenue permissions
    if (permissionId === PERMISSIONS.REVENUE_NO_ACCESS) {
      local = permissions.filter(
        (item) => !CONDITIONS.REVENUE_REMOVE_ALL.includes(item),
      );
    }

    // rate module permissions
    if (permissionId === PERMISSIONS.RATE_VIEW_AND_EDIT) {
      local = permissions.filter(
        (item) => !CONDITIONS.RATE_VIEW_AND_EDIT.includes(item),
      );
    }

    // removing all the rate permissions
    if (permissionId === PERMISSIONS.RATE_VIEW) {
      local = permissions.filter(
        (item) => !CONDITIONS.RATE_REMOVE_ALL.includes(item),
      );
    }

    // removing all the rate permissions
    if (permissionId === PERMISSIONS.RATE_NO_ACCESS) {
      local = permissions.filter(
        (item) => !CONDITIONS.RATE_REMOVE_ALL.includes(item),
      );
    }

    setPermissions([...local, permissionId]);
  };

  const handleIndividualPermission = (permissionId) => {
    let local = [];

    if (permissions.includes(permissionId)) {
      local = permissions.filter((item) => item !== permissionId);
      setPermissions(local);
    } else {
      if (
        permissionId === PERMISSIONS.REVENUE_MODIFY_PAYMENT ||
        permissionId === PERMISSIONS.REVENUE_NO_MODIFY_PAYMENT
      ) {
        // handling either modify or don't modify payment
        revenueModifyPayment({
          current: permissionId,
          permissions,
          setPermissions,
        });
      } else if (permissionId === PERMISSIONS.RATE_ADD) {
        // handling multiple insertions for rate add, edit, delete
        rateAddEditDelete({
          setPermissions,
        });
      } else {
        setPermissions((prev) => [...prev, permissionId]);
      }
    }
  };

  return (
    <div>
      <ModuleHeader
        module="Finance"
        icon={<FinanceHoverIcon size={16} className="text-primary" />}
      />
      {/* main module permission for revenue manager section */}
      <div className="w-full px-6 py-2 flex items-center gap-x-2 font-poppins text-xs font-medium bg-background border-b border-light-grey">
        <p>A.</p>
        <p>Revenue Manager</p>
      </div>

      <div className="w-full h-[60px]">
        <div
          className="flex items-center w-full h-full border-b"
          style={{
            background: "#FAF2D8",
            borderColor: "#FAD152",
          }}
        >
          <div
            className="w-[40%] border-r py-3 px-6"
            style={{
              borderColor: "#FAD152",
            }}
          >
            <PermissionTitle
              title={revenuePermissions[0].title}
              description={revenuePermissions[0].description}
            />
          </div>

          <div className="w-[60%] h-full flex items-center">
            {revenuePermissions[0].options.map((option) => {
              // rendering empty div for layout consistency
              if (!option) {
                return (
                  <div
                    className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                    style={{
                      borderColor: "#FAD152",
                    }}
                  />
                );
              }

              const selected = permissions.includes(option.permissionId);

              return (
                <div
                  key={option.text}
                  className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                  style={{
                    borderColor: "#FAD152",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    onClick={() => handleModulePermission(option.permissionId)}
                  />
                  <p
                    className={`font-poppins text-xs ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {option.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* individual functionality permission for revenue manager section */}
      <div className="w-full">
        {revenuePermissions.slice(1).map((item) => {
          return (
            <div
              key={item.id}
              className="flex items-center w-full h-[60px] border-b"
              style={{
                borderColor: "#DFDFDF",
              }}
            >
              <div
                className="w-[40%] h-full border-r py-3 px-6"
                style={{
                  borderColor: "#DFDFDF",
                }}
              >
                <PermissionTitle
                  title={item.title}
                  description={item.description}
                />
              </div>

              <div className="w-[60%] h-full flex items-center">
                {item.options.map((option, idx) => {
                  // rendering empty div for layout consistency
                  if (!option) {
                    return (
                      <div
                        key={idx}
                        className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                        style={{
                          borderColor: "#DFDFDF",
                        }}
                      />
                    );
                  }

                  const selected = permissions.includes(option.permissionId);

                  // if the permission is view only, remove pointer events and add an opacity
                  return (
                    <div
                      key={option.text}
                      className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                      style={{
                        borderColor: "#DFDFDF",
                        opacity: disabledRevenue && 0.5,
                        pointerEvents: disabledRevenue && "none",
                      }}
                    >
                      {/* if the permissions should be disabled then it should not
                          show any tick mark else show the selected tick mark */}
                      <Checkbox
                        checked={disabledRevenue ? false : selected}
                        onClick={() =>
                          handleIndividualPermission(option.permissionId)
                        }
                      />
                      <p
                        className={`font-poppins text-xs
                            ${selected ? "font-medium" : "font-normal"}
                            `}
                      >
                        {option.text}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {/* main module permission for rate manager module */}
      <div className="w-full px-6 py-2 flex items-center gap-x-2 font-poppins text-xs font-medium bg-background border-b border-light-grey">
        <p>B.</p>
        <p>Rate Manager</p>
      </div>
      <div className="w-full h-[60px]">
        <div
          className="flex items-center w-full h-full border-b"
          style={{
            background: "#FAF2D8",
            borderColor: "#FAD152",
          }}
        >
          <div
            className="w-[40%] border-r py-3 px-6"
            style={{
              borderColor: "#FAD152",
            }}
          >
            <PermissionTitle
              title={ratePermissions[0].title}
              description={ratePermissions[0].description}
            />
          </div>

          <div className="w-[60%] h-full flex items-center">
            {ratePermissions[0].options.map((option) => {
              // rendering empty div for layout consistency
              if (!option) {
                return (
                  <div
                    className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                    style={{
                      borderColor: "#FAD152",
                    }}
                  />
                );
              }

              const selected = permissions.includes(option.permissionId);

              return (
                <div
                  key={option.text}
                  className="w-[25%] border-r px-6 h-full flex items-center gap-x-2"
                  style={{
                    borderColor: "#FAD152",
                  }}
                >
                  <Checkbox
                    checked={selected}
                    onClick={() => handleModulePermission(option.permissionId)}
                  />
                  <p
                    className={`font-poppins text-xs ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {option.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* individual functionality permission for rate manager module */}
      <div className="w-full">
        {ratePermissions.slice(1).map((item) => {
          return (
            <div
              key={item.id}
              className="flex items-center w-full h-[60px] border-b"
              style={{
                borderColor: "#DFDFDF",
              }}
            >
              <div
                className="w-[40%] h-full border-r py-3 px-6"
                style={{
                  borderColor: "#DFDFDF",
                }}
              >
                <PermissionTitle
                  title={item.title}
                  description={item.description}
                />
              </div>

              <div className="w-[60%] h-full flex items-center">
                {item.options.map((option, idx) => {
                  // rendering empty div for layout consistency
                  if (!option) {
                    return (
                      <div
                        key={idx}
                        className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                        style={{
                          borderColor: "#DFDFDF",
                        }}
                      />
                    );
                  }

                  const selected = permissions.includes(option.permissionId);

                  // if the permission is view only, remove pointer events and add an opacity
                  return (
                    <div
                      key={option.text}
                      className="w-[25%] h-full border-r px-6 flex items-center gap-x-2"
                      style={{
                        borderColor: "#DFDFDF",
                        opacity: disabledRate && 0.5,
                        pointerEvents: disabledRate && "none",
                      }}
                    >
                      {/* if the permissions should be disabled then it should not
                          show any tick mark else show the selected tick mark */}
                      <Checkbox
                        checked={disabledRate ? false : selected}
                        onClick={() =>
                          handleIndividualPermission(option.permissionId)
                        }
                      />
                      <p
                        className={`font-poppins text-xs
                            ${selected ? "font-medium" : "font-normal"}
                            `}
                      >
                        {option.text}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FinancePermissions;
