import React from "react";

const PermissionTitle = ({ title, description }) => {
  return (
    <div>
      <p className="font-poppins text-xs font-medium">{title}</p>
      <p className="mt-1 font-aileron text-xs font-normal text-dark-gray">
        {description}
      </p>
    </div>
  );
};

export default PermissionTitle;
