/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import SettingsIcon, {
  ActiveSettingsIcon,
} from "../../../Common/Svgs/SettingsIcon";
import getThemeColor from "../../../Utils/getThemeColor";

const SettingsItem = ({ sidebarActive, pageName }) => {
  const navigate = useNavigate();

  const { theme } = useSelector((state) => state.loggedInUser);

  return (
    <div
      role="button"
      className="flex items-center gap-x-2 mt-4 py-2 px-6 cursor-pointer w-full group mx-auto"
      onClick={() => navigate("/settings")}
    >
      <motion.div
        // layout={subMenu === SUBMENU.DEFAULT}
        className="w-5 h-5"
      >
        {pageName === "Settings" ? (
          <ActiveSettingsIcon
            size={20}
            className="text-sidebar-active"
            centerColor={getThemeColor(theme)}
          />
        ) : (
          <SettingsIcon className="w-5 h-5 stroke-sidebar-base group-hover:stroke-sidebar-active" />
        )}
      </motion.div>

      {sidebarActive && (
        <motion.p
          // layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className={` font-aileron font-semibold text-xs group-hover:text-sidebar-active pt-1 ${
            pageName === "Settings"
              ? "text-sidebar-active"
              : "text-sidebar-base"
          }
      `}
        >
          Settings
        </motion.p>
      )}
    </div>
  );
};

export default SettingsItem;
