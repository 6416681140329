/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  deleteUser,
  getAllSystemUsers,
  getMetaData,
  getSystemRoles,
  getSystemUsers,
  getUserByEmail,
  updateMetaData,
  updateUser,
} from "../APIs/systemUsersAPI";

const initialState = {
  loading: false,
  userDetails: {},
  locationDetails: {
    temperature: 20,
    city: "Kisii",
    countryCode: "KE",
  },
  theme: "green-theme",
  companyLogo:
    "https://smp-content.s3.eu-west-3.amazonaws.com/platform-content/songa_logo.png",

  settingsTab: "general",
  deletingUserModal: false,
  savingChangesModal: false,

  toastMessageModal: {
    visible: false,
    title: "",
    description: "",
  },

  systemUsers: {
    result: [],
    totalRecords: 0,
  },

  allSystemUsers: [],

  // phase 3
  selectedUser: {},
  systemRoles: [],
  currentRole: {},
  duplicateRole: false,
  deleteSystemUserModal: {
    visible: false,
    user: null,
  },
  deleteUserRoleModal: {
    visible: false,
    role: null,
  },
};

export const loggedInUserSlice = createSlice({
  name: "loggedInUser",
  initialState,
  reducers: {
    updateLoggedInUser: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    resetLoggedInUser: (state) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key]),
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(getSystemUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSystemUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.systemUsers = action.payload;
    });
    builder.addCase(getSystemUsers.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get all system users
    builder.addCase(getAllSystemUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllSystemUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.allSystemUsers = action.payload.data;
    });
    builder.addCase(getAllSystemUsers.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // update user details
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // update meta data
    builder.addCase(updateMetaData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMetaData.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(updateMetaData.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get meta details
    builder.addCase(getMetaData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMetaData.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";

      state.theme = action.payload.theme;
      state.companyLogo = action.payload.company_logo;
    });
    builder.addCase(getMetaData.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // get user by email
    builder.addCase(getUserByEmail.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUserByEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.userDetails = action.payload.user;
    });

    builder.addCase(getUserByEmail.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // delete user
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get system user roles
    builder.addCase(getSystemRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSystemRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.systemRoles = action.payload.roles;
    });
    builder.addCase(getSystemRoles.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { updateLoggedInUser, resetLoggedInUser } =
  loggedInUserSlice.actions;

export default loggedInUserSlice.reducer;
