/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import { Checkbox, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import CustomInput from "../../../Core/Components/CustomInput";
import { LOCATION_TYPE } from "../../Location/Data";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import AlertIcon from "../../../Common/Svgs/AlertIcon";
import { InfoCircleSecondaryIcon } from "../../../Common/Svgs/InfoCircleIcon";
import { client } from "../../../Utils/axiosClient";
import {
  getSystemUsers,
  getUserByEmail,
} from "../../../Redux/APIs/systemUsersAPI";
import { PERMISSIONS, SEED_ROLES } from "../../Login/constants";
import usePermission from "../../../Core/hooks/usePermission";

const getHubStationText = ({ allHubs, detail }) => {
  if (allHubs.length === detail.hubs.length) return "All Hub Stations";
  if (detail.hubs.length === 1) return "1 Hub Station";
  return `${detail.hubs.length} Hub Stations`;
};

const ShowOnlyHubStation = ({ allHubs, detail }) => {
  return (
    <div className="flex items-center gap-x-2">
      <p className="font-aileron text-xs font-semibold text-primary capitalize">
        {getHubStationText({ allHubs, detail })}
      </p>
    </div>
  );
};

const AssignHubStationDropdown = ({ detail }) => {
  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const { allHubs } = useSelector((state) => state.locations);

  // check if the current user is a super admin
  const isSuperAdmin = detail.roleId === SEED_ROLES.SUPER_ADMIN;

  // check if permission exists to edit the role of the users
  const hasPermission = usePermission([PERMISSIONS.SETTINGS_SYSTEM_USER_EDIT]);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedHubs, setSelectedHubs] = useState(detail.hubs || []);

  const filteredData = () => {
    return allHubs.filter((hub) =>
      hub?.name?.toLowerCase().includes(search.toLowerCase()),
    );
  };

  const handleClick = async (hub) => {
    const isHubSelected = selectedHubs.includes(hub.id);

    const payload = {
      userId: detail.id,
      hubId: hub.id,
      roleId: detail.roleId,
    };

    try {
      // remove the user-hub entry
      if (isHubSelected) {
        await client.post(
          `${process.env.REACT_APP_API_URL}/auth/remove-hub`,
          payload,
        );
      }
      // add a new user-hub entry
      else {
        await client.post(
          `${process.env.REACT_APP_API_URL}/auth/assign-hub`,
          payload,
        );
      }

      // update the state
      setSelectedHubs((prev) =>
        isHubSelected
          ? prev.filter((item) => item !== hub.id)
          : [...prev, hub.id],
      );
    } catch (error) {
      console.log("Error:", error);
    } finally {
      // refetch the user details
      if (detail.id === userDetails.userId) {
        dispatch(getUserByEmail({ email: detail.email }));
      }
      dispatch(getSystemUsers());
    }
  };

  // show only the hub station count if the current user (detail) is a super admin or if the logged in user does not have the permission
  if (isSuperAdmin || !hasPermission)
    return <ShowOnlyHubStation allHubs={allHubs} detail={detail} />;

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1 max-h-[200px] overflow-y-scroll"
          style={{
            maxWidth: "250px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className="px-1">
            <CustomInput
              intent="searchHome"
              placeholder="Search Role"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {filteredData().map((hub) => {
            const selected = selectedHubs.includes(hub.id);

            return (
              <button
                type="button"
                className="w-full flex items-center justify-between gap-x-3 px-3 py-2 group hover:bg-background"
                key={hub.id}
                onClick={() => handleClick(hub)}
              >
                <div className="flex items-center gap-x-2">
                  <Checkbox checked={selected} />

                  <p
                    className="font-aileron text-xs font-semibold capitalize group-hover:text-black"
                    style={{
                      color: selected ? "#000" : "#525252",
                    }}
                  >
                    {hub.name}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
      }
    >
      {!detail.hubs.length ? (
        <button type="button" className="flex items-center gap-x-2">
          <InfoCircleSecondaryIcon />

          <p className="font-aileron text-xs font-semibold text-yellow-dark capitalize">
            Assign hub station
          </p>

          <ChevronIcon
            strokeWidth="1"
            className={`mt-0.5 w-4 h-4 stroke-yellow-dark ${
              open ? "-rotate-90" : "rotate-90"
            }`}
          />
        </button>
      ) : (
        <button type="button" className="flex items-center gap-x-2">
          <p className="font-aileron text-xs font-semibold text-primary capitalize">
            {getHubStationText({ allHubs, detail })}
          </p>

          <ChevronIcon
            strokeWidth="1"
            className={`mt-0.5 w-4 h-4 stroke-primary ${
              open ? "-rotate-90" : "rotate-90"
            }`}
          />
        </button>
      )}
    </Popover>
  );
};

export default AssignHubStationDropdown;

export const AssignHubWhileCreation = ({
  selectedHubs,
  onSelect,
  hasError,
}) => {
  const { allLocations } = useSelector((state) => state.locations);

  // get all the hubs
  const hubStations = allLocations.filter(
    (item) => item.type === LOCATION_TYPE.HUB_STATION,
  );

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const filteredData = () => {
    return hubStations.filter((hub) =>
      hub.name.toLowerCase().includes(search.toLowerCase()),
    );
  };

  const getFieldText = () => {
    if (selectedHubs.length === 1) {
      return `${selectedHubs[0]?.name}`;
    }

    if (selectedHubs.length > 1) {
      return `${selectedHubs.length} Hub Stations`;
    }

    return "Assign One Or More Hub Stations";
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1 max-h-[200px] overflow-y-scroll"
          style={{
            width: "367px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className="px-1">
            <CustomInput
              intent="searchHome"
              placeholder="Search Hub Station"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {filteredData().map((hub) => {
            const selected =
              selectedHubs.findIndex((item) => item.id === hub.id) > -1;

            return (
              <button
                type="button"
                className="w-full flex items-center justify-between gap-x-3 px-3 py-2 group hover:bg-background"
                key={hub.id}
                onClick={() => onSelect(hub)}
              >
                <div className="flex items-center gap-x-2">
                  <Checkbox checked={selected} />

                  <p
                    className="font-aileron text-xs font-semibold capitalize group-hover:text-black"
                    style={{
                      color: selected ? "#000" : "#525252",
                    }}
                  >
                    {hub.name}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
      }
    >
      <div
        role="button"
        className={`px-3.5 py-2 w-[367px] bg-white flex items-center justify-between border rounded-md ${
          hasError
            ? "border-alert-red"
            : open
            ? "border-primary"
            : "border-light-grey"
        }`}
      >
        <p className="font-aileron text-xs font-semibold capitalize text-dark-gray">
          {getFieldText()}
        </p>

        {hasError ? (
          <AlertIcon className="text-alert-red" />
        ) : (
          <ChevronIcon
            strokeWidth="1"
            className={`w-5 h-5 stroke-dark-gray ${
              open ? "-rotate-90" : "rotate-90"
            }`}
          />
        )}
      </div>
    </Popover>
  );
};
