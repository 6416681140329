import React from "react";
import CustomTooltip from "./CustomTooltip";
import useSystemUser from "../hooks/useSystemUser";
import getDateAndTime from "../../Utils/getDateAndTime";

const SubmittedDetails = ({ record, truncate = false, className = "" }) => {
  const submittedBy = useSystemUser(record.submittedBy);
  const dateAndTime = getDateAndTime(record.submittedAt);

  return (
    <CustomTooltip tooltipTitle={`As on ${dateAndTime}`} placement="topLeft">
      <div className={className}>
        <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
          {submittedBy}
        </div>
        <div className="text-10 font-light font-aileron leading-3.75 text-dark-gray">
          {truncate ? `${dateAndTime.substring(0, 15)}...` : dateAndTime}
        </div>
      </div>
    </CustomTooltip>
  );
};

export default SubmittedDetails;
