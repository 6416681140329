import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: {},
  status: "idle",

  selectedPayment: {},
  selectedCollection: {},
  showToastMessage: {
    visible: false,
    data: {},
  },
  threeDotsPopoverId: 0,
  paymentDetailsPopoverId: 0,
  collectionDetailsPopoverId: 0,
  selectedPaymentDetailStatusId: 0,
  selectedCollectionDetailStatusId: 0,
};

export const revenueSlice = createSlice({
  name: "revenue",
  initialState,
  reducers: {
    updateRevenue: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { updateRevenue } = revenueSlice.actions;

export default revenueSlice.reducer;
