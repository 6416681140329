import React from 'react';

const SpoiltMilkIcon = ({ size = 17, className = 'text-alert-red' }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 17 17"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.263"
          d="M12.75 7.671V13.458a2.131 2.131 0 01-2.125 2.125h-4.25a2.131 2.131 0 01-2.125-2.125V7.672c0-.327.184-.773.418-1.007L6.17 5.164a.714.714 0 00.205-.496V3.54h4.25v1.127c0 .184.078.368.205.496l1.502 1.501c.234.234.418.68.418 1.006zM4.25 10.611v-.708"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.263"
          d="M10.268 3.541H6.727A1.066 1.066 0 015.664 2.48c0-.581.475-1.063 1.063-1.063h3.541c.588 0 1.063.482 1.063 1.063 0 .58-.475 1.062-1.063 1.062zM8.5 7.083v3.541M8.5 12.75h.006"
        />
      </svg>
    </span>
  );
};

export default SpoiltMilkIcon;
