/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import TickIcon from '../../../Common/Svgs/TickIcon';

const UpdateTheme = ({ selectedTheme, setSelectedTheme }) => {
  const themeColors = [
    '#085054',
    '#135bba',
    '#dd6898',
    '#aa68d9',
    '#8e786c',
    '#878787',
    '#3a3b3e',
  ];

  const handleThemeSelection = (color) => {
    setSelectedTheme(color);
  };

  return (
    <div>
      <p className="text-xs font-normal capitalize font-aileron text-dark-gray">
        Current Theme
      </p>
      <div className="flex items-center mt-2">
        {themeColors.map((color) => (
          <div
            className="flex items-center justify-center w-12 h-12"
            key={color}
          >
            <div
              className="flex items-center justify-center rounded-full cursor-pointer"
              style={{
                background: color,
                width: selectedTheme === color ? '48px' : '24px',
                height: selectedTheme === color ? '48px' : '24px',
              }}
              onClick={() => handleThemeSelection(color)}
            >
              {selectedTheme === color && <TickIcon className="text-white" />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpdateTheme;
