/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ZoomButton, {
  LiveLocationButton,
  RelocateButton,
} from "../../MapMarkerButtons";
import CloseIcon from "../../../../Common/Svgs/CloseIcon";
import SaveGeoFenceLatLngModal from "./SaveGeoFenceLatLngModal";
import Button from "../../CustomButton";
import { updateTrikes } from "../../../../Redux/Slices/trikes.slice";
import { client } from "../../../../Utils/axiosClient";
import { getTrikeByIdV3 } from "../../../../Redux/APIs/trikesAPI";
import usePermission from "../../../hooks/usePermission";
import { PERMISSIONS } from "../../../../Modules/Login/constants";

const ModalBody = ({ onCancel, mapRef, id }) => {
  const dispatch = useDispatch();

  const { geoFenceLatLngs, currentTrike } = useSelector(
    (state) => state.trikes,
  );

  // geofence add or edit permission
  const hasGeofenceAddOrEditPermission = usePermission([
    PERMISSIONS.TRIKE_GEOFENCE_ADD,
    PERMISSIONS.TRIKE_GEOFENCE_EDIT,
  ]);

  // geofence delete permission
  const hasGeofenceDeletePermission = usePermission([
    PERMISSIONS.TRIKE_GEOFENCE_DELETE,
  ]);

  const onClear = async () => {
    try {
      const response = await client.put(
        `${process.env.REACT_APP_API_URL}/trike/clear-geofence`,
        {
          trikeId: geoFenceLatLngs?.trikeId,
          currentStatus: currentTrike?.status,
        },
      );

      dispatch(
        updateTrikes({
          key: "geoFenceLatLngs",
          value: {
            trikeId: "",
            latLngs: [],
          },
        }),
      );

      dispatch(
        updateTrikes({
          key: "geoFenceModal",
          value: false,
        }),
      );

      dispatch(getTrikeByIdV3({ id: currentTrike.id }));

      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="absolute top-6 left-6 z-[400]">
        <LiveLocationButton />
      </div>

      <div className="absolute top-6 right-6 z-[1000]">
        <div className="flex items-start w-full gap-x-4">
          {id !== "fullscreen" ? (
            <div className="flex items-start gap-x-4">
              {/* show the save geofence modal only if the permission exists */}
              {hasGeofenceAddOrEditPermission && <SaveGeoFenceLatLngModal />}

              {/* show the clear geofence button (deleting geofence) only if the permission exists */}
              {hasGeofenceDeletePermission &&
                geoFenceLatLngs?.latLngs.length > 1 && (
                  <Button colors="grey" size="primary" onClick={onClear}>
                    Clear Geofence
                  </Button>
                )}
            </div>
          ) : null}
          <div className="flex items-center justify-center w-8 h-8 rounded-md cursor-pointer bg-primary">
            <CloseIcon className="w-4 h-4 stroke-white" onClick={onCancel} />
          </div>
        </div>
      </div>
      <div className="absolute bottom-6 right-6 z-[1000]">
        <div className="flex flex-col gap-y-3">
          <RelocateButton />
          <ZoomButton mapRef={mapRef} />
        </div>
      </div>
    </div>
  );
};

export default ModalBody;
