import { useSelector } from "react-redux";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const useDataForGeneralSettings = (name) => {
  const { userDetails } = useSelector((state) => state.loggedInUser);

  const hasEditPermission = usePermission([
    PERMISSIONS.SETTINGS_GENERAL_VIEW_AND_EDIT,
  ]);

  // if edit permission exists, user can edit first name and last name
  // Note - user can never edit the email id

  const dataForGeneralSettings = [
    {
      id: 1,
      label: "First name",
      field: "firstName",
      intent: hasEditPermission ? "generalSettings" : "generalSettingsDisable",
      defaultValue: userDetails.firstName,
      value: name.firstName,
      placeholder: "Edit firstname",
      disabled: !hasEditPermission,
    },
    {
      id: 2,
      label: "last name",
      field: "lastName",
      intent: hasEditPermission ? "generalSettings" : "generalSettingsDisable",
      defaultValue: userDetails.lastName,
      value: name.lastName,
      placeholder: "Edit lastname",
      disabled: !hasEditPermission,
    },
    {
      id: 3,
      label: "Email Address",
      field: "email",
      intent: "generalSettingsDisable",
      defaultValue: userDetails.email,
      value: name.email,
      placeholder: "Edit email",
      disabled: true,
    },
  ];

  return dataForGeneralSettings;
};

export default useDataForGeneralSettings;
