import React from "react";
import ViewDetailsIcon from "../../../Common/Svgs/ViewDetailsIcon";
import EditIcon from "../../../Common/Svgs/EditIcon";
import AddSquareIcon from "../../../Common/Svgs/AddSquareIcon";
import QRCodeIcon from "../../../Common/Svgs/QRCodeIcon";
import TrikeDispatchIcon from "../../../Common/Svgs/TrikeDispatchIcon";
import AddToServiceIcon from "../../../Common/Svgs/AddToServiceIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import MoreSquareIcon from "../../../Common/Svgs/MoreSquareIcon";
import HubStationIcon from "../../../Common/Svgs/HubStationIcon";

const useTrikeThreeDotsOptions = () => {
  const threeDotsOptions = [
    {
      id: 1,
      value: "View Details",
      icon: (
        <ViewDetailsIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([
        PERMISSIONS.TRIKE_VIEW,
        PERMISSIONS.TRIKE_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 2,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.TRIKE_EDIT]),
    },
    {
      id: 3,
      value: "Add Issues",
      icon: <AddSquareIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.TRIKE_ISSUE_ADD]),
    },
    {
      id: 10,
      value: "View Issues",
      icon: (
        <MoreSquareIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([PERMISSIONS.TRIKE_ISSUE_VIEW]),
    },
    {
      id: 4,
      value: "View QR Code",
      icon: <QRCodeIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.TRIKE_VIEW_QR]),
    },
    {
      id: 7,
      value: "Dispatch",
      icon: (
        <TrikeDispatchIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([PERMISSIONS.TRIKE_DISPATCH_AND_MOVE]),
    },
    {
      id: 8,
      value: "Move To Hub Station",
      icon: (
        <HubStationIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([PERMISSIONS.TRIKE_DISPATCH_AND_MOVE]),
    },
    {
      id: 5,
      value: "Add to Service",
      icon: (
        <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([PERMISSIONS.TRIKE_ADD_TO_SERVICE]),
    },
    {
      id: 9,
      value: "View Service",
      icon: (
        <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
      ),
      hasPermission: usePermission([
        PERMISSIONS.SERVICE_VIEW,
        PERMISSIONS.SERVICE_VIEW_AND_EDIT,
      ]),
    },
    {
      id: 6,
      value: "Delete Trike",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.TRIKE_DELETE]),
    },
  ];

  return threeDotsOptions;
};

export default useTrikeThreeDotsOptions;
