function distanceCalculator(point1, point2) {
  if (!point1 || !point2) {
    return '0 Km';
  }
  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  // Extract latitude and longitude from the points
  const lat1 = point1?.lat;
  const lon1 = point1?.lng;
  const lat2 = point2?.lat;
  const lon2 = point2?.lng;

  // Convert latitude and longitude from degrees to radians
  const lat1Rad = toRadians(lat1);
  const lon1Rad = toRadians(lon1);
  const lat2Rad = toRadians(lat2);
  const lon2Rad = toRadians(lon2);

  // Haversine formula (same as before)
  const dLat = lat2Rad - lat1Rad;
  const dLon = lon2Rad - lon1Rad;
  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Radius of the Earth in kilometers (mean radius)
  const radiusEarthKm = 6371.0;

  // Calculate the distance in kilometers
  const distanceKm = `${Math.round(radiusEarthKm * c).toFixed(2)} Km`;
  return distanceKm;
}
export default distanceCalculator;
