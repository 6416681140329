/* eslint-disable no-lonely-if */
import React from "react";
import UnauthorizedAccess from "./UnauthorizedAccess";
import usePermission, { useTenantPermission } from "../hooks/usePermission";

const ProtectedRoute = ({
  component,
  allowedPermissions = [],
  tenantPath = false,
}) => {
  const hasPermission = usePermission(allowedPermissions);

  const hasTenantPermission = useTenantPermission();

  if (tenantPath) {
    if (!hasTenantPermission) {
      return <UnauthorizedAccess />;
    }
  } else {
    if (!hasPermission) {
      return <UnauthorizedAccess />;
    }
  }

  // if (tenantPath && !hasTenantPermission) {
  //   return <UnauthorizedAccess />;
  // }

  return component;
};

export default ProtectedRoute;
