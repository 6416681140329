import React from 'react';
import CustomTags from '../../../Core/Components/CustomTags';

const renderOriginDestination = (id, length) => {
  switch (id) {
    case 1:
      return (
        <CustomTags
          colors="stopOrigin"
          background="stopOrigin"
          size="stopOrigin"
        >
          <div className="flex items-center justify-center w-full h-full">
            <p className="font-semibold font-aileron text-10 text-background-white">
              origin
            </p>
          </div>
        </CustomTags>
      );
    case length:
      return (
        <CustomTags
          colors="stopOrigin"
          background="stopOrigin"
          size="stopOrigin"
        >
          <div className="flex items-center justify-center w-full h-full">
            <p className="font-semibold font-aileron text-10 text-background-white">
              destination
            </p>
          </div>
        </CustomTags>
      );
    default:
      return null;
  }
};

export default renderOriginDestination;
