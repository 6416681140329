import React from 'react';

const ParkingIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        // stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      />
      <path
        fill="#fff"
        d="M9.448 19a.804.804 0 01-.592-.24.804.804 0 01-.24-.592V7.336c0-.245.08-.443.24-.592.16-.16.357-.24.592-.24h3.2c.8 0 1.509.176 2.128.528a3.677 3.677 0 011.44 1.472c.352.619.528 1.333.528 2.144 0 .779-.176 1.472-.528 2.08a3.626 3.626 0 01-1.44 1.424c-.619.341-1.328.512-2.128.512H10.28v3.504c0 .235-.08.432-.24.592a.772.772 0 01-.592.24zm.832-5.872h2.368c.49 0 .922-.107 1.296-.32.373-.213.666-.507.88-.88.213-.373.32-.8.32-1.28 0-.512-.107-.96-.32-1.344a2.377 2.377 0 00-.88-.928c-.374-.224-.806-.336-1.296-.336H10.28v5.088z"
      />
    </svg>
  );
};

export const ParkingLotSecondaryIcon = ({
  size = 16,
  className = 'text-[#0892BD]',
}) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M7.999 14.666a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333z"
        />
        <path
          fill="currentColor"
          d="M6.903 12a.503.503 0 01-.37-.15.503.503 0 01-.15-.37V4.71c0-.153.05-.276.15-.37.1-.1.223-.15.37-.15h2c.5 0 .943.11 1.33.33.386.22.686.527.9.92.22.387.33.834.33 1.34 0 .487-.11.92-.33 1.3-.213.38-.513.677-.9.89-.387.214-.83.32-1.33.32h-1.48v2.19c0 .147-.05.27-.15.37a.482.482 0 01-.37.15zm.52-3.67h1.48c.306 0 .576-.066.81-.2.233-.133.417-.316.55-.55.133-.233.2-.5.2-.8 0-.32-.067-.6-.2-.84a1.486 1.486 0 00-.55-.58 1.544 1.544 0 00-.81-.21h-1.48v3.18z"
        />
      </svg>
    </span>
  );
};

export default ParkingIcon;
