import React from "react";

const ServiceIcon = ({ size = 24, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.004 11.578a1.154 1.154 0 00-.191-.256l-.86-.848a1.013 1.013 0 00-.307-.214m1.358 1.318a1.02 1.02 0 01-.18 1.181c-.303.298-.839.838-1.795 1.779-1.86 1.839-5.004 5.337-6.375 6.948a.755.755 0 01-1.11.042L1.718 19.68a.751.751 0 01.05-1.104c1.581-1.331 5.077-4.453 6.96-6.315.984-.972 1.53-1.508 1.827-1.801a.981.981 0 011.092-.2m1.358 1.318c.653-.633 2.357-2.04 2.813-2.322.456-.28.824-.36 1-.379.348-.033.697.042 1 .217.052.03.101.067.144.111a.79.79 0 01-.024 1.113l-.094.089a.259.259 0 000 .367l1.609 1.594a.258.258 0 00.187.074.265.265 0 00.188-.074l2.6-2.571a.273.273 0 000-.375l-1.609-1.594a.255.255 0 00-.187-.074.262.262 0 00-.188.074.535.535 0 01-.448.153c-.21-.023-.433-.088-.577-.227-.329-.322.05-.955-.238-1.36-.33-.443-.691-.862-1.082-1.252-.33-.328-1.632-1.57-3.823-2.463a5.803 5.803 0 00-2.203-.433c-1.235 0-2.185.552-2.531.868-.276.25-.563.645-.563.645.166-.06.335-.11.507-.15.359-.076.726-.1 1.091-.07.73.061 1.609.403 2.08.774.76.609 1.087 1.424 1.138 2.476.037.782-1.435 2.665-2.148 3.47"
      />
    </svg>
  );
};

export const ActiveServiceIcon = ({ size = 24, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M13.005 11.578a1.154 1.154 0 00-.191-.256l-.86-.848a.981.981 0 00-1.399-.014c-.298.293-.843.829-1.828 1.801-1.882 1.863-5.378 4.984-6.958 6.315a.75.75 0 00-.051 1.104l1.828 1.848a.756.756 0 001.11-.042c1.37-1.611 4.513-5.11 6.374-6.948.956-.94 1.492-1.48 1.795-1.779a1.02 1.02 0 00.18-1.18z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M13.005 11.578a1.154 1.154 0 00-.191-.256l-.86-.848a1.013 1.013 0 00-.307-.214m1.358 1.318a1.02 1.02 0 01-.18 1.181c-.303.298-.839.838-1.795 1.779-1.86 1.839-5.004 5.337-6.375 6.948a.755.755 0 01-1.11.042L1.719 19.68a.751.751 0 01.05-1.104c1.581-1.331 5.077-4.453 6.96-6.315.984-.972 1.53-1.508 1.827-1.801a.981.981 0 011.092-.2m1.358 1.318c.653-.633 2.357-2.04 2.813-2.322.456-.28.824-.36 1-.379.348-.033.697.042 1 .217.052.03.101.067.144.111a.79.79 0 01-.024 1.113l-.093.089a.257.257 0 000 .367l1.608 1.594a.257.257 0 00.187.074.265.265 0 00.188-.074l2.6-2.571a.273.273 0 000-.375l-1.609-1.594a.256.256 0 00-.187-.074.262.262 0 00-.188.074.535.535 0 01-.447.153c-.21-.023-.434-.088-.578-.227-.329-.322.05-.955-.238-1.36-.33-.443-.692-.862-1.082-1.252-.33-.328-1.632-1.57-3.823-2.463a5.802 5.802 0 00-2.203-.433c-1.235 0-2.185.552-2.531.868-.276.25-.563.645-.563.645.166-.06.335-.11.507-.15.359-.076.726-.1 1.091-.07.73.061 1.609.403 2.08.774.76.609 1.087 1.424 1.138 2.476.037.782-1.435 2.665-2.148 3.47"
        />
      </svg>
    </span>
  );
};

export default ServiceIcon;
