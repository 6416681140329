/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CustomLayout from "../../Core/Layout/CustomLayout";
import DriversList from "./phase3/DriversList";
import PartnersList from "./phase3/PartnersList";
import { updateUsers } from "../../Redux/Slices/users.slice";
import ToastMessageModal from "./phase3/ToastMessageModal";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";

function UsersList() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { showToastMessage } = useSelector((state) => state.users);

  const selectedTab = searchParams.get("tab");

  // check permission for driver module
  const hasDriverPermission = usePermission([
    PERMISSIONS.DRIVER_VIEW,
    PERMISSIONS.DRIVER_VIEW_AND_EDIT,
  ]);

  // clear the toast message
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateUsers({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  // update the tab selection based on the permission
  useEffect(() => {
    if (!searchParams.has("tab")) {
      if (hasDriverPermission) {
        searchParams.set("tab", "drivers");
        setSearchParams(searchParams);
      } else {
        searchParams.set("tab", "partners");
        setSearchParams(searchParams);
      }
    }
  }, [searchParams, setSearchParams]);

  return (
    <CustomLayout pageName="Users">
      {selectedTab === "drivers" ? <DriversList /> : <PartnersList />}

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "showToastMessage",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
}
export default UsersList;
