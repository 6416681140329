import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import { TickWithBgIcon } from '../../../Common/Svgs/TickIcon';
import { updateTrips } from '../../../Redux/Slices/trips.slice';

const RouteInformation = () => {
  const dispatch = useDispatch();
  const { optimisedRouteDistance } = useSelector((state) => state.trips);

  const getTime = () => {
    const totalTime = optimisedRouteDistance / 25;

    const hours = Math.floor(totalTime);
    const minutes = Math.round((totalTime - hours) * 60);

    dispatch(
      updateTrips({
        key: 'optimisedRouteTime',
        value: `${hours}h ${minutes}m`,
      }),
    );

    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="">
      <Divider className="m-0 text-light-grey" />
      <div className="flex items-center justify-start py-2 gap-x-2">
        <div className="bg-primary w-[19px] h-[19px] rounded-full flex items-center justify-center">
          <TickWithBgIcon />
        </div>
        <p className="font-semibold text-primary font-aileron text-10">
          Route Optimised
        </p>
      </div>
      <div className="mt-4 bg-background-white-hover py-2 px-[27px] rounded-lg">
        <div className="flex items-center justify-center gap-x-6">
          <div className="w-[117px]">
            <p className="font-normal font-aileron text-10 text-dark-gray">
              Optimised Time
            </p>
            <p className="mt-2 text-xs font-semibold font-aileron">
              {getTime()}
            </p>
          </div>
          <div className="w-[117px]">
            <p className="font-normal font-aileron text-10 text-dark-gray">
              Optimised Distance
            </p>
            <p className="mt-2 text-xs font-semibold font-aileron">
              {parseFloat(optimisedRouteDistance).toFixed(2)} Km
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteInformation;
