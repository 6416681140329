import { Checkbox } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

const useMoreColumnsData = () => {
  const { selectedColumns } = useSelector((state) => state.trikes);

  const moreColumns = [
    {
      key: "TRIKES_DRIVER_NAME",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_DRIVER_NAME")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_DRIVER_NAME")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Driver
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_BATTERY_PACK",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_BATTERY_PACK")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_BATTERY_PACK")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Battery pack
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_CURRENT_SPEED",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox
            checked={selectedColumns.includes("TRIKES_CURRENT_SPEED")}
          />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_CURRENT_SPEED")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Current Speed
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_STATUS",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_STATUS")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_STATUS")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Status
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_ON_OFF",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_ON_OFF")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_ON_OFF")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            On/Off
          </div>
        </div>
      ),
      hasPermission: usePermission([PERMISSIONS.TRIKE_MOBILISE]),
    },
    {
      key: "TRIKES_TRACKER",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_TRACKER")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_TRACKER")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Tracker
          </div>
        </div>
      ),
      hasPermission: usePermission([PERMISSIONS.TRACKER_SWITCH]),
    },
    {
      key: "TRIKES_CURRENT_PAYLOAD",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox
            checked={selectedColumns.includes("TRIKES_CURRENT_PAYLOAD")}
          />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_CURRENT_PAYLOAD")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Current Payload
          </div>
        </div>
      ),
      hasPermission: true,
    },

    {
      key: "DIVIDER",
      label: <div className="w-full h-[1px] my-1 bg-light-grey" />,
    },
    {
      key: "TRIKES_MODEL_NO",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_MODEL_NO")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_MODEL_NO")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Model Number
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_CHECKLIST",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_CHECKLIST")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_CHECKLIST")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Checklist
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_DISTANCE",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_DISTANCE")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_DISTANCE")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Distance Traveled
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_MILEAGE",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_MILEAGE")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_MILEAGE")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Mileage
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_AVERAGE_SPEED",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox
            checked={selectedColumns.includes("TRIKES_AVERAGE_SPEED")}
          />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_AVERAGE_SPEED")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Average Speed
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_CHASSIS_NO",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_CHASSIS_NO")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_CHASSIS_NO")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Chassis Number
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_VEHICLE_TYPE",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_VEHICLE_TYPE")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_VEHICLE_TYPE")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Vehicle Type
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_ASSET_TAG",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox checked={selectedColumns.includes("TRIKES_ASSET_TAG")} />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_ASSET_TAG")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Asset Tag
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_INSURANCE_VALIDITY",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox
            checked={selectedColumns.includes("TRIKES_INSURANCE_VALIDITY")}
          />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_INSURANCE_VALIDITY")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Insurance Validity
          </div>
        </div>
      ),
      hasPermission: true,
    },
    {
      key: "TRIKES_DATE_OF_ENTRY",
      label: (
        <div className="flex justify-start px-3 gap-x-3 py-2.25 items-center group">
          <Checkbox
            checked={selectedColumns.includes("TRIKES_DATE_OF_ENTRY")}
          />
          <div
            className={`font-poppins font-medium text-xs leading-4.5 group-hover:text-new-black ${
              selectedColumns.includes("TRIKES_DATE_OF_ENTRY")
                ? "text-new-black"
                : "text-dark-grey-text"
            }`}
          >
            Date Of Entry
          </div>
        </div>
      ),
      hasPermission: true,
    },
  ];

  return moreColumns;
};

export default useMoreColumnsData;
