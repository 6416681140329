import React from 'react';

const TickIcon = ({ size = 24, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M4 12l5.327 5L20 7"
        />
      </svg>
    </span>
  );
};

export const TickCircleIcon = ({ size = 24, className = 'text-primary' }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7.75 12l2.83 2.83 5.67-5.66"
        />
      </svg>
    </span>
  );
};

export const TickWithBgIcon = ({ size = 19, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 19 19"
      >
        <path
          fill="#085054"
          stroke="#085054"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M9.499 17.416c4.354 0 7.916-3.562 7.916-7.916 0-4.354-3.562-7.917-7.916-7.917-4.354 0-7.917 3.563-7.917 7.917 0 4.354 3.563 7.916 7.917 7.916z"
        />
        <path
          stroke="#FAFAFA"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.137 9.5l2.24 2.24 4.489-4.48"
        />
      </svg>
    </span>
  );
};

export default TickIcon;
