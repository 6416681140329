/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Dropdown, Popover } from "antd";
import AlertIcon from "../../Common/Svgs/AlertIcon";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import TickIcon from "../../Common/Svgs/TickIcon";
import CustomInput from "./CustomInput";

const CustomDropdown = ({
  items = [],
  prefix = false,
  children,
  arrow = false,
  placement = "bottom",
  handleClick,
  trigger,
  dropDownOpen = false,
  onOpenChange,
  disabled = false,
  customHeight = "",
  enableSearch = false,
  searchPlaceholder = "",
}) => {
  const [open, setOpen] = React.useState(dropDownOpen);
  const [search, setSearch] = useState("");

  const updateOpenChange = (flag) => {
    setOpen(flag);
    if (onOpenChange) {
      onOpenChange(flag);
    }
  };

  const handleMenuClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);

    const customEvent = {
      key: e.key,
      value: selectedItem ? selectedItem.title : e.item.props.value,
    };
    handleClick(customEvent);
    setSearch("");

    updateOpenChange(false);
    if (prefix === true && e.key === "1") {
      updateOpenChange(false);
    }
  };

  const handleOpenChange = (flag) => {
    if (disabled) {
      return;
    }
    // setOpen(flag);
    updateOpenChange(flag);
  };

  // get filtered values based on search input
  const filteredData = enableSearch
    ? items.filter((item) =>
        item.value.toLowerCase().includes(search.toLowerCase()),
      )
    : items;

  useEffect(() => {
    setOpen(dropDownOpen);
  }, [dropDownOpen]);

  return (
    <Dropdown
      overlayClassName={` ${
        customHeight ? `${customHeight} overflow-y-scroll` : null
      }`}
      menu={{
        items: filteredData,
        onClick: handleMenuClick,
      }}
      onOpenChange={handleOpenChange}
      open={open}
      trigger={trigger}
      placement={placement}
      arrow={arrow}
      dropdownRender={(origin) => {
        // check if there are menu items present
        // if yes then show the dropdown else return empty fragments
        const hasItems = items.length;

        if (hasItems) {
          return (
            <div>
              {enableSearch && (
                <div className="mb-1 rounded-md bg-white">
                  <CustomInput
                    intent="primary"
                    placeholder={searchPlaceholder}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              )}
              {origin}
            </div>
          );
        }

        return <></>;
      }}
    >
      {children}
    </Dropdown>
  );
};

export const PopoverDropdown = ({
  formState,
  error = "",
  name,
  value,
  placeholder,
  options,
  onSelect,
  enableSearch = false,
  searchPlaceholder = "",
  customValueRender = () => false,
  valueStyle = {},
  disabled = false,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [customValueColor, setCustomValueColor] = useState("");

  // const { errors } = formState;
  const hasError = error || formState?.errors?.[name] || null;

  // get filtered values based on search input
  const filteredData = enableSearch
    ? options.filter((item) =>
        item.value.toLowerCase().includes(search.toLowerCase()),
      )
    : options;

  const getValueContainerClassName = () => {
    if (hasError) return "border-alert-red bg-white";
    if (disabled) return "bg-background cursor-not-allowed";
    if (open) return "border-primary bg-white";
    return "border-light-grey bg-white";
  };

  const getValueTextClassName = () => {
    if (disabled) return "text-dark-gray";
    if (value && customValueColor) return customValueColor;
    if (value) return "text-black font-semibold";
    return "text-dark-gray font-normal";
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={disabled ? false : open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div
          className={`w-[367px] bg-background-white rounded-md py-1 ${className}`}
        >
          {/* search input which will filter the items */}
          {enableSearch && (
            <div className="px-1">
              <CustomInput
                intent="searchHome"
                placeholder={searchPlaceholder}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          )}

          {filteredData.map((option) => {
            const selected = option.value === value;
            const textColor = option?.textColor;

            return (
              <button
                type="button"
                key={option.id}
                className="w-full flex items-center justify-between py-2 px-3 capitalize"
                onClick={() => {
                  onSelect(option);
                  setOpen(false);
                  setCustomValueColor(textColor);
                }}
              >
                {option.customField ? (
                  option.customField
                ) : (
                  <p
                    className={`font-aileron text-xs font-semibold text-left
                    ${
                      textColor
                        ? textColor
                        : selected
                        ? "text-black"
                        : "text-dark-gray"
                    }
                   `}
                  >
                    {option.value}
                  </p>
                )}

                {selected ? (
                  <TickIcon size={16} className="text-primary" />
                ) : null}
              </button>
            );
          })}
        </div>
      }
    >
      <div
        role="button"
        className={`px-3.5 py-2 w-[367px] flex items-center justify-between border rounded-md ${getValueContainerClassName()}`}
        style={valueStyle}
      >
        <p
          className={`font-aileron text-xs capitalize ${getValueTextClassName()}`}
        >
          {customValueRender() ? customValueRender() : value || placeholder}
        </p>

        {hasError ? (
          <AlertIcon className="text-alert-red" />
        ) : (
          <ChevronIcon
            strokeWidth="1"
            className={`w-5 h-5 stroke-dark-gray ${
              disabled ? "rotate-90" : open ? "-rotate-90" : "rotate-90"
            }`}
          />
        )}
      </div>
    </Popover>
  );
};

export default CustomDropdown;
