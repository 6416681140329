/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable default-param-last */
/* eslint-disable radix */
import React from "react";
import * as ReactDOMServer from "react-dom/server";
import L from "leaflet";
import SnapToRoadIcon from "../Common/Svgs/SnapToRoadIcon";

const getTripPointsOnMap = (
  stopNumber,
  totalLength,
  status = 1,
  currentTrip,
) => {
  const isFirstAndLastStopSame =
    currentTrip?.stopsId[0] ===
    currentTrip?.stopsId[currentTrip?.stopsId?.length - 1];

  // eslint-disable-next-line new-cap
  return new L.divIcon({
    html: ReactDOMServer.renderToString(
      <div className="relative">
        <p
          className={`absolute font-medium text-center text-white font-poppins ${
            stopNumber === 1 && isFirstAndLastStopSame
              ? "top-[9px] left-[10px] text-[15px]"
              : "top-2 left-[16px] text-base"
          }`}
        >
          {stopNumber === 1 && isFirstAndLastStopSame
            ? `${stopNumber}/${totalLength}`
            : stopNumber}
        </p>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="42"
          fill="none"
          viewBox="0 0 40 42"
        >
          <g filter="url(#filter0_f_3482_20243)">
            <path
              fill="#000"
              fillOpacity="0.26"
              d="M4.45 13.91c3.656-15.892 27.463-15.874 31.1.018 2.134 9.322-3.73 17.212-8.87 22.093-5.567 5.287-7.793 5.324-13.378 0C8.18 31.141 2.317 23.231 4.45 13.91z"
            />
          </g>
          <path
            fill={status === 2 ? "#525252" : "#EAB726"}
            stroke={status === 2 ? "#525252" : "#EAB726"}
            strokeWidth="1.5"
            d="M6.034 14.15C9.316-.283 30.7-.267 33.966 14.167 35.883 22.633 30.617 29.8 26 34.233c-5 4.802-7 4.835-12.017 0-4.6-4.433-9.866-11.616-7.95-20.083z"
          />
          <defs>
            <filter
              id="filter0_f_3482_20243"
              width="34.2"
              height="40.2"
              x="2.9"
              y="0.9"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                result="effect1_foregroundBlur_3482_20243"
                stdDeviation="0.55"
              />
            </filter>
          </defs>
        </svg>
      </div>,
    ),
  });
};

export const getSnapToRoadPointsOnMap = () => {
  // eslint-disable-next-line new-cap
  return new L.divIcon({
    html: ReactDOMServer.renderToString(
      <div className="absolute z-10 w-3 h-3 top-[-6px] left-[-4px]">
        <SnapToRoadIcon size={24} className="text-black" />
      </div>,
    ),
  });
};

export default getTripPointsOnMap;
