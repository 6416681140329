import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";

const DriverCurrentTrike = ({ detail }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allTrikes } = useSelector((state) => state.trikes);

  const handleClick = (e) => {
    e.stopPropagation();

    if (detail.currentTrike) {
      const current = allTrikes.find(
        (item) => item.registrationNo === detail.currentTrike,
      );

      dispatch(
        updateTrikes({
          key: "currentTrike",
          value: current,
        }),
      );

      navigate(`/trikes/${detail.currentTrike}`);
    }
  };

  return (
    <div
      className={`text-xs font-semibold font-aileron leading-4.5 text-dark-grey-text max-w-max ${
        detail.currentTrike && "cursor-pointer hover:text-black hover:underline"
      }`}
      onClick={handleClick}
    >
      {detail.currentTrike || "--"}
    </div>
  );
};

export default DriverCurrentTrike;
