import React from "react";

const DriverResumeIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.627 12.7l1.013 1.013 2.027-2.026M8.107 7.246a1.211 1.211 0 00-.22 0A2.947 2.947 0 015.04 4.293a2.952 2.952 0 012.953-2.96 2.962 2.962 0 012.96 2.96c0 1.6-1.266 2.9-2.846 2.953zM7.993 14.54c-1.213 0-2.42-.306-3.34-.92-1.613-1.08-1.613-2.84 0-3.913 1.834-1.227 4.84-1.227 6.674 0"
        />
      </svg>
    </span>
  );
};

export default DriverResumeIcon;
