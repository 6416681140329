import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  endTripByAdmin,
  getKpiData,
  getTripsV3,
} from "../../../Redux/APIs/tripsAPI";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CustomRoundedImage from "../../../Core/Components/CustomRoundedImage";
import TripsIcon from "../../../Common/Svgs/TripsIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import { updateTrips } from "../../../Redux/Slices/trips.slice";

const EndTripByAdminModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const { toastMessage, currentPage, endTripByAdminModal } = useSelector(
    (state) => state.trips,
  );

  const onConfirm = async () => {
    await dispatch(endTripByAdmin({ tripId: endTripByAdminModal.tripId }));

    await dispatch(
      getTripsV3({
        offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT,
      }),
    );

    await dispatch(getKpiData());

    dispatch(
      updateTrips({
        key: "toastMessage",
        value: {
          ...toastMessage,
          visible: true,
          type: "endTripByAdmin",
          id: 1,
        },
      }),
    );

    onCancel();
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px" centered>
      <div className="w-full p-4 rounded-md">
        <div className="flex items-center justify-between">
          <div className="relative">
            <CustomRoundedImage
              size="w-12 h-12"
              image={<TripsIcon className="text-primary" />}
            />
          </div>
          <button type="button" onClick={onCancel}>
            <CloseIcon className="w-6 h-6 stroke-dark-gray" />
          </button>
        </div>
        <div className="px-2 mt-5">
          <p className="text-base font-medium text-alert-red font-poppins">
            End trip
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            Are you sure you want to end this trip?
          </p>
          <p className="mt-2 text-xs font-normal text-black font-aileron">
            This action cannot be undone.
          </p>
        </div>
        <div className="flex justify-end gap-x-3 mt-5.5">
          <Button colors="cancelButton" size="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button colors="alert" size="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default EndTripByAdminModal;
