const getUniqueList = (inputList, parameter) => {
  const uniqueList = inputList.reduce((previousValue, currentValue) => {
    const valueFound = previousValue?.find(
      (i) => i[parameter] === currentValue[parameter],
    );

    if (valueFound) {
      return previousValue;
    }
    return [...previousValue, currentValue];
  }, []);

  return uniqueList;
};

export default getUniqueList;
