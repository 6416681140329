/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useNavigate } from "react-router-dom";

const UnauthorizedAccess = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/home");
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col items-center text-center">
        <div className="w-[84px] h-[84px] rounded-full bg-light-alert-red-hover flex items-center justify-center">
          <img
            src="/assets/icons/lock.png"
            className="w-[42px] h-[42px]"
            alt="unauthorized"
          />
        </div>

        <h1 className="mt-6 font-poppins text-base font-medium">
          Access Restricted
        </h1>

        <p className="mt-2 font-poppins text-xs font-light">
          Sorry you don’t have the permission to view this page. Please
          <br /> contact your admin/operator for further assistance.
        </p>

        <p className="mt-2 font-poppins text-sm font-normal">
          Go back to{" "}
          <span
            role="button"
            onClick={handleRedirect}
            className="underline font-medium"
          >
            Homepage
          </span>
        </p>
      </div>
    </div>
  );
};

export default UnauthorizedAccess;
