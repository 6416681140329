import React from "react";
import EditIcon from "../../Common/Svgs/EditIcon";
import TrashIcon from "../../Common/Svgs/TrashIcon";

const useThreeDotsOptions = () => {
  const threeDotsOptions = [
    {
      id: 1,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: true,
    },
    {
      id: 2,
      value: "Delete Commodity",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: true,
    },
  ];

  return threeDotsOptions;
};

export default useThreeDotsOptions;
