import React from "react";

const ChargingIcon = ({ size, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M13.666 6.333c1 0 1 .334 1 1v1.334c0 .666 0 1-1 1M6.667 5.333L5.407 7c-.26.447.06 1 .573 1h1.533c.514 0 .834.553.58 1l-1.426 1.667M4.667 12.667c-2.666 0-3.333-.667-3.333-3.334V6.667c0-2.667.667-3.334 3.333-3.334M8.666 3.333c2.667 0 3.333.667 3.333 3.334v2.666c0 2.667-.666 3.334-3.333 3.334"
        />
      </svg>
    </span>
  );
};

export default ChargingIcon;
