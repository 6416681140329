import React from "react";

const ModuleHeader = ({ module, icon }) => {
  return (
    <div className="py-3 px-6 w-full flex items-center gap-x-2 bg-primary-10">
      <div>{icon}</div>

      <p className="font-poppins text-sm text-primary font-semibold uppercase">
        {module}
      </p>
    </div>
  );
};

export default ModuleHeader;
