import { createSlice } from "@reduxjs/toolkit";
import {
  addLocationV3,
  deleteLocationV3,
  editLocationV3,
  getAllHubs,
  getAllLocationsV3,
  getGeoLocations,
  getLocations,
  getLocationsFromODK,
  getLocationsV3,
  getTrikeLocations,
} from "../APIs/locationsAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  loggedIn: false,
  locations: [],
  geoLocations: [],
  trikeLocations: [],
  trikeBatteries: [],

  // phase 3
  currentLocation: null,
  locationsList: {
    result: [],
    totalRecords: 0,
  },
  allLocations: [],
  allHubs: [],
  threeDotsPopoverId: 0,
  deleteLocationModal: {
    visible: false,
    location: {},
  },
  showToastMessage: {
    visible: false,
    data: {},
  },
};

export const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    updateLocations: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    builder.addCase(getLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.locations = action.payload;
    });
    builder.addCase(getLocations.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.locations = [];
      state.error = action.error;
    });
    builder.addCase(getTrikeLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTrikeLocations.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.trikeLocations = [action.payload.global_values];
      state.trikeBatteries = [action.payload];
    });
    builder.addCase(getTrikeLocations.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.trikeLocations = [];
      state.error = action.error;
    });

    // get Locations from DB
    builder.addCase(getGeoLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGeoLocations.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.geoLocations = action.payload;
    });
    builder.addCase(getGeoLocations.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.geoLocations = [];
      state.error = action.error;
    });

    // get Locations from ODK
    builder.addCase(getLocationsFromODK.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLocationsFromODK.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(getLocationsFromODK.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // phase3
    // get locations
    builder.addCase(getLocationsV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLocationsV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.locationsList = action.payload;
    });
    builder.addCase(getLocationsV3.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });

    // add location
    builder.addCase(addLocationV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addLocationV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(addLocationV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // edit location
    builder.addCase(editLocationV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editLocationV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(editLocationV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // delete location
    builder.addCase(deleteLocationV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteLocationV3.fulfilled, (state) => {
      state.loading = false;
      state.status = "fulfilled";
    });
    builder.addCase(deleteLocationV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get all location
    builder.addCase(getAllLocationsV3.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllLocationsV3.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.allLocations = action.payload.data;
    });
    builder.addCase(getAllLocationsV3.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get all hub stations
    builder.addCase(getAllHubs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllHubs.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.allHubs = action.payload.data;
    });
    builder.addCase(getAllHubs.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
  },
});

export const { updateLocations, locations, trikeLocations } =
  locationSlice.actions;
export default locationSlice.reducer;
