import React from "react";

const RateManagerIcon = ({ size, className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.78 10.2l3.02 3.02a3.187 3.187 0 004.5 0l2.927-2.927a3.187 3.187 0 000-4.5L10.2 2.78a3.167 3.167 0 00-2.4-.927l-3.333.16a2.576 2.576 0 00-2.46 2.447l-.16 3.333c-.04.9.3 1.774.933 2.407z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d="M6.333 8a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z"
        />
      </svg>
    </div>
  );
};

export default RateManagerIcon;
