import React from "react";
import { useDispatch } from "react-redux";
import TrikeIcon from "../../Common/Svgs/TrikeIcon";
import { LOCATION_FETCH_SOURCE } from "../../Modules/Trikes/Data";
import TrackerMobileIcon from "../../Common/Svgs/TrackerMobileIcon";
import { updateTrikes } from "../../Redux/Slices/trikes.slice";
import CustomTooltip from "./CustomTooltip";

const TrikeTrackerSwitch = ({
  trikeId,
  trikeRegistrationNo,
  locationFetchSource,
  tripId = 0,
}) => {
  const dispatch = useDispatch();

  const locationFromMobile =
    locationFetchSource === LOCATION_FETCH_SOURCE.MOBILE;

  const onClick = () => {
    dispatch(
      updateTrikes({
        key: "trikeTrackerModal",
        value: {
          visible: true,
          trikeId,
          trikeRegistrationNo,
          currentSource: locationFetchSource,
          tripId,
        },
      }),
    );
  };

  return (
    <CustomTooltip
      tooltipTitle={
        locationFromMobile ? "Fetched from Mobile GPS" : "Fetched from Trike"
      }
      placement="topLeft"
    >
      <div
        className="p-[2px] max-w-[100px] rounded-md bg-background-white flex items-center"
        style={{
          boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        {/* location is currently from thingsboard */}
        <button
          type="button"
          className="rounded flex items-center justify-center px-4 py-2"
          style={{
            background: locationFromMobile ? "#FAFAFA" : "#085054",
          }}
          onClick={onClick}
        >
          <TrikeIcon
            size={16}
            className={` ${
              locationFromMobile ? "text-dark-gray" : "text-white"
            }`}
          />
        </button>

        {/* location is currently from mobile device */}
        <button
          type="button"
          className="rounded flex items-center justify-center px-4 py-2"
          style={{
            background: locationFromMobile ? "#EAB726" : "#FAFAFA",
          }}
          onClick={onClick}
        >
          <TrackerMobileIcon
            size={16}
            className={` ${
              locationFromMobile ? "text-white" : '"text-dark-gray"'
            }`}
          />
        </button>
      </div>
    </CustomTooltip>
  );
};

export default TrikeTrackerSwitch;
