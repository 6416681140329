/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import CustomTable from "../../Core/Components/CustomTable";
import { rateColumns } from "./data";
import { FinanceHoverIcon } from "../../Common/Svgs/FinanceIcon";
import ToastMessageModal from "./ToastMessageModal";
import { updateRates } from "../../Redux/Slices/rates.slice";
import CommodityListHeader from "./CommodityListHeader";
import DeleteCommodityModal from "./DeleteCommodityModal";
import { getCommodities } from "../../Redux/APIs/ratesAPI";
import useDebounce from "../../Core/hooks/useDebounce";
import { getTableContainerHeight } from "../../Utils/getTableContainerHeight";
import { getAllLocationsV3 } from "../../Redux/APIs/locationsAPI";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";

const LOCAL_DEFAULT_LIMIT = 10;

const CommodityList = () => {
  const dispatch = useDispatch();

  const { deleteCommodityModal, showToastMessage, commodityList } = useSelector(
    (state) => state.rates,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const tableContainerRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
  });

  const debounceSearch = useDebounce(search, 500);

  // fetch the commodities along with the filters
  useEffect(() => {
    const filterObj = {
      limit: LOCAL_DEFAULT_LIMIT,
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hubs = filters.hub;

    dispatch(getCommodities(filterObj));
  }, [dispatch, currentPage, debounceSearch, filters]);

  // clear the timeout
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateRates({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );

      return () => clearTimeout(timeout);
    }, 5000);
  }, [dispatch, showToastMessage]);

  // fetch user details and all the locations (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
    dispatch(getAllLocationsV3());
  }, [dispatch]);

  return (
    <CustomLayout pageName="Rates Manager">
      <div>
        <CommodityListHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />

        <div
          className={`h-[calc(100vh-72px-69px-40px)] ${
            commodityList.result.length && "overflow-y-scroll"
          }`}
          ref={tableContainerRef}
        >
          <CustomTable
            // scrollX={0}
            // scrollY="388px"
            disableScroll
            columns={rateColumns}
            data={commodityList.result}
            emptyState={
              <FinanceHoverIcon size={16} className="text-dark-grey-text" />
            }
            emptyStateHeight={getTableContainerHeight(tableContainerRef)}
            rowClassName="group"
            pagination={{
              pageSize: LOCAL_DEFAULT_LIMIT,
              total: commodityList.totalRecords,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              current: currentPage,
              itemRender: (current, type, originalElement) => {
                const lastPage = commodityList.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>

      <DeleteCommodityModal
        visible={deleteCommodityModal}
        onCancel={() =>
          dispatch(updateRates({ key: "deleteCommodityModal", value: false }))
        }
      />

      <ToastMessageModal
        visible={showToastMessage?.visible}
        onCancel={() =>
          dispatch(
            updateRates({
              key: "showToastMessage",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
};

export default CommodityList;
