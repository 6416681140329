import getBatteryStatus from "../../../Utils/getBatteryStatus";

export const batteryPercentageFilters = [
  {
    id: 1,
    value: "0-10%",
  },
  {
    id: 2,
    value: "11-20%",
  },
  {
    id: 3,
    value: "21-30%",
  },
  {
    id: 4,
    value: "31-40%",
  },
  {
    id: 5,
    value: "41-50%",
  },
  {
    id: 6,
    value: "51-60%",
  },
  {
    id: 7,
    value: "61-70%",
  },
  {
    id: 8,
    value: "71-80%",
  },
  {
    id: 9,
    value: "81-90%",
  },
  {
    id: 10,
    value: "91-100%",
  },
];

export const statusFilters = [
  {
    id: 1,
    value: getBatteryStatus(1),
  },
  {
    id: 2,
    value: getBatteryStatus(2),
  },
  {
    id: 3,
    value: getBatteryStatus(3),
  },
  {
    id: 4,
    value: getBatteryStatus(4),
  },
];

export const BATTERY_STATUS = {
  ON_A_TRIP: 1,
  DISPATCHED: 2,
  HUB_STATION: 3,
  SERVICE: 4,
};

export const BATTERY_REQUEST_TYPE = {
  HUB: 1,
  TRIP: 2,
};

export const BATTERY_REQUEST_TRANSFER_TYPE = {
  TRANSFER: 1,
  SWAP: 2,
};

export const BATTERY_REQUEST_STATUS = {
  NO_ACTION: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  COMPLETED: 3,
};

export const BATTERY_REQUEST_DETAILS_STATUS = {
  YET_TO_START: 1,
  CHECK_OUT_COMPLETE: 2,
  CHECK_IN_COMPLETE: 3,
};

export const batteryRequestTypeOptions = [
  {
    id: 1,
    value: "For Hub",
  },
  {
    id: 2,
    value: "For A Trip",
  },
];

export const batteryTransferForTripOptions = [
  {
    id: 1,
    value: "Transfer",
  },
  {
    id: 2,
    value: "Swap",
  },
];
