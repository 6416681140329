import React from "react";

/**
 * @param  {string} color
 * @param  {string} image
 * @param  {string} module
 */

const CustomRoundedImage = ({
  size = "w-9.5 h-9.5",
  image,
  background = "bg-kpi-background",
}) => {
  return (
    <div
      className={`rounded-full flex justify-center items-center ${background} ${size}`}
    >
      <div>{image}</div>
    </div>
  );
};

export default CustomRoundedImage;
