import React from 'react';

const TrashIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14 3.987a67.801 67.801 0 00-6.68-.334c-1.32 0-2.64.067-3.96.2L2 3.987M5.668 3.313l.147-.873C5.92 1.807 6 1.333 7.128 1.333h1.747c1.126 0 1.213.5 1.313 1.114l.147.866M12.567 6.093l-.433 6.714c-.074 1.046-.134 1.86-1.994 1.86H5.86c-1.86 0-1.92-.814-1.993-1.86l-.433-6.714M6.887 11h2.22M6.332 8.333h3.333"
        />
      </svg>
    </span>
  );
};

export const TrashSecondaryIcon = ({ size = 14, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12.25 3.488a59.326 59.326 0 00-5.845-.291c-1.155 0-2.31.058-3.465.175l-1.19.116M4.957 2.9l.128-.765c.094-.554.164-.968 1.15-.968h1.528c.986 0 1.062.437 1.149.974l.128.758"
        />
        <path
          fill="currentColor"
          d="M10.996 5.332l-.38 5.874c-.064.916-.116 1.627-1.744 1.627H5.127c-1.627 0-1.68-.711-1.744-1.627l-.38-5.874"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10.996 5.332l-.38 5.874c-.064.916-.116 1.627-1.744 1.627H5.127c-1.627 0-1.68-.711-1.744-1.627l-.38-5.874M6.027 9.625H7.97M5.543 7.292H8.46"
        />
      </svg>
    </span>
  );
};
export default TrashIcon;
