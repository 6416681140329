/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import GeneralSettings from "./General/GeneralSettings";
import SettingsHeader from "./SettingsHeader";
import { updateLoggedInUser } from "../../Redux/Slices/loggedInUser.slice";

const Settings = () => {
  const dispatch = useDispatch();

  const { toastMessageModal } = useSelector((state) => state.loggedInUser);

  useEffect(() => {
    dispatch(
      updateLoggedInUser({
        key: "settingsTab",
        value: "general",
      }),
    );
  }, []);

  // clear the toast message
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateLoggedInUser({
          key: "toastMessageModal",
          value: { visible: false, title: "", description: "" },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, toastMessageModal]);

  return (
    <CustomLayout pageName="Settings">
      <div className="w-full h-full bg-background-white">
        <SettingsHeader />

        <div className="w-[50%]">
          <GeneralSettings />
        </div>
      </div>
    </CustomLayout>
  );
};

export default Settings;
