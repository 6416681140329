import React from "react";
import { useSelector } from "react-redux";
import { LOCATION_TYPE } from "../../Location/Data";

const useOriginStops = () => {
  const { allLocations } = useSelector((state) => state.locations);

  const hubs = allLocations
    .filter((loc) => loc.type === LOCATION_TYPE.HUB_STATION)
    .map((item) => ({
      key: item.id,
      value: item.name,
      label: (
        <div className="flex items-center px-2 py-2 gap-x-3">
          <p className="text-xs font-semibold text-black capitalize font-aileron">
            {item.name}
          </p>
        </div>
      ),
    }));

  return hubs;
};

export default useOriginStops;
