import React from 'react';

const CalendarIcon = ({ size = 16, className = '' }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="#525252"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M5.334 1.333v2M10.666 1.333v2M2.334 6.06h11.333M14 5.666v5.667c0 2-1 3.333-3.333 3.333H5.333C3 14.666 2 13.333 2 11.333V5.666c0-2 1-3.333 3.333-3.333h5.334C13 2.333 14 3.666 14 5.666z"
        />
        <path
          stroke="#525252"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10.463 9.133h.006M10.463 11.133h.006M7.998 9.133h.006M7.998 11.133h.006M5.529 9.133h.006M5.529 11.133h.006"
        />
      </svg>
    </span>
  );
};

export default CalendarIcon;
