import React from 'react';

const ClockIcon = ({ size = 16, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.665 8A6.67 6.67 0 018 14.667 6.67 6.67 0 011.332 8a6.67 6.67 0 016.667-6.667A6.67 6.67 0 0114.665 8z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10.474 10.12L8.407 8.887c-.36-.214-.653-.727-.653-1.147V5.007"
        />
      </svg>
    </span>
  );
};

export default ClockIcon;
