import { Popover } from 'antd';
import React from 'react';
import CloseIcon from '../../../Common/Svgs/CloseIcon';

const Section = ({ title, content, className }) => (
  <div className="w-[50%]">
    <p className="px-6 py-3 font-normal text-10 bg-background">{title}</p>
    {content.map((item, index) => (
      <p
        key={item}
        className={`px-6 py-3 text-xs font-semibold ${className} ${
          index < content.length ? 'border-b border-light-grey' : ''
        }`}
      >
        {item}
      </p>
    ))}
  </div>
);

const SpeedLimit = () => {
  const [visible, setVisible] = React.useState(false);

  return (
    <div>
      <Popover
        open={visible}
        placement="bottom"
        trigger="click"
        arrow={false}
        onOpenChange={() => setVisible(!visible)}
        content={
          <div className="w-[460px] bg-white rounded-xl p-6 shadow-moreFilters">
            <div className="flex justify-between">
              <p className="text-base font-medium text-black font-poppins">
                Speed Limit
              </p>
              <button type="button" onClick={() => setVisible(false)}>
                <CloseIcon className="w-6 h-6 stroke-dark-gray" />
              </button>
            </div>
            <div className="flex mt-6 font-aileron text-dark-gray">
              <Section
                title="Weight range"
                content={['0 - 50 Kg', '51 - 150 Kg', '> 151 Kg']}
                className="text-black"
              />
              <Section
                title="Max. Speed"
                content={['20 Km/Hr', '30 Km/Hr', '50 Km/Hr']}
              />
            </div>
          </div>
        }
      >
        <button
          type="button"
          className="bg-background py-[9px] px-2 rounded-md flex items-center gap-x-2"
          onClick={() => setVisible(!visible)}
        >
          <img src="/assets/info-circle.svg" alt="Speed Limit" />
          <p className="text-xs font-medium font-poppins text-dark-gray">
            Speed Limit
          </p>
        </button>
      </Popover>
    </div>
  );
};

export default SpeedLimit;
