/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Spin, Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ArrowIcon from "../../../Common/Svgs/ArrowIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import CropModal from "./CropLogoModal";
import { client } from "../../../Utils/axiosClient";

const UploadLogo = ({ uploadLogo, setUploadLogo }) => {
  const [loader, setLoader] = useState(false);

  const handleCustomRequest = async (options) => {
    const { onError, onSuccess, file } = options;

    const filePayload = {
      uid: file?.uid,
      lastModified: file?.lastModified,
      lastModifiedDate: file?.lastModifiedDate,
      name: file?.name,
      size: file?.size,
      type: file?.type,
      webkitRelativePath: file?.webkitRelativePath,
      path: file?.path,
    };

    // console.log(filePayload, "check file");

    const formData = new FormData();

    formData.append("files", filePayload);
    // console.log(formData, "check formdata");

    try {
      setLoader(true);

      const response = await client.post(
        `${process.env.REACT_APP_API_URL}/auth/upload-logo`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      );

      if (response.status === 200) {
        setUploadLogo(response.data.fileUrl);
      }
      onSuccess(response.data);
    } catch (error) {
      onError(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <p className="text-xs font-normal capitalize font-aileron text-dark-gray">
        Your logo
      </p>
      <div className="flex items-start justify-between mt-2">
        {uploadLogo === null ? (
          <div className="w-16 h-16 border border-dashed rounded-full border-dark-gray">
            <div className="flex items-center justify-center w-full h-full text-xs font-medium text-dark-gray font-poppins">
              {loader ? <Spin indicator={<LoadingOutlined />} /> : "Upload"}
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center w-16 h-16 border border-dashed rounded-full border-dark-gray">
            <img
              src={uploadLogo}
              alt="upload logo"
              className="rounded-full w-15 h-15"
            />
          </div>
        )}
        <div className="flex flex-col justify-end">
          <CropModal>
            <Upload
              customRequest={(options) => handleCustomRequest(options)}
              showUploadList={false}
              maxCount={1}
              multiple={false}
              accept="image/png, image/jpeg, image/jpg"
            >
              <button
                type="button"
                className="flex items-center justify-center px-2 py-1 rounded-md gap-x-2 bg-primary"
              >
                <p className="text-xs font-medium text-white capitalize font-poppins">
                  Upload Image
                </p>
                <ArrowIcon className="text-white" />
              </button>
            </Upload>
          </CropModal>

          <button
            type="button"
            className="flex items-center justify-end px-2 py-1 mt-3 rounded-md gap-x-2"
            onClick={() => {
              setLoader(false);
              setUploadLogo(null);
            }}
          >
            <p className="text-xs pt-[1px] font-medium capitalize text-dark-gray font-poppins">
              Delete
            </p>
            <TrashIcon className="text-dark-gray" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadLogo;
