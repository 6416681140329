import React from "react";
import { PARTNER_ROLE } from "../Data";
import TickIcon from "../../../Common/Svgs/TickIcon";
import { AlertSecondaryIcon } from "../../../Common/Svgs/AlertIcon";

export const PartnerTag = ({ role }) => {
  const isFarmer = parseInt(role, 10) === PARTNER_ROLE.FARMER;

  const getStyle = () => {
    if (isFarmer) {
      return "bg-yellow-10 text-yellow max-w-[60px]";
    }

    return "bg-green-10 text-green max-w-[74px]";
  };

  return (
    <div className={`py-1 px-2 rounded-md ${getStyle()}`}>
      <p className="font-poppins text-xs font-medium">
        {isFarmer ? "Farmer" : "Off-Taker"}
      </p>
    </div>
  );
};

export const PaymentStatus = ({ detail }) => {
  const getStyle = () => {
    if (detail.id % 3 === 1) {
      return "bg-green-10 text-green";
    }
    if (detail.id % 3 === 2) {
      return "bg-alert-10 text-alert-red";
    }

    return "bg-green-10 text-green";
  };

  const getIcon = () => {
    if (detail.id % 3 === 1) {
      return <TickIcon size={16} className="text-green" />;
    }
    if (detail.id % 3 === 2) {
      return <AlertSecondaryIcon size={16} className="text-alert-red" />;
    }

    return <TickIcon size={16} className="text-green" />;
  };
  const getText = () => {
    if (detail.id % 3 === 1) {
      return "Paid";
    }
    if (detail.id % 3 === 2) {
      return "Due";
    }

    return "Collected";
  };

  return (
    <div
      className={`flex items-center gap-x-2 px-2 py-1 max-w-max font-poppins text-xs font-medium capitalize rounded-md ${getStyle()}`}
    >
      {getIcon()}
      {getText()}
    </div>
  );
};
