import React from 'react';
import CustomTags from '../../../Core/Components/CustomTags';
import CollectionPointIcon from '../../../Common/Svgs/CollectionPointIcon';
import { HomeKPIInventoryIcon } from '../../../Common/Svgs/InventoryIcon';
import AggregationPointIcon from '../../../Common/Svgs/AggregationPointIcon';
import {
  AGGREGATION_ACTIVITY_ID,
  COLLECTION_ACTIVITY_ID,
  DELIVERY_ACTIVITY_ID,
} from '../Data/TripConstants';
import CloseIcon from '../../../Common/Svgs/CloseIcon';

const getActivityTag = (activity, cargo) => {
  switch (activity) {
    case COLLECTION_ACTIVITY_ID:
      return (
        <CustomTags colors="collectionPoint" background="collectionPoint">
          <div className="flex items-center justify-center w-full h-full gap-x-2">
            <CollectionPointIcon />
            <p className="text-xs font-semibold capitalize font-aileron">
              Collection {cargo ? ` : ${cargo}` : null}
            </p>
          </div>
        </CustomTags>
      );
    case DELIVERY_ACTIVITY_ID:
      return (
        <CustomTags colors="deliveryPoint" background="deliveryPoint">
          <div className="flex items-center justify-center w-full h-full gap-x-2">
            <HomeKPIInventoryIcon className="text-user-pink" />
            <p className="text-xs font-semibold capitalize font-aileron">
              Delivery {cargo ? ` : ${cargo}` : null}
            </p>
          </div>
        </CustomTags>
      );
    case AGGREGATION_ACTIVITY_ID:
      return (
        <CustomTags colors="aggregationPoint" background="aggregationPoint">
          <div className="flex items-center justify-center w-full h-full gap-x-2">
            <AggregationPointIcon />
            <p className="text-xs font-semibold capitalize font-aileron">
              Aggregation {cargo ? ` : ${cargo}` : null}
            </p>
          </div>
        </CustomTags>
      );
    default:
      return (
        <div className="text-xs font-semibold text-black font-aileron">
          None
        </div>
      );
  }
};

export const getActivityTagWithDelete = (activity, cargo) => {
  switch (activity) {
    case COLLECTION_ACTIVITY_ID:
      return (
        <CustomTags colors="collectionPoint" background="collectionPoint">
          <div className="flex items-center justify-center w-full h-full cursor-pointer gap-x-2">
            <CollectionPointIcon />
            <p className="text-xs font-semibold capitalize font-aileron">
              Collection {cargo ? ` : ${cargo}` : null}
            </p>
            <CloseIcon className="w-4 h-4 stroke-purple" />
          </div>
        </CustomTags>
      );
    case DELIVERY_ACTIVITY_ID:
      return (
        <CustomTags colors="deliveryPoint" background="deliveryPoint">
          <div className="flex items-center justify-center w-full h-full cursor-pointer gap-x-2">
            <HomeKPIInventoryIcon className="text-user-pink" />
            <p className="text-xs font-semibold capitalize font-aileron">
              Delivery {cargo ? ` : ${cargo}` : null}
            </p>
            <CloseIcon className="w-4 h-4 stroke-user-pink" />
          </div>
        </CustomTags>
      );
    case AGGREGATION_ACTIVITY_ID:
      return (
        <CustomTags colors="aggregationPoint" background="aggregationPoint">
          <div className="flex items-center justify-center w-full h-full cursor-pointer gap-x-2">
            <AggregationPointIcon />
            <p className="text-xs font-semibold capitalize font-aileron">
              Aggregation {cargo ? ` : ${cargo}` : null}
            </p>
            <CloseIcon className="w-4 h-4 stroke-dark-gray" />
          </div>
        </CustomTags>
      );
    default:
      return (
        <div className="text-xs font-semibold font-aileron text-dark-gray">
          None
        </div>
      );
  }
};

export default getActivityTag;
